import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import userService from '../../Services/user/user.service';
import { Button } from '@mui/material';
import ReportServices from '../../Services/reportservices';
import ReportUtils from '../../utills/users/reports';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import jsPDF from 'jspdf';
import ReportsFilesmanagement from '../shared/downloadandprint';
import { Helmet } from 'react-helmet';


interface Doctor {
  PhoneNumber: string;
  EmailId: string;
  Report: string;
  id: string;
  FirstName: string;
  LastName: string;
}

const Reports = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [doctorList, setDoctorList] = useState<Doctor[]>([]);
  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [reports, setReports] = useState([]);
  const [viewFiles, setViewFiles] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [fileTypeList, setFileTypeList] = useState([]);

  async function ProvInfo() {
    const patients = await userService().ListDoctor("Patient");
    const appontments = await userService().ListPatient();
    const listOfReports = await ReportServices().reportsList();
    const fileTypes: any = await ReportServices().reportTypeList();
    setFileTypeList(fileTypes);
    console.log("fileTypeList", fileTypes);

    const filteredReports: any = listOfReports?.filter(report =>
      appontments?.map((appontment: any) => appontment?.id)?.includes(report?.appointmentID)
    );
    // setReports(filteredReports)
    function getPatientName(report: any, patients: any, appointments: any) {
      let patientName = '';

      if (report?.patientID) {
        const patient = patients.find((patient: any) => patient?.id === report?.patientID);
        if (patient) {
          patientName = patient?.FirstName + ' ' + patient?.LastName;
        }
      }

      if (!patientName && report?.appointmentID) {
        const appointment = appointments?.find((app: any) => app?.id === report?.appointmentID);
        if (appointment) {
          patientName = appointment?.PatientName;
          // }
        }
      }
      return patientName;
    }

    const reportsWithPatientNames = listOfReports?.map(report => ({
      ...report,
      patientName: getPatientName(report, patients, appontments)
    }));

    const combinedFiles: any = {};
    const combinedReportsByAppointment: any = {};
    reportsWithPatientNames?.forEach((report: any) => {
      const { appointmentID, patientID, patientName, patientEmail, patientPhoneNumber, updatedBy } = report;

      // Initialize combined report if it doesn't exist for this appointmentID
      if (!combinedReportsByAppointment[appointmentID]) {
        combinedReportsByAppointment[appointmentID] = {
          id: `combined-report-${appointmentID}`,
          patientID,
          appointmentID,
          updatedBy,
          patientName,
          patientEmail,
          patientPhoneNumber,
          doctorID: null,
          Status: "1",
          file: {},
          type: "reports",
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          __typename: "Reports"
        };
      }
      report?.file?.forEach((file: any) => {
        const { fileType, name } = file;

        // Initialize fileType group if it doesn't exist
        if (!combinedReportsByAppointment[appointmentID].file[fileType]) {
          combinedReportsByAppointment[appointmentID].file[fileType] = {
            fileType,
            names: []
          };
        }

        // Combine file names
        combinedReportsByAppointment[appointmentID].file[fileType].names.push(...name);
      });
    });
    Object.keys(combinedReportsByAppointment).forEach(appointmentID => {
      const combinedReport = combinedReportsByAppointment[appointmentID];
      combinedReport.file = Object.values(combinedReport?.file);
    });

    const combinedReportList: any = Object.values(combinedReportsByAppointment);
    // console.log("combinedReportList", combinedReportList);

    const mergeAppointments = (appointments: any[]) => {
      const mergedMap = new Map();

      appointments?.forEach((appointment) => {
        if (!mergedMap.has(appointment?.patientPhoneNumber)) {
          // Initialize the map entry with the current appointment
          mergedMap.set(appointment?.patientPhoneNumber, {
            ...appointment,
            appointments: [appointment]
          });
        } else {
          // Merge the current appointment with the existing one
          const existingEntry = mergedMap.get(appointment?.patientPhoneNumber);
          existingEntry?.appointments.push(appointment);
          mergedMap.set(appointment?.patientPhoneNumber, existingEntry);
        }
      });

      return Array.from(mergedMap.values())?.map((entry) => {
        // Combine the appointment details as needed
        return {
          ...entry,
          appointments: entry?.appointments
        };
      });
    };
    const uniquePatientList = mergeAppointments(combinedReportList);
    console.log("combinedReportList", combinedReportList);
    console.log("uniquePatientList", uniquePatientList);

    // Update the state with the combined report list
    setDoctorList(combinedReportList);

  }

  useEffect(() => {


    if (!logUserObject?.accessToken) {
      navigate('/login');
    }
  }, [navigate]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % viewFiles?.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + viewFiles?.length) % viewFiles?.length);
  };


  useEffect(() => {
    ProvInfo();
  }, []);

  const getFileUrl = (fileName: any) => {
    const baseUrl = process.env.REACT_APP_S3_BUCKET_FETCH;
    const file = `${baseUrl}/images/${fileName}`;
    return file;
  };

  const handleDelete = async (doctor: any) => {
    const confirmBox = window.confirm("Are you sure you want to delete the selected report?");
    if (confirmBox) {
      const result = await ReportUtils().reportDelete(doctor);
      if (result) {
        ProvInfo();
      }
      // Remove the doctor from the list
      // setDoctorList(doctorList.filter(doctor => doctor?.id !== id));
    } else {
      alert('Deletion canceled.');
    }
  };

  const handleDownload = async (doctor: any) => {
    const zip = new JSZip();

    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const folderName = `reports_${timestamp}`;
    const folder: any = zip.folder(folderName);

    try {
      for (const file of doctor?.file) {
        const fileNames = Array.isArray(file?.names) ? file?.names : [file?.names];

        for (const fileName of fileNames) {
          try {
            const fileUrl = getFileUrl(fileName);
            const response = await fetch(fileUrl);

            if (!response?.ok) {
              console.error(`Failed to fetch file: ${fileName}. Status: ${response.status}`);
              continue;
            }

            const fileBlob = await response.blob();

            if (!fileBlob.size) {
              console.error(`Empty file received: ${fileName}`);
              continue;
            }

            // Determine MIME type
            let mimeType = fileBlob.type;
            if (mimeType === "application/octet-stream") {
              const extension = fileName.split('.').pop().toLowerCase();
              switch (extension) {
                case 'jpg':
                case 'jpeg':
                  mimeType = 'image/jpeg';
                  break;
                case 'png':
                  mimeType = 'image/png';
                  break;
                default:
                  mimeType = 'application/octet-stream';
              }
            }

            // Create new Blob with the correct MIME type
            const correctBlob = new Blob([fileBlob], { type: mimeType });
            // Add file to zip
            folder?.file(fileName, correctBlob, { binary: true });
          } catch (fetchError) {
            console.error(`Error processing file: ${fileName}`, fetchError);
          }
        }
      }

      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `${folderName}.zip`);
    } catch (error) {
      console.error('Error while downloading files:', error);
    }
  }


  const highlightText = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text?.split(regex);
    return parts?.map((part, index) => (regex.test(part) ? <span key={index} className="highlighted-text">{part}</span> : part));
  };

  const filteredData = doctorList?.filter((doctor: Doctor) =>
    Object?.values(doctor)?.some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredData?.length / pageSize);
  const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleSearch = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    setSearchTerm('');
  }, [currentPage]);

  async function handleEdit(event: any) {
    navigate('/addReports', { state: { appointmentID: event?.appointmentID } });
  }
  //working code
  const handleView = async (doctor: any) => {
    setCurrentIndex(0);
    const singleReview = await ReportServices().reportsListBypatientID(doctor?.appointmentID);
    console.log("singleReview", singleReview);

    const combinedFiles: any = [];

    singleReview?.forEach((report: any) => {
      report?.file?.forEach((fileItem: any) => {
        const { fileType, name, description, type } = fileItem;

        if (type === "upload" && name?.length > 0) {
          name.forEach((fileName: any) => {
            combinedFiles.push({
              fileType,
              name: fileName,
              description: "",  // No description for upload type
              date: report?.createdAt
            });
          });
        } else if (type === "written" && description) {
          combinedFiles.push({
            fileType,
            name: null,  // No name for written type
            description,
            date: report?.createdAt
          });
        }
      });
    });

    setViewFiles(combinedFiles);
    setIsModalOpen(true);
  }


  // const handleView = async (doctor: any) => {
  //   // console.log("doctor", doctor);
  //   setCurrentIndex(0);
  //   const singleReview = await ReportServices().reportsListBypatientID(doctor?.appointmentID);
  //   console.log("singleReview", singleReview);
  //   const combinedFiles: any = [];
  //   let fileTypeMap: any;

  //   singleReview?.forEach((report: any) => {
  //     report?.file?.forEach((fileItem: any) => {
  //       const { fileType, name, description } = fileItem;
  //       console.log("fileItem", fileItem);

  //       if (!fileTypeMap[fileType]) {
  //         fileTypeMap[fileType] = { names: new Set(), description: description };
  //       }

  //       name.forEach((fileName: any) => {
  //         fileTypeMap[fileType].add(fileName);
  //       });
  //     });
  //   });
  //   // console.log("fileTypeMap", fileTypeMap);
  //   Object.keys(fileTypeMap).forEach(fileType => {
  //     combinedFiles.push({
  //       fileType: fileType,
  //       names: Array.from(fileTypeMap[fileType]),
  //       description: fileTypeMap[fileType].description
  //     });
  //   });

  //   // return combinedFiles;
  //   // };
  //   const allNames: any = [];
  //   combinedFiles?.forEach((fileGroup: any) => {
  //     fileGroup.names.forEach((name: any) => {
  //       allNames.push({ name, description: fileGroup?.description });
  //     });
  //   });
  //   // return allNames;
  //   setViewFiles(allNames);
  //   setIsModalOpen(true);
  //   // setModalHeading("hiiii");
  //   // document.body.style.overflow = 'hidden';
  // }

  const handleViewHistory = async (event: any) => {
    console.log("event", event);
    navigate('/appointmentHistory', { state: { patientID: event?.patientID, PhoneNumber: event?.patientPhoneNumber } });
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setModalHeading("");
  };

  // const singleDownload: any = async (single: any, action: any) => {
  //   console.log("single", single);
  //   let fileUrl: any;
  //   let description: any
  //   if (single?.name) {
  //     fileUrl = `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${single?.name}`;
  //   } else if (single?.description) {
  //     const div = document.createElement("div");
  //     div.innerHTML = single.description;
  //     description = div.textContent || div.innerText || "";
  //   }
  //   const fileExtension = fileUrl?.split('.').pop().toLowerCase();
  //   console.log("description", description);
  //   const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
  //   const isPDF = fileExtension === 'pdf';

  //   if (action === 'download') {
  //     if (fileUrl) {
  //       const link = document.createElement('a');
  //       link.href = fileUrl;
  //       link.download = fileUrl; // You can set a custom file name here
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else if (description) {
  //       const doc = new jsPDF();
  //       const lines = doc.splitTextToSize(description, 180);
  //       doc.text(lines, 10, 10);
  //       doc.save('description.pdf');
  //     } else {
  //       console.error('No file or description to download');
  //     }
  //   } else if (action === 'print') {
  //     const printWindow: Window | null = window.open('', '_blank');
  //     console.log("printWindow", printWindow);

  //     if (printWindow) {
  //       printWindow.document.open();
  //       if (isImage && fileUrl) {
  //         printWindow.document.write(`
  //                   <!DOCTYPE html>
  //                   <html>
  //                   <head>
  //                       <title>Print</title>
  //                   </head>
  //                   <body>
  //                       <img src="${fileUrl}" style="width: 100%; height: auto;" />
  //                       <script>
  //                           window.onload = function() {
  //                               setTimeout(function() {
  //                                   window.print();
  //                                   window.close();
  //                               }, 1000);
  //                           };
  //                       </script>
  //                   </body>
  //                   </html>
  //               `);
  //       } else if (isPDF && fileUrl) {
  //         printWindow.document.write(`
  //           <!DOCTYPE html>
  //           <html>
  //           <head>
  //               <title>Print</title>
  //               <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.min.js"></script>
  //               <style>
  //                   body { margin: 0; }
  //                   canvas { display: block; margin-bottom: 10px; }
  //               </style>
  //           </head>
  //           <body>
  //               <div id="pdf-container"></div>
  //               <script>
  //                   const loadingTask = pdfjsLib.getDocument('${fileUrl}');
  //                   loadingTask.promise.then(function(pdf) {
  //                       for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
  //                           pdf.getPage(pageNum).then(function(page) {
  //                               const viewport = page.getViewport({ scale: 1.5 });
  //                               const canvas = document.createElement('canvas');
  //                               const context = canvas.getContext('2d');
  //                               canvas.height = viewport.height;
  //                               canvas.width = viewport.width;
  //                               document.getElementById('pdf-container').appendChild(canvas);

  //                               const renderContext = {
  //                                   canvasContext: context,
  //                                   viewport: viewport
  //                               };
  //                               page.render(renderContext).promise.then(function() {
  //                                   if (pageNum === pdf.numPages) {
  //                                       setTimeout(function() {
  //                                           window.print();
  //                                           window.close();
  //                                       }, 1000);
  //                                   }
  //                               });
  //                           });
  //                       }
  //                   });
  //               </script>
  //           </body>
  //           </html>
  //         `);
  //       } else if (description) {
  //         const doc = new jsPDF();
  //         const lines = doc.splitTextToSize(description, 180);
  //         doc.text(lines, 10, 10);
  //         const pdfBlob = doc.output('blob');

  //         const reader = new FileReader();
  //         reader.onload = function (event) {
  //           const pdfDataUrl = event?.target?.result;
  //           printWindow.document.write(`
  //           <!DOCTYPE html>
  //           <html>
  //           <head>
  //               <title>Print</title>
  //           </head>
  //           <body>
  //               <iframe src="${pdfDataUrl}" style="width: 100%; height: 100%; border: none;"></iframe>
  //               <script>
  //                   window.onload = function() {
  //                       setTimeout(function() {
  //                           window.print();
  //                           window.close();
  //                       }, 1000);
  //                   };
  //               </script>
  //           </body>
  //           </html>
  //         `);
  //           printWindow.document.close();
  //         };
  //         reader.readAsDataURL(pdfBlob);
  //       } else {
  //         console.error('Unsupported file type');
  //         printWindow.close();
  //       }
  //       printWindow.document.close();
  //     } else {
  //       console.error('Failed to open print window');
  //     }
  //   }
  // };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const renderFile = (file: any) => {
    const { name, description, fileType, date } = file;
    console.log("file", file);
    const formattedDate = ReportsFilesmanagement().formatDate(file?.date);
    console.log("formattedDate", formattedDate);
    const fileType1: any = fileTypeList?.find((type: any) => type.id === fileType);
    if (name) {
      const fileExtension = file?.name?.split('.').pop().toLowerCase();

      return (
        <div>
          {fileExtension === 'pdf' ? (
            <><div style={{ float: "left" }}>
              <strong>ReportType:</strong> {fileType1?.name}<br />
              <strong style={{ marginLeft: "40px" }}>Report Date:</strong> {formattedDate}
            </div>
              {/* //  return ( */}
              <>

                <div className='clrbth'>


                  {/* <Document file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${file}`}>
    
             <Page pageNumber={1} />
    
             </Document> */}

                  <Document
                    file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="pdf-viewer"
                  >
                    {Array.from(
                      new Array(numPages),
                      (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                      )
                    )}
                  </Document>
                  {/* <div>{viewFiles[currentIndex]}</div> */}
                </div>
                <div className='clrbth'>
                  <button className="nav-button" onClick={handlePrev}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <button className="nav-button" onClick={handleNext}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>

              </></>
            // );
          ) : (

            <>
              <div style={{ float: "left" }}>
                <strong>ReportType:</strong> {fileType1?.name}<br />
                <strong style={{ marginLeft: "40px" }}>Report Date:</strong> {formattedDate}
              </div>
              <div className='clrbth' >
                <img
                  key={file}
                  src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`
                  }
                  alt={file}
                  className='img-viewer'
                />
              </div>
              < div className='clrbth' >
                <button className="nav-button" onClick={handlePrev} >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                < button className="nav-button" onClick={handleNext} >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>

            </>
          )}
        </div>
      )
    } else if (description) {
      return (
        <>
          <div style={{ float: "left" }}>
            <strong>ReportType:</strong> {fileType1?.name}<br />
            <strong style={{ marginLeft: "40px" }}>Report Date:</strong> {formattedDate}
          </div>
          <div className='clrbth pdf-viewer'>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div><div className='clrbth'>

            <button className="nav-button" onClick={handlePrev}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button className="nav-button" onClick={handleNext}>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </>
      )
      // return (
      //   <><button className="nav-button" onClick={handlePrev}>
      //     <FontAwesomeIcon icon={faArrowLeft} />
      //   </button><div>
      //       <p dangerouslySetInnerHTML={{ __html: description }} />
      //     </div><button className="nav-button" onClick={handleNext}>
      //       <FontAwesomeIcon icon={faArrowRight} />
      //     </button></>
      // );
    } else {
      return <div>No content available</div>;
    }


  };

  return (
    <>
      <Helmet>
        <title>Reports</title>
        <meta name="description" content="This is the Reports page description" />
      </Helmet>
      <section className="admin-section">
        <div
          className="slider-item bread-item"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h1>
                  Reports
                </h1>

              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <ul className="paginationbanner">
          <li>
          <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
          </li>
          <li>Reports</li>
        </ul>
      </div>
      <div className="container mt-50">
        <div className="row">
          {/* <div className="col-md-2">
            <Sidebar />
          </div> */}
          <div className="col-md-12" >
            <div className="row">
              <div className="col-md-8" >
                {/* <h1 className="mt-100 textAlign">Report List</h1> */}
                <div className="button-holder">
                </div>
              </div>
              <div className="col-md-3 textAlign">

              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mt-50">
              <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="page-size-dropdown">
                  <label style={{ padding: '5px' }}>Show</label>
                  <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label style={{ padding: '5px' }}>entries</label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                  <p style={{ marginRight: '10px' }}>Search</p>
                  <div>
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e?.target?.value)}
                      className="search-bar"
                    />
                  </div>
                </div>
              </div>
            </div>
            <table className="table table-striped table-responsive">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Patient Name</th>
                  <th>Phone Number</th>
                  <th>Email Id</th>
                  {/* <th>View Reports</th> */}
                  <th>Actions</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {paginatedData?.map((doctor: any, index: number) => (
                  <tr key={doctor?.id} className="highlighted-row">
                    <td>{(currentPage - 1) * pageSize + index + 1}</td>
                    <td>{highlightText(doctor?.patientName)}</td>
                    <td>{highlightText(doctor?.patientPhoneNumber)}</td>
                    <td>{highlightText(doctor?.patientEmail)}</td>
                    <td>
                      <i className="fas fa-eye" title="View Reports" style={{ color: 'blue', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleView(doctor)}></i>
                      {/* <i className="fas fa-eye" title="View Reports" style={{ color: 'blue', paddingRight: '20px', cursor: 'pointer' }} onClick={() => ReportsFilesmanagement().handleView(doctor, setCurrentIndex, setViewFiles, setIsModalOpen, "reports")}></i> */}
                      <i className="fas fa-eye" title="View Reports History" style={{ color: 'blue', paddingRight: '20px', cursor: 'pointer' }}
                        onClick={() => handleViewHistory(doctor)}
                      // startIcon={<VisibilityIcon style={{ color: 'green' }} />}
                      >
                      </i>
                      {/* <i className="fas fa-edit" title="Edit" style={{ color: 'green' }} onClick={() => handleEdit(doctor)}></i>
                      <i className="fas fa-trash" title="Delete" style={{ marginLeft: '20px', paddingRight: '20px', color: 'red' }} onClick={() => handleDelete(doctor)}></i> */}
                      <i className="fas fa-download" title="download" style={{ color: 'blue', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleDownload(doctor)}></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ marginBottom: '40px' }}>
              Showing {((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

              <div style={{ display: 'flex', justifyContent: 'right', float: 'right' }}>
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  variant="contained"
                  style={{ marginRight: '10px' }}
                >
                  Previous
                </Button>
                <div className="pageNumb">{currentPage}</div>
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  variant="contained"
                  style={{ marginLeft: '10px' }}
                >
                  Next
                </Button>
              </div>
            </div>

            <div className={`modal ${isModalOpen ? "open" : ""}`}>
              <div className="modal-content2" style={{ marginTop: '100px', height: '550px', width: '800px' }}>
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "download")}><FontAwesomeIcon style={{ color: 'white' }} icon={faDownload} /></button>
                <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "print")}><FontAwesomeIcon style={{ color: 'white' }} icon={faPrint} /></button>
                {/* <h6 className="text-center">Review By {modalHeading}</h6> */}
                <div style={{ textAlign: 'center' }}>
                  {viewFiles?.length > 0 ?
                    renderFile(viewFiles[currentIndex])
                    : "No files to view"}
                  <div className="thumbnail-container">
                    {viewFiles?.map((file: any, index: number) => {
                      const extension = file?.name?.split('.').pop().toLowerCase();
                      // console.log('extension', extension),
                      return (
                        <img
                          key={index}
                          className={`thumb-view thumbnail ${currentIndex === index ? 'active' : ''}`}
                          src={(extension === "pdf" || !file?.name) ? 'images/pdf-thumbnail.png' : getFileUrl(file?.name)}
                          alt={`Thumbnail ${index + 1}`}
                          onClick={() => setCurrentIndex(index)}
                        />
                      )
                    })}
                  </div>
                  {/* <button className="nav-button" onClick={handlePrev}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <img
                    key={viewFiles[currentIndex]}
                    src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${viewFiles[currentIndex]}`}
                    alt={viewFiles[currentIndex]}
                    style={{
                      width: '460px',
                      height: '360px',
                      margin: '10px',
                      borderRadius: '8px',
                      objectFit: 'cover'
                    }}
                  />
                  <button className="nav-button" onClick={handleNext}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                  <div>{viewFiles[currentIndex]}</div>
                 
                  <div className="thumbnail-container">
                    {viewFiles.map((file: any, index: number) => (
                      <img
                        key={index}
                        className={`thumbnail ${currentIndex === index ? 'active' : ''}`}
                        src={getFileUrl(file)}
                        alt={`Thumbnail ${index + 1}`}
                        onClick={() => setCurrentIndex(index)}
                        style={{
                          width: '70px',
                          height: '70px',
                          margin: '10px',
                          borderRadius: '8px',
                          objectFit: 'cover'
                        }} />
                    ))}
                  </div> 
                  */}
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  );
};

export default Reports;