import React, { useState, useEffect } from "react";
import { FieldArray, Field, useFormikContext, ErrorMessage } from 'formik';
import FileType from './fileType';
import { handleRemoveFile, handleRemoveFileUpload, ReportsFileUpload, updDelFile } from '../shared/fileupload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditorComponent from '../../utills/CKEditor';

const FileInput = ({ setFieldValue, values }: any) => {
    const [text, setText] = useState('');

    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);

    const handleChange = (e: any) => {
        setText(e?.target?.value);
    };

    const handleAddFileUpload = () => {

        setFieldValue('file', [...values?.file, { fileType: '', name: [], type: '', description: '' }]);

    }
    function formatFileSize(bytes: any) {
        const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 bytes';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        const convertedSize = (bytes / Math.pow(1024, i)).toFixed(2);
        return `${convertedSize} ${sizes[i]}`;
    }
    const truncateText = (text: any, length: any) => {
        if (text?.length <= length) return text;
        return text?.slice(0, length) + '...';
    };

    return (
        <><FieldArray name="file">
            {({ insert, push }) => (
                <div>
                    {values?.type === "prescriptions" && (
                        <><label htmlFor="foodDiet" className="form-label">Food Diet</label><div className="ckeditor-container" style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Field name="foodDiet">
                                {({ field, form }: any) => (
                                    // <CKEditor
                                    //     editor={ClassicEditor}
                                    //     data={field?.value || ''}
                                    //     onChange={(event, editor) => {
                                    //         form?.setFieldValue("foodDiet", editor?.getData());
                                    //     }} />

                                    <CKEditorComponent
                                        data={field?.value || ''}
                                        onDataChange={(newContent: string) => setFieldValue(`foodDiet`, newContent)}
                                        setFieldValue={setFieldValue}
                                        disableLink={false}
                                    />
                                )}
                            </Field>
                        </div></>
                    )}
                    {values?.file?.map((fileItem: any, index: number) => (
                        console.log('index', index),
                        <div key={index}>

                            <div className="mb-2"  >
                                <label htmlFor="type" className="form-label">{values?.type === "reports" ? "Reports Upload Type*" : "Prescription Upload Type*"}</label>
                                <div className="d-flex align-items-center">
                                    <div style={{ display: 'flex' }}>
                                        <div className="form-check me-3" style={{ display: 'flex', marginRight: '20px' }}>
                                            <Field
                                                type="radio"
                                                name={`file[${index}].type`}
                                                value="written"
                                                className="form-check-input"
                                                id={`written-${index}`}
                                                onClick={() => {
                                                    setFieldValue(`file[${index}].type`, "written");
                                                }}
                                                disabled={values?.id && fileItem?.data === 'existed' ? true : false}
                                                // disabled={values?.id && `file[${index}].description` !== null ? true : false}
                                                style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                                            <label className="form-check-label ms-2" htmlFor="written">
                                                By Written
                                            </label>
                                        </div>
                                        <div className="form-check" style={{ display: 'flex' }}>
                                            <Field
                                                type="radio"
                                                name={`file[${index}].type`}
                                                value="upload"
                                                className="form-check-input"
                                                id={`upload-${index}`}
                                                onClick={() => {
                                                    setFieldValue(`file[${index}].type`, "upload");
                                                }}
                                                disabled={values?.id && fileItem?.data === 'existed' ? true : false}
                                                // disabled={values?.id && `file[${index}].name`.length > 0 ? true : false}
                                                // disabled={fileItem?.type ? true : false}
                                                style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                                            <label className="form-check-label ms-2" htmlFor="upload">
                                                Upload
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ErrorMessage name={`file[${index}].type`} component="div" className="text-danger" />
                            </div>
                            <FileType
                                setselectedYear={(year: any) => {
                                    setFieldValue(`file[${index}].fileType`, year ? year?.value : fileItem?.fileType);
                                }}
                                index={index}
                                label={values?.type === "reports" ? `Report Type ${index + 1}*` : `Prescription Type ${index + 1}*`}
                                selectedYear={fileItem?.fileType} />
                            <ErrorMessage name={`file[${index}].fileType`} component="div" className="text-danger" />

                            {fileItem?.type === 'upload' ? (
                                <>
                                    <label>
                                        <ReportsFileUpload
                                            label={values?.type === "reports" ? "Choose reports*" : "Choose prescriptions*"}
                                            Files={fileItem?.name}
                                            setFile={(fileList: any) => setFieldValue(`file[${index}].name`, fileList)} />
                                    </label>

                                    <div className="file-list  filesize">
                                        {fileItem?.name?.map((file: any, fileIndex: number) => {

                                            const selectedFileSize = (file?.size);
                                            // Convert 500 KB to bytes
                                            const maxSizeInBytes = 500 * 1024;
                                            console.log({ selectedFileSize })

                                            const isSizeExceeded = selectedFileSize > maxSizeInBytes;

                                            return (

                                                <div className='fileReports' style={{ height: 'auto' }} key={file[fileIndex]}>
                                                    <div className='mx-2 py-1 px-2 bg-secondary text-white' style={{
                                                        border: isSizeExceeded ? '2px solid red' : ''
                                                    }}>
                                                        <>
                                                            <span dangerouslySetInnerHTML={{ __html: truncateText(file?.name ? file?.name : file, 30) }} /> ({formatFileSize(file?.size)})
                                                        </>

                                                    </div>
                                                    {/* {(logUserObject?.Role === "MasterAdmin" || logUserObject?.Role === "Doctor") ? */}
                                                    <button
                                                        type="button"
                                                        className="btn btn-link text-danger ml-2 rmvBtn"
                                                        onClick={() => handleRemoveFile(index, fileIndex, values, setFieldValue, file, values?.type)}
                                                        style={{
                                                            border: isSizeExceeded ? '2px solid red' : ''
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </button>
                                                    {/* //     : null
                                                // } */}
                                                </div>
                                            )
                                        })}
                                    </div><ErrorMessage name={`file[${index}].name`} component="div" className="text-danger" />
                                </>
                            ) : fileItem?.type === 'written' ? (
                                <div className="ckeditor-container" style={{ marginBottom: '10px' }}>
                                    <label htmlFor="type" className="form-label">Description*</label>
                                    {/* <textarea
                                    name="description"
                                        value={text}
                                        onChange={handleChange}
                                        rows={10}
                                        cols={50}
                                        placeholder="Start typing here..."
                                        style={{ fontSize: '16px', padding: '10px', width: '100%' }}
                                    /> */}
                                    <Field name={`file[${index}].description`}>
                                        {({ field, form }: any) => (
                                            // <CKEditor
                                            //     editor={ClassicEditor}
                                            //     data={field?.value || ''}
                                            //     onChange={(event, editor) => {
                                            //         form?.setFieldValue(`file[${index}].description`, editor?.getData());
                                            //     }} />
                                            <CKEditorComponent
                                                data={field?.value || ''}
                                                onDataChange={(newContent: string) => setFieldValue(`file[${index}].description`, newContent)}
                                                setFieldValue={setFieldValue}
                                                disableLink={false}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name={`file[${index}].description`} component="div" className="text-danger" />
                                </div>
                            ) : null}
                            <label>
                            </label>
                            {/* {index > 0 && ( */}
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleRemoveFileUpload(index, values, setFieldValue)}
                            >
                                Remove
                            </button>
                            {/* )} */}
                        </div>
                    ))}

                </div>
            )}
        </FieldArray>
            <button type="button" className="btn btn-primary" onClick={() => handleAddFileUpload()} >
                Add
            </button></>
    );
};

export default FileInput;

