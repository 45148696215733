import React, { Suspense, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import userService from "../../Services/user/user.service";
import './doctors.css';
import pages from "../../utills/pageUtils";
import getFAQs from "../../Services/FAQ";
import { Helmet } from 'react-helmet';
import Loader from "../shared/Loader";
import LazyLoad from "react-lazyload";

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

const Doctor: React.FC<compProps> = ({ title, description, keywords }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const faqRef = useRef<HTMLDivElement>(null);
  const doctorRef = useRef<HTMLDivElement>(null);
  const [doctorList, setDoctorList] = useState<any[]>([]);
  const [pageData, setPageData] = useState<{ banner: any; title: string; description: string } | null>(null);
  const [faqItems, setFAQItems] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const doctorsPerPage = 6; // Number of doctors to display per page

  const { slug } = useParams();

  const handleReadMore = (slug: any) => {
    navigate(`/Doctors/${slug}`);
  };

  const truncateText = (text: any, length: any) => {
    if (text?.length <= length) return text;
    return text?.slice(0, length) + '...';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await pages(location?.pathname);

        if (result && result?.length > 0) {
          const { Banner, Title, Description } = result[0];  // Directly use the first item if it's relevant
          setPageData({ banner: Banner, title: Title, description: Description });
        }
      } catch (error) {
        console.error('Error fetching page data:', error);
      }
    };

    if (location?.pathname) {
      fetchData();
    }
  }, [location?.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch doctors and specializations in parallel
        const [response, specializationList] = await Promise.all([
          userService().ActiveDoctors('Doctor'),
          userService().getlistSpecializations()
        ]);

        // Map doctors with their specialization names
        const updatedDoctors = response?.map((item: any) => {
          const specName = specializationList?.find((spec: any) => spec?.id === item?.Specialization);
          return { ...item, Specialization: specName?.name };  // Return a modified copy of the doctor item
        });

        setDoctorList(updatedDoctors);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching doctors:", error);
        setLoading(false);
      }
    };

    const fetchSliderItems = async () => {
      try {
        const response = await getFAQs().ActiveFAQlist();
        setFAQItems(response);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchData();
    fetchSliderItems();
    // window.scrollTo(0, 0);  // If necessary, consider conditions before calling this
  }, []);


  useEffect(() => {
    if (location.hash === "#faq")  {
      setTimeout(() => {
        faqRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 50);
    } else {
      setTimeout(() => {
        doctorRef.current?.scrollIntoView({ behavior: "smooth" });
        window.scrollTo(0, 0);
      }, 0);
    }
  }, [location]);

  // useEffect(() => {
  //   if (location.hash === "#faq" && faqRef.current)  {
  //     setTimeout(() => {
  //       // faqRef.current?.scrollIntoView({ behavior: "smooth" });
  //     }, 0);
  //   } else if (location.hash === "#Doctors") {
  //     setTimeout(() => {
  //       // doctorRef.current?.scrollIntoView({ behavior: "smooth" });
  //     }, 0);
  //   }
  // }, [location]);

  // Pagination logic
  const indexOfLastDoctor = currentPage * doctorsPerPage;
  const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
  const currentDoctors = doctorList?.slice(indexOfFirstDoctor, indexOfLastDoctor);
  const totalPages = Math.ceil(doctorList?.length / doctorsPerPage);

  // Accessing data
  const banner: any = pageData?.banner ? pageData?.banner : null;
  const bannerName: any = pageData?.title ? pageData?.title : null;
  const caption: any = pageData?.description ? pageData?.description : null;

  const paginate = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (doctorRef.current) {
      doctorRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
     
        <>
          <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta name="author" content="Gurram Dental Care" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>

          <section className="home-slider owl-carousel">
            <LazyLoad>
            <div
              className="slider-item bread-item"
              style={{ backgroundImage: banner ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${banner})` : 'images/bg_1.jpg' }}
              data-stellar-background-ratio="0.5"
            >
              <div className="overlay"></div>
              <div className="container" data-scrollax-parent="true">
                <div className="row banner-text align-items-center">
                  <div className="col-md-12 col-sm-12 ftco-animate">
                    <h1 className="banner-ttl">{bannerName}</h1>
                    <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
                  </div>
                </div>
              </div>
            </div>
            </LazyLoad>
          </section>

          <div>
            <ul className="ullist">
              <li>
                <a className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</a>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div>

          <section className="ftco-section" ref={doctorRef} id="Doctors">
            <div className="container">
              <div className="row justify-content-center pb-5">
                <div className="col-md-7 text-center heading-section ftco-animate">
                  <h3 className="mb-3 hfont">Our Dental Experts</h3>
                  <p className="pfont">Experience our serene setting, comprehensive dental care, and personalized treatments.</p>
                </div>
              </div>

              {/* <Suspense fallback={<Loader />}> */}
              {loading ? (
        <Loader />
      ) : (
              

              currentDoctors?.length > 0 ? (
                <div className="ftco-animate">
                  <div className="row">
                    {currentDoctors?.map((member: any) => (
                      <div key={member?.id} className="col-md-4 bx-mb">
                        <div className="staff doctorsimg" style={{ height: '350px' }}>
                          <LazyLoad>
                          <div
                            className="img mb-4"
                            style={{
                              backgroundImage: `url(${member?.userProfile ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${member?.userProfile}` : 'images/profile.jpg'})`,
                            }}
                          ></div>
                          </LazyLoad>
                          <div className="info text-center">
                            <h3 className="hfont">{member?.FirstName}</h3>
                            <p className="splz">{member?.Specialization}</p>
                            <p className="descheight" dangerouslySetInnerHTML={{ __html: truncateText(member?.Description, 80) }} />

                            <div className="text">
                              <ul className="ftco-social">
                                {member?.SocialIcons?.map((social: any, i: any) => (
                                  <li key={i} className="ftco-animate">
                                    <a href={social?.icon} target="_blank" rel="noopener noreferrer">
                                      <span className={`icon-${social?.name?.toLowerCase()}`}></span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div>
                            <center>
                              <button
                                className="btn btn-sm buton"
                                onClick={() => handleReadMore(member?.Slug)}
                                style={{
                                  color: 'blue',
                                  cursor: 'pointer',
                                  marginBottom: '10px',
                                }}
                              >
                                Know More
                              </button>
                            </center>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <span>No doctors found</span>
                </div>
              )
      )
    }

              {/* Pagination Controls */}
              <div className="custom-pagination" style={{ marginTop: '20px' }}>
                <ul className="custom-pagination-list">
                  <li className={`custom-pagination-item ${currentPage === 1 || totalPages === 0 ? 'disabled' : ''}`}>
                    <button 
                      onClick={() => {paginate(currentPage - 1); window.scrollTo({ top: 0, behavior: 'smooth' });}} 
                      disabled={currentPage === 1 || totalPages === 0}
                      className="pagination-button"
                    >
                      Previous
                    </button>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                    <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                      <button 
                        onClick={() => paginate(index + 1)} 
                        disabled={totalPages === 0}
                        className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li className={`custom-pagination-item ${currentPage === totalPages || totalPages === 0 ? 'disabled' : ''}`}>
                    <button 
                      onClick={() => paginate(currentPage + 1)} 
                      disabled={currentPage === totalPages || totalPages === 0}
                      className="pagination-button"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
              {/* </Suspense> */}

            </div>
          </section>

          <section ref={faqRef} className="ftco-section" id="faq">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-7 text-center heading-section ftco-animate">
                  <h3 className="mb-3 headingsize">Frequently Asked Questions</h3>
                  <p className="pfont">Find answers to common questions about our dental services.</p>
                </div>
              </div>
              <div className="row">
                {faqItems?.map((item: any, index: any) => (
                  <div key={index} className="col-md-12 mb-4 ftco-animate">
                    <h4 className="mb-3 questionsize">{item.Title}</h4>
                    <span className="blog-content" dangerouslySetInnerHTML={{ __html: item?.Description }} />
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      
    </>
  );
};

export default Doctor;
