import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import userService from '../../Services/user/user.service';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Sidebar from '../DashBoard/sidebar';
import { updDelFile } from '../shared/fileupload';
import { Helmet } from 'react-helmet';
import './style.css';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';


interface Doctor {
  id: string;
  FirstName: string;
  LastName: string;
}

interface Patient {
  LastName: any;
  FirstName: any;
  id: string;
  PatientName: string;
  EmailId: string;
  PhoneNumber: string;
  Age: any;
  userType: any;
}

const ListRegisteredPatients = () => {
  const initialState: Patient = {
    id: '',
    PatientName: '',
    PhoneNumber: '',
    Age: '',
    EmailId: '',
    LastName: undefined,
    FirstName: undefined,
    userType: ''
  };

  const navigate = useNavigate();
  const [data, setData] = useState<Patient>(initialState);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [patientList, setPatientList] = useState<Patient[]>([]);
  const [doctorList, setDoctorList] = useState<Doctor[]>([]);
  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);
  // const logUserObject:any = useAuthRedirect();
  // useAuthRedirect(logUserObject);



  const getPatients = async () => {
    try {
      //   let response: Patient[] = [];
      const response = await userService().ListDoctor('Patient');
      console.log("response", response);
      setPatientList(response || []);
    } catch (error) {
      console.error("Error fetching patients:", error);
      setPatientList([]);
    }
  };

  useEffect(() => {
    getPatients();
    // getDoctors();
    window.scrollTo(0, 0);
  }, []);

  const handleDelete = async (id: any) => {
    const confirmBox = window.confirm("Are you sure you want to delete the selected patient?");
    if (confirmBox) {
      const user: any = await userService().getuserSingle(id);
      const filedel = await updDelFile(user?.data?.getUser?.userProfile, "images");
      console.log("filedel", filedel);
      if (filedel?.status === 200) {
        await userService().deleteDoctor(id);
        getPatients();
      }
    } else {
      alert('Deletion canceled.');
    }
  };

  const highlightText = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text?.split(regex);
    return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
  };

  const filteredData = patientList?.filter((row: Patient) =>
    Object?.values(row)?.some((value: any) => value?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredData.length / pageSize);
  const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleSearch = () => {
    if (!searchTerm) {
      setData(initialState);
    } else {
      setData(filteredData[0] || initialState);
    }
    setCurrentPage(1);
  };

  const handleSubmit = () => {
    navigate('/register');
  };

  useEffect(() => {
    setSearchTerm('');
  }, [currentPage]);

  const handleEdit = (row: Patient) => {
    console.log("row", row);
    console.log("rowId", row.id);
    navigate("/register", { state: { patientId: row.id } });
  };

  return (
    <>

      <Helmet>
        <title>Registred Patients</title>
        <meta name="description" content="This is the Patients page description" />
      </Helmet>
      <section className="admin-section">
        <div
          className="slider-item bread-item"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h1>
                  Registered Patients
                </h1>

              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <ul className="paginationbanner">
          <li>
            <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
          </li>
          <li> Registered Patients</li>
        </ul>
      </div>

      <div className="container">
        <div className="row tablefs">
          {/* <div className="col-md-2">
            <Sidebar />
          </div> */}
          <div className="col-md-12">
            <div>
              <div className="row">
                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>

                  <div className="page-size-dropdown">

                    <label style={{ padding: '5px' }}>Show</label>
                    <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                    <label style={{ padding: '5px' }}>entries</label>
                  </div>

                  <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                    {logUserObject?.Role === "MasterAdmin" ? (<div className="pb-3">
                      <Button
                        onClick={() => handleSubmit()}
                        style={{ marginRight: '10px', float: 'right' }}
                        // startIcon={<EditIcon style={{ color: 'green' }}  />}
                        className='btn btn-primary'
                      >
                        Add
                      </Button>
                    </div>) : null}
                    <p style={{ marginRight: '10px' }}>Search</p>
                    <div>
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-bar"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ overflowX: 'auto' }}>
                <table className="table table-bordered table-striped table-hover table-responsive listRegisteredPatients">
                  <thead>
                    <tr style={{ borderBottom: '1px solid #ccc' }}>
                      <th className="sno">S.No</th>
                      <th className='mdl'>Patient Name</th>
                      <th className='mdl'>EmailId</th>
                      <th className='mdl'>Phone Number</th>
                      <th className='mdl'>UserType</th>
                      <th className='mdl'>Age</th>
                      <th className='actions'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((row: Patient, index: number) => (
                      <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        {/* <td style={{ padding: '10px' }}>{highlightText(row.FirstName )}</td> */}
                        <td>{highlightText(`${row.FirstName} ${row?.LastName}`)}</td>
                        <td>{highlightText(row?.EmailId)}</td>
                        <td>{highlightText(row?.PhoneNumber)}</td>
                        <td>{highlightText(row?.userType)}</td>
                        <td>{highlightText(row?.Age)}</td>
                        <td className='actions'>
                          <Button title="Edit"
                            onClick={() => handleEdit(row)}
                            style={{ marginRight: '10px' }}
                            startIcon={<EditIcon style={{ color: 'green' }} />}
                          >

                          </Button>
                          <Button title="Delete"
                            onClick={() => handleDelete(row.id)}
                            startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                          >

                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div style={{ marginBottom: '40px' }}>
                Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries


                <div style={{ display: 'flex', justifyContent: 'right', float: 'right' }}>
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    variant="contained"
                    style={{ marginRight: '10px' }}
                  >
                    Previous
                  </Button>
                  <div className="pageNumb">{currentPage}</div>
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    variant="contained"
                    style={{ marginLeft: '10px' }}
                  >
                    Next
                  </Button>
                </div>


              </div> */}

              <div className='mttable'>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                <div className="paginationstyle mttable1">
                  <div
                    style={{
                      cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                      marginRight: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage > 1 ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage > 1) {
                        handlePageChange(currentPage - 1);
                        window.scrollTo(0, 0); // Scroll to top
                      }
                    }}
                    className={currentPage === 1 ? 'disabled' : ''}
                  >
                    Previous
                  </div>
                  <div className="pageNumb">{currentPage}</div>
                  <div
                    style={{
                      cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                      marginLeft: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage < totalPages) {
                        handlePageChange(currentPage + 1);
                        window.scrollTo(0, 0);
                      }
                    }}
                    className={currentPage === totalPages ? 'disabled' : ''}
                  >
                    Next
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ListRegisteredPatients;