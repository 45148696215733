import React, { useEffect, useState, useRef, useMemo, Suspense } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Homeservices from '../../Services/homeServices';
import pages from '../../utills/pageUtils';
import { Helmet } from 'react-helmet';
import './style.css'
import Loader from '../shared/Loader';
import LazyLoad from 'react-lazyload';

interface compProps {
  title: string;
  description: string;
  keywords: string;
}


const Services: React.FC<compProps> = ({ title, description, keywords }) => {

  const navigate = useNavigate();

  // const [pageInfo, setPageInfo] = useState<any>()
  // const [bannerName, setBannerName] = useState<any>()
  // const [caption, setCaption] = useState<any>()

  const [pageData, setPageData] = useState<{ banner: any; title: string; description: string } | null>(null);


  const ServicesRef = useRef<HTMLDivElement>(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const ServicesPerPage = 6;

  const { slug } = useParams();
  const location = useLocation();
  const [categoryName, setCategoryName] = useState("");
  console.log("categoryID", location?.state?.categoryID);

  const [showAllServices, setShowAllServices] = useState(false);
  const [loading, setLoading] = useState(true);

  const [serviceItems, setServiceItems] = useState<any>([]);
  const [currentServices, setCurrentServices] = useState<any[]>([]);



  const handleBreadcrumbClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (window.location.pathname === '/services') {
      setShowAllServices(true);
    } else {
      navigate('/services');
    }
  };

  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };

  const handleReadMore = (slug: any) => {
    navigate(`/services/${slug}`);
  };





  // useEffect(() => {
  //   const fetchData = async () => {
  //     // Simulate fetching data
  //     const result = await pages(location?.pathname)

  //     result?.map((item: any) => {
  //       setPageInfo(item?.Banner);
  //       setBannerName(item?.Title)
  //       setCaption(item?.Description)
  //     })

  //   };
  //   fetchData();
  // }, [])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const result = await pages(location?.pathname);

  //       if (result && result.length > 0) {
  //         const { Banner, Title, Description } = result[0]; // Only process the first item if applicable
  //         setPageInfo(Banner);
  //         setBannerName(Title);
  //         setCaption(Description);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   if (location?.pathname) {
  //     fetchData();
  //   }
  // }, [location?.pathname]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await pages(location?.pathname);

        if (result && result.length > 0) {
          const { Banner, Title, Description } = result[0]; // Only process the first item if applicable
          setPageData({ banner: Banner, title: Title, description: Description });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (location?.pathname) {
      fetchData();
    }
  }, [location?.pathname]);

  // Accessing data
  const banner: any = pageData?.banner ? pageData?.banner : null;
  const bannerName: any = pageData?.title ? pageData?.title : null;
  const caption: any = pageData?.description ? pageData?.description : null;


  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        setLoading(true);

        // Fetch services and categories in parallel
        const [services, categories] = await Promise.all([
          Homeservices().ActiveservicesList(),
          Homeservices().serviceCategoryList()
        ]);

        // Set category name based on categoryID
        const categoryID = location?.state?.categoryID;
        const categoryName = categoryID
          ? categories?.find((item: any) => item?.id === categoryID)?.name
          : "";
        setCategoryName(categoryName);

        // Filter services based on status and categoryID
        const filteredServices = services.filter((item: any) => {
          if (item?.Status !== "1") return false;
          if (showAllServices) return true;
          if (!categoryID) return true;

          // Check if service belongs to the selected category
          const categories = item?.category?.flatMap((catId: any) => catId?.split(','));
          return categories.includes(categoryID);
        });

        // Update service items and current services
        setServiceItems(filteredServices);
        setCurrentServices(filteredServices);
      } catch (error) {
        console.error('Error fetching gallery items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryItems();
  }, [location?.state?.categoryID, showAllServices]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Pagination logic
  const indexOfLastServices = currentPage * ServicesPerPage;
  const indexOfFirstServices = indexOfLastServices - ServicesPerPage;
  // const currentDoctors = blogsFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  // const currentServices = serviceItems.slice(indexOfFirstServices, indexOfLastServices)
  const totalPages = Math.ceil(serviceItems.length / ServicesPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (ServicesRef.current) {
      ServicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <Suspense fallback={<Loader />}>
    <div>
      

      

        <Helmet>
          <title>{title}</title>
          <meta name="keywords" content={keywords} />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta name="author" content="Gurram Dental Care" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>


        <section className="home-slider owl-carousel">
          <LazyLoad>
            <div
              className="slider-item bread-item"
              style={{ backgroundImage: banner ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${banner})` : 'images/bg_1.jpg' }}
              data-stellar-background-ratio="0.5"
            >
              <div className="overlay"></div>
              <div className="container" data-scrollax-parent="true">
                <div className="row banner-text align-items-center">
                  <div className="col-md-12 col-sm-12 ftco-animate">

                    <h1 className="banner-ttl">{bannerName}</h1>
                    <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoad>
        </section>
        
        <div>
          <ul className="ullist">
            <li>
              <a className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</a>
            </li>
            <li>
              {categoryName ? (
                <>
                  <a
                    className="aColor"
                    onClick={handleBreadcrumbClick}
                    style={{ cursor: 'pointer' }}
                  >
                    Services
                  </a>
                  /&nbsp;{categoryName}
                </>
              ) : (
                <>Services</>
              )}
            </li>
          </ul>
        </div>
        
        <section className="ftco-section ftco-services" ref={ServicesRef} id="services" style={{ marginBottom: '10px' }}>


              {loading ? (
        <Loader />
      ) : (    
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-12 text-center heading-section ftco-animate">
                <h3 className="mb-2">Our Services</h3>
                {/* <p>we provide a comprehensive range of dental services to cater to all your oral health needs.Whether you're due for a routine check-up or seeking specialized treatment, we're here to help you achieve and maintain a healthy, beautiful smile.</p> */}
                <p>Comprehensive Healthcare, Tailored for You</p>
              </div>
            </div>
            <div className="row">
              {currentServices && currentServices?.length > 0 ?
                currentServices?.map((item: any) => (
                  <div className="col-md-4 d-flex align-self-stretch " key={item?.id} style={{ marginBottom: '10px' }}>
                    <div className="media block-6 services d-block text-center" style={{ border: '1px solid #eee', marginTop: '5px', marginBottom: '5px' }}>
                      <div className="icon staff d-flex justify-content-center align-items-center">
                        <LazyLoad>
                          <img
                            className="img"
                            src={item?.Profile?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.Profile}` : 'images/tooth.jpg'}
                            alt={item?.name} />
                        </LazyLoad>
                      </div>
                      <div className="media-body mt-3">
                        <h3 className="heading">{item?.Title}</h3>
                        {/* <p className="paraHeight">{truncateText(removeHtmlTags(item?.Description), 80)}</p> */}
                        <p className="paraHeight" dangerouslySetInnerHTML={{ __html: truncateText(item?.Description, 40) }} />
                        <div>
                          <button
                            className="read-more btn btn-sm"
                            onClick={() => handleReadMore(item?.Slug)}
                          >
                            Read more
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                )) 
                
                :
                <div className='text-center mb-4' style={{ fontSize: "30px" }}>
                  No Active Services
                </div>
                }
            </div>
            
            {/* Pagination Controls */}
            {currentServices?.length > 0 ?
              <div className="custom-pagination">
                <ul className="custom-pagination-list">
                  <li className={`custom-pagination-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                      Previous
                    </button>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                    <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                      <button onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li className={`custom-pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                      Next
                    </button>
                  </li>
                </ul>
              </div>
              : null}
          </div>
           )}
          

          <div className="container-wrap mbheight" style={{ height: '554px', overflowX: 'clip' }}>
            <div className="row d-flex no-gutters">
              <div
                className="col-md-6 img ImgMediaquery"
                style={{ backgroundImage: `url('images/about-3.jpg')` }}
              ></div>
              <div className="col-md-6 d-flex">
                <div className="about-wrap">
                  <div className="heading-section heading-section-white mb-3 ftco-animate">
                    <h2 className="mfontsize1">
                      Your Partner in Personalized Dental Health
                    </h2>
                    <p className='pfontsize'>
                      Tailoring dental health solutions specifically to meet your
                      unique needs.
                    </p>
                  </div>
                  <div className="list-services d-flex ftco-animate Mmargint">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="icon-check2"></span>
                    </div>
                    <div className="text">
                      <h3 className="mfontsize">Expert in Dentistry</h3>
                      <p className='pfontsize'>
                        Mastery in dentistry establishes expertise committed to
                        dental excellence.
                      </p>
                    </div>
                  </div>
                  <div className="list-services d-flex ftco-animate Mmargint">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="icon-check2"></span>
                    </div>
                    <div className="text">
                      <h3 className="mfontsize">Advanced Technological Infrastructure</h3>
                      <p className='pfontsize'>
                        Enhance your dental experience with our advanced
                        technological infrastructure, delivering precise
                        diagnostics and treatments.
                      </p>
                    </div>
                  </div>
                  <div className="list-services d-flex ftco-animate Mmargint">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="icon-check2"></span>
                    </div>
                    <div className="text">
                      <h3 className="mfontsize">Serene Medical Environment</h3>
                      <p className='pfontsize'>
                        A quiet and harmonious healthcare environment promoting
                        therapeutic tranquility.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <LazyLoad>
          <section
            className="ftco-section ftco-counter img"
            id="section-counter"
            style={{
              backgroundImage: "url(images/bg_1.jpg)",
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
            data-stellar-background-ratio="0.5"
          >
            {/* <div className="container">
          </div> */}
          </section>
        </LazyLoad>
        
      
     

   
      
    </div >
    </Suspense>
  )
}

export default Services;