import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Box,
    Typography,
    Container,
    Paper,
    TextField,
    Button,
} from "@mui/material"; // Material-UI components imported here

import { MultipleFileUpload, RemovePrevFile, updDelFile } from "../shared/fileupload";
import GalleryUtils from "../../utills/galleryUploads";
import { validationSchema } from './validationSchema';
import { Helmet } from 'react-helmet';
// import useAuthRedirect from "../../utills/Redirect/useAuthRedirect";

interface Gallery {
    id: any
    Category: string
    Files: []
    fileType: string
    url?: string
}
const initialState: Gallery = {
    id: '',
    Category: '',
    Files: [],
    fileType: '',
    url: '',
};

function GalleryUpload() {
    const navigate = useNavigate();
    const location = useLocation();
    const [errMsgMatch, setErrMsgMatch] = useState("");
    const [InputDetails, setInputDetails] = useState<Gallery | any>(initialState);
    const [directory, setDirectory] = useState();
    const [newState, setNewState] = useState<any>({ fileType: "" });
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // useAuthRedirect(logUserObject);
    // async function ProvInfo() {
    //     const response = await GalleryUtils().fetchSingleGallery(location?.state?.id);
    //     setInputDetails(response);
    // }



    const fieldRefs: any = useRef({
        Category: null as HTMLDivElement | null,
        fileType: null as HTMLDivElement | null,
    });


    async function ProvInfo() {
        const response = await GalleryUtils().fetchSingleGallery(location?.state?.id);
        console.log({ response })
        if (response?.fileType === "url") {
            // Update Files from array to string
            const updatedResponse = {
                ...response,
                Files: response?.Files[0]  // Convert the array to a string
            };
            setNewState({ fileType: response?.fileType });
            setInputDetails(updatedResponse);
        } else {
            setNewState({ fileType: response?.fileType });
            setInputDetails(response);
        }
    }
    useEffect(() => {
        ProvInfo();
    }, [location?.state?.id]);
    // useEffect(() => {
    //     setFieldValue("Files", []);
    //     setInputDetails((prev: Gallery) => ({ ...prev, Files: [] }));
    // }, [InputDetails.fileType]);
    console.log("directory", directory);
    const handleSumit: any = async (values: Gallery) => {
        console.log("values", values);
        console.log("InputDetails", InputDetails);
        let updatedGalleryObject: any;
        if (location?.state?.id && values?.fileType !== InputDetails?.fileType) {
            const deleteFilesByExtension = async (files: string[]) => {
                const allowedImageExtensions = ["png", "jpg", "jpeg", "gif"];
                const allowedVideoExtensions = ["mp4", "mov", "avi"];

                const filesToDelete = {
                    images: [] as string[],
                    videos: [] as string[],
                    urls: [] as string[]
                };
                const filesArray = Array.isArray(files) ? files : [files];
                // Categorize files by extension
                filesArray?.forEach(file => {
                    // const extension: any = file.split('.').pop()?.toLowerCase();
                    const extension: any = typeof file === 'string' ? file.split('.').pop()?.toLowerCase() : null;
                    if (allowedImageExtensions.includes(extension)) {
                        filesToDelete?.images.push(file);
                    } else if (allowedVideoExtensions.includes(extension)) {
                        filesToDelete?.videos.push(file);
                    } else if (typeof file === 'string' && (file.startsWith('http') || file.startsWith('https'))) {
                        filesToDelete?.urls.push(file);
                    }
                });
                console.log("filesToDelete", filesToDelete);
                // Delete image files concurrently
                const imageDeletionPromises: any = filesToDelete?.images?.map(imageFile => {
                    updDelFile(imageFile, "images");
                    const filesArray = Array.isArray(values?.Files) ? values?.Files : [values?.Files];
                    const updatedFiles = filesArray.filter((file: string) => typeof file !== 'string');
                    updatedGalleryObject = {
                        ...values,
                        Files: updatedFiles
                    };
                    console.log("updatedGalleryObject", updatedGalleryObject);
                });

                // Delete video files concurrently
                const videoDeletionPromises: any = filesToDelete?.videos?.map(videoFile => {
                    updDelFile(videoFile, "videos");
                    const filesArray = Array.isArray(values?.Files) ? values?.Files : [values?.Files];

                    const updatedFiles = filesArray?.filter((file: string) => typeof file !== 'string');
                    updatedGalleryObject = {
                        ...values,
                        Files: updatedFiles
                    };
                    console.log("updatedGalleryObject", updatedGalleryObject);
                });
                const urlDeletionPromises = filesToDelete?.urls?.map(async (url) => {
                    console.log("url", url);
                    const updatedGallery = {
                        ...InputDetails,
                        Files: []
                    };
                    console.log("updatedGalleryObject", updatedGalleryObject);
                    const galleryUp = await GalleryUtils().galleryUpdateUtil(updatedGallery);
                    console.log("galleryUp", galleryUp);
                    updatedGalleryObject = {
                        ...values,
                        Files: values?.Files
                    };
                });

                await Promise.all([...imageDeletionPromises, ...videoDeletionPromises, ...urlDeletionPromises]);
            };
            // let deletedFiles: any;
            // if (InputDetails?.fileType === "url") {
            //     const response = await GalleryUtils().fetchSingleGallery(location?.state?.id);
            //     deletedFiles = await deleteFilesByExtension(response?.Files);
            // } else {
            const deletedFiles = await deleteFilesByExtension(InputDetails?.Files);
            // }
            console.log("deletedFiles", deletedFiles);
        }

        let submit: any;
        if (location?.state?.id) {
            values.id = location?.state?.id;
            if ((values?.fileType === "image" || values?.fileType === "video") && values?.Files?.length > 0) {
                if (values?.fileType !== InputDetails?.fileType) {
                    values.Files = updatedGalleryObject?.Files
                }
                console.log("values before check", values);
                const s3Check = await GalleryUtils().check(values);
                console.log("s3Check", s3Check);
                submit = await GalleryUtils().galleryUpdateUtil(values);
            } else {
                submit = await GalleryUtils().galleryUpdateUtil(values);
            }
        } else {
            submit = await GalleryUtils().handleSubmit(values);
        }
        console.log("submit", submit);
        if (submit) {
            navigate('/listGallery');
        }
    };

    return (
        <>

            <Helmet>
                <title>Upload Gallery</title>
                <meta name="description" content="This is the Gallery page description" />
            </Helmet>

            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Gallery
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Gallery</li>
                </ul>
            </div>

            <div className="container">
                <div className="row">
                    {/* <div className="col-md-2" style={{marginTop:'50px'}}>
                    <Sidebar />
                    </div> */}
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div
                            className="row"
                            style={{
                                display: "flex",
                                height: "auto",
                                marginBottom: "10px !important",
                            }}
                        >
                            <p style={{ color: 'red' }}>
                                {errMsgMatch ? errMsgMatch : ''}
                            </p>
                            <Container>
                                <Paper
                                    elevation={3}
                                    style={{
                                        padding: "30px",
                                        background: "transparent !important",
                                        boxShadow: "none",
                                    }}
                                >
                                    <Formik
                                        initialValues={{
                                            id: location?.state?.id ? location?.state?.id : "",
                                            Category: location?.state?.id ? InputDetails?.Category : "",
                                            fileType: location?.state?.id ? InputDetails?.fileType : "",
                                            Files: location?.state?.id ? InputDetails?.Files : [],
                                        }}
                                        onSubmit={handleSumit}
                                        validationSchema={validationSchema}
                                        enableReinitialize
                                        sx={{ width: "100%" }}
                                    >
                                        {({ isSubmitting, values, setFieldValue, touched, errors }) => {
                                            const errorCount = Object.keys(errors).length;
                                            console.log({ errorCount })

                                            if (isSubmitting && errorCount > 0) {

                                                for (const key in errors) {
                                                    console.log({ key })
                                                    if (fieldRefs.current[key]) {
                                                        fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                                        break; // Stop at the first error we find
                                                    }
                                                }


                                            }
                                            return (
                                                <Form autoComplete="off">
                                                    <div className="col-md-12">
                                                        <Box className="form-field" mb={3}  ref={(el) => (fieldRefs.current.Category = el)}>
                                                            <TextField
                                                                name="Category"
                                                                label="Category/Title *"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={values?.Category}
                                                                onChange={(e) => setFieldValue("Category", e?.target?.value)}
                                                                error={touched?.Category && Boolean(errors?.Category)}
                                                                helperText={touched?.Category ? errors?.Category as string : ""}
                                                            />
                                                        </Box>
                                                    </div>
                                                    <div className="col-md-12 d-flex"  ref={(el) => (fieldRefs.current.fileType = el)}>
                                                        <label className="form-label me-3">File Type *</label>
                                                        <div className="form-check mt-5 mb-0 me-3" style={{ marginLeft: '-98px' }}>
                                                            <Field
                                                                type="radio"
                                                                name="fileType"
                                                                value="image"
                                                                className="form-check-input"
                                                                id="image"
                                                                // disabled={!!location?.state?.id}
                                                                onChange={(e: { target: { value: any; }; }) => {
                                                                    setFieldValue("fileType", e?.target?.value);
                                                                    if (!location?.state?.id) {
                                                                        setFieldValue("Files", []);
                                                                        setInputDetails((prev: any) => ({ ...prev, Files: [] }));
                                                                    }
                                                                    else {
                                                                        setNewState((prev: any) => ({ ...prev, fileType: e?.target?.value }));
                                                                        setFieldValue("Files", Array.isArray(values?.Files) && values?.Files?.map((item: any) => (typeof item !== 'string' ? [] : item)))
                                                                    }
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="image">
                                                                Image
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-5 mb-0 me-3">
                                                            <Field
                                                                type="radio"
                                                                name="fileType"
                                                                value="video"
                                                                className="form-check-input"
                                                                id="video"
                                                                // disabled={!!location?.state?.id}
                                                                onChange={(e: { target: { value: any; }; }) => {
                                                                    setFieldValue("fileType", e?.target?.value);
                                                                    if (!location?.state?.id) {
                                                                        setFieldValue("Files", []);
                                                                        setInputDetails((prev: any) => ({ ...prev, Files: [] }));
                                                                    }
                                                                    else {
                                                                        setNewState((prev: any) => ({ ...prev, fileType: e?.target?.value }));
                                                                        setFieldValue("Files", Array.isArray(values?.Files) && values?.Files?.map((item: any) => (typeof item !== 'string' ? [] : item)))
                                                                    }
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="video">
                                                                Video
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-5 mb-0">
                                                            <Field
                                                                type="radio"
                                                                name="fileType"
                                                                value="url"
                                                                className="form-check-input"
                                                                id="url"
                                                                // disabled={!!location?.state?.id}
                                                                onChange={(e: { target: { value: any; }; }) => {
                                                                    setFieldValue("fileType", e?.target?.value);
                                                                    if (!location?.state?.id) {
                                                                        setFieldValue("Files", []);
                                                                        setInputDetails((prev: any) => ({ ...prev, Files: [] }));
                                                                    }
                                                                    else {
                                                                        setNewState((prev: any) => ({ ...prev, fileType: e?.target?.value }));
                                                                        setFieldValue("Files", Array.isArray(values?.Files) && values?.Files?.map((item: any) => (typeof item !== 'string' ? [] : item)));
                                                                    }
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="url">
                                                                URL
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {touched?.fileType && errors?.fileType && (
                                                        <div className="error-message" style={{ color: 'red' }}>
                                                            {errors?.fileType ? errors?.fileType as string : ""}
                                                        </div>
                                                    )}

                                                    {values?.fileType === 'image' && (
                                                        <div className=" col-md-12">
                                                            <MultipleFileUpload
                                                                label="Upload Photos"
                                                                setDirectory={setDirectory}
                                                                setFieldValue={setFieldValue}
                                                                InputDetails={InputDetails}
                                                                setInputDetails={setInputDetails}
                                                                type="image"
                                                                setNewState={setNewState}
                                                                newState={newState}
                                                                locationID={location?.state?.id}
                                                            />
                                                            {touched?.Files && errors?.Files && (
                                                                <div className="error-message">{errors?.Files as string}</div>
                                                            )}
                                                            <div className="file-list" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                {Array.isArray(values?.Files) && values?.Files
                                                                    .filter((file1: any) => {
                                                                        const extension: any = typeof file1 === 'string' ? file1.split('.').pop()?.toLowerCase() : null;
                                                                        const isImage = ['png', 'jpg', 'jpeg', 'gif'].includes(extension);
                                                                        const isVideo = ['mp4', 'mov', 'avi'].includes(extension);
                                                                        const isURL = typeof file1 === 'string' && (file1.startsWith('http') || file1.startsWith('https'));

                                                                        // Show only images if fileType is image, videos if fileType is video, or URLs if fileType is URL
                                                                        if (values?.fileType === 'image' && (isImage || file1 instanceof File)) {
                                                                            return true;
                                                                        }
                                                                        if (values?.fileType === 'video' && (isVideo || file1 instanceof File)) {
                                                                            return true;
                                                                        }
                                                                        if (values?.fileType === 'url' && isURL) {
                                                                            return true;
                                                                        }
                                                                        return false;
                                                                    })
                                                                    .map((file1: any, fileIndex: any) => (
                                                                        <span key={fileIndex} className="mx-2 py-1 px-2 bg-secondary text-white" style={{ borderRadius: "5px", marginTop: "5px" }}>
                                                                            {file1?.name ? file1?.name : file1}
                                                                            <span onClick={() => RemovePrevFile(file1, fileIndex, InputDetails, setInputDetails, "images", setFieldValue, values)} style={{ paddingLeft: "10px", cursor: "pointer" }} role="button">
                                                                                <FontAwesomeIcon icon={faTimes} />
                                                                            </span>
                                                                        </span>
                                                                    ))}
                                                            </div>

                                                            {/* {
                                                            Array.isArray(values?.Files) && values?.Files?.map((file1: any, fileIndex: any) => (

                                                                <>
                                                                    <span key={fileIndex} className="mx-2 py-1 px-2 bg-secondary text-white" style={{ borderRadius: "5px", marginTop: "5px" }}>
                                                                        {file1?.name ? file1?.name : file1}

                                                                        <span onClick={() => RemovePrevFile(file1, fileIndex, InputDetails, setInputDetails, "images", setFieldValue, values)} style={{ paddingLeft: "10px", cursor: "pointer" }} role="button">
                                                                            <FontAwesomeIcon icon={faTimes} />
                                                                        </span>
                                                                    </span>
                                                                </>
                                                            ))

                                                        } */}
                                                        </div>
                                                    )}

                                                    {values?.fileType === 'video' && (
                                                        <div className=" col-md-12">
                                                            <MultipleFileUpload
                                                                label="Upload Video"
                                                                setDirectory={setDirectory}
                                                                setFieldValue={setFieldValue}
                                                                InputDetails={InputDetails}
                                                                setInputDetails={setInputDetails}
                                                                type="video"
                                                                setNewState={setNewState}
                                                                newState={newState}
                                                                locationID={location?.state?.id}
                                                            />
                                                            {touched?.Files && errors?.Files && (
                                                                <div className="error-message">{errors?.Files as string}</div>
                                                            )}
                                                            <div className="file-list" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                {Array.isArray(values?.Files) && values?.Files
                                                                    .filter((file1: any) => {
                                                                        const extension: any = typeof file1 === 'string' ? file1.split('.').pop()?.toLowerCase() : null;
                                                                        const isImage = ['png', 'jpg', 'jpeg', 'gif'].includes(extension);
                                                                        const isVideo = ['mp4', 'mov', 'avi'].includes(extension);
                                                                        const isURL = typeof file1 === 'string' && (file1.startsWith('http') || file1.startsWith('https'));

                                                                        // Show only images if fileType is image, videos if fileType is video, or URLs if fileType is URL
                                                                        if (values?.fileType === 'image' && (isImage || file1 instanceof File)) {
                                                                            return true;
                                                                        }
                                                                        if (values?.fileType === 'video' && (isVideo || file1 instanceof File)) {
                                                                            return true;
                                                                        }
                                                                        if (values?.fileType === 'url' && isURL) {
                                                                            return true;
                                                                        }
                                                                        return false;
                                                                    })?.map((file1: any, fileIndex: any) => (
                                                                        <span key={fileIndex} className="mx-2 py-1 px-2 bg-secondary text-white" style={{ borderRadius: "5px", marginTop: "5px" }}>
                                                                            {file1?.name ? file1?.name : file1}
                                                                            <span onClick={() => RemovePrevFile(file1, fileIndex, InputDetails, setInputDetails, "videos", setFieldValue, values)} style={{ paddingLeft: "10px", cursor: "pointer" }} role="button">
                                                                                <FontAwesomeIcon icon={faTimes} />
                                                                            </span>
                                                                        </span>
                                                                    ))}
                                                            </div>


                                                            {/* <div className="file-list" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                            {Array.isArray(values?.Files) && values?.Files?.map((file1: any, fileIndex: any) => (
                                                                <span key={fileIndex} className="mx-2 py-1 px-2 bg-secondary text-white" style={{ borderRadius: "5px", marginTop: "5px" }}>
                                                                    {file1?.name ? file1?.name : file1}
                                                                    <span onClick={() => RemovePrevFile(file1, fileIndex, InputDetails, setInputDetails, "videos", setFieldValue, values)} style={{ paddingLeft: "10px", cursor: "pointer" }} role="button">
                                                                        <FontAwesomeIcon icon={faTimes} />
                                                                    </span>
                                                                </span>
                                                            ))}
                                                        </div> */}
                                                        </div>
                                                    )}
                                                    {values?.fileType === 'url' && (
                                                        <div className=" col-md-12">
                                                            <Box mb={3} mt={7}>
                                                                <TextField
                                                                    name="url"
                                                                    label="Paste your YouTube URL"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    defaultValue={location?.state?.id && InputDetails?.fileType === "url" ? InputDetails?.Files : ""}
                                                                    onChange={(e) => setFieldValue("Files", e?.target?.value)}
                                                                />
                                                                {touched?.Files && errors?.Files && (
                                                                    <div className="error-message ml-3">{errors?.Files as string}</div>
                                                                )}
                                                            </Box>
                                                        </div>
                                                    )}

                                                    <Box textAlign="center" marginTop="50px">
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                            style={{ padding: "10px", fontSize: "16px" }}
                                                        >
                                                            {location?.state?.id ? "Update" : "Upload"}
                                                        </Button>
                                                    </Box>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </Paper>
                            </Container>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div >
        </>
    );
}

export default GalleryUpload;

