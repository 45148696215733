/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTodo = /* GraphQL */ `mutation CreateTodo(
  $input: CreateTodoInput!
  $condition: ModelTodoConditionInput
) {
  createTodo(input: $input, condition: $condition) {
    id
    name
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTodoMutationVariables,
  APITypes.CreateTodoMutation
>;
export const updateTodo = /* GraphQL */ `mutation UpdateTodo(
  $input: UpdateTodoInput!
  $condition: ModelTodoConditionInput
) {
  updateTodo(input: $input, condition: $condition) {
    id
    name
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTodoMutationVariables,
  APITypes.UpdateTodoMutation
>;
export const deleteTodo = /* GraphQL */ `mutation DeleteTodo(
  $input: DeleteTodoInput!
  $condition: ModelTodoConditionInput
) {
  deleteTodo(input: $input, condition: $condition) {
    id
    name
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTodoMutationVariables,
  APITypes.DeleteTodoMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    FirstName
    LastName
    Specialization
    Gender
    Age
    Status
    Role
    PhoneNumber
    EmailId
    Password
    countryCode
    userProfile
    Otp
    ShowInTeam
    TimeSlot
    Description
    SocialIcons {
      name
      icon
      __typename
    }
    Address
    KYC {
      pastHealthConditions
      pastHealthConditionsDescription
      foodAllergies
      foodAllergiesDescription
      CurrentMedications
      CurrentMedicationsDescription
      ChronicIllnesses
      ChronicIllnessesDescription
      SmokingStatus
      AlcoholConsumption
      heardFrom
      sugar
      bloodPressure
      heartAttack
      pregnacy
      __typename
    }
    Slug
    City
    Street
    pinCode
    DOB
    HouseNumber
    MaritalStatus
    qualification
    userType
    resetPWD
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    FirstName
    LastName
    Specialization
    Gender
    Age
    Status
    Role
    PhoneNumber
    EmailId
    Password
    countryCode
    userProfile
    Otp
    ShowInTeam
    TimeSlot
    Description
    SocialIcons {
      name
      icon
      __typename
    }
    Address
    KYC {
      pastHealthConditions
      pastHealthConditionsDescription
      foodAllergies
      foodAllergiesDescription
      CurrentMedications
      CurrentMedicationsDescription
      ChronicIllnesses
      ChronicIllnessesDescription
      SmokingStatus
      AlcoholConsumption
      heardFrom
      sugar
      bloodPressure
      heartAttack
      pregnacy
      __typename
    }
    Slug
    City
    Street
    pinCode
    DOB
    HouseNumber
    MaritalStatus
    qualification
    userType
    resetPWD
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    FirstName
    LastName
    Specialization
    Gender
    Age
    Status
    Role
    PhoneNumber
    EmailId
    Password
    countryCode
    userProfile
    Otp
    ShowInTeam
    TimeSlot
    Description
    SocialIcons {
      name
      icon
      __typename
    }
    Address
    KYC {
      pastHealthConditions
      pastHealthConditionsDescription
      foodAllergies
      foodAllergiesDescription
      CurrentMedications
      CurrentMedicationsDescription
      ChronicIllnesses
      ChronicIllnessesDescription
      SmokingStatus
      AlcoholConsumption
      heardFrom
      sugar
      bloodPressure
      heartAttack
      pregnacy
      __typename
    }
    Slug
    City
    Street
    pinCode
    DOB
    HouseNumber
    MaritalStatus
    qualification
    userType
    resetPWD
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createSpecialization = /* GraphQL */ `mutation CreateSpecialization(
  $input: CreateSpecializationInput!
  $condition: ModelSpecializationConditionInput
) {
  createSpecialization(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSpecializationMutationVariables,
  APITypes.CreateSpecializationMutation
>;
export const updateSpecialization = /* GraphQL */ `mutation UpdateSpecialization(
  $input: UpdateSpecializationInput!
  $condition: ModelSpecializationConditionInput
) {
  updateSpecialization(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpecializationMutationVariables,
  APITypes.UpdateSpecializationMutation
>;
export const deleteSpecialization = /* GraphQL */ `mutation DeleteSpecialization(
  $input: DeleteSpecializationInput!
  $condition: ModelSpecializationConditionInput
) {
  deleteSpecialization(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpecializationMutationVariables,
  APITypes.DeleteSpecializationMutation
>;
export const createPatientAppointment = /* GraphQL */ `mutation CreatePatientAppointment(
  $input: CreatePatientAppointmentInput!
  $condition: ModelPatientAppointmentConditionInput
) {
  createPatientAppointment(input: $input, condition: $condition) {
    id
    PatientName
    patientID
    PhoneNumber
    DoctorID
    AppointmentDate
    AppointmentTime
    AppointmentID
    EmailId
    Message
    PaymentMode
    PaymentStatus
    Amount
    KYC {
      pastHealthConditions
      pastHealthConditionsDescription
      foodAllergies
      foodAllergiesDescription
      CurrentMedications
      CurrentMedicationsDescription
      ChronicIllnesses
      ChronicIllnessesDescription
      SmokingStatus
      AlcoholConsumption
      heardFrom
      sugar
      bloodPressure
      heartAttack
      pregnacy
      __typename
    }
    Address
    City
    Street
    pinCode
    DOB
    HouseNumber
    MaritalStatus
    userType
    Otp
    Status
    Gender
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePatientAppointmentMutationVariables,
  APITypes.CreatePatientAppointmentMutation
>;
export const updatePatientAppointment = /* GraphQL */ `mutation UpdatePatientAppointment(
  $input: UpdatePatientAppointmentInput!
  $condition: ModelPatientAppointmentConditionInput
) {
  updatePatientAppointment(input: $input, condition: $condition) {
    id
    PatientName
    patientID
    PhoneNumber
    DoctorID
    AppointmentDate
    AppointmentTime
    AppointmentID
    EmailId
    Message
    PaymentMode
    PaymentStatus
    Amount
    KYC {
      pastHealthConditions
      pastHealthConditionsDescription
      foodAllergies
      foodAllergiesDescription
      CurrentMedications
      CurrentMedicationsDescription
      ChronicIllnesses
      ChronicIllnessesDescription
      SmokingStatus
      AlcoholConsumption
      heardFrom
      sugar
      bloodPressure
      heartAttack
      pregnacy
      __typename
    }
    Address
    City
    Street
    pinCode
    DOB
    HouseNumber
    MaritalStatus
    userType
    Otp
    Status
    Gender
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePatientAppointmentMutationVariables,
  APITypes.UpdatePatientAppointmentMutation
>;
export const deletePatientAppointment = /* GraphQL */ `mutation DeletePatientAppointment(
  $input: DeletePatientAppointmentInput!
  $condition: ModelPatientAppointmentConditionInput
) {
  deletePatientAppointment(input: $input, condition: $condition) {
    id
    PatientName
    patientID
    PhoneNumber
    DoctorID
    AppointmentDate
    AppointmentTime
    AppointmentID
    EmailId
    Message
    PaymentMode
    PaymentStatus
    Amount
    KYC {
      pastHealthConditions
      pastHealthConditionsDescription
      foodAllergies
      foodAllergiesDescription
      CurrentMedications
      CurrentMedicationsDescription
      ChronicIllnesses
      ChronicIllnessesDescription
      SmokingStatus
      AlcoholConsumption
      heardFrom
      sugar
      bloodPressure
      heartAttack
      pregnacy
      __typename
    }
    Address
    City
    Street
    pinCode
    DOB
    HouseNumber
    MaritalStatus
    userType
    Otp
    Status
    Gender
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePatientAppointmentMutationVariables,
  APITypes.DeletePatientAppointmentMutation
>;
export const createDoctorAvaiableDate = /* GraphQL */ `mutation CreateDoctorAvaiableDate(
  $input: CreateDoctorAvaiableDateInput!
  $condition: ModelDoctorAvaiableDateConditionInput
) {
  createDoctorAvaiableDate(input: $input, condition: $condition) {
    id
    DoctorID
    Date
    Time
    Status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDoctorAvaiableDateMutationVariables,
  APITypes.CreateDoctorAvaiableDateMutation
>;
export const updateDoctorAvaiableDate = /* GraphQL */ `mutation UpdateDoctorAvaiableDate(
  $input: UpdateDoctorAvaiableDateInput!
  $condition: ModelDoctorAvaiableDateConditionInput
) {
  updateDoctorAvaiableDate(input: $input, condition: $condition) {
    id
    DoctorID
    Date
    Time
    Status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDoctorAvaiableDateMutationVariables,
  APITypes.UpdateDoctorAvaiableDateMutation
>;
export const deleteDoctorAvaiableDate = /* GraphQL */ `mutation DeleteDoctorAvaiableDate(
  $input: DeleteDoctorAvaiableDateInput!
  $condition: ModelDoctorAvaiableDateConditionInput
) {
  deleteDoctorAvaiableDate(input: $input, condition: $condition) {
    id
    DoctorID
    Date
    Time
    Status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDoctorAvaiableDateMutationVariables,
  APITypes.DeleteDoctorAvaiableDateMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    id
    Name
    EmailId
    Rating
    Comment
    Status
    userProfile
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    id
    Name
    EmailId
    Rating
    Comment
    Status
    userProfile
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    id
    Name
    EmailId
    Rating
    Comment
    Status
    userProfile
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createContact = /* GraphQL */ `mutation CreateContact(
  $input: CreateContactInput!
  $condition: ModelContactConditionInput
) {
  createContact(input: $input, condition: $condition) {
    id
    Name
    emailID
    phoneNumber
    Message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactMutationVariables,
  APITypes.CreateContactMutation
>;
export const updateContact = /* GraphQL */ `mutation UpdateContact(
  $input: UpdateContactInput!
  $condition: ModelContactConditionInput
) {
  updateContact(input: $input, condition: $condition) {
    id
    Name
    emailID
    phoneNumber
    Message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactMutationVariables,
  APITypes.UpdateContactMutation
>;
export const deleteContact = /* GraphQL */ `mutation DeleteContact(
  $input: DeleteContactInput!
  $condition: ModelContactConditionInput
) {
  deleteContact(input: $input, condition: $condition) {
    id
    Name
    emailID
    phoneNumber
    Message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactMutationVariables,
  APITypes.DeleteContactMutation
>;
export const createGallery = /* GraphQL */ `mutation CreateGallery(
  $input: CreateGalleryInput!
  $condition: ModelGalleryConditionInput
) {
  createGallery(input: $input, condition: $condition) {
    id
    Category
    Files
    fileType
    Status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGalleryMutationVariables,
  APITypes.CreateGalleryMutation
>;
export const updateGallery = /* GraphQL */ `mutation UpdateGallery(
  $input: UpdateGalleryInput!
  $condition: ModelGalleryConditionInput
) {
  updateGallery(input: $input, condition: $condition) {
    id
    Category
    Files
    fileType
    Status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGalleryMutationVariables,
  APITypes.UpdateGalleryMutation
>;
export const deleteGallery = /* GraphQL */ `mutation DeleteGallery(
  $input: DeleteGalleryInput!
  $condition: ModelGalleryConditionInput
) {
  deleteGallery(input: $input, condition: $condition) {
    id
    Category
    Files
    fileType
    Status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGalleryMutationVariables,
  APITypes.DeleteGalleryMutation
>;
export const createBlog = /* GraphQL */ `mutation CreateBlog(
  $input: CreateBlogInput!
  $condition: ModelBlogConditionInput
) {
  createBlog(input: $input, condition: $condition) {
    id
    catergory
    title
    description
    status
    userID
    blogImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBlogMutationVariables,
  APITypes.CreateBlogMutation
>;
export const updateBlog = /* GraphQL */ `mutation UpdateBlog(
  $input: UpdateBlogInput!
  $condition: ModelBlogConditionInput
) {
  updateBlog(input: $input, condition: $condition) {
    id
    catergory
    title
    description
    status
    userID
    blogImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBlogMutationVariables,
  APITypes.UpdateBlogMutation
>;
export const deleteBlog = /* GraphQL */ `mutation DeleteBlog(
  $input: DeleteBlogInput!
  $condition: ModelBlogConditionInput
) {
  deleteBlog(input: $input, condition: $condition) {
    id
    catergory
    title
    description
    status
    userID
    blogImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBlogMutationVariables,
  APITypes.DeleteBlogMutation
>;
export const createBlogCategory = /* GraphQL */ `mutation CreateBlogCategory(
  $input: CreateBlogCategoryInput!
  $condition: ModelBlogCategoryConditionInput
) {
  createBlogCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBlogCategoryMutationVariables,
  APITypes.CreateBlogCategoryMutation
>;
export const updateBlogCategory = /* GraphQL */ `mutation UpdateBlogCategory(
  $input: UpdateBlogCategoryInput!
  $condition: ModelBlogCategoryConditionInput
) {
  updateBlogCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBlogCategoryMutationVariables,
  APITypes.UpdateBlogCategoryMutation
>;
export const deleteBlogCategory = /* GraphQL */ `mutation DeleteBlogCategory(
  $input: DeleteBlogCategoryInput!
  $condition: ModelBlogCategoryConditionInput
) {
  deleteBlogCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBlogCategoryMutationVariables,
  APITypes.DeleteBlogCategoryMutation
>;
export const createPages = /* GraphQL */ `mutation CreatePages(
  $input: CreatePagesInput!
  $condition: ModelPagesConditionInput
) {
  createPages(input: $input, condition: $condition) {
    id
    Title
    Link
    Description
    Order
    ShowInHeader
    ShowInFooter
    Status
    Banner
    Caption
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePagesMutationVariables,
  APITypes.CreatePagesMutation
>;
export const updatePages = /* GraphQL */ `mutation UpdatePages(
  $input: UpdatePagesInput!
  $condition: ModelPagesConditionInput
) {
  updatePages(input: $input, condition: $condition) {
    id
    Title
    Link
    Description
    Order
    ShowInHeader
    ShowInFooter
    Status
    Banner
    Caption
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePagesMutationVariables,
  APITypes.UpdatePagesMutation
>;
export const deletePages = /* GraphQL */ `mutation DeletePages(
  $input: DeletePagesInput!
  $condition: ModelPagesConditionInput
) {
  deletePages(input: $input, condition: $condition) {
    id
    Title
    Link
    Description
    Order
    ShowInHeader
    ShowInFooter
    Status
    Banner
    Caption
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePagesMutationVariables,
  APITypes.DeletePagesMutation
>;
export const createServiceCategory = /* GraphQL */ `mutation CreateServiceCategory(
  $input: CreateServiceCategoryInput!
  $condition: ModelServiceCategoryConditionInput
) {
  createServiceCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceCategoryMutationVariables,
  APITypes.CreateServiceCategoryMutation
>;
export const updateServiceCategory = /* GraphQL */ `mutation UpdateServiceCategory(
  $input: UpdateServiceCategoryInput!
  $condition: ModelServiceCategoryConditionInput
) {
  updateServiceCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceCategoryMutationVariables,
  APITypes.UpdateServiceCategoryMutation
>;
export const deleteServiceCategory = /* GraphQL */ `mutation DeleteServiceCategory(
  $input: DeleteServiceCategoryInput!
  $condition: ModelServiceCategoryConditionInput
) {
  deleteServiceCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServiceCategoryMutationVariables,
  APITypes.DeleteServiceCategoryMutation
>;
export const createServices = /* GraphQL */ `mutation CreateServices(
  $input: CreateServicesInput!
  $condition: ModelServicesConditionInput
) {
  createServices(input: $input, condition: $condition) {
    id
    Title
    Profile
    Description
    ShowInHome
    Status
    Slug
    category
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServicesMutationVariables,
  APITypes.CreateServicesMutation
>;
export const updateServices = /* GraphQL */ `mutation UpdateServices(
  $input: UpdateServicesInput!
  $condition: ModelServicesConditionInput
) {
  updateServices(input: $input, condition: $condition) {
    id
    Title
    Profile
    Description
    ShowInHome
    Status
    Slug
    category
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServicesMutationVariables,
  APITypes.UpdateServicesMutation
>;
export const deleteServices = /* GraphQL */ `mutation DeleteServices(
  $input: DeleteServicesInput!
  $condition: ModelServicesConditionInput
) {
  deleteServices(input: $input, condition: $condition) {
    id
    Title
    Profile
    Description
    ShowInHome
    Status
    Slug
    category
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServicesMutationVariables,
  APITypes.DeleteServicesMutation
>;
export const createReports = /* GraphQL */ `mutation CreateReports(
  $input: CreateReportsInput!
  $condition: ModelReportsConditionInput
) {
  createReports(input: $input, condition: $condition) {
    id
    patientID
    appointmentID
    updatedBy
    patientName
    patientEmail
    patientPhoneNumber
    doctorID
    Status
    file {
      fileType
      name
      type
      description
      __typename
    }
    foodDiet
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportsMutationVariables,
  APITypes.CreateReportsMutation
>;
export const updateReports = /* GraphQL */ `mutation UpdateReports(
  $input: UpdateReportsInput!
  $condition: ModelReportsConditionInput
) {
  updateReports(input: $input, condition: $condition) {
    id
    patientID
    appointmentID
    updatedBy
    patientName
    patientEmail
    patientPhoneNumber
    doctorID
    Status
    file {
      fileType
      name
      type
      description
      __typename
    }
    foodDiet
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportsMutationVariables,
  APITypes.UpdateReportsMutation
>;
export const deleteReports = /* GraphQL */ `mutation DeleteReports(
  $input: DeleteReportsInput!
  $condition: ModelReportsConditionInput
) {
  deleteReports(input: $input, condition: $condition) {
    id
    patientID
    appointmentID
    updatedBy
    patientName
    patientEmail
    patientPhoneNumber
    doctorID
    Status
    file {
      fileType
      name
      type
      description
      __typename
    }
    foodDiet
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportsMutationVariables,
  APITypes.DeleteReportsMutation
>;
export const createReportType = /* GraphQL */ `mutation CreateReportType(
  $input: CreateReportTypeInput!
  $condition: ModelReportTypeConditionInput
) {
  createReportType(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportTypeMutationVariables,
  APITypes.CreateReportTypeMutation
>;
export const updateReportType = /* GraphQL */ `mutation UpdateReportType(
  $input: UpdateReportTypeInput!
  $condition: ModelReportTypeConditionInput
) {
  updateReportType(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportTypeMutationVariables,
  APITypes.UpdateReportTypeMutation
>;
export const deleteReportType = /* GraphQL */ `mutation DeleteReportType(
  $input: DeleteReportTypeInput!
  $condition: ModelReportTypeConditionInput
) {
  deleteReportType(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportTypeMutationVariables,
  APITypes.DeleteReportTypeMutation
>;
export const createCareerCategory = /* GraphQL */ `mutation CreateCareerCategory(
  $input: CreateCareerCategoryInput!
  $condition: ModelCareerCategoryConditionInput
) {
  createCareerCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCareerCategoryMutationVariables,
  APITypes.CreateCareerCategoryMutation
>;
export const updateCareerCategory = /* GraphQL */ `mutation UpdateCareerCategory(
  $input: UpdateCareerCategoryInput!
  $condition: ModelCareerCategoryConditionInput
) {
  updateCareerCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCareerCategoryMutationVariables,
  APITypes.UpdateCareerCategoryMutation
>;
export const deleteCareerCategory = /* GraphQL */ `mutation DeleteCareerCategory(
  $input: DeleteCareerCategoryInput!
  $condition: ModelCareerCategoryConditionInput
) {
  deleteCareerCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCareerCategoryMutationVariables,
  APITypes.DeleteCareerCategoryMutation
>;
export const createCareeres = /* GraphQL */ `mutation CreateCareeres(
  $input: CreateCareeresInput!
  $condition: ModelCareeresConditionInput
) {
  createCareeres(input: $input, condition: $condition) {
    id
    category
    title
    description
    status
    userID
    careerImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCareeresMutationVariables,
  APITypes.CreateCareeresMutation
>;
export const updateCareeres = /* GraphQL */ `mutation UpdateCareeres(
  $input: UpdateCareeresInput!
  $condition: ModelCareeresConditionInput
) {
  updateCareeres(input: $input, condition: $condition) {
    id
    category
    title
    description
    status
    userID
    careerImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCareeresMutationVariables,
  APITypes.UpdateCareeresMutation
>;
export const deleteCareeres = /* GraphQL */ `mutation DeleteCareeres(
  $input: DeleteCareeresInput!
  $condition: ModelCareeresConditionInput
) {
  deleteCareeres(input: $input, condition: $condition) {
    id
    category
    title
    description
    status
    userID
    careerImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCareeresMutationVariables,
  APITypes.DeleteCareeresMutation
>;
export const createSpecialityCategory = /* GraphQL */ `mutation CreateSpecialityCategory(
  $input: CreateSpecialityCategoryInput!
  $condition: ModelSpecialityCategoryConditionInput
) {
  createSpecialityCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSpecialityCategoryMutationVariables,
  APITypes.CreateSpecialityCategoryMutation
>;
export const updateSpecialityCategory = /* GraphQL */ `mutation UpdateSpecialityCategory(
  $input: UpdateSpecialityCategoryInput!
  $condition: ModelSpecialityCategoryConditionInput
) {
  updateSpecialityCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpecialityCategoryMutationVariables,
  APITypes.UpdateSpecialityCategoryMutation
>;
export const deleteSpecialityCategory = /* GraphQL */ `mutation DeleteSpecialityCategory(
  $input: DeleteSpecialityCategoryInput!
  $condition: ModelSpecialityCategoryConditionInput
) {
  deleteSpecialityCategory(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpecialityCategoryMutationVariables,
  APITypes.DeleteSpecialityCategoryMutation
>;
export const createSpeciality = /* GraphQL */ `mutation CreateSpeciality(
  $input: CreateSpecialityInput!
  $condition: ModelSpecialityConditionInput
) {
  createSpeciality(input: $input, condition: $condition) {
    id
    category
    title
    description
    status
    userID
    specialityImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSpecialityMutationVariables,
  APITypes.CreateSpecialityMutation
>;
export const updateSpeciality = /* GraphQL */ `mutation UpdateSpeciality(
  $input: UpdateSpecialityInput!
  $condition: ModelSpecialityConditionInput
) {
  updateSpeciality(input: $input, condition: $condition) {
    id
    category
    title
    description
    status
    userID
    specialityImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSpecialityMutationVariables,
  APITypes.UpdateSpecialityMutation
>;
export const deleteSpeciality = /* GraphQL */ `mutation DeleteSpeciality(
  $input: DeleteSpecialityInput!
  $condition: ModelSpecialityConditionInput
) {
  deleteSpeciality(input: $input, condition: $condition) {
    id
    category
    title
    description
    status
    userID
    specialityImage
    Slug
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSpecialityMutationVariables,
  APITypes.DeleteSpecialityMutation
>;
export const createBanner = /* GraphQL */ `mutation CreateBanner(
  $input: CreateBannerInput!
  $condition: ModelBannerConditionInput
) {
  createBanner(input: $input, condition: $condition) {
    Title
    Caption
    Files
    Status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBannerMutationVariables,
  APITypes.CreateBannerMutation
>;
export const updateBanner = /* GraphQL */ `mutation UpdateBanner(
  $input: UpdateBannerInput!
  $condition: ModelBannerConditionInput
) {
  updateBanner(input: $input, condition: $condition) {
    Title
    Caption
    Files
    Status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBannerMutationVariables,
  APITypes.UpdateBannerMutation
>;
export const deleteBanner = /* GraphQL */ `mutation DeleteBanner(
  $input: DeleteBannerInput!
  $condition: ModelBannerConditionInput
) {
  deleteBanner(input: $input, condition: $condition) {
    Title
    Caption
    Files
    Status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBannerMutationVariables,
  APITypes.DeleteBannerMutation
>;
export const createFAQ = /* GraphQL */ `mutation CreateFAQ(
  $input: CreateFAQInput!
  $condition: ModelFAQConditionInput
) {
  createFAQ(input: $input, condition: $condition) {
    Title
    Description
    Status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFAQMutationVariables,
  APITypes.CreateFAQMutation
>;
export const updateFAQ = /* GraphQL */ `mutation UpdateFAQ(
  $input: UpdateFAQInput!
  $condition: ModelFAQConditionInput
) {
  updateFAQ(input: $input, condition: $condition) {
    Title
    Description
    Status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFAQMutationVariables,
  APITypes.UpdateFAQMutation
>;
export const deleteFAQ = /* GraphQL */ `mutation DeleteFAQ(
  $input: DeleteFAQInput!
  $condition: ModelFAQConditionInput
) {
  deleteFAQ(input: $input, condition: $condition) {
    Title
    Description
    Status
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFAQMutationVariables,
  APITypes.DeleteFAQMutation
>;
export const createPayment = /* GraphQL */ `mutation CreatePayment(
  $input: CreatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  createPayment(input: $input, condition: $condition) {
    id
    appointmentId
    razorpay_payment_id
    Amount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentMutationVariables,
  APITypes.CreatePaymentMutation
>;
export const updatePayment = /* GraphQL */ `mutation UpdatePayment(
  $input: UpdatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  updatePayment(input: $input, condition: $condition) {
    id
    appointmentId
    razorpay_payment_id
    Amount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentMutationVariables,
  APITypes.UpdatePaymentMutation
>;
export const deletePayment = /* GraphQL */ `mutation DeletePayment(
  $input: DeletePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  deletePayment(input: $input, condition: $condition) {
    id
    appointmentId
    razorpay_payment_id
    Amount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentMutationVariables,
  APITypes.DeletePaymentMutation
>;
