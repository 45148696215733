import React, { useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useIdleTimeout } from '../SessionExpire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import './NavBar.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = React.useState<any>(null);
  const [anchorElNav, setAnchorElNav] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLButtonElement | null>(null);
  const [token, setToken] = React.useState<string | null>(null);
  const [isUserDropdownOpen, setUserDropdownOpen] = React.useState(false);
  const [isGalleryDropdownOpen, setGalleryDropdownOpen] = React.useState(false);

  const navbarCollapseRef = useRef<HTMLDivElement>(null);
  const navbarInnerCollapseRef = useRef<HTMLDivElement>(null);

  

  const handleMenuItemClick : any= () => {
    handleCloseNavbar();
    //  setGalleryDropdownOpen(false);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    
    setAnchorElNav(event.currentTarget);
    
  };
  
  

 
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    // setAnchorElUser(null);
    handleCloseNavbar()
  };
  const handleGalleryDropdownToggle = () => {
    setGalleryDropdownOpen(!isGalleryDropdownOpen);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleScroll = (hash: string) => {
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleCloseNavbar = () => {
   
    if (navbarCollapseRef.current) {
      const collapseElement = navbarCollapseRef.current;
      if (collapseElement.classList.contains('show')) {
        collapseElement.classList.remove('show');
      }
    }
  };

  const handleInnerCloseNavbar = () => {
    if (navbarInnerCollapseRef.current) {
      const collapseElement = navbarInnerCollapseRef.current;
      if (collapseElement.classList.contains('show')) {
        collapseElement.classList.remove('show');
      }
    }
  };




  const isIdle = useIdleTimeout(1000 * 900);
  const isActive = useIdleTimeout(1000 * 900);

  React.useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUser = JSON.parse(userData);
      setUser(parsedUser);
      setToken(parsedUser.token);
    } else {
      setToken(null);
    }
  }, [localStorage.getItem('userData')]);

  const logout = () => {
    setUser(null);
    localStorage.removeItem('userData');
    setToken(null);
    navigate("/login");
  };

  React.useEffect(() => {
    if (location.hash) {
      handleScroll(location.hash.substring(1));
    }
  }, [location]);

  // const handleUserDropdownToggle = () => {
  //   setUserDropdownOpen(true);
  // };

  const handleUserDropdownToggle = () => {
    setUserDropdownOpen((prev) => !prev);
  };
  const handleUserDropdownClose = () => {
    setUserDropdownOpen(false);
  };

  const handleInnerUserDropdownToggle = () => {
    setUserDropdownOpen((prev) => !prev);
    
  };




  const logUser = localStorage.getItem('userData');
  const logUserObject = logUser ? JSON.parse(logUser) : null;

  // console.log('logUserObject', logUserObject);


  //   useEffect(() => {
  //     if(!isIdle && logUserObject && logUserObject?.accessToken) {  
  //         localStorage?.removeItem("userData");
  //             alert("Your session has expired. Please login again.");
  //             navigate('/')
  //     }
  // }, [logUserObject, navigate, isIdle]);


  useEffect(() => {
    if (!isIdle && logUserObject && logUserObject?.accessToken) {
      // Use a flag to ensure the alert only shows once
      const isAlertShown = localStorage.getItem('isAlertShown');

      if (!isAlertShown) {
        alert("Your session has expired. Please login again.");
        localStorage.setItem('isAlertShown', 'true');
        localStorage.removeItem("userData");
        navigate('/');

        // Optionally, remove the flag after navigating to prevent it from being set indefinitely
        setTimeout(() => {
          localStorage.removeItem('isAlertShown');
        }, 0);  // You can adjust this timeout as needed
      }
    }
  }, [isIdle, logUserObject, navigate]);



  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar" style={{ fontFamily: 'helvetica' }}>
        <div className="container-fluid">

          <div className='mobNav'>

            <div>
              <a href="javascript:void(0)" onClick={() => navigate('/')}>
                <img src={`${process.env.REACT_APP_HOST}images/DentalLogo1.png`} className="logo mbLogo" alt="Gurram Dental Care" style={{ width: '40px', marginRight: '5px' }} /></a>
              <a href="javascript:void(0)" onClick={() => navigate('/')} className="navbar-brand">
                <strong className='brndTtl'>Gurram Dental Care</strong></a>
            </div>

            <button className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#ftco-nav"
              aria-controls="ftco-nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleOpenNavMenu}
              // onMouseLeave={handleCloseNavbar}
              
            >
               
              <span className="oi oi-menu"></span>
            </button>
          </div>




          <div className="collapse navbar-collapse" id="ftco-nav" ref={navbarCollapseRef}>
            <ul className="navbar-nav ml-auto navtabs">


              <div ref={navbarInnerCollapseRef} onMouseLeave={handleUserDropdownClose}>

                {logUserObject?.Role === 'MasterAdmin' && (

                  <li className="nav-item listStyle cta mobAuthentication" style={{ listStyle: 'none' }}>
                    {/* <Link className="nav-link text-black dropdown" to="#" onMouseEnter={handleUserDropdownToggle} onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px' }}> */}

                    <Link className="nav-link text-black dropdown" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px' }}>

                      {/* <div className="icon-text text-white">
<FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} />
</div> */}
                      <div className="mdsize" style={{ fontSize: '13px', color: 'white' }}> Hi, {logUserObject.userName}
                        <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                      </div>

                      <ul className={`dropdown-menu userDetails-icon categories-container listItems ${isUserDropdownOpen ? 'show' : ''}`} >
                        {/* <li   className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          Welcome, {logUserObject.userName}
                        </li> */}
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="/ListDoctor" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Doctors
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="/BulkUpload" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Upload Existing patients
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="/listreviews" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Reviews
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listServices" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Services
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listBlogs" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Blogs
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/ListRegisteredPatients" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Patients
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Appointments
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listGallery" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Gallery
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listSpeciality" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Specialities
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listCareer" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Careers
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AvailabilityList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Doctor's Availibilty Dates
                          </Link>
                        </li>
                        {/* <li>
                          <Link className="dropdown-item" to="/sendSms" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Send SMS
                          </Link>
                        </li> */}
                        <li>
                          <Link className="dropdown-item" to="/enquirylist" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            EnquiryList
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listTermsAndCondtions" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Pages
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listSliders" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Sliders
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listFAQs" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage FAQs
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listLab" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Labs
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/BlogCategoryList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Blog Category List
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/careerCategoryList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Career Category List
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/specialityCategoryList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            speciality Category List
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/serviceCategoryList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            service Category List
                          </Link>
                        </li>

                        {/* <li>
<Link className="dropdown-item" to="/listPharmacy" onClick={handleUserDropdownClose}>
Manage Pharmacy
</Link>
</li> */}
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>
                )}
                {logUserObject?.Role === 'Doctor' && (
                  <li className="nav-item mobAuthentication " style={{ listStyle: 'none' }}>
                    <Link className="nav-link text-black dropdown" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px', marginTop: '-6px' }}>
                      <div className="icon-text text-white">
                        {/* <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} /> */}
                        <span style={{ fontSize: '12px', color: 'white' }}>Hi, {logUserObject?.userName}
                          <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                        </span>
                      </div>
                      <ul className={`dropdown-menu mt-10 userDetails-icon ${isUserDropdownOpen ? 'show' : ''}`} >
                        <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          <span style={{ fontSize: '12px', color: 'white' }}>Welcome, {logUserObject?.userName}</span>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/doctorAvailability" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Availability Dates
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Appointments
                          </Link>
                          <li>
                            <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                              Profile
                            </Link>
                          </li>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>
                )}
                {logUserObject?.Role === 'Patient' && (
                  <li className="nav-item listStyle mobAuthentication" style={{ listStyle: 'none' }}>
                    <Link className="nav-link text-black dropdown" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px', marginTop: '-6px' }}>
                      <div className="icon-text text-white">
                        {/* <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} /> */}
                        <span style={{ fontSize: '12px', color: 'white' }}>
                          Hi, {logUserObject.userName}
                          <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                        </span>
                      </div>
                      <ul className={`dropdown-menu mt-10 userDetails-icon ${isUserDropdownOpen ? 'show' : ''}`} style={{ marginLeft: '-175px' }}>
                        {/* <li className="dropdown-item" style={{ fontWeight: 'bold' }}>

</li> */}
                        <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          <span style={{ fontSize: '12px', color: 'white' }}>Welcome, {logUserObject?.userName}</span>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Appointments
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>
                )}
                {logUserObject?.Role === 'Lab' && (
                  <li className="nav-item listStyle mobAuthentication" style={{ listStyle: 'none' }}>
                    <Link className="nav-link text-black dropdown" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px', marginTop: '-6px' }}>
                      <div className="icon-text text-white">
                        {/* <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} /> */}
                        <span style={{ fontSize: '12px', color: 'white' }}>
                          Hi, {logUserObject?.userName}
                          <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                        </span>
                      </div>
                      <ul className={`dropdown-menu mt-10 userDetails-icon ${isUserDropdownOpen ? 'show' : ''}`} style={{ marginLeft: '-175px' }}>
                        <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          <span style={{ fontSize: '12px', color: 'white' }}>
                            Welcome, {logUserObject?.userName}</span>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Appointments
                          </Link>
                        </li>
                        {/* <li>
<Link className="dropdown-item" to="/reports" onClick={handleUserDropdownClose}>
Manage Reports
</Link>
</li> */}
                        <li>
                          <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>
                )}
                {logUserObject?.Role === 'Pharmacy' && (
                  <li className="nav-item listStyle mobAuthentication" style={{ listStyle: 'none' }}>
                    <Link className="nav-link text-black dropdown" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px', marginTop: '-6px' }}>
                      {/* <div className="icon-text text-white">
<FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} />
</div> */}
                      <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                        <span style={{ fontSize: '12px', color: 'white' }}>Welcome, {logUserObject?.userName}</span>
                      </li>
                      <ul className={`dropdown-menu mt-10 userDetails-icon ${isUserDropdownOpen ? 'show' : ''}`} style={{ marginLeft: '-175px' }}>
                        <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          <span style={{ fontSize: '12px', color: 'white' }}>
                            Hi, {logUserObject.userName}
                            <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                          </span>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Appointments
                          </Link>
                        </li>
                        {/* <li>
<Link className="dropdown-item" to="/prescriptions" onClick={handleUserDropdownClose}>
Manage Prescriptions
</Link>
</li> */}
                        <li>
                          <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>

                )}



                {!logUserObject && (
                  <li className="listStyle nav-item cta mobAuthentication " >
                    <Link className="nav-link" to="/login" style={{ fontSize: '13px', color: 'white' }} onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                      Hi, Register / Sign in
                    </Link>
                  </li>

                )}


              </div>


              <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                <Link to="/" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Home</Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/about" className="nav-link" onClick={() => handleScroll("")}>About Us</Link>
              </li> */}
              <li className={`nav-item ${location.pathname === '/AboutUs' ? 'active' : ''}`}>
                <Link to="/AboutUs" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>About Us</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/services' ? 'active' : ''}`}>
                <Link to="/services" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Services</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/Doctors' ? 'active' : ''}`}>
                <Link to="/Doctors" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Doctors</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/Blogs' ? 'active' : ''}`}>
                <Link to="/Blogs" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Blogs</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/Specialities' ? 'active' : ''}`}>
                <Link to="/Specialities" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Specialities</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/careers' ? 'active' : ''}`}>
                <Link to="/careers" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Careers</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/ContactUs' ? 'active' : ''}`}>
                <Link to="/ContactUs" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Contact Us</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/gallery' ? 'active' : ''}`}>
                <Link to="/gallery" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Gallery</Link>
              </li>

              <li className={`nav-item ${location.pathname === '/PatientAppointment' ? 'active' : ''}`}>
                <Link to="/PatientAppointment" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Book Appointment</Link>
              </li>

              <li className={`nav-item ${location.pathname === '/TrackAppointment' ? 'active' : ''}`}>
                <Link to="/TrackAppointment" className="nav-link" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>Track Appointment</Link>
              </li>

              <div ref={navbarInnerCollapseRef}>

                {logUserObject?.Role === 'MasterAdmin' && (

                  <li className="nav-item listStyle mobHide" style={{ listStyle: 'none' }}>
                    <Link className="nav-link text-black dropdown dropdownscroll" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle}>
                      {/* <div className="icon-text text-white">
                      <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} />
                    </div> */}
                      <span className="mdsize" style={{ fontSize: '13px', color: 'white' }}> Hi, {logUserObject.userName}
                        <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                      </span>

                      <ul className={`dropdown-menu userDetails-icon categories-container  ${isUserDropdownOpen ? 'show' : ''}`}>
                        <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          Welcome, {logUserObject.userName}
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>
                        {/* <li>
                        <Link className="dropdown-item" to="/reports" onClick={handleUserDropdownClose}>
                          Manage Reports
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/prescriptions" onClick={handleUserDropdownClose}>
                          Manage Prescriptions
                        </Link>
                      </li> */}
                        <li>
                          <Link className="dropdown-item" to="/ListDoctor" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Doctors
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/BulkUpload" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Upload Existing Patients
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="/listreviews" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Reviews
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listServices" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Services
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listBlogs" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Blogs
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/ListRegisteredPatients" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Patients
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Appointments
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listGallery" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Gallery
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listSpeciality" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Specialities
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listCareer" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Careers
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AvailabilityList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Doctor's Availibilty Dates
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/sendSms" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Send SMS
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/enquirylist" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            EnquiryList
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listTermsAndCondtions" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Pages
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listSliders" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Sliders
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listFAQs" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage FAQs
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/listLab" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Manage Labs
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/BlogCategoryList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Blog Category List
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/careerCategoryList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Career Category List
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/specialityCategoryList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            speciality Category List
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/serviceCategoryList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            service Category List
                          </Link>
                        </li>

                        {/* <li>
                        <Link className="dropdown-item" to="/listPharmacy" onClick={handleUserDropdownClose}>
                          Manage Pharmacy
                        </Link>
                      </li> */}
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>
                )}
                {logUserObject?.Role === 'Doctor' && (
                  <li className="nav-item mobHide" style={{ listStyle: 'none' }}>
                    <Link className="nav-link text-black dropdown" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px', marginTop: '-14px' }}>
                      <div className="icon-text text-white">
                        {/* <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} /> */}
                        <span style={{ fontSize: '12px', color: 'white' }}>Hi, {logUserObject?.userName}
                          <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                        </span>
                      </div>
                      <ul className={`dropdown-menu mt-10 userDetails-icon ${isUserDropdownOpen ? 'show' : ''}`} >
                        <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          <span style={{ fontSize: '12px', color: 'white' }}>Welcome, {logUserObject?.userName}</span>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/doctorAvailability" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Availability Dates
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Appointments
                          </Link>
                          <li>
                            <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                              Profile
                            </Link>
                          </li>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>
                )}
                {logUserObject?.Role === 'Patient' && (
                  <li className="nav-item listStyle mobHide" style={{ listStyle: 'none' }}>
                    <Link className="nav-link text-black dropdown" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px', marginTop: '-14px' }}>
                      <div className="icon-text text-white">
                        {/* <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} /> */}
                        <span style={{ fontSize: '12px', color: 'white' }}>
                          Hi, {logUserObject.userName}
                          <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                        </span>
                      </div>
                      <ul className={`dropdown-menu mt-10 userDetails-icon ${isUserDropdownOpen ? 'show' : ''}`} style={{ marginLeft: '-175px' }}>
                        {/* <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                       
                      </li> */}
                        <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          <span style={{ fontSize: '12px', color: 'white' }}>Welcome, {logUserObject?.userName}</span>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Appointments
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>
                )}
                {logUserObject?.Role === 'Lab' && (
                  <li className="nav-item listStyle mobHide" style={{ listStyle: 'none' }}>
                    <Link className="nav-link text-black dropdown" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px', marginTop: '-14px' }}>
                      <div className="icon-text text-white">
                        {/* <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} /> */}
                        <span style={{ fontSize: '12px', color: 'white' }}>
                          Hi, {logUserObject?.userName}
                          <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                        </span>
                      </div>
                      <ul className={`dropdown-menu mt-10 userDetails-icon ${isUserDropdownOpen ? 'show' : ''}`} style={{ marginLeft: '-175px' }}>
                        <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          <span style={{ fontSize: '12px', color: 'white' }}>
                            Welcome, {logUserObject?.userName}</span>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Appointments
                          </Link>
                        </li>
                        {/* <li>
                      <Link className="dropdown-item" to="/reports" onClick={handleUserDropdownClose}>
                        Manage Reports
                      </Link>
                    </li> */}
                        <li>
                          <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>
                )}
                {logUserObject?.Role === 'Pharmacy' && (
                  <li className="nav-item listStyle mobHide" style={{ listStyle: 'none' }}>
                    <Link className="nav-link text-black dropdown" to="#" onMouseLeave={handleUserDropdownClose} onClick={handleInnerUserDropdownToggle} style={{ fontSize: '18px', marginTop: '-14px' }}>
                      {/* <div className="icon-text text-white">
                      <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: '30px', color: 'white' }} />
                    </div> */}
                      <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                        <span style={{ fontSize: '12px', color: 'white' }}>Welcome, {logUserObject?.userName}</span>
                      </li>
                      <ul className={`dropdown-menu mt-10 userDetails-icon ${isUserDropdownOpen ? 'show' : ''}`} style={{ marginLeft: '-175px' }}>
                        <li className="dropdown-item" style={{ fontWeight: 'bold' }}>
                          <span style={{ fontSize: '12px', color: 'white' }}>
                            Hi, {logUserObject.userName}
                            <ArrowDropDownIcon style={{ marginLeft: '0px', width: '25px' }} />
                          </span>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/DashBoard" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/AppointmentList" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Appointments
                          </Link>
                        </li>
                        {/* <li>
                      <Link className="dropdown-item" to="/prescriptions" onClick={handleUserDropdownClose}>
                        Manage Prescriptions
                      </Link>
                    </li> */}
                        <li>
                          <Link className="dropdown-item" to="/UpdateProfile" onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </Link>
                  </li>

                )}

              </div>

              {!logUserObject && (
                <li className="listStyle nav-item cta mobHide" >
                  <Link className="nav-link" to="/login" style={{ color: 'white' }} onClick={() => { handleScroll(""); handleMenuItemClick(); }}>
                    Hi, Register / Signin
                  </Link>
                </li>

              )}



            </ul>
          </div>

        </div>
      </nav>

    </>
  );
};

export default NavBar;

