import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { TextField, Button, Select, MenuItem } from '@mui/material';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import './listCareer.css';
import getCareer from '../../Services/Careers';
import { updDelFile } from '../shared/fileupload';
import { Helmet } from 'react-helmet';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';


interface Career {
    catergory: any,
    title: string,
    description: string,
    bolgImage: string
}

const ListCareer = () => {
    const initialState: Career = {
        catergory: '',
        title: '',
        description: '',
        bolgImage: '',
    };

    const navigate = useNavigate();
    const [data, setData] = useState<Career>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [careerlist, setCareerlist] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('PDF Viewer Modal');
    const [singleReview, setSingleReview] = useState<any>({});
    const [categories, setCategories] = useState([]);

    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // useAuthRedirect(logUserObject);

    const listCareers = async () => {
        const response = await getCareer().CareerList();
        const categoryname = await getCareer().getcategory();
        const categoryIds = response?.map((item: any) => item?.category)
        // console.log("categoryIds", categoryIds)
        const updatedResponse = response?.map((item: any) => {
            const categoryItems = item?.category[0]?.split(',');
            const categoryNames = categoryItems?.map((cateId: string) => {
                const category = categoryname?.find((cate: any) => cate?.id === cateId);
                return category ? category?.name : cateId;
            });
            return {
                ...item,
                categoryName: categoryNames
            };
        });


        setCareerlist(updatedResponse);
    };

    const catergoryname = async () => {
        const response: any = await getCareer().CategoryList();
        setCategories(response)
        const ids = response.map((category: any) => category.id);
    };

    useEffect(() => {
        listCareers();
        catergoryname();
    }, []);
    
    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected Career?");
        if (confirmBox) {
            const career: any = await getCareer().getSingleCareer(id);
            const filedel = await updDelFile(career?.careerImage[0], "images");
            if (filedel?.status === 200) {
                await getCareer().CareerDelete(id);
                listCareers();
            }
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    
    const filteredData: any = careerlist?.filter((row: any) =>
        Object?.values(row)?.some((value: any) => value?.toString().toLowerCase().includes(searchTerm?.toLowerCase()))
    );

    const totalPages = Math?.ceil(filteredData?.length / pageSize);
    const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

  

    const closeModal = () => {
        setIsModalOpen(false);
        setModalHeading("");
    };

    const handleStatusToggle = async (rowData: any) => {
        const updatedStatus = rowData?.status === 1 ? 0 : 1;
        const updatedRowData = { ...rowData, id: rowData?.id, status: updatedStatus };
        const { categoryName, ...newObject } = updatedRowData;
        const update = await getCareer().CareerUpdate(newObject);
        setCareerlist((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, status: updatedStatus } : row
            )
        );
    };
    async function handleEdit(event: any) {
        navigate('/addCareer', { state: { id: event?.id } });
    }

    const handleSubmit = (setUpdateService: boolean) => {
        navigate('/addCareer', { state: { setUpdateService: false } });
    };
    // const truncateText = (text: any) => {
    // if (!text) return '';
    // const truncatedText = text?.length > 10 ? text?.substring(0, 10) + '...' : text;
    // return truncatedText;
    // };
    const removeHtmlTags = (str: any) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    const truncateText = (text: any, maxLength: any) => {
        if (!text) return '';
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + '...';
    };

    return (
        <>

            <Helmet>
                <title>Careers</title>
                <meta name="description" content="This is the Careers page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Careers
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                    <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li> Careers</li>
                </ul>
            </div>

            <div className="container">
                <div className="row tablefs">
                    {/* <div className='col-md-2'>
 < Sidebar />
 </div> */}
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <div className="page-size-dropdown">

                                    <label style={{ padding: '5px' }}>Show</label>
                                    <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                    </select>
                                    <label style={{ padding: '5px' }}>entries</label>
                                </div>

                                <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                                    {logUserObject?.Role === "MasterAdmin" ? (<div className="pb-3">
                                        <Button
                                            onClick={() => handleSubmit(false)}
                                            style={{ marginRight: '10px', float: 'right' }}
                                            // startIcon={<EditIcon style={{ color: 'green' }}  />}
                                            className='btn btn-primary'
                                        >
                                            Add
                                        </Button>
                                    </div>) : null}
                                    <p style={{ marginRight: '10px' }}>Search</p>
                                    <div>
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="search-bar"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-bordered table-striped table-responsive listCareers">
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                            <th className='sno'>S.No</th>
                                            <th className='mdl'>Title</th>
                                            {/* <th className='mdl'>Description</th> */}
                                            <th className='mdl'>Category</th>
                                            <th className='actions'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((row: any, index: number) => (

                                            <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>{highlightText(row?.title)}</td>
                                                {/* <td
                                                    dangerouslySetInnerHTML={{
                                                        __html: row?.description?.substring(0, 30) + (row?.description?.length > 30 ? "..." : "")
                                                    }}
                                                /> */}
                                                <td>
                                                    {row?.categoryName?.map((catName: any, index: number) => (
                                                        <div key={index} style={{ marginRight: '5px' }}>
                                                            {catName}{index < row?.categoryName?.length - 1 ? ',' : ''}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td className='actions'>
                                                    <Button title="Edit"
                                                        onClick={() => handleEdit(row)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<EditIcon style={{ color: 'green' }} />}
                                                    >

                                                    </Button>
                                                
                                                    <Button title="Status"
                                                        onClick={() => handleStatusToggle(row)}
                                                        className={row?.status === 1 ? 'statusSuccess' : 'statusDanger'}
                                                        startIcon={<VisibilityIcon />}
                                                    >
                                                    </Button>
                                                    <Button title="Delete"
                                                        onClick={() => handleDelete(row?.id)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<DeleteIcon style={{ color:'#af0e0e' }} />}
                                                    >

                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className='mttable'>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                <div className="paginationstyle mttable1">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                handlePageChange(currentPage - 1);
                                                window.scrollTo(0, 0); // Scroll to top
                                            }
                                        }}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage < totalPages) {
                                                handlePageChange(currentPage + 1);
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>

                </div>
            </div>


        </>

    );
};

export default ListCareer;

