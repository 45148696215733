import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { SHA256 } from "crypto-js";
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Container,
  Paper,
  Grid,
  TextField,
  FormControl,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Button,
  IconButton,
} from "@mui/material"; // Material-UI components imported here

import userService from "../../Services/user/user.service";
import { registerValidationSchema } from "./registerValidationSchema";
import { useIdleTimeout } from "../shared/SessionExpire";
import DoctorSpecialization from "../Doctor/Doctorspec";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import bg_img from "./docReg.jpg";
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from "../shared/fileupload";
import CKEditorComponent from "../../utills/CKEditor";
import Users from "../../utills/users";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Sidebar from '../DashBoard/sidebar';
// import { Facebook, Twitter, WhatsApp } from '@mui/icons-material';
// import { FileExtension} from "../utills/FileExtension";
// import replaceSpecialChars from '../../utills/Fileformat';
import { Helmet } from 'react-helmet';
import { FormatFile } from "../../utills/Fileformat";
import Loader from "../shared/Loader";
import { Alert } from "react-bootstrap";
// import useAuthRedirect from "../../utills/Redirect/useAuthRedirect";


interface SocialIcons {
  name: string;
  icon: string;
}
interface RegisterDetails {
  FirstName: string;
  LastName: string;
  Gender: string;
  Age: string;
  Specialization: string;
  Role: string;
  PhoneNumber: string;
  EmailId: string;
  Password: string;
  countryCode: string;
  confirmPassword: string;
  // [key: string]: string | number | undefined;
  userProfile: string;
  Description: string;
  TimeSlot: string,
  SocialIcons: SocialIcons[];
  Slug: string;
  qualification: string;
  Status: string;
  userType: string;
  resetPWD: string;

}

const initialState: RegisterDetails = {
  FirstName: "",
  LastName: "",
  Age: "N/A",
  Gender: "",
  Specialization: "", // Change type to Specialization | null
  EmailId: "",
  PhoneNumber: "",
  Password: "",
  Role: "Doctor",
  confirmPassword: "",
  countryCode: "+91",
  userProfile: "",
  Description: "",
  TimeSlot: '',
  SocialIcons: [
    { name: "Facebook", icon: "" },
    { name: "Twitter", icon: "" },
    { name: "WhatsApp ", icon: "" },
  ],
  Slug: '',
  qualification: '',
  Status: '',
  userType: '',
  resetPWD: ''
}

function DoctorRegister() {
  const navigate = useNavigate();
  const location = useLocation();
  const [errMsg, setErrMsg] = useState(initialState);
  const isIdle = useIdleTimeout(1000 * 900);
  const isActive = useIdleTimeout(1000 * 900);
  const [errMsgMatchpwd, setErrMsgMatchpwd] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errMsgPwd, setErrMsgPwd] = useState("");
  const [errMsgMatch, setErrMsgMatch] = useState("");
  const [InputDetails, setInputDetails] = useState<RegisterDetails>(initialState);
  const [doctorDetails, setDoctorDetails] = useState<any>();
  let [updateDoctor, setUpdateDoctor] = useState<boolean>(false);
  const [file, setFile] = useState<any>([]);
  const [directory, setDirectory] = useState();
  const [doctor, setDoctor] = useState<any>([]);
  const [ErrMsgSlug, setErrMsgSlug] = useState("");
  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState<any>('');
  // useAuthRedirect(logUserObject);




  const fieldRefs: any = useRef({
    FirstName: null as HTMLDivElement | null,
    LastName: null as HTMLDivElement | null,
    Specialization: null as HTMLDivElement | null,
    Slug: null as HTMLDivElement | null,
    EmailId: null as HTMLDivElement | null,
    countryCode: null as HTMLDivElement | null,
    PhoneNumber: null as HTMLDivElement | null,
    Gender: null as HTMLDivElement | null,
    Password: null as HTMLDivElement | null,
    confirmPassword: null as HTMLDivElement | null,
    userProfile: null as HTMLDivElement | null,
    qualification: null as HTMLDivElement | null,
    TimeSlot: null as HTMLDivElement | null,
    Description: null as HTMLDivElement | null,
  });

  const hashPassword = (password: string) => {
    try {
      const hashedPassword = SHA256(password).toString();
      return hashedPassword;
    } catch (error) {
      console.error("Error hashing password:", error);
      return "";
    }
  };

  useEffect(() => {
    getExistingUser();
  }, [location?.state]);

  const getExistingUser: any = async () => {
    if (location?.state?.id) {
      const getDoctorDetails = await userService().ListDoctors(location?.state?.id);
      console.log(getDoctorDetails[0]);
      if (getDoctorDetails?.length > 0) {
        setInputDetails(getDoctorDetails[0]);
        setUpdateDoctor(true);
      }
      setDoctorDetails(getDoctorDetails[0]);
    }
  };

  const handleRemove = () => {
    if (window.confirm('Are you sure you want to remove this file?')) {
      RemoveProfileforAll(InputDetails?.userProfile, setInputDetails, 'userProfile', 'images'); // Pass the key and S3 directory
    } else {
      alert('Deletion canceled');
    }
  };

  const handleUpdate = async (values: any, { setSubmitting }: any) => {
    window.scrollTo(0, 0);


    try {
      setLoading(true);
      const userEmail = await userService().validateEmail(values?.EmailId.toLowerCase());
      const email = userEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.id
      const userPhoneNumber = await userService().validatePhonenumber(values.PhoneNumber);
      const phn = userPhoneNumber?.existingPhoneUser?.data?.listUsers?.items[0]?.id
      // console.log('detailvalues', userPhoneNumber?.existingPhoneUser?.data?.listUsers?.items[0]?.id)
      if (userEmail?.status === 1 && userPhoneNumber?.status === 1 && email != values?.id && phn != values?.id) {
        setErrMsgMatch('Email address and phone number are already in use');
        setLoading(false);
        setSubmitting(false);
        return;
      } else if (userEmail?.status === 1 && email != values?.id) {
        setErrMsgMatch('Email address is already in use');
        setLoading(false);
        setSubmitting(false);
        return;
      } else if (userPhoneNumber?.status === 1 && phn != values?.id) {
        setErrMsgMatch('Phone number is already in use');
        setLoading(false);
        setSubmitting(false);
        return;
      } else {
        setErrMsgMatch('')
      }
      const generateSlug = (name: any) => name.replace(/\s+/g, '_').toLowerCase();
      const slug = generateSlug(values?.Slug)
      let slugError = false;
      const SlugDetails = await userService().validateSlug(slug);
      if (SlugDetails?.msg) {
        SlugDetails?.data?.map((item: any) => {
          if (item?.id !== location?.state?.id) {
            slugError = true;
          }
        });
        if (slugError) {
          setErrMsgSlug("Slug is already in use");
          return;
        } else {
          // setErrMsgSlug("");
          setTimeout(() => setErrMsgSlug(''), 3000);
        }
      }
      // let newFilename: any;
      // if (file?.name) {
      // const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
      // newFilename = replaceSpecialChars(filename);
      const newFilename = file?.name ? await FormatFile(file) : '';
      file?.name && await updDelFile(values?.userProfile, "images");
      file?.name && await SendFile(file, newFilename, "images");
      const formatEmail = values?.EmailId.toLowerCase();
      // }

      const GetInsertDataUpdate = {
        id: location?.state?.id,
        FirstName: values?.FirstName,
        LastName: values?.LastName,
        Gender: values?.Gender,
        Age: values?.Age,
        Specialization: values?.Specialization,
        EmailId: formatEmail,
        PhoneNumber: values?.PhoneNumber,
        Password: values?.Password,
        Status: values?.Status,
        Role: "Doctor",
        countryCode: values?.countryCode,
        userProfile: file?.name ? newFilename : values?.userProfile,
        ShowInTeam: "1",
        TimeSlot: values?.TimeSlot,
        Description: values?.Description,
        SocialIcons: values?.SocialIcons,
        Slug: slug,
        qualification: values?.qualification,
        userType: values?.userType,
        resetPWD: values?.resetPWD
      };
      // console.log("GetInsertDataUpdate",GetInsertDataUpdate)
      const userResponse = await userService().updateDoctorByData(GetInsertDataUpdate);
      if (userResponse) {
        setLoading(false);
        setAlertMsg("Doctor Details Updated successfully!");
        setSubmitting(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);

    }
  }


  const handleSubmit = async (
    values: RegisterDetails,
    { setSubmitting, resetForm }: any
  ) => {
    // console.log("values", values);
    window.scrollTo(0, 0);
    const generateSlug = (name: any) => name.replace(/\s+/g, '_').toLowerCase();
    const slug = generateSlug(values?.Slug)

    if (values.Password !== values.confirmPassword) {
      setErrMsgMatchpwd("Password does not match");
      return;
    }

    try {
      setLoading(true);
      const userEmail = await userService().validateEmail(values?.EmailId.toLowerCase());
      console.log('userEmail',userEmail)
      const userPhoneNumber = await userService().validatePhonenumber(
        values.PhoneNumber
      );
      const SlugDetails = await userService().validateSlug(slug);
      if (userEmail?.status === 1 && userPhoneNumber?.status === 1) {
        setErrMsgMatch("Email address and phone number are already in use");
        setLoading(false);

        return;
      } else if (userEmail?.status === 1) {
        setErrMsgMatch("Email address is already in use");
        setLoading(false);
        return;
      } else if (userPhoneNumber?.status === 1) {
        setErrMsgMatch("Phone number is already in use");
        setLoading(false);
        return;
      } else if (SlugDetails?.msg) {
        setErrMsgSlug("Slug is already in use");
        setLoading(false);
        return;
      } else {
        // setTimeout(()=>setErrMsgMatch(''),3000);
        setErrMsgMatch('')
        // return;
      }
      // let newFilename: any;
      // if (file.name) {
      //   const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
      //   newFilename = replaceSpecialChars(filename);
      // }
      const newFilename = file?.name ? await FormatFile(file) : '';
      file?.name && await SendFile(file, newFilename, directory);
      const formatEmail = values?.EmailId.toLowerCase();
      const PassWord = values?.Password

      const GetInsertDataUpdate = {
        FirstName: values?.FirstName,
        LastName: values?.LastName,
        Gender: values?.Gender,
        Age: values?.Age,
        Specialization: values?.Specialization,
        EmailId: formatEmail,
        PhoneNumber: values?.PhoneNumber,
        TimeSlot: values?.TimeSlot,
        Password: hashPassword(values?.Password),
        Status: "1",
        Role: "Doctor",
        countryCode: values?.countryCode,
        userProfile: file?.name ? newFilename : null,
        ShowInTeam: "1",
        Description: values?.Description,
        SocialIcons: values?.SocialIcons,
        Slug: slug,
        qualification: values?.qualification,
        userType: "R",  
        resetPWD: logUserObject ? "Yes" : "No"
      };
      console.log('GetInsertDataUpdate',GetInsertDataUpdate)
      const userResponse = await userService().getcreateUser(GetInsertDataUpdate);
      console.log('userResponse',userResponse)
      const userRegResponse = userResponse?.data?.createUser;
      if (userResponse?.status === 200) {
        if (userResponse?.data?.createUser?.EmailId) {
          try {
            await Users().generateEmailSMS(
              userRegResponse?.EmailId,
              `<html><body><div>Welcome to Gurram Dental Hospitals!<br /><br />Thank you for registering with us.<br />Your Credentials for login :<br/>Email Id :${userRegResponse?.EmailId} / Phone Number :${userRegResponse?.PhoneNumber} <br />Password : ${PassWord}<br/><a href=${process.env.REACT_APP_HOST}login>Click here</a>to login your account <br />Best Regards,<br />Gurram Dental Hospitals Team</div></body></html>`,
              'Gurram Dental,Doctor Registration Details',
              'Your Registered successfully'
            );
          } catch (error) {
            console.log("error", error);
            setLoading(false);
          }
        }

        try {
          await Users().generateMobileMsgBulk(userRegResponse?.countryCode + userRegResponse?.PhoneNumber, `Thank you for registering with us. Log in using your Email: ${userRegResponse?.EmailId} or Phone Number: ${userRegResponse?.PhoneNumber}, and pwd: ${PassWord} - CLUSTERIT.`, "success"
          )
        } catch (error) {
          console.log("error", error);
          setLoading(false);
        }
        if (userRegResponse) {
          setLoading(false);
          resetForm();
          setLoading(false);
          setAlertMsg("Doctor Registered successfully!");
        }
      } else {
        setLoading(false);
        console.error("Error in registration:", userResponse);
      }
      // setSubmitting(false);
    } catch (error) {
      setLoading(false);
      console.error("Error during form submission:", error);
      setSubmitting(false);
    } finally {
      setLoading(false);
      setSubmitting(false); // Ensure that setSubmitting is always set to false at the end
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // const scrollToTopOnErrors = (isValid: boolean) => {
  //   if (!isValid) {
  //     window.scrollTo({ top: 0, behavior: 'smooth' });
  //   }
  // };




  return (
    <>
      {/* {loading ? (
        <Loader />
      ) : ( */}
      <>
        <Helmet>
          <title>Doctor Registraton</title>
          <meta name="description" content="This is the home page description" />
        </Helmet><section className="admin-section">
          <div
            className="slider-item bread-item"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <h1>
                    Doctor Registration
                  </h1>

                </div>
              </div>
            </div>
          </div>
        </section><div>
          <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
            <li>
              <span className="aColor" onClick={() => navigate('/')}>Home  /&nbsp;</span>
            </li>
            <li>Doctor Registration</li>
          </ul>
        </div>
        {/* {
          alertMsg &&
          <div className='alertMessage'>
            <Alert variant="success" onClose={() => { logUserObject?.userId && navigate('/ListDoctor') }} dismissible>
              {alertMsg}
            </Alert>
          </div>
        } */}
        <div className="container">
          <div className="row">
            {/* <div className="col-md-2" style={{marginTop:'50px'}}>
      <Sidebar />
    </div> */}
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 tabmediaqueries">
                  <div
                    style={{
                      display: "flex",
                      height: "auto",
                      marginBottom: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "15px 0px",
                      }}
                    >

                      <Container>
                        {
                          alertMsg &&
                          <div className="alertMessage"
                          // style={{
                          //   position: 'fixed',
                          //   top: '50%',
                          //   left: '50%',
                          //   transform: 'translate(-50%, -50%)',
                          //   zIndex: 1000,
                          //   width: '50%',
                          // }}
                          >
                            <Alert variant="success" onClose={() => { logUserObject?.userId && navigate('/ListDoctor') }} dismissible>
                              {alertMsg}
                              {loading ? (
                                <Loader />
                              ) : (null)}
                            </Alert>
                          </div>
                        }

                        <Box mb={2}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton>
                              <AccountCircleIcon style={{ fontSize: 40, color: "#2CBCBC" }} />
                            </IconButton>
                          </div>

                          <Typography
                            variant="h5"
                            style={{
                              color: "#2CBCBC",
                              marginTop: "8px",
                              textAlign: "center",
                            }}
                          >
                            Doctor Registration
                          </Typography>
                        </Box>
                        <p className="text-center" style={{ color: 'red' }}>{errMsgMatch ? errMsgMatch : ''}</p>
                        <Paper
                          elevation={3}
                          style={{
                            padding: "30px",
                            background: "transparent !important",
                            boxShadow: "none",
                          }}
                        >
                          <Formik
                            initialValues={InputDetails}
                            validationSchema={registerValidationSchema(location?.state?.id)}
                            onSubmit={updateDoctor ? handleUpdate : handleSubmit}
                            enableReinitialize
                            sx={{ width: "100%" }}
                          >

                            {({ isSubmitting, values, setFieldValue, touched, errors, isValid }) => {
                              // scrollToTopOnErrors(isValid); 
                              const errorCount = Object.keys(errors).length;
                              console.log({ errorCount })

                              if (isSubmitting && errorCount > 0) {

                                for (const key in errors) {
                                  console.log({ key })
                                  if (fieldRefs.current[key]) {
                                    fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                    break; // Stop at the first error we find
                                  }
                                }


                              }
                              return (
                                <Form autoComplete="off">
                                  <Box mb={3} ref={(el) => (fieldRefs.current.FirstName = el)}>
                                    <TextField className="form-field"
                                      name="FirstName"
                                      label="First Name*"
                                      variant="outlined"
                                      fullWidth
                                      value={values?.FirstName}
                                      onChange={(e) => setFieldValue("FirstName", e?.target?.value)}
                                      error={touched?.FirstName && Boolean(errors?.FirstName)}
                                      helperText={touched?.FirstName && errors?.FirstName} />
                                  </Box>
                                  <Box mb={3} ref={(el) => (fieldRefs.current.LastName = el)}>
                                    <TextField className="form-field"
                                      name="LastName"
                                      label="Last Name*"
                                      variant="outlined"
                                      fullWidth
                                      value={values?.LastName}
                                      onChange={(e) => setFieldValue("LastName", e?.target?.value)}
                                      error={touched?.LastName && Boolean(errors?.LastName)}
                                      helperText={touched?.LastName && errors?.LastName} />
                                  </Box>
                                  <Box mb={3} ref={(el) => (fieldRefs.current.Specialization = el)}>
                                    <DoctorSpecialization
                                      value={values.Specialization
                                        ? {
                                          label: values?.Specialization,
                                          value: values?.Specialization,
                                        }
                                        : null}
                                      setselectedSpecialization={async (value: string) => setFieldValue("Specialization", value)}
                                      label="Doctor Specialization*"
                                      error={touched?.Specialization && Boolean(errors?.Specialization)} // Pass error prop based on Formik's touched and errors
                                    />

                                    <ErrorMessage
                                      name="Specialization"
                                      component="div"
                                      className="errMsg" />
                                  </Box>
                                  {/* <div className="col-md-6"> */}

                                  <Box mb={3} ref={(el) => (fieldRefs.current.Slug = el)}>
                                    <TextField className="form-field"
                                      name="Slug"
                                      label="Slug Name*"
                                      variant="outlined"
                                      fullWidth
                                      value={values?.Slug}
                                      onChange={(e) => setFieldValue("Slug", e?.target?.value)}
                                      disabled={updateDoctor ? true : false}
                                      error={touched?.Slug && Boolean(errors?.Slug)}
                                      helperText={touched?.Slug && errors?.Slug} />
                                  </Box>

                                  <Box mb={3} ref={(el) => (fieldRefs.current.EmailId = el)}>
                                    <TextField className="form-field"
                                      name="EmailId"
                                      type="email"
                                      label="Email Address*"
                                      variant="outlined"
                                      fullWidth
                                      value={values?.EmailId}
                                      onChange={(e) => setFieldValue("EmailId", e?.target?.value)}
                                      error={touched?.EmailId && Boolean(errors?.EmailId)}
                                      helperText={touched?.EmailId && errors?.EmailId} />
                                  </Box>
                                  <Box mb={3}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={4} ref={(el) => (fieldRefs.current.countryCode = el)}>
                                        <TextField className="form-field"
                                          name="countryCode"
                                          label="Country Code*"
                                          type="tel"
                                          variant="outlined"
                                          fullWidth
                                          InputProps={{
                                            // startAdornment: (
                                            //   <InputAdornment position="start">
                                            //     +91
                                            //   </InputAdornment>
                                            // ),
                                            readOnly: true,
                                          }}
                                          value={values?.countryCode}
                                          onChange={(e) => setFieldValue("countryCode", e?.target?.value)}
                                          error={touched?.countryCode && Boolean(errors?.countryCode)}
                                          helperText={touched?.countryCode && errors?.countryCode} />
                                      </Grid>
                                      <Grid item xs={8} ref={(el) => (fieldRefs.current.PhoneNumber = el)}>
                                        <TextField className="form-field"
                                          name="PhoneNumber"
                                          label="Phone Number*"
                                          variant="outlined"
                                          fullWidth
                                          type="tel"
                                          value={values?.PhoneNumber}
                                          onChange={(e) => setFieldValue("PhoneNumber", e?.target?.value)}
                                          error={touched?.PhoneNumber && Boolean(errors?.PhoneNumber)}
                                          helperText={touched?.PhoneNumber && errors?.PhoneNumber} />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box mb={3} ref={(el) => (fieldRefs.current.Gender = el)}>
                                    <FormControl component="fieldset">
                                      <FormLabel component="legend">Gender</FormLabel>
                                      <RadioGroup className="form-field"
                                        aria-label="gender*"
                                        name="Gender"
                                        value={values?.Gender}
                                        onChange={(e) => setFieldValue("Gender", e?.target?.value)}
                                        row
                                      >
                                        <FormControlLabel
                                          value="Male"
                                          control={<Radio />}
                                          label="Male" />
                                        <FormControlLabel
                                          value="Female"
                                          control={<Radio />}
                                          label="Female" />
                                      </RadioGroup>
                                      <ErrorMessage
                                        name="Gender"
                                        component="div"
                                        className="errMsg" />
                                    </FormControl>
                                  </Box>
                                  {!updateDoctor ?
                                    <>
                                      <Box mb={3} ref={(el) => (fieldRefs.current.Password = el)}>
                                        <TextField className="form-field"
                                          name="Password"
                                          type="password"
                                          label="Password*"
                                          variant="outlined"
                                          fullWidth
                                          value={values?.Password}
                                          onChange={(e) => setFieldValue("Password", e?.target?.value)}
                                          error={touched?.Password && Boolean(errors?.Password)}
                                          helperText={touched?.Password && errors?.Password} />
                                      </Box><Box mb={3} ref={(el) => (fieldRefs.current.confirmPassword = el)}>
                                        <TextField className="form-field"
                                          name="confirmPassword"
                                          type="password"
                                          label="Confirm Password*"
                                          variant="outlined"
                                          fullWidth
                                          value={values?.confirmPassword}
                                          onChange={(e) => setFieldValue("confirmPassword", e?.target?.value)}
                                          error={touched.confirmPassword &&
                                            Boolean(errors?.confirmPassword)}
                                          helperText={touched?.confirmPassword && errors?.confirmPassword} />
                                      </Box></>
                                    : null}
                                  {/* {InputDetails?.userProfile !== null && location?.state?.id ?
                                    <>
                                    <img src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.userProfile}`} alt="Profile" style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }} />
                                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                        <p style={{ margin: 0, lineHeight: '24px' }}>
                                          <strong>FileName:</strong> {InputDetails?.userProfile}
                                        </p>
                                        <span
                                          style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                          onClick={handleRemove}
                                        >
                                          <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                      </div>
                                    </>
                                    : <strong>No Profile Photo</strong>} */}
                                  <div ref={(el) => (fieldRefs.current.confirmPassword = el)}>
                                    {InputDetails?.userProfile &&
                                      <>
                                        <img src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.userProfile}`} alt="Profile"
                                          style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }} />

                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                          <p style={{ margin: 0, lineHeight: '24px' }}><strong>FileName:</strong>{InputDetails?.userProfile}</p>
                                          <span
                                            style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                            onClick={handleRemove}
                                          >
                                            <FontAwesomeIcon icon={faTimes} />
                                          </span>
                                        </div>
                                      </>}
                                  </div>
                                  <FileUpload label={location?.state?.id ? "Update Profile Image" : "Choose Profile"} setDirectory={setDirectory} setFile={setFile} className="file-upload-label" />

                                  <Box mb={3} ref={(el) => (fieldRefs.current.qualification = el)}>
                                    <TextField className="form-field"
                                      name="qualification"
                                      type="qualification"
                                      label="qualification*"
                                      variant="outlined"
                                      fullWidth
                                      value={values?.qualification}
                                      onChange={(e) => setFieldValue("qualification", e?.target?.value)}
                                      error={touched?.qualification && Boolean(errors?.qualification)}
                                      helperText={touched?.qualification && errors?.qualification} />
                                  </Box>

                                  <Box mb={3} ref={(el) => (fieldRefs.current.TimeSlot = el)}>
                                    <TextField className="form-field"
                                      name="TimeSlot"
                                      label="OP TimeSlot(in minutes)"
                                      variant="outlined"
                                      fullWidth
                                      value={values?.TimeSlot}
                                      onChange={(e) => setFieldValue("TimeSlot", e?.target?.value)}
                                      error={touched?.TimeSlot && Boolean(errors?.TimeSlot)}
                                      helperText={touched?.TimeSlot && errors?.TimeSlot} />
                                  </Box>
                                  <Box mb={3} ref={(el) => (fieldRefs.current.Description = el)}>
                                    {/* <TextField
              name="Description"
              type="text"
              label="Description"
              variant="outlined"
              fullWidth
              value={values?.Description}
              onChange={(e) => setFieldValue("Description", e?.target?.value)}
              error={touched?.Description && Boolean(errors?.Description)}
              helperText={touched?.Description && errors?.Description}
            /> */}
                                    <Field name="Description" className="form-field">
                                      {({ field, form }: any) => (
                                        <CKEditorComponent
                                          data={field?.value || ''}
                                          onDataChange={(newContent: string) => setFieldValue('Description', newContent)}
                                          setFieldValue={setFieldValue} />
                                      )}
                                    </Field>
                                  </Box>
                                  <Grid container spacing={3}>
                                    {values?.SocialIcons?.length > 0 &&
                                      values.SocialIcons.map((icon, index) => (
                                        <React.Fragment key={index}>
                                          <Grid item xs={4}>
                                            <Field className="form-field"
                                              as={TextField}
                                              name={`SocialIcons[${index}].icon`}
                                              label={`${icon.name} URL`}
                                              variant="outlined"
                                              fullWidth
                                              type="text"
                                              value={values.SocialIcons[index]?.icon}
                                              onChange={(e: any) => setFieldValue(`SocialIcons[${index}].icon`, e?.target?.value)} />
                                          </Grid>
                                        </React.Fragment>
                                      ))}
                                  </Grid>

                                  <Box textAlign="center">
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      color="primary"
                                      disabled={isSubmitting}
                                      fullWidth
                                      style={{ padding: "10px", fontSize: "16px", marginTop: "20px" }}
                                    >
                                      {updateDoctor ? "Update" : "Register"}
                                    </Button>
                                  </Box>
                                </Form>
                              );

                            }}

                          </Formik>
                        </Paper>
                      </Container>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div></>
      {/* )} */}
    </>

  );
}

export default DoctorRegister;