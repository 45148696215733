import * as Yup from 'yup';

export const ProfileValidationSchema = Yup.object().shape({

    EmailId: Yup.string()
        .email("Invalid email format")
        .required("Email is required")
        .test("valid-domain", "Invalid domain extension", (value) => {
            // Custom validation for domain extension
            if (value) {
                const domainRegex =
                    /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
                return domainRegex.test(value);
            }
            return true; // Allow empty values, Yup's required handles that
        })
})

export const ProfilePhoneValidationSchema = Yup.object().shape({

    PhoneNumber: Yup.string()
        .required("Phone Number is required")
        .test(
            "phone-validation",
            "Phone number must have exactly 10 digits",
            (value) => {
                const inputValue = value.replace(/\D/g, "");
                const phoneRegex = /^\d{10}$/;
                return phoneRegex.test(inputValue);
            }
        ),
})

const characterValidation = (value: any) => {
    // return /^[A-Za-z]+$/.test(value);
    return /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value);
};
const spaceValidation = (value: string) => {
    const hasLeadingOrTrailingSpaces = /^\s+|\s+$/.test(value);
    const hasMultipleSpacesInMiddle = /\s{3,}/.test(value);

    if (hasLeadingOrTrailingSpaces || hasMultipleSpacesInMiddle) {
        return false;
    } else {
        return true;
    }
};

export const ProfileDetailsValidationSchema = Yup.object().shape({

    FirstName: Yup.string()
        .required("First Name is required")
        .min(3, "First Name must be at least 3 characters")
        .test(
            "character-validation",
            "First Name must contain only characters",
            characterValidation
        ),
    LastName: Yup.string()
        .required("Last Name is required")
        .min(3, "Last Name must be at least 3 characters")
        .test(
            "character-validation",
            "Last Name must contain only characters",
            characterValidation
        ),
    Gender: Yup.string()
        .required("Gender is required")
        .oneOf(["Male", "Female"], "Gender must be either Male or Female"),
    // Age: Yup.string()
    //     .required("Age is required")
    //     .typeError("Age must be a number"),
    // .positive('Age must be a positive number'),
    Specialization: Yup.string()
        .required("Specialization is required")
        .test(
            "space-validation",
            "Specialization must not contain spaces",
            (value) => {
                return spaceValidation(value);
            }
        ),

})

