import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  // catergory: Yup.string().required('Category is required'),
  category: Yup.mixed()
  .test(
    'is-string-or-valid-array',
    'Category is required',
    value => {
      if (typeof value === 'string') {
        return value.trim() !== ''; // Check if the string is not empty
      }
      if (Array.isArray(value)) {
        // Ensure the array has at least one valid string and doesn't contain empty strings
        return value.length > 0 && value.every(item => typeof item === 'string' && item.trim() !== '');
      }
      return false; // Invalid type
    }
  )
  .required('Category is required')
  .test(
    'no-empty-strings-in-array',
    'Array must not contain empty strings',
    value => !(Array.isArray(value) && value.some(item => item.trim() === ''))
  ),
// other fields...

  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  Slug: Yup.string().required('Slug is required'),
});