import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import './style.css';
import getblogs from "../../Services/BlogServices";
import { Helmet } from 'react-helmet';
import Loader from "../shared/Loader";
import LazyLoad from "react-lazyload";


interface compProps {
  title: string;
  description: string;
}


const BlogPage: React.FC<compProps> = ({ title, description }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [singleBlog, setSingleBlog] = useState<any>();
  const [blogItems, setBlogItems] = useState<any>([]);
  const [filteredCareer, setFilteredCareer] = useState<any>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [careerItems, setCareerItems] = useState<any>([]);
  const [allCats, setAllCats] = useState<any>(false);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const { slug } = useParams();
  // async function ProvInfo() {
  //   const SingleBlog = await getblogs().getSingleBlog(location?.state?.id);
  //   console.log("SingleBlog", SingleBlog);
  //   setSingleBlog(SingleBlog);
  // }
  // console.log("blogItems", blogItems);

  const listBlogs = async () => {
    try {
      const response = await getblogs().ActiveBlogList();
      // console.log("filteredBlogs", filteredBlogs);
      const categoryname = await getblogs().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.catergory.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });
      setBlogItems(updatedResponse);
      const categoryIds = updatedResponse.map((item: any) => item?.catergory);
      // console.log("categoryIds", categoryIds);
      // category List
      const catResponse = await getblogs().CategoryList();
      setCategories(catResponse);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  async function ProvInfo() {
    setLoading(true);
    const singleBlog: any = (await getblogs().ActiveBlogList()).find((blog: any) => blog?.Slug === slug);
    setSingleBlog(singleBlog);
    setLoading(false);

  }

  useEffect(() => {
    ProvInfo();
    listBlogs();
  }, [])
  const removeHtmlTags = (str: any) => {
    return str && str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const countByCategory = blogItems && blogItems?.reduce((acc: any, blog: any) => {
    const categoryIds = blog?.catergory[0]
    const splitString = categoryIds?.includes(',') ? categoryIds?.split(',') : [categoryIds];
    // console.log("careers", splitString)
    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });
    return acc;
  }, {});

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
      (day % 10 === 2 && day !== 12) ? 'nd' :
        (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

    return `${day}${suffix} ${month} ${year}`;
  };

  const bgImageStyle = {
    backgroundImage: `url(${process.env.REACT_APP_HOST}images/bg_1.jpg)`
  };
  const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/blogsingle.jpg`;
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <><Helmet>
          {/* <title>{title}</title> */}
          <title>{` ${singleBlog?.title || ''}`}</title>
          <meta name="description" content={description} />
        </Helmet><section className="home-slider owl-carousel">
            <LazyLoad>
              <div
                className="slider-item bread-item"
                // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
                style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/bannerr1.jpg` }}
              >
                <div className="overlay"></div>
                <div className="container" data-scrollax-parent="true">
                  <div className="row banner-text align-items-center">
                    <div className="col-md-12 col-sm-12 ftco-animate">


                      <h1 className="banner-ttl">{singleBlog?.title}</h1>
                      {/* <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div> */}
                      <div className="captionName" style={{ textAlign: 'center' }}>Your trusted source for healthcare information, insights, and advice.</div>
                    </div>
                  </div>
                </div>
              </div>
            </LazyLoad>
          </section>

          <div>

            <ul className="breadCrumb">
              <li>
                <a role="button" className="aColor" onClick={() => navigate('/')}>Home /</a>
              </li>
              <li>
                <a role="button" className="aColor" onClick={() => navigate('/Blogs')}>Blogs /</a>
              </li>
              <li className="nocursor">{singleBlog?.title}</li>
            </ul>


          </div>

          <section className="ftco-section">
            <div className="container">
              <div className="row justify-content-center">
                {/* <div className="col-md-7 text-center heading-section ftco-animate">
<h2 >{singleService?.title}</h2>

</div> */}
              </div>
              <div className="row">
                <div className="col-md-9">

                  {/* <div className="icon d-flex align-items-center" style={{ justifyContent: 'center' }}> */}
                  <div className="icon" style={{ justifyContent: 'center' }}>
                    <LazyLoad>
                      <img className="mediaqueriimagesize newimg"
                        src={singleBlog?.blogImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleBlog?.blogImage}` : bgImageStylesingle}
                        alt={singleBlog?.catergory}
                        // width="75%"
                        style={{ marginBottom: '9px', height: '300px' }} />
                    </LazyLoad>
                  </div>
                  <div>
                    <h3 className="heading heading-section ftco-animate headingsingle">{singleBlog?.title}</h3>
                  </div>
                  {/* <span>{singleBlog?.createdAt}</span> */}
                  {/* <span>{singleBlog?.createdAt && formatDate(singleBlog.createdAt)}</span> */}
                  <p className="text-muted date">{formatDate(singleBlog?.createdAt)}</p>

                  <div className="d-flex align-self-stretch ftco-animate">
                    <div className="media  block-6 services d-block contentnew">
                      <div className="media-body p-2">
                        {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
{removeHtmlTags(singleBlog?.description)}
</p> */}
                        <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleBlog?.description }} />

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6>Categories</h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">
                          <li style={{ cursor: 'pointer' }} onClick={() => navigate('/Blogs')}>All</li>
                          {categories?.map((item: any) => {
                            // console.log("countByCategory", countByCategory);
                            const blogCount = countByCategory[item?.id] || 0;
                            return (
                              <li key={item?.id} onClick={() => { navigate(`/Blogs`, { state: { category: item?.id } }); }} style={{ cursor: 'pointer' }} className="capitalTxt">
                                {item?.name.toLowerCase()} <span>({blogCount})</span>
                              </li>
                            );
                          }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section></>
      )}
    </>





  )
}
export default BlogPage;
