import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { createContact, deleteContact, updateContact, updateReview } from './../../../graphql/mutations';
import { getContact, listContacts } from './../../../graphql/queries';


const ContactServices: any = () => {
const contactCreate = async (input: any) => {

        try {
            const response: any = await API.graphql(graphqlOperation(createContact, { input }));
            // console.log("Services response", response);
            // const Conresponse = await fetch(`${process.env.REACT_APP_SNS_Function}`);
            if (response?.data?.createContact) {
                return { response: response.data.createContact, status: 200 };
            }
        } catch (error) {
            console.error("Error creating doctor available date:", error);
            throw error;
        }
}
      
const contactdelete = async (id: any) => {
    try {
        const result = await API.graphql(graphqlOperation(deleteContact, { input: { id: id } })) as any;
        return result?.data?.deleteContact;
    } catch (error) {
        console.error('Error in deleteReview:', error);
        return null;
    }
}
const getcontactSingle = async (id: any) => {
    try {
        const result = await API.graphql(graphqlOperation(getContact, { id: id })) as any;
        return result?.data?.getContact;
    } catch (error) {
        console.error('Error in getContact :', error);
        return null;
    }
}

const contactList = async () => {
    try {
        let nextToken: any = null;
        const limit = 100;
        let contacts: any[] = [];

        do {
            const filter = {
                limit: limit,
                nextToken: nextToken
            };
            const result = await API.graphql(graphqlOperation(listContacts, filter)) as any;
            const specializations = result?.data?.listContacts?.items || [];
            contacts.push(...specializations);
            nextToken = result?.data?.listContacts?.nextToken;
        } while (nextToken);
        contacts.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        return contacts;
    } catch (error) {
        console.error('Error fetching listContacts:', error);
        return [];
    }
}
const removeFields: any = (obj: any, fieldsToRemove: any) => {
    if (Array.isArray(obj)) {
        return obj.map(item => removeFields(item, fieldsToRemove));
    } else if (typeof obj === 'object' && obj !== null) {
        const newObj: any = {};
        for (const key in obj) {
            if (!fieldsToRemove.includes(key)) {
                newObj[key] = removeFields(obj[key], fieldsToRemove);
            }
        }
        return newObj;
    }
    return obj;
}

const contactUpdate = async (data: any) => {

    const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
    const sanitizedUserInfo = removeFields(data, fieldsToRemove);

    try {
        const result = await API.graphql(graphqlOperation(updateContact, { input: sanitizedUserInfo })) as any;
        return result?.data?.updateReview;
    } catch (error) {
        console.error('Error updating updateReview:', error);
        return null;
    }
}
return {contactCreate,contactList,contactdelete,contactUpdate,getcontactSingle}
}
export default ContactServices;
