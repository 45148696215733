import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextField, Button, Select, MenuItem, Switch, FormControlLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ReviewServices from '../../Services/reviewServices/service';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { updDelFile } from '../shared/fileupload';
import { Helmet } from 'react-helmet';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';

interface Review {
    Name: string;
    EmailId: string;
    Rating: any;
    Comment: string;
    Status: any;
}

const ListReviews = () => {
    const initialState: Review = {
        Name: '',
        EmailId: '',
        Rating: '',
        Comment: '',
        Status: ''
    };

    const navigate = useNavigate();
    const [data, setData] = useState<Review>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [reviewsList, setReviewList] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('PDF Viewer Modal');
    const [singleReview, setSingleReview] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);


    const logUser: any = localStorage?.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // useAuthRedirect(logUserObject);

    const listReview = async () => {
        const response = await ReviewServices().reviewList();
        response && setReviewList(response);
        setIsLoading(false);
    };

    useMemo(() => {
        listReview();
    }, []);

    const StarRating = ({ rating }: any) => {
        const stars = [];

        for (let i = 1; i <= 5; i++) {
            stars.push(
                <span key={i} style={{ color: i <= rating ? 'gold' : 'gray', fontSize: '24px' }}>★</span>
            );
        }

        return <div>{stars}</div>;
    };

    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected Review?");
        if (confirmBox) {
            const Review = await ReviewServices().getreviewSingle(id);
            const filedel = await updDelFile(Review?.userProfile, "images");
            console.log("filedel", filedel);
            if (filedel?.status === 200) {
                await ReviewServices().reviewdelete(id);
                listReview();
            }
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const filteredData: any = reviewsList?.filter((row: Review) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

    const openModal = async (files: any) => {
        const singleReview = await ReviewServices().getreviewSingle(files?.id);
        setSingleReview(singleReview);
        setIsModalOpen(true);
        setModalHeading(files?.Name);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalHeading("");
    };

    const handleStatusToggle = (rowData: any) => async (event: any) => {
        const updatedStatus = event?.target?.checked === true ? "1" : "0";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        const update = await ReviewServices().reviewUpdate(updatedRowData);
        setReviewList((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, Status: updatedStatus } : row
            )
        );
    };


    const truncateText = (text: any) => {
        if (!text) return '';
        const truncatedText = text?.length > 10 ? text?.substring(0, 10) + '...' : text;
        return truncatedText;
    };

    return (
        <>
            <Helmet>
                <title>Reviews</title>
                <meta name="description" content="This is the Reviews page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Reviews
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Reviews</li>
                </ul>
            </div>

            <div className="container">
                <div className="row">

                    <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="page-size-dropdown">
                            <label style={{ padding: '5px' }}>Show</label>
                            <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                            </select>
                            <label style={{ padding: '5px' }}>entries</label>
                        </div>
                        <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                            <p className='tablefs' style={{ marginRight: '10px' }}>Search</p>
                            <div>
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="search-bar"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row tablefs">
                    {!isLoading ? (
                        <div className="col-md-12" style={{ overflowX: 'auto' }}>
                            <table className="table table-bordered table-striped table-hover table-responsive listReviews ">
                                <thead>
                                    <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <th className="sno">S.No</th>
                                        <th className='mdl'>Name</th>
                                        <th className='mdl'>EmailId ID</th>
                                        <th className='mdl'>Rating</th>
                                        <th className='mdl'>Comment</th>
                                        <th className='actions'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData?.map((row: any, index: number) => (
                                        <tr key={row?.id} style={{ borderBottom: '1px solid #ccc' }}>
                                            <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                            <td>{highlightText(row?.Name)}</td>
                                            <td>{highlightText(row?.EmailId)}</td>
                                            <td>{highlightText(row?.Rating)}</td>
                                            <td>{highlightText(truncateText(row?.Comment))}</td>
                                            <td className="actions">
                                                <Button title="View"
                                                    onClick={() => openModal(row)}

                                                    startIcon={<VisibilityIcon style={{ color: 'green' }} />}
                                                >

                                                </Button>
                                                <Button title="Delete"
                                                    onClick={() => handleDelete(row?.id)}

                                                    startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                >

                                                </Button>

                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={row?.Status === "1"}
                                                            onChange={handleStatusToggle(row)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <span className={row?.Status === "1" ? 'Status-active' : 'Status-inactive'}>
                                                            {row?.Status === "1" ? 'Active' : 'Inactive'}
                                                        </span>
                                                    }

                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : <p style={{ color: 'red' }}>Loading...</p>}

                    {/* {isLoading && <p style={{color: 'red'}}>Loading...</p>}  */}

                    {/* <div style={{ marginBottom: '40px' }}>
                        Showing {((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries


                        <div style={{ display: 'flex', justifyContent: 'right', float: 'right' }}>
                            <Button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                variant="contained"
                                style={{ marginRight: '10px' }}
                            >
                                Previous
                            </Button>
                            <div className="pageNumb">{currentPage}</div>
                            <Button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                variant="contained"
                                style={{ marginLeft: '10px' }}
                            >
                                Next
                            </Button>
                        </div>

                    </div> */}

                    <div className='mttable'>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                        <div className="paginationstyle mttable1">
                            <div
                                style={{
                                    cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                    marginRight: '10px',
                                    borderBottom: '1px solid red',
                                    fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                }}
                                onClick={() => {
                                    if (currentPage > 1) {
                                        handlePageChange(currentPage - 1);
                                        window.scrollTo(0, 0); // Scroll to top
                                    }
                                }}
                                className={currentPage === 1 ? 'disabled' : ''}
                            >
                                Previous
                            </div>
                            <div className="pageNumb">{currentPage}</div>
                            <div
                                style={{
                                    cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                    marginLeft: '10px',
                                    borderBottom: '1px solid red',
                                    fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                }}
                                onClick={() => {
                                    if (currentPage < totalPages) {
                                        handlePageChange(currentPage + 1);
                                        window.scrollTo(0, 0); // Scroll to top
                                    }
                                }}
                                className={currentPage === totalPages ? 'disabled' : ''}
                            >
                                Next
                            </div>
                        </div>

                    </div>
                    <div className={`modal ${isModalOpen ? "open" : ""}`}>
                        <div className="modal-content1" style={{ marginTop: '177px', height: '380px' }}>

                            <h6 className="text-center">Review By <strong>{modalHeading}</strong></h6>
                            <span className="close closebtn1" onClick={closeModal}>
                                &times;
                            </span>
                            {singleReview && (
                                <div style={{ padding: '40px' }}>
                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                        <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                            <strong>Name:</strong>
                                        </div>
                                        <div>
                                            <span>{highlightText(singleReview?.Name)}</span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                        <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                            <strong>Email ID:</strong>
                                        </div>
                                        <div>
                                            <span>{highlightText(singleReview?.EmailId)}</span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                        <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                            <strong>Rating:</strong>
                                        </div>
                                        <div>
                                            <StarRating rating={singleReview?.Rating} />
                                            {/* <span>{highlightText(singleReview?.Rating)}</span> */}
                                            {/* <span><StarRating(singleReview?.Rating)/></span> */}

                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                                        <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                            <strong>Comment:</strong>
                                        </div>
                                        <div>
                                            <span>{highlightText(singleReview?.Comment)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>

            </div>


        </>

    );
};

export default ListReviews;