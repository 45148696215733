import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jsPDF from 'jspdf';
import { useState } from 'react';
import { Page } from 'react-pdf';
import ReportServices from '../../../Services/reportservices';
import PrescriptionServices from '../../../Services/prescriptionServices';
import html2canvas from 'html2canvas';


// const [numPages, setNumPages] = useState(null);
// const onDocumentLoadSuccess = ({ numPages }: any) => {
//     setNumPages(numPages);
// };

const ReportsFilesmanagement = () => {

    const singleDownload: any = async (single: any, action: any) => {
        console.log("single", single);
        let fileUrl: any;
        let jsPath: any = `${process.env.REACT_APP_HOST}js/pdf.min.js`;
        let workerPath: any = `${process.env.REACT_APP_HOST}js/pdf.worker.min.js`;
        let description: any
        if (single?.name) {
            fileUrl = `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${single?.name}`;
        } else if (single?.description) {
            const div = document.createElement("div");
            div.innerHTML = single.description;
            description = div.textContent || div.innerText || "";
        }
        const fileExtension = fileUrl?.split('.').pop().toLowerCase();
        const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
        const isPDF = fileExtension === 'pdf';

        if (action === 'download') {
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = fileUrl; // You can set a custom file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (description) {
                await imageCanvas(single?.description, null, false, null);
                // const doc = new jsPDF();
                // const margin = 10;
                // const pageHeight = doc.internal.pageSize.height;
                // const lineHeight = 10;

                // let yPosition = margin;
                // const lines = doc.splitTextToSize(description, 180);

                // lines.forEach((line: any, index: any) => {
                //     if (yPosition + lineHeight > pageHeight - margin) {
                //         doc.addPage();
                //         yPosition = margin;
                //     }
                //     doc.text(line, margin, yPosition);
                //     yPosition += lineHeight;
                // });


                // const lines = doc.splitTextToSize(description, 180);
                // doc.text(lines, 10, 10);
                // doc.save('description.pdf');
            } else {
                console.error('No file or description to download');
            }
        } else if (action === 'print') {
            console.log("description", description);
            const printWindow: Window | null = window.open('', '_blank');

            if (printWindow) {
                printWindow.document.open();
                if (isImage && fileUrl) {
                    printWindow.document.write(`
                        <!DOCTYPE html>
                        <html>
                        <head>
                            <title>Print</title>
                        </head>
                        <body>
                            <img src="${fileUrl}" style="width: 100%; height: auto;" />
                            <script>
                                window.onload = function() {
                                    setTimeout(function() {
                                        window.print();
                                        window.close();
                                    }, 1000);
                                };
                            </script>
                        </body>
                        </html>
                    `);
                } else if (isPDF && fileUrl) {
                    printWindow.document.write(`
                <!DOCTYPE html>
                <html>
                <head>
                    <title>Print</title>
                    <script src='${jsPath}'></script>
                     <script>
            pdfjsLib.GlobalWorkerOptions.workerSrc = '${workerPath}';
        </script>

                    <style>
                        body { margin: 0; }
                        canvas { display: block; margin-bottom: 10px; }
                    </style>
                </head>
                <body>
                    <div id="pdf-container"></div>
                    <script>
                        const loadingTask = pdfjsLib.getDocument('${fileUrl}');
                        loadingTask.promise.then(function(pdf) {
                            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                                pdf.getPage(pageNum).then(function(page) {
                                    const viewport = page.getViewport({ scale: 1.5 });
                                    const canvas = document.createElement('canvas');
                                    const context = canvas.getContext('2d');
                                    canvas.height = viewport.height;
                                    canvas.width = viewport.width;
                                    document.getElementById('pdf-container').appendChild(canvas);
      
                                    const renderContext = {
                                        canvasContext: context,
                                        viewport: viewport
                                    };
                                    page.render(renderContext).promise.then(function() {
                                        if (pageNum === pdf.numPages) {
                                            setTimeout(function() {
                                                window.print();
                                                window.close();
                                            }, 1000);
                                        }
                                    });
                                });
                            }
                        });
                    </script>
                </body>
                </html>
              `);
                } else if (single?.description) {
                    printWindow.document.write(`

                        <!DOCTYPE html>
        <html>
                    <head>
                        <title>Print</title>
                        <style>
                            /* Add any necessary styles here */
                            img {
                                max-width: 100%; /* Ensure images fit the page */
                                height: auto; /* Maintain aspect ratio */
                            }
                        </style>
                    </head>
                    <body>
                        <div>${single?.description}</div>
                        <script>
                            window.onload = function() {
                                const images = document.querySelectorAll('img'); // Select all images

                                // Create an array of promises for image loading
                                const promises = Array.from(images).map((img) => {
                                    return new Promise((resolve) => {
                                        if (img.complete) {
                                            resolve(); // Image already loaded
                                        } else {
                                            img.onload = () => resolve(); // Resolve on load
                                            img.onerror = () => resolve(); // Resolve on error (optional)
                                        }
                                    });
                                });

                                // Wait for all images to load before printing
                                Promise.all(promises).then(() => {
                                    setTimeout(() => {
                                        window.print(); // Print the window
                                        window.close(); // Close the print window
                                    }, 1000);
                                });
                            };
                        </script>
                    </body>
        </html>
                      `);
                } else {
                    console.error('Unsupported file type');
                    printWindow.close();
                }
                printWindow.document.close();
            } else {
                console.error('Failed to open print window');
            }
        }
    };

    const handleView = async (doctor: any, setCurrentIndex: any, setViewFiles: any, setIsModalOpen: any, type: any) => {
        console.log("doctor", doctor);
        setCurrentIndex(0);
        const singleReview: any = type === "reports" ? await ReportServices().reportsListBypatientID(doctor?.id) : await PrescriptionServices().prescriptionListBypatientID(doctor?.id);

        // const singleReview = await ReportServices().reportsListBypatientID(doctor?.appointmentID);
        console.log("singleReview", singleReview);
        const combinedFiles: any = [];
        singleReview && singleReview?.forEach((report: any) => {
            console.log("report", report);
            report?.file?.forEach((fileItem: any) => {
                const { fileType, name, description, type } = fileItem;
                console.log("fileItem", fileItem);

                if (type === "upload" && name?.length > 0) {
                    name?.forEach((fileName: any) => {
                        combinedFiles.push({
                            fileType,
                            name: fileName,
                            description: "",  // No description for upload type
                            date: report?.createdAt,
                            AppointmentID: doctor?.AppointmentID,
                            patientName: doctor?.PatientName
                        });
                    });
                } else if (type === "written" && description) {
                    combinedFiles.push({
                        fileType,
                        name: null,  // No name for written type
                        description,
                        date: report?.createdAt,
                        AppointmentID: doctor?.AppointmentID,
                        patientName: doctor?.PatientName
                    });
                }
            });
        });
        console.log("combinedFiles", combinedFiles);
        setViewFiles(combinedFiles);
        setIsModalOpen(true);
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    };
    const loadImages = async (element: HTMLElement): Promise<void[]> => {
        const images = element.querySelectorAll('img');

        const promises = Array.from(images).map((img) => {

            img.setAttribute('crossorigin', 'anonymous');

            console.log("images images", img);
            return new Promise<void>((resolve) => {
                if (img.complete) {
                    resolve();
                } else {
                    img.onload = () => resolve();
                    img.onerror = () => resolve(); // handle broken images
                }
            });
        });
        return Promise.all(promises);
    };
    const imageCanvas = async (description: any, folder: any, saveToFolder: boolean, fileType: any) => {
        const fileTypeList: any = await ReportServices().reportTypeList();
        const fileType1: any = fileTypeList?.find((type: any) => type?.id === fileType);
        const pdfFileName = fileType1?.name ? `description_${fileType1?.name}.pdf` : `description.pdf`;

        try {
            const tempDiv = document.createElement('div');
            console.log("tempDiv", tempDiv);
            tempDiv.style.position = 'absolute';
            tempDiv.style.width = '180mm'; // A4 width for better scaling
            tempDiv.style.padding = '5px'; // Add padding to match PDF margins
            tempDiv.innerHTML = description;
            document.body.appendChild(tempDiv);

            const images = await loadImages(tempDiv);
            console.log("images", images);

            const canvas = await html2canvas(tempDiv, {
                useCORS: true,
                scale: 2 // Adjust the scale for higher resolution
            });
            const imgData = canvas.toDataURL('image/png');

            const doc = new jsPDF();
            const margin = 10;
            const pageWidth = doc.internal.pageSize.width;
            const pageHeight = doc.internal.pageSize.height;

            const imgWidth = pageWidth - 2 * margin;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            if (imgHeight > pageHeight - 2 * margin) {
                doc.addImage(imgData, 'PNG', margin, margin, imgWidth, pageHeight - 2 * margin);
            } else {
                doc.addImage(imgData, 'PNG', margin, margin, imgWidth, imgHeight);
            }

            if (saveToFolder) {
                const pdfBlob = doc.output('blob');
                folder.file(pdfFileName, pdfBlob, { binary: true });
            } else {
                doc.save(pdfFileName); // This will trigger the download
            }

            document.body.removeChild(tempDiv);
        } catch (err) {
            console.error('Error generating PDF from HTML:', err);
        }
    };


    // const renderFile = (file: any) => {
    //     const fileExtension = file?.split('.').pop().toLowerCase();
    //     // console.log("fileExtension", fileExtension);
    //     if (fileExtension === 'pdf') {
    //         return (
    //             <>
    //                 <div className='clrbth' >
    //                     {/* <Document file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${file}`}>

    //  <Page pageNumber={1} />

    //  </Document> */}
    //                     < Document
    //                         file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${file}`
    //                         }
    //                         onLoadSuccess={onDocumentLoadSuccess}
    //                         className="pdf-viewer"
    //                     >
    //                         {
    //                             Array.from(
    //                                 new Array(numPages),
    //                                 (el, index) => (
    //                                     <Page key={`page_${index + 1}`} pageNumber={index + 1
    //                                     } />
    //                                 )
    //                             )}
    //                     </Document>


    //                     {/* <div>{viewFiles[currentIndex]}</div> */}
    //                 </div>

    //                 < div className='clrbth' >

    //                     <button className="nav-button" onClick={handlePrev} >
    //                         <FontAwesomeIcon icon={faArrowLeft} />
    //                     </button>

    //                     < button className="nav-button" onClick={handleNext} >
    //                         <FontAwesomeIcon icon={faArrowRight} />
    //                     </button>

    //                 </div>



    //             </>
    //         );
    //     } else {
    //         return (
    //             <>

    //                 <div className='clrbth' >
    //                     <img
    //                         key={file}
    //                         src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${file}`
    //                         }
    //                         alt={file}
    //                         className='img-viewer'
    //                     />
    //                 </div>

    //                 < div className='clrbth' >

    //                     <button className="nav-button" onClick={handlePrev} >
    //                         <FontAwesomeIcon icon={faArrowLeft} />
    //                     </button>
    //                     < button className="nav-button" onClick={handleNext} >
    //                         <FontAwesomeIcon icon={faArrowRight} />
    //                     </button>

    //                 </div>


    //             </>
    //         );
    //     }
    // };

    return {
        singleDownload,
        // renderFile,
        handleView,
        formatDate,
        imageCanvas
    }

}


export default ReportsFilesmanagement;