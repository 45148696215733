import React, { useEffect, useState, useRef, Suspense, startTransition } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import getCareer from '../../Services/Careers';
// import getblogs from '../../../services/Blogs';
// import './BlogPage.css';
import { Helmet } from 'react-helmet';
import pages from "../../utills/pageUtils";
import Loader from "../shared/Loader";
// import LazyLoad from "react-lazyload";


interface compProps {
  title: string;
  description: string;
  keywords: string;
}


const CareerPage: React.FC<compProps> = ({ title, description, keywords }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [careerItems, setCareerItems] = useState<any>([]);
  const [filteredCareer, setFilteredCareer] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [blogsPerPage] = useState<number>(10);
  const [allCats, setAllCats] = useState<any>(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [pageInfo, setPageInfo] = useState<any>()
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()
  const [loading, setLoading] = useState(true);
  const CareerRef = useRef<HTMLDivElement>(null);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const CareerPerPage = 6;
  const { slug } = useParams();
  // const location = useLocation();


  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      })

    };
    fetchData();
  }, [])


  const listCareers = async () => {
    try {
      setLoading(true);
  
      // Fetch data concurrently
      const [response, categoryNames, catResponse] = await Promise.all([
        getCareer().ActiveCareerList(),
        getCareer().getcategory(),
        getCareer().CategoryList(),
      ]);
  
      // Map careers with their category names
      const updatedResponse = response?.map((item: any) => {
        const categoryNamesMapped = item?.category?.map((cateId: any) => {
          const category = categoryNames?.find((cate: any) => cate?.id === cateId);
          return category?.name ?? cateId;
        });
        return {
          ...item,
          catergoryName: categoryNamesMapped,
        };
      }) ?? [];
  
      // Update state
      setCareerItems(updatedResponse);
      setCategories(catResponse);
  
      // Handle active category based on location state
      const activeCategory = location?.state?.category ?? null;
      setSelectedCategory(activeCategory);
      setActiveCategory(activeCategory);
  
    } catch (error) {
      console.error('Error fetching careers:', error);
    } finally {
      setLoading(false);
    }
  };
  


  useEffect(() => {
    listCareers();
    window.scrollTo(0, 0);
  }, [location?.state?.category]);

  useEffect(() => {
    // const filterCat = selectedCategory ? selectedCategory : location?.state?.category
    const filterCat = selectedCategory;
    const filterCareers: any = filterCat && careerItems?.filter((careers: any) => {
      const categoryIds = careers?.category[0]
      const splitString = categoryIds?.includes(',') ? categoryIds?.split(',') : [categoryIds];
      return splitString.includes(filterCat)
    });
    filterCareers && setFilteredCareer(filterCareers);
  }, [selectedCategory, location?.state?.category, careerItems]);


  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };

  const handleReadMore = (slug: any) => {

    navigate(`/careers/${slug}`);

    
  };

  // const CareersFiltered = filteredCareer?.length > 0 ? filteredCareer : careerItems;
  const CareersFiltered: any = !selectedCategory ? careerItems : filteredCareer?.length > 0 ? filteredCareer : [];
  const countByCategory = careerItems && careerItems?.reduce((acc: any, careers: any) => {
    const categoryIds = careers?.category[0]
    const splitString = categoryIds.includes(',') ? categoryIds?.split(',') : [categoryIds];
    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });

    return acc;
  }, {});

  // Pagination logic
  const indexOfLastCareer = currentPage * CareerPerPage;
  const indexOfFirstCareer = indexOfLastCareer - CareerPerPage;
  // const currentDoctors = blogsFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const currentCareers = CareersFiltered.slice(indexOfFirstCareer, indexOfLastCareer);
  const totalPages = Math.ceil(CareersFiltered.length / CareerPerPage);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  useEffect(() => {
    if (CareerRef.current) {
      CareerRef.current.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);
  }, [currentPage]);



  // console.log("careerItems", CareersFiltered);

  return (
    <div className="page-wrapper">      
        <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta name="author" content="Gurram Dental Care" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>
        
        <section className="home-slider owl-carousel">
          
            <div
              className="slider-item bread-item"
              // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
              style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})` : 'images/bg_1.jpg' }}
              data-stellar-background-ratio="0.5"
            >
              <div className="overlay"></div>
              <div className="container" data-scrollax-parent="true">
                <div className="row banner-text align-items-center">
                  <div className="col-md-12 col-sm-12 ftco-animate">
                   
                    <h1 className="banner-ttl">{bannerName}</h1>
                    <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>

                  </div>
                </div>
              </div>
            </div>
            
          </section>
          
          <div>
            <ul className="ullist">
              <li>
                <a className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</a>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div>
          
          {loading ? (
        <Loader />
      ) : (
          <section className="blog-section style-four section" ref={CareerRef} id="Careers">
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    {currentCareers?.length > 0 ? (
                      currentCareers?.map((item: any) => (
                        <div className="col-md-6" key={item?.id}>
                          <div className="left-side">
                            <div className="item-holder">
                              <div className="image-box">
                                <figure>
                                  {/* <LazyLoad> */}
                                  <img
                                    src={item?.careerImage && item?.careerImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.careerImage[0]}` : 'images/career.jpg'}
                                    alt={item?.title}
                                    className="img-fluid"
                                    style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} />
                                    {/* </LazyLoad> */}
                                </figure>
                              </div>
                              <div className="content-text">
                                <p><strong>{item?.title}</strong></p>
                                <p>
                                  {item?.categoryName?.map((catName: any, index: number) => (
                                    <span key={index} style={{ marginRight: '5px' }}>
                                      {catName}{index < item?.categoryName?.length - 1 ? ',' : ''}
                                    </span>
                                  ))}
                                </p>
                                <p className="descheight" dangerouslySetInnerHTML={{ __html: truncateText(item?.description, 80) }} />
                                <div className="link-btn">
                                  <a href='javascript:void(0)' className="btn btn-sm mtbtnew" onClick={() => handleReadMore(item?.Slug)}>
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) 
                    : (
                      <div className="col-12">
                        <p style={{ textAlign: 'center', fontSize: '30px' }}>No Careers available</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6><strong>Categories</strong></h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">
                          <li  style={{backgroundColor: selectedCategory === "" ? '#2cbcbc' : 'transparent', color: selectedCategory === "" ? 'white' : 'black', padding: '10px', cursor: 'pointer'}} onClick={() => setSelectedCategory("")}>All</li>
                          {categories?.map((item: any) => {
                            const blogCount = countByCategory[item?.id] || 0;
                            return (
                              <li className="capitalTxt"
                              
                            style={{backgroundColor: selectedCategory === item?.id ? '#2cbcbc' : 'transparent', color: selectedCategory === item?.id ? 'white' : 'black', padding: '10px',cursor: 'pointer'}}  

                              key={item?.id} onClick={() => { setSelectedCategory(item?.id); setAllCats(false); window.scrollTo(0, 0); }} >
                                {item?.name.toLowerCase()} <span>({blogCount})</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {currentCareers?.length > 0 ?

                  <div className="custom-pagination">
                    <ul className="custom-pagination-list">
                      <li className={`custom-pagination-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                          Previous
                        </button>
                      </li>
                      {[...Array(totalPages)].map((_, index) => (
                        <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                          <button onClick={() => paginate(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li className={`custom-pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  : null}
              </div>
            </div>
          </section>
          )}
          
          




     
    </div>
  );
};

export default CareerPage;