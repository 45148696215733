// import { useNavigate } from "react-router-dom";
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import { Grid, TextField, Button, Alert, Box, Container } from '@mui/material';
// import { validationSchema } from './validationSchema';
// import Papa from 'papaparse';
// import { useState } from "react";
// import userService from "../../Services/user/user.service";
// import BulkUploadUtils from "../../utills/bulkupload";
// import BulkServices from '../../Services/bulkuploadservices';


// const BulkUpload = () => {
//     const navigate = useNavigate();
//     const [csvData, setCSVData] = useState<any[]>([]);
//     const [ErrFile, setErrFile] = useState("");

//     const handleSubmit = async () => {
//         console.log(csvData, "csvData");

//         await Promise.all(
//             csvData?.map(async (item: any) => {
//                 const existing: any = await BulkServices().checkExistingUsers(item?.EmailId, item?.PhoneNumber);
//                 let GetInsertData: any;
//                 if (existing?.length > 0) {
//                     try {
//                         GetInsertData = {
//                             ...item, id: existing[0]?.id, EmailId: item?.EmailId, Password: "NA", Role: item?.Role, Status: "1", FirstName: item?.FirstName, LastName: item?.LastName, PhoneNumber: item?.PhoneNumber, countryCode: "+91", Slug: "NA", resetPWD: "No", Specialization: "NA",
//                         };
//                         if (existing[0]?.userType === "E") {
//                             const update = await BulkUploadUtils().updateBulkuser(GetInsertData);
//                         }
//                     } catch (err) {
//                         console.log("err", err);
//                     }
//                 } else {
//                     try {
//                         GetInsertData = {
//                             ...item, EmailId: item?.EmailId, Password: "NA", Role: item?.Role, Status: "1", userType: "E",
//                             FirstName: item?.FirstName, LastName: item?.LastName, PhoneNumber: item?.PhoneNumber,
//                             countryCode: "+91", Slug: "NA", resetPWD: "Yes", Specialization: "NA",
//                         };
//                         const register = await BulkUploadUtils().createBulkUser(GetInsertData);
//                     } catch (err) {
//                         console.log("err", err);
//                     }
//                 }
//             })
//         )
//     }


//     return (
//         <>
//             <section className="admin-section">
//                 <div
//                     className="slider-item bread-item"
//                 >
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-12 col-sm-12">
//                                 <h1>
//                                     Bulk Upload
//                                 </h1>

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <div>
//                 <ul className="paginationbanner">
//                     <li>
//                         <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
//                     </li>
//                     <li>Bulk Upload</li>
//                 </ul>
//             </div>

//             <Container>
//                 <Grid container justifyContent="center">
//                     <Grid item xs={8} md={8}>
//                         <Formik
//                             initialValues={{ bulkupload: null }}
//                             validationSchema={validationSchema}
//                             onSubmit={handleSubmit}
//                         >
//                             {({ setFieldValue, isSubmitting }) => (
//                                 <Form autoComplete="off">
//                                     <Box sx={{ mb: 3 }}>
//                                         <input
//                                             id="file"
//                                             name="bulkupload"
//                                             type="file"
//                                             accept=".csv"
//                                             onChange={(event: any) => {
//                                                 setFieldValue("bulkupload", event?.currentTarget?.files[0]);
//                                                 BulkUploadUtils().handleFileChange(event, setCSVData, setErrFile);
//                                             }}
//                                             placeholder="select file"
//                                         />
//                                         <ErrorMessage name="bulkupload" component="div" className="text-danger mt-1" />
//                                     </Box>
//                                     <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
//                                         Upload
//                                     </Button>
//                                 </Form>
//                             )}
//                         </Formik>
//                         {/* {errorMessage && <Alert severity="error" sx={{ mt: 3 }}>{errorMessage}</Alert>} */}
//                     </Grid>
//                 </Grid>
//             </Container >
//         </>

//         // <div className="container">
//         //     <div className="row">
//         //         <div className="col-md-12 col-sm-12 mt-5">
//         //             <input type="file" />
//         //         </div>
//         //     </div>
//         // </div>
//     )

// }
// export default BulkUpload;



import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, Button, Box, Container, Typography } from '@mui/material';
import { validationSchema } from './validationSchema';
import { useState } from "react";
import BulkUploadUtils from "../../utills/bulkupload";
import BulkServices from '../../Services/bulkuploadservices';

const BulkUpload = () => {
    const navigate = useNavigate();
    const [csvData, setCSVData] = useState<any[]>([]);
    const [ErrFile, setErrFile] = useState("");

    const handleSubmit = async () => {

        await Promise.all(
            csvData?.map(async (item: any) => {
                const existing: any = await BulkServices().checkExistingUsers(item?.EmailId, item?.PhoneNumber);
                let GetInsertData: any;
                if (existing?.length > 0) {
                    try {
                        GetInsertData = {
                            ...item, id: existing[0]?.id, EmailId: item?.EmailId, Password: "NA", Role: item?.Role, Status: "1", FirstName: item?.FirstName, LastName: item?.LastName, PhoneNumber: item?.PhoneNumber, countryCode: "+91", Slug: "NA", Specialization: "NA",
                        };
                        if (existing[0]?.userType === "E") {
                            const update = await BulkUploadUtils().updateBulkuser(GetInsertData);
                            if (update) {
                                navigate("/ListRegisteredPatients");
                            }
                        }
                    } catch (err) {
                        console.log("err", err);
                    }
                } else {
                    try {
                        GetInsertData = {
                            ...item, EmailId: item?.EmailId, Password: "NA", Role: item?.Role, Status: "1", userType: "E",
                            FirstName: item?.FirstName, LastName: item?.LastName, PhoneNumber: item?.PhoneNumber,
                            countryCode: "+91", Slug: "NA", resetPWD: "Yes", Specialization: "NA",
                        };
                        const register = await BulkUploadUtils().createBulkUser(GetInsertData);
                        if (register) {
                            navigate("/ListRegisteredPatients");
                        }
                    } catch (err) {
                        console.log("err", err);
                    }
                }
            })
        );
    }
    const downloadUrl = '/example.csv';

    const downloadCSV = () => {
        const link = document.createElement('a');
        link.href = '/example.csv'; // Path to the CSV file in the public folder
        link.download = 'example.csv'; // Name of the file when downloaded
        link.click(); // Programmatically click the link to trigger download
    };


    return (
        <>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Bulk Upload
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Bulk Upload</li>
                </ul>
            </div>

            {/* Bulk Upload Form */}
            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Formik
                            initialValues={{ bulkupload: null }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ setFieldValue, isSubmitting }) => (
                                <Form autoComplete="off">
                                    <Box sx={{ mb: 3 }}>
                                        <input
                                            id="file"
                                            name="bulkupload"
                                            type="file"
                                            accept=".csv"
                                            onChange={(event: any) => {
                                                setFieldValue("bulkupload", event?.currentTarget?.files[0]);
                                                BulkUploadUtils().handleFileChange(event, setCSVData, setErrFile);
                                            }}
                                            placeholder="Select file"
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                marginBottom: '1rem',
                                                boxSizing: 'border-box',
                                            }}
                                        />
                                        <ErrorMessage name="bulkupload" component="div" className="text-danger mt-1" />
                                    </Box>
                                    <div>
                                        <button type="button" className="btn btn-sm" color="primary" onClick={downloadCSV}>Download CSV</button>
                                    </div>
                                    <Button className="btn btn-sm"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        sx={{
                                            width: '20%',
                                            padding: { xs: '10px', sm: '12px', md: '5px' },
                                            fontSize: { xs: '14px', sm: '16px', md: '10px' },
                                            display: 'block',
                                            margin: '0 auto',
                                        }}
                                    >
                                        Upload
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default BulkUpload;