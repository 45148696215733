import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Papa from 'papaparse';
import { Container, Grid, Typography, Button, TextField, Alert, Box } from '@mui/material';
import { styled } from '@mui/system';
import Sidebar from '../DashBoard/sidebar';
import { Helmet } from 'react-helmet';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';

interface FormValues {
    file: File | null;
    message: string;
}

const Input = styled('input')({
    display: 'none',
});

const SendSms: React.FC = () => {
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState('');
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // useAuthRedirect(logUserObject);

    const fieldRefs: any = useRef({
        file: null as HTMLDivElement | null,
        message: null as HTMLDivElement | null,
    });





    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const initialValues: FormValues = {
        file: null,
        message: ''
    };

    const validationSchema = Yup.object({
        file: Yup.mixed().required('File is required'),
        // file: Yup.mixed().required('File type should be in XLSX format'),
        message: Yup.string().required('Message is required')
    });

    const sendSMS = async (phoneNumber: string, message: string) => {
        const url = process.env.REACT_APP_SENDPhoneL_URL;

        if (!url) {
            throw new Error('REACT_APP_SENDPhoneL_URL is not defined');
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json, text/plain, */*'
            },
            body: JSON.stringify({ phoneNumber, message })
        };

        try {
            const response = await fetch(url, options);

            if (!response?.ok) {
                const errorText = await response.text();
                console.error('Server Error:', errorText);
                throw new Error(`Failed to send SMS: ${response?.status} ${response?.statusText}`);
            }

            const data = await response.json();
            console.log(`SMS sent to ${phoneNumber}:`, data);
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error sending SMS:', error.message);
                throw error;
            } else {
                console.error('Unexpected error:', error);
                throw new Error('Unexpected error occurred');
            }
        }
    };

    const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
        setSuccessMessage('');
        setErrorMessage('');

        const file = values?.file;
        if (file) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const csvData = event?.target?.result as string;

                Papa.parse(csvData, {
                    header: true,
                    skipEmptyLines: true,
                    complete: async (results: Papa.ParseResult<{ phoneNumber: string }>) => {
                        const phoneNumbers = results?.data
                            .map((row: { phoneNumber: any }) => row?.phoneNumber)
                            .filter((phoneNumber: any) => phoneNumber);

                        try {
                            for (const phoneNumber of phoneNumbers) {
                                await sendSMS(phoneNumber, values?.message);
                            }
                            setSuccessMessage('SMS delivered successfully');
                            resetForm();
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                            setTimeout(() => {
                                setSuccessMessage('');
                            }, 2000);
                        } catch (error) {
                            console.log("error", error);
                            if (error instanceof Error) {
                                setErrorMessage('Error sending SMS: ' + error.message);
                            } else {
                                setErrorMessage('Unexpected error occurred');
                            }
                        } finally {
                            setSubmitting(false);
                        }
                    },
                    error: (error: any) => {
                        console.error("Error parsing CSV file:", error);
                        setErrorMessage('Error parsing CSV file: ' + error.message);
                        setSubmitting(false);
                    }
                });
            };
            reader.readAsText(file);
        } else {
            setSubmitting(false);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const file: any = event?.currentTarget?.files?.[0];
        setFieldValue('file', event?.currentTarget?.files?.[0]);
        setFileName(file?.name);
    };

    return (
        <>
            <Helmet>
                <title>Send Sms</title>
                <meta name="description" content="This is the Send Sms page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Send Sms
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li>Send Sms</li>
                </ul>
            </div>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-2" style={{marginTop:'50px', marginBottom:'50px'}}>
                        <Grid item xs={4} sx={{
                            marginBottom: '60px',
                        }}>
                            <Sidebar />
                        </Grid>
                    </div> */}
                    <div className="col-md-12">
                        <Grid container justifyContent="center" >
                            <Grid item xs={8} md={8}>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ setFieldValue, isSubmitting,errors }) => {

                                        const errorCount = Object.keys(errors).length;
                                        console.log({ errorCount })

                                        if (isSubmitting && errorCount > 0) {

                                            for (const key in errors) {
                                                console.log({ key })
                                                if (fieldRefs.current[key]) {
                                                    fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                                    break; // Stop at the first error we find
                                                }
                                            }


                                        }
                                        return (
                                            <Form>
                                                {successMessage && <Alert severity="success">{successMessage}</Alert>}
                                                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                                                <Box sx={{ mb: 3 }}  ref={(el) => (fieldRefs.current.file = el)}>
                                                    <label htmlFor="file" className="form-upload" style={{ marginTop: '13px' }} >
                                                        <Input
                                                            accept=".csv, .xlsx, .xls"
                                                            id="file"
                                                            type="file"
                                                            onChange={(event) => handleFileChange(event, setFieldValue)}
                                                            ref={fileInputRef}
                                                        />
                                                        <Button variant="contained" component="span">
                                                            Upload File
                                                        </Button>
                                                    </label>
                                                    {fileName && (
                                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                                            <strong>Selected file:</strong> {fileName}
                                                        </Typography>
                                                    )}
                                                    <ErrorMessage name="file" component="div" className="text-danger mt-1" />
                                                </Box>
                                                <Box sx={{ mb: 3 }}  ref={(el) => (fieldRefs.current.message = el)}>
                                                    <Field as={TextField}
                                                        fullWidth
                                                        label="Message"
                                                        name="message"
                                                        multiline
                                                        rows={5}
                                                        variant="outlined"
                                                        placeholder="Message"
                                                    />
                                                    <ErrorMessage name="message" component="div" className="text-danger mt-1" />
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                                        Send
                                                    </Button>
                                                </Box>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>

                        </Grid>
                    </div>
                </div>
            </div>
            <Container sx={{ mt: 10, mb: 5 }} >
            </Container>
        </>
    );
};

export default SendSms;




























// import React, { useState, useEffect, useRef } from 'react';
// import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
// import * as Yup from 'yup';
// import Papa from 'papaparse';
// import { Container, Grid, Typography, Button, TextField, Alert, Box } from '@mui/material';
// import { styled } from '@mui/system';

// interface FormValues {
//     file: File | null;
//     message: string;
// }

// const Input = styled('input')({
//     display: 'none',
// });

// const SendSms: React.FC = () => {
//     const [successMessage, setSuccessMessage] = useState<string>('');
//     const [errorMessage, setErrorMessage] = useState<string>('');
//     const fileInputRef = useRef<HTMLInputElement>(null);
//     const [fileName, setFileName] = useState('');

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const initialValues: FormValues = {
//         file: null,
//         message: ''
//     };

//     const validationSchema = Yup.object({
//         file: Yup.mixed().required('File is required'),
//         message: Yup.string().required('Message is required')
//     });

//     const sendSMS = async (phoneNumber: string, message: string) => {
//         const url = process.env.REACT_APP_SENDPhoneL_URL;

//         if (!url) {
//             throw new Error('REACT_APP_SENDPhoneL_URL is not defined');
//         }

//         const options = {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Accept': 'application/json, text/plain, */*'
//             },
//             body: JSON.stringify({ phoneNumber, message })
//         };

//         try {
//             const response = await fetch(url, options);

//             if (!response?.ok) {
//                 const errorText = await response.text();
//                 console.error('Server Error:', errorText);
//                 throw new Error(`Failed to send SMS: ${response?.status} ${response?.statusText}`);
//             }

//             const data = await response.json();
//             console.log(`SMS sent to ${phoneNumber}:`, data);
//         } catch (error) {
//             if (error instanceof Error) {
//                 console.error('Error sending SMS:', error.message);
//                 throw error;
//             } else {
//                 console.error('Unexpected error:', error);
//                 throw new Error('Unexpected error occurred');
//             }
//         }
//     };

//     const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
//         setSuccessMessage('');
//         setErrorMessage('');

//         const file = values?.file;
//         if (file) {
//             const reader = new FileReader();
//             reader.onload = async (event) => {
//                 const csvData = event?.target?.result as string;

//                 Papa.parse(csvData, {
//                     header: true,
//                     skipEmptyLines: true,
//                     complete: async (results: Papa.ParseResult<{ phoneNumber: string }>) => {
//                         const phoneNumbers = results?.data
//                             .map((row: { phoneNumber: any }) => row?.phoneNumber)
//                             .filter((phoneNumber: any) => phoneNumber);

//                         try {
//                             for (const phoneNumber of phoneNumbers) {
//                                 await sendSMS(phoneNumber, values?.message);
//                             }
//                             setSuccessMessage('SMS delivered successfully');
//                             resetForm();
//                             if (fileInputRef.current) {
//                                 fileInputRef.current.value = '';
//                             }
//                             setTimeout(() => {
//                                 setSuccessMessage('');
//                             }, 2000);
//                         } catch (error) {
//                             console.log("error", error);
//                             if (error instanceof Error) {
//                                 setErrorMessage('Error sending SMS: ' + error.message);
//                             } else {
//                                 setErrorMessage('Unexpected error occurred');
//                             }
//                         } finally {
//                             setSubmitting(false);
//                         }
//                     },
//                     error: (error: any) => {
//                         console.error("Error parsing CSV file:", error);
//                         setErrorMessage('Error parsing CSV file: ' + error.message);
//                         setSubmitting(false);
//                     }
//                 });
//             };
//             reader.readAsText(file);
//         } else {
//             setSubmitting(false);
//         }
//     };

//     const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
//         const file: any = event?.currentTarget?.files?.[0];
//         setFieldValue('file', event?.currentTarget?.files?.[0]);
//         setFileName(file?.name);
//     };

//     return (
//         <>
//         <section className="home-slider owl-carousel">
//                 <div
//                     className="slider-item bread-item"
//                     style={{ backgroundImage: `url('images/header_bg.jpg')` }}
//                     // data-stellar-background-ratio="0.5"
//                 >
//                     <div className="overlay"></div>
//                     <div className="container" data-scrollax-parent="true">
//                         <div className="row slider-text align-items-end">
//                             <div className="col-md-7 col-sm-12 ftco-animate mb-5">
//                                 <p
//                                     className="breadcrumbs"
//                                     data-scrollax={`properties: { translateY: '70%', opacity: 1.6}`}
//                                 >
//                                     <span className="mr-2">
//                                         <a href="/">Home</a>
//                                     </span>{' '}
//                                     <span>Send SMS</span>
//                                 </p>
//                                 <h1
//                                     className="mb-3"
//                                     data-scrollax={`properties: { translateY: '70%', opacity: .9}`}
//                                 >
//                                     Send SMS
//                                 </h1>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         <Container sx={{ mt: 5, mb: 5 }}>
//             <Grid container justifyContent="center" style={{ marginTop: '80px', marginBottom: '50px' }}>
//                 <Grid item xs={12} md={8}>
//                     <Formik
//                         initialValues={initialValues}
//                         validationSchema={validationSchema}
//                         onSubmit={handleSubmit}
//                     >
//                         {({ setFieldValue, isSubmitting }) => (
//                             <Form>
//                                 {successMessage && <Alert severity="success">{successMessage}</Alert>}
//                                 {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
//                                 <Box sx={{ mb: 3 }}>
//                                     <label htmlFor="file" className="form-upload" style={{ marginTop: '13px' }}>
//                                         <Input
//                                             accept=".csv, .xlsx, .xls"
//                                             id="file"
//                                             type="file"
//                                             onChange={(event) => handleFileChange(event, setFieldValue)}
//                                             ref={fileInputRef}
//                                         />
//                                         <Button variant="contained" component="span">
//                                             Upload File
//                                         </Button>
//                                     </label>
//                                     {fileName && (
//                                         <Typography variant="body2" sx={{ mt: 1 }}>
//                                             <strong>Selected file:</strong> {fileName}
//                                         </Typography>
//                                     )}
//                                     <ErrorMessage name="file" component="div" className="text-danger mt-1" />
//                                 </Box>
//                                 <Box sx={{ mb: 3 }}>
//                                     <Field as={TextField}
//                                         fullWidth
//                                         label="Message"
//                                         name="message"
//                                         multiline
//                                         rows={5}
//                                         variant="outlined"
//                                         placeholder="Message"
//                                     />
//                                     <ErrorMessage name="message" component="div" className="text-danger mt-1" />
//                                 </Box>
//                                 <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                     <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
//                                         Send
//                                     </Button>
//                                 </Box>
//                             </Form>
//                         )}
//                     </Formik>
//                 </Grid>
//             </Grid>
//         </Container>
//         </>
//     );
// };

// export default SendSms;







