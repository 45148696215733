import React, { Suspense, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Navigate, Route, Router, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import './App.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
// import 'aos/dist/aos.js';




// import Home from './components/views/Home/Home';
import About from './components/views/About';
import Banner from './components/views/Home/Banner/Banner';
import Whyus from './components/views/Home/Whyus/Whyus';
import OurExperts from './components/views/Home/OurExperts/OurExperts';
import Navbar from './components/views/shared/NavBar/NavBar';
import Footer from './components/views/shared/Footer/Footer';
import Services from './components/views/providedServices/Services';
import Login from './components/views/Login/index';
import Register from './components/views/Register/index';
import DoctorRegister from './components/views/Register/DoctorRegister'
import Appointment from './components/views/PatientAppointment/AppoinementList';
import ListDoctor from './components/views/ListDoctors';
import SendSms from './components/views/sendSms';
import Gallery from './components/views/Gallary';
import awsExports from './aws-exports';
import ReviewForm from './components/views/Reviews';
import ListReviews from './components/views/Reviews/listreviews';
import PatientAppointment from './components/views/PatientAppointment';
import Faq from './components/views/Faq';
import Reports from './components/views/Reports';
import Doctor from './components/views/Doctor';
import AllBlogs from './components/views/Blogs/blogs';
import Contact from './components/views/Contact';
import UserProfile from './components/views/user/myAccount';
import Dashboard from './components/views/DashBoard/Dashbord';
import ListRegisteredPatients from './components/views/ListPatient/ListRegisteredPatients';
import ForgotPassword from './components/views/ForgotPassword';
import GalleryUpload from './components/views/Gallary/galleryUpload';
import ListGallery from './components/views/Gallary/listGallery';
import UpdatePassword from './components/views/updatepassword';
import ListContacts from './components/views/Contact/ContactusList';
import AvailabilityList from './components/views/Doctor/AvaliabilityDates/Doctoravaliablelist';
import DoctorAvailability from './components/views/Doctor/AvaliabilityDates/Doctoravaliable';
import ListService from './components/views/Servicepage/listServices';
import CreateServices from './components/views/Servicepage/AddServices';
import ServicePage from './components/views/Servicepage/ServicePage';
import AddReports from './components/views/Reports/addReports';
import CreateBlog from './components/views/Blogs/addBlog';
import ListBlogs from './components/views/Blogs/listBlogs';
import BlogPage from './components/views/Blogs/SingleBlog';
import AddPrescription from './components/views/Prescription/addPrescription';
import AppoinementListHistory from './components/views/PatientAppointment/appointmentHistory';
import ListSpeciality from './components/views/specialities/listSpeciality';
import Createspeciality from './components/views/specialities/AddSpeciality';
import SpecialityDetails from './components/views/specialities/singleSpeciality';
import SpecialityPage from './components/views/specialities/speciality';
import SingleDoctor from './components/views/Doctor/singleDoctor';
import CareerPage from './components/views/careeres/career';



import ListCareer from './components/views/careeres/listCareer';
import CreateCarrer from './components/views/careeres/addCareers';
import CareerDetails from './components/views/careeres/singleCareer';
import CreateTermsAndCondtions from './components/views/pages/addTermsAndConditions';
import ListTermsAndConditions from './components/views/pages/listTermsAndCondtions';
import TermsAndConditions from './components/views/pages/TermsAndConditions';
import PrescriptionList from './components/views/Prescription/listPrescription';
// import PrescriptionList from './components/views/Prescription/listPrescription';
import LabRegister from './components/views/Register/LabRegister';
import ListLabs from './components/views/Lab';
import ListPharmacy from './components/views/Pharmacy';
import PharmacyRegister from './components/views/Register/PharmacyRegister';
import CreateSlider from './components/views/Sliders/AddSliders';
import CreateFAQ from './components/views/Faq/AddFAQ';
import ListSliders from './components/views/Sliders/listSliders';
import ListFAQs from './components/views/Faq/listFAQs';
import NotFound from './components/views/NotFound/NotFound';
import AppointmentTrack from './components/views/trackappointment';
import ListCarrerCategory from './components/views/careeres/categoryList';
import ListBlogCategory from './components/views/Blogs/BlogCategoryList';
import ListSpecialityCategory from './components/views/specialities/specialityCategoryList';
import ListServiceCategory from './components/views/Servicepage/ServiceCategoryList';
import { isAuthenticated } from './components/utills/Redirect/auth';
import BulkUpload from './components/views/bulkUpload';
import ViewAppointment from './components/views/PatientAppointment/ViewAppointment';
import Loader from './components/views/shared/Loader';

// import LoadScripts from './LoadScripts'; // Adjust the import path as needed

const Home: any  = React.lazy(() => import('./components/views/Home/Home'));





Amplify.configure(awsExports);

const theme = createTheme({
  spacing: 4,
});

function ScrollToTop() {

  const { pathname } = useLocation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${process.env.REACT_APP_HOST}js/main.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [pathname]);



  return null;
}

const App: React.FC = () => {



  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  


  // Protected Route Wrapper
  const ProtectedRoute = ({ children }: { children: any }) => {
    if (!isAuthenticated()) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };




  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        {/* <LoadScripts /> */}
        <ScrollToTop />
        <Navbar />
        <div>
          <Routes>
            <Route path="/DashBoard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/docRegister" element={<ProtectedRoute><DoctorRegister /></ProtectedRoute>} />
            <Route path="/AppointmentList" element={<ProtectedRoute><Appointment /></ProtectedRoute>} />
            <Route path="/ListDoctor" element={<ProtectedRoute><ListDoctor /></ProtectedRoute>} />
            <Route path="/sendSms" element={<ProtectedRoute><SendSms /></ProtectedRoute>} />
            <Route path="/listreviews" element={<ProtectedRoute><ListReviews /></ProtectedRoute>} />
            <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
            <Route path="/UpdateProfile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
            <Route path="/ListRegisteredPatients" element={<ProtectedRoute><ListRegisteredPatients /></ProtectedRoute>} />
            <Route path="/galleryUpload" element={<ProtectedRoute><GalleryUpload /></ProtectedRoute>} />
            <Route path="/listGallery" element={<ProtectedRoute><ListGallery /></ProtectedRoute>} />
            <Route path="/updatepassword" element={<UpdatePassword />}/>
            <Route path="/enquirylist" element={<ProtectedRoute><ListContacts /></ProtectedRoute>} />
            <Route path="/AvailabilityList" element={<ProtectedRoute><AvailabilityList /></ProtectedRoute>} />
            <Route path="/doctorAvailability" element={<ProtectedRoute><DoctorAvailability /></ProtectedRoute>} />
            <Route path="/listServices" element={<ProtectedRoute><ListService /></ProtectedRoute>} />
            <Route path="/addServices" element={<ProtectedRoute><CreateServices /></ProtectedRoute>} />
            <Route path="/services/:slug" element={<ServicePage />} />
            <Route path="/addReports" element={<ProtectedRoute><AddReports /></ProtectedRoute>} />
            <Route path="/listBlogs" element={<ProtectedRoute><ListBlogs /></ProtectedRoute>} />
            <Route path="/addBlogs" element={<ProtectedRoute><CreateBlog /></ProtectedRoute>} />
            <Route path="/addprescription" element={<ProtectedRoute><AddPrescription /></ProtectedRoute>} />
            <Route path="/appointmentHistory/:slug?" element={<AppoinementListHistory />} />
            <Route path="/prescriptions" element={<ProtectedRoute><PrescriptionList /></ProtectedRoute>} />
            <Route path="/listSpeciality" element={<ProtectedRoute><ListSpeciality /></ProtectedRoute>} />
            <Route path="/addSpeciality" element={<ProtectedRoute><Createspeciality /></ProtectedRoute>} />
            <Route path="/addTermsAndCondtions" element={<ProtectedRoute><CreateTermsAndCondtions /></ProtectedRoute>} />
            <Route path="/listTermsAndCondtions" element={<ProtectedRoute><ListTermsAndConditions /></ProtectedRoute>} />
            <Route path="/listCareer" element={<ProtectedRoute><ListCareer /></ProtectedRoute>} />
            <Route path="/addCareer" element={<ProtectedRoute><CreateCarrer /></ProtectedRoute>} />
            <Route path="/addLab" element={<ProtectedRoute><LabRegister /></ProtectedRoute>} />
            <Route path="/listLab" element={<ProtectedRoute><ListLabs /></ProtectedRoute>} />
            <Route path="/listPharmacy" element={<ProtectedRoute><ListPharmacy /></ProtectedRoute>} />
            <Route path="/addPharmacy" element={<ProtectedRoute><PharmacyRegister /></ProtectedRoute>} />
            <Route path="/addSliders" element={<ProtectedRoute><CreateSlider /></ProtectedRoute>} />
            <Route path="/listSliders" element={<ProtectedRoute><ListSliders /></ProtectedRoute>} />
            <Route path="/addFAQs" element={<ProtectedRoute><CreateFAQ /></ProtectedRoute>} />
            <Route path="/listFAQs" element={<ProtectedRoute><ListFAQs /></ProtectedRoute>} />
            <Route path="/careerCategoryList" element={<ProtectedRoute><ListCarrerCategory title="careerCategoryList" description="careerCategoryList" /></ProtectedRoute>} />
            <Route path="/BlogCategoryList" element={<ProtectedRoute><ListBlogCategory title="BlogCategoryList" description="BlogCategoryList" /></ProtectedRoute>} />
            <Route path="/specialityCategoryList" element={<ProtectedRoute><ListSpecialityCategory title="specialityCategoryList" description="specialityCategoryList" /></ProtectedRoute>} />
            <Route path="/serviceCategoryList" element={<ProtectedRoute><ListServiceCategory title="serviceCategoryList" description="serviceCategoryList" /></ProtectedRoute>} />
{/* public Routes */}

            <Route path="/login" element={<Login title="Login | Gurram Dental Care - Access Your Dental Health Dashboard" keywords="Gurram Dental Care login, dental health login, patient portal, Gurram Dental login, dental appointments, access dental care, Gurram dental dashboard, dental history, patient care portal" description="Login to Gurram Dental Care to manage appointments, view treatment history, and access personalized dental health tips. Join our patient portal for seamless care and communication." />} />
            
            <Route path="/Specialities/:slug" element={<SpecialityDetails title="SpecialityDetails" description="SpecialityDetails" />} />
            
            

            <Route path="/rating" element={<ReviewForm />} />
            <Route path="/FAQS" element={<Faq />} />
            
            <Route path="/Blogs/:slug" element={<BlogPage title="Single Blog" description="Single Blog" />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/banner" element={<Banner />} />
            <Route path="/whyus" element={<Whyus />} />
            <Route path="/OurExperts" element={<OurExperts />} />


            <Route path="/PatientAppointment/:slug?" element={<PatientAppointment title="Book an Appointment | Gurram Dental Care | Convenient Online Scheduling" keywords='book appointment Gurram Dental Care, dental appointment scheduling, online appointment booking, schedule dental visit, dental services appointment, patient appointment, dental care scheduling, easy appointment booking' description="Schedule your visit to Gurram Dental Care easily! Use our online booking system to make an appointment for comprehensive dental services tailored to your needs." />} />


            <Route path="/gallery" element={<Gallery title="Gallery | Gurram Dental Care | Our Dental Facility and Patient Smiles" keywords='Gurram Dental Care gallery, dental clinic images, dental facility photos, patient smiles, before and after dental treatments, dental services gallery, dental office tour, dental care images, dental practice photos' description="Explore our gallery at Gurram Dental Care to see our state-of-the-art facility, patient smiles, and the comprehensive dental services we offer. Experience our commitment to excellence!" />} />

            <Route path="/" element={
            <Suspense fallback={<Loader />}>
              <Home title="Gurram Dental Care | Premier Dental Clinic for Comprehensive Care" description="Welcome to Gurram Dental Care, your premier dental clinic offering personalized care and comprehensive services for healthy smiles. Experience exceptional dentistry in a comfortable environment." keywords="Gurram Dental Care, dental clinic, family dental care, personalized dental services, oral health, cosmetic dentistry, orthodontics, dental hygiene, patient comfort, expert dental team" />
              </Suspense>
            } />

            <Route path="/AboutUs" element={<About title="Gurram Dental Care - About Us | Expert Dental Services for Healthy Smiles" description="At Gurram Dental Care, we provide comprehensive dental services with a focus on patient comfort and personalized care. Our expert team is dedicated to creating healthy, beautiful smiles." keywords="Gurram Dental Care, about Gurram Dental, dental clinic, expert dental services, personalized dental care, family dental care, oral health, dental team, healthy smiles" />} />

            <Route path="/Services" element={<Services title="Dental Services at Gurram Dental Care | Comprehensive Care for All Ages" keywords="dental services, Gurram Dental Care, routine checkups, dental implants, orthodontics, cosmetic dentistry, teeth cleaning, family dental care, oral health treatments, advanced dental treatments" description="Explore a full range of dental services at Gurram Dental Care, from routine checkups to advanced treatments like dental implants, orthodontics, and cosmetic dentistry for all ages." />} />

            <Route path="/Doctors" element={<Doctor title="Meet Our Doctors | Expert Dental Professionals at Gurram Dental Care" keywords="Gurram Dental Care doctors, dental professionals, experienced dentists, skilled dentists, dental team, dental specialists, family dental care, personalized dental care, expert dentists" description="Meet the experienced and compassionate dental professionals at Gurram Dental Care. Our skilled doctors are committed to providing personalized care and achieving excellent patient outcomes." />} />

            <Route path="/Blogs" element={<AllBlogs title="Dental Health Blog | Tips and Expert Advice from Gurram Dental Care" keywords='dental blog, oral health tips, Gurram Dental Care blog, dental care advice, dental treatments, healthy smile tips, oral hygiene, dental news, expert dental insights"' description="Stay updated with the latest dental health tips, treatment options, and oral care advice from the experts at Gurram Dental Care. Explore our blog for valuable insights on maintaining a healthy smile." />} />


            <Route path="/Specialities" element={<SpecialityPage title="Dental Specialties at Gurram Dental Care | Comprehensive Care for Every Need" keywords='dental specialties, Gurram Dental Care, cosmetic dentistry, orthodontics, restorative dentistry, pediatric dental care, dental procedures, dental services, specialized dental treatments, oral health specialties' description="Discover the specialties offered at Gurram Dental Care, including cosmetic dentistry, orthodontics, restorative procedures, and pediatric dental care tailored to your needs." />} />


            <Route path="/careers" element={
            <CareerPage title="Careers at Gurram Dental Care | Join Our Passionate Dental Team" keywords="careers in dentistry, Gurram Dental Care jobs, dental career opportunities, join our team, dental support roles, dental professionals, employment in dentistry, dental jobs, patient care careers" description="Join the dedicated team at Gurram Dental Care! Explore exciting career opportunities in dentistry and support roles, and be part of a community committed to patient care." />  
            
            } />

            <Route path="/ContactUs" element={<Contact title="Contact Us | Gurram Dental Care | Your Trusted Dental Clinic" keywords='contact Gurram Dental Care, dental inquiries, appointment requests, dental clinic contact, patient support, dental health assistance, dental services inquiries, reach us, dental care contact information"' description="Get in touch with Gurram Dental Care for inquiries, appointments, or assistance. Our friendly team is here to help you achieve optimal dental health." />} />


          <Route path="/TrackAppointment" element={<AppointmentTrack title="Track Your Appointment | Gurram Dental Care | Stay Updated" keywords='track appointment Gurram Dental Care, appointment tracking, dental visit updates, check appointment status, dental appointment reminders, manage dental appointments, appointment history, patient appointment tracking' description="Easily track your appointment at Gurram Dental Care. Stay updated with reminders and changes for your upcoming dental visits for a seamless experience." />} />

            
            <Route path="/register" element={<Register title='Sign Up | Gurram Dental Care - Join Our Patient Portal Today' keywords='Gurram Dental Care sign up, dental care registration, patient portal sign up, Gurram Dental new patient, dental appointments, register dental services, sign up dental care portal, dental health dashboard' description='Create your account with Gurram Dental Care to schedule appointments, track dental treatments, and receive personalized dental care tips. Sign up today for easy access to quality dental services.' /> } />

            
            
           

            <Route path="*" element={<NotFound />} />
            <Route path="/careers/:slug" element={<CareerDetails title="SingleCareer" description="SingleCareer" />} />
            <Route path="/termsConditions" element={<TermsAndConditions title="TermsAndConditions" description="TermsAndConditions" />} />
            
            <Route path="/Doctors/:slug" element={<SingleDoctor title="Doctors" description="Doctors" />} />
            <Route path='/BulkUpload' element={<BulkUpload />}></Route>
            <Route path='/ViewAppointment' element={<ViewAppointment title={''} description={''} />}></Route>
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;