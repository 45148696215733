import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './styles.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { validationSchema } from './validationSchema';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from '../shared/fileupload';
import { useLocation, useNavigate } from 'react-router-dom';
import getblogs from '../../Services/BlogServices';
import BlogsComponent from './CreateBlogCatergory';
import CKEditorComponent from '../../utills/CKEditor';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import replaceSpecialChars from '../../utills/Fileformat';
import { Helmet } from 'react-helmet';
import { FormatFile } from '../../utills/Fileformat';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';


const CreateBlog: React.FC = () => {
    const location = useLocation();
    const initialValues = {
        catergory: '',
        title: '',
        description: '',
        blogImage: [],
        Slug: '',

    };

    const navigate = useNavigate();
    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [InputDetails, setInputDetails] = useState<any>(initialValues);
    const [updateBlog, setUpdateBlog] = useState<boolean>(false);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const [ErrMsgSlug, setErrMsgSlug] = useState("");
    // const logUserObject:any = useAuthRedirect();
    // useAuthRedirect(logUserObject);

    const fieldRefs: any = useRef({
        catergory: null as HTMLDivElement | null,
        title: null as HTMLDivElement | null,
        slug: null as HTMLDivElement | null,
        description: null as HTMLDivElement | null
    });





    async function provInfo() {
        const service = await getblogs().getSingleBlog(location?.state?.id);
        setInputDetails(service);
        setUpdateBlog(true);
    }

    useEffect(() => {
        provInfo();
    }, [location?.state]);


    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.blogImage[0], setInputDetails, 'blogImage', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };

    const handleSubmit = async (values: any, { resetForm }: any) => {
        window.scrollTo(0, 0);
        try {
            const generateSlug = (name: any) => name.replace(/\s+/g, '_').toLowerCase();
            const slug = generateSlug(values?.Slug)
            let slugError = false;

            if (values?.id) {
                const SlugDetails = await getblogs().validateSlug(slug);
                if (SlugDetails?.msg) {
                    SlugDetails?.data?.map((item: any) => {
                        if (item?.id !== values?.id) {
                            slugError = true;
                        }
                    });
                    if (slugError) {
                        setErrMsgSlug("Slug is already in use");
                        return;
                    } else {
                        setErrMsgSlug("");
                    }
                }
            } else {
                const SlugDetails = await getblogs().validateSlug(slug);

                if (SlugDetails?.msg) {
                    setErrMsgSlug("Slug is already in use");
                    return;
                }
            }

            // let newFilename: any;
            // if (file?.name) {
            const newFilename = await FormatFile(file)
            // fileName = Date.now() + "_" + file?.name;
            // const updatedFileName = file?.name?.replace(/ /g, '_');
            // fileName = Date.now() + "_" + updatedFileName;
            // const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            // newFilename = replaceSpecialChars(filename);
            if (values?.id) {
                file?.name && await updDelFile(values?.bolgImage, "images");
            }
            file?.name && await SendFile(file, newFilename, "images");
            // }

            const BlogData = {
                id: values?.id,
                title: values?.title,
                catergory: values?.catergory,
                description: values?.description,
                blogImage: file?.name ? newFilename : values?.blogImage,
                Slug: slug,
                status: 1,
                userID: logUserObject?.userId,
            };

            console.log("BlogData", BlogData);

            const BlogResponse = values?.id
                ? await getblogs().BlogUpdate(BlogData)
                : await getblogs().CreateBlog(BlogData);

            if (BlogResponse) {
                navigate('/listblogs');
            }

        } catch (error) {
            console.error('Error submitting Service:', error);
            throw new Error('Failed to submit Service');
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Helmet>
                <title>Create Blog</title>
                <meta name="description" content="This is the Blog page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1 className="section-title">{!location?.state?.id ? "Create Blog" : "Update Blog"}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" href="/">Home  /&nbsp;</a>
                    </li>
                    <li><span className="section-title">{!location?.state?.id ? "Create Blog" : "Update Blog"}</span>
                    </li>
                </ul>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <Formik
                            initialValues={location?.state?.id ? InputDetails : initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({ isSubmitting, setFieldValue, values, errors }) => {

                                const errorCount = Object.keys(errors).length;
                                console.log({ errorCount })

                                if (isSubmitting && errorCount > 0) {

                                    for (const key in errors) {
                                        console.log({ key })
                                        if (fieldRefs.current[key]) {
                                            fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                            break; // Stop at the first error we find
                                        }
                                    }


                                }

                                return (
                                    <Form className="blog-form" autoComplete='off'>
                                        {/* <h3 className="section-title">{!location?.state?.id ? "Create Blog" : "Update Blog"}</h3> */}

                                        <div className="input-container" ref={(el) => (fieldRefs.current.catergory = el)}>
                                            <BlogsComponent
                                                InputDetails={InputDetails}
                                                setInputDetails={setInputDetails}
                                                setFieldValue={setFieldValue}
                                                editValue={values.catergory}

                                            />
                                            <ErrorMessage name="catergory" component="div" className="error-message" />
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.title = el)}>
                                                <label htmlFor="title">Blog Title:*</label>
                                                <Field
                                                    type="text"
                                                    id="title"
                                                    name="title"
                                                    value={values?.title}
                                                    onChange={(e: any) => setFieldValue("title", e?.target?.value)}
                                                />
                                                <ErrorMessage name="title" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.Slug = el)}>
                                                <label htmlFor="Slug">Slug Name:*</label>
                                                <Field
                                                    type="text"
                                                    id="Slug"
                                                    name="Slug"
                                                    value={values?.Slug}
                                                    onChange={(e: any) => setFieldValue("Slug", e?.target?.value)}
                                                    disabled={location?.state?.id ? true : false}
                                                />
                                                <p style={{ color: 'red' }}>{ErrMsgSlug ? ErrMsgSlug : ''}</p>
                                                <ErrorMessage name="Slug" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.description = el)}>
                                                <label htmlFor="description">Blog Description:*</label>
                                                <Field name="description">
                                                    {({ field, form }: any) => (
                                                        <CKEditorComponent
                                                            data={field.value}
                                                            onDataChange={(newContent: string) => setFieldValue('description', newContent)}
                                                            setFieldValue={setFieldValue}
                                                            disableLink={true}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="description" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container">
                                                {InputDetails?.blogImage?.length > 0 && updateBlog ? (
                                                    <>
                                                        <img
                                                            src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.blogImage}`}
                                                            alt="blogImage"
                                                            style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }}
                                                        />
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                            <p style={{ margin: 0, lineHeight: '24px' }}><strong>FileName:</strong>{InputDetails?.blogImage}</p>
                                                            <span
                                                                style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                                                onClick={handleRemove}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <strong>No Profile Photo</strong>
                                                )}
                                                <FileUpload
                                                    label={location?.state?.id ? "Update image" : "Choose image"}
                                                    setDirectory={setDirectory}
                                                    setFile={setFile}
                                                />
                                                <ErrorMessage name="blogImage" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <button className='btn btn-primary' type="submit" disabled={isSubmitting}>
                                            {!location?.state?.id ? "Create Blog" : "Update Blog"}
                                        </button>
                                    </Form>
                                )

                            }}
                        </Formik>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        </>
    );
};

export default CreateBlog;













