import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { SpinningCircles } from 'react-loading-icons';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import forgotPasswordServices from '../../Services/forgotPasswordServices';
import { ValidationSchema } from './validationScheme';
import './style.css';
import pages from '../../utills/pageUtils';

interface UserDetails {
    userName: string;
    PhoneNumber: string;
}

const initialState: UserDetails = {
    userName: '',
    PhoneNumber: '',
};

function ForgotPassword() {
    const location = useLocation();
    const navigate = useNavigate();
    const [errSubmitMsg, setSubmitMsg] = useState("");
    const [SuccessMsg, setSuccessMsg] = useState("");
    const [Loader, setLoader] = useState(false);
    const [getModal, setModal] = useState(false);
    const [Response, setResponse] = useState<any>();
    const [CurrentOTP, setCurrentOTP] = useState("");
    const [OTPErr, setOTPErr] = useState("");
    const [timer, setTimer] = useState<number>(60);
    const [userName, setUserName] = useState("");

    const [isTimerRunning, setIsTimerRunning] = useState(false);
 

    let intervalId: NodeJS.Timeout | null = null;

    // const setTimerDetails = () => {
    //     if (intervalId === null) {
    //         intervalId = setInterval(() => {
    //             setTimer((prevTimer) => {
    //                 if (prevTimer <= 1) {
    //                     clearInterval(intervalId as NodeJS.Timeout);
    //                     intervalId = null;
    //                     return 0;
    //                 }
    //                 return prevTimer - 1;
    //             });
    //         }, 1000);
    //     }
    // };
    // console.log("EmailID", userName);
    const [pageInfo, setPageInfo] = useState<any>()
    const [bannerName, setBannerName] = useState<any>()
    const [caption, setCaption] = useState<any>()


    useEffect(() => {
        const fetchData = async () => {
            // Simulate fetching data
            const result = await pages(location?.pathname)
            result?.map((item: any) => {
                setPageInfo(item?.Banner);
                setBannerName(item?.Title)
                setCaption(item?.Description)
            })

        };
        fetchData();
    }, [])

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerRunning(false);
        }

        // Clean up the interval when the component unmounts or the timer is stopped
        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);


    const handleSubmit = async (values: UserDetails) => {
        // console.log("values", values);
        setLoader(true);
        setUserName(values?.userName);
        const userResponse: any = await forgotPasswordServices().SendEmail(values?.userName, '', '');
        console.log({ userResponse });
        // debugger
        if (userResponse) {
            setLoader(false);
            if (userResponse?.type === "email" && userResponse?.status === 200) {
                setSuccessMsg(userResponse?.msg);
                setSubmitMsg("");
                setIsTimerRunning(true);
                setTimer(60);
                setModal(true);
                // setTimerDetails();
                setUserName(userResponse?.userDetails?.EmailId);
            } else if (userResponse?.type === "mobile" && userResponse?.status === 200) {
                setResponse(userResponse);
                setSubmitMsg("");
                setIsTimerRunning(true);
                setTimer(60);
                setModal(true);
                // setTimerDetails();
                setUserName(userResponse?.userDetails?.PhoneNumber);
            } else if (userResponse?.status === 404) {
                setSubmitMsg("User is not registered with us, please provide proper email/phone details");
            } else {
                setSubmitMsg(userResponse?.msg);
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const SubmitOTP = async () => {
        // console.log("SubmitOTP", userName);
        // debugger
        // console.log("CurrentOTP", CurrentOTP);
        const currentTime = new Date();
        const formattedTime = currentTime.toISOString();
        const getOTP: any = await forgotPasswordServices().validateotp(userName);
        // console.log("getOTP", getOTP);

        if (getOTP && getOTP?.data?.listUsers.items[0]?.Otp === CurrentOTP) {
            const email = getOTP?.data?.listUsers?.items[0]?.EmailId;
            const phoneNumber = getOTP?.data?.listUsers?.items[0]?.PhoneNumber;

            navigate({
                pathname: '/updatepassword',
                search: `?email=${Response?.userDetails?.EmailId}&dt=${formattedTime}`
            }, {
                state: { email: email, phoneNumber: phoneNumber }
            });
        } else {
            setOTPErr("Invalid OTP");
        }
    };

    const ResendEmail = async () => {
        try {
            setCurrentOTP("");
            const userResponse: any = await forgotPasswordServices().SendEmail(userName);
            // console.log("userResponse", userResponse);
            setTimer(60);
            setIsTimerRunning(true);
            // setTimerDetails();
        } catch (error) {
            console.error("Error resending OTP:", error);
        }
    };
    const closeModal = () => {
        setModal(false);
    };
    return (
        <>
            <section className="home-slider owl-carousel">
                {/* <div
                    className="slider-item bread-item"
                    style={{ backgroundImage: `url('images/bg_1.jpg')` }}
                    data-stellar-background-ratio="0.5"
                >
                    <div className="overlay"></div>
                    <div className="container" data-scrollax-parent="true">
                        <div className="row banner-text align-items-center">
                            <div className="col-md-12 col-sm-12 ftco-animate">
                                <p className="breadcrumbs" style={{ marginTop: '90px' }}>
                                   
                                </p><br />
                                <h2 style={{ textAlign: 'center', marginTop: '-50px' }}>Forgot Password</h2>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div
                    className="slider-item bread-item"
                    // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
                    style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})` : 'images/bg_1.jpg' }}
                    data-stellar-background-ratio="0.5"
                >
                    <div className="overlay"></div>
                    <div className="container" data-scrollax-parent="true">
                        <div className="row banner-text align-items-center">
                            <div className="col-md-12 col-sm-12 ftco-animate">

                                <h1 className="banner-ttl">{bannerName}</h1>
                                <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container-fluid loginfluidforgot">
                <Formik
                    initialValues={initialState}
                    validationSchema={ValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, resetForm }) => (
                        <FormikForm style={{ textAlign: 'center' }}>
                            {Loader &&
                                <Modal size='sm' centered show={Loader}>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <h5><SpinningCircles fill='#b3b3b3' style={{ marginLeft: "20%", marginRight: "5%" }} />Loading</h5>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            }

                            {getModal &&
                                <Modal size='sm' centered show={getModal}>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <span className="close new" onClick={closeModal} style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}>
                                                &times;
                                            </span>
                                            <h4 style={{ color: '#007bff', marginBottom: '20px' }}>OTP</h4>
                                            {timer > 0 ?
                                                <>
                                                    <Form.Group controlId="password" style={{ textAlign: 'left' }}>
                                                        <Form.Label style={{ fontWeight: 'bold' }}>Enter Your OTP</Form.Label>
                                                        <p>Timer: {timer} seconds</p>
                                                        <Form.Control type="text" name="otp" placeholder="Enter OTP" onChange={(e: any) => setCurrentOTP(e?.target?.value)} style={{ padding: '14px 20px' }} />
                                                        <p className='errMsg'>{OTPErr}</p>
                                                    </Form.Group>
                                                    <Button variant="primary" type="submit" className="btn btn-primary" style={{ marginRight: '30px', backgroundColor: '#007bff', borderColor: '#007bff' }} onClick={SubmitOTP}>
                                                        Check
                                                    </Button>
                                                </>
                                                :
                                                <p>The OTP has expired. Please click the link to resend the OTP.
                                                    <br />
                                                    <br />
                                                    <button className='btn btn-primary btn-sm text-center' onClick={(e: any) => { ResendEmail(); setTimer(60) }}>Click here to resend</button>
                                                </p>
                                            }
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            }

                            <Row className="mt-5" style={{ marginBottom: '80px' }}>
                                <Col md={4}></Col>
                                <Col md={4} className="rowborder" >
                                    <h4 style={{ color: '#2cbcbc', marginBottom: '20px',marginTop:'10px' }}>Forgot Password</h4>
                                    <p className='errMsg'>{errSubmitMsg}</p>
                                    <p className='text-success'>{SuccessMsg}</p>

                                    <Form.Group controlId="emailOrPhone" style={{ textAlign: 'left' }}>
                                        <Form.Label >EMAIL ID/Phone Number</Form.Label>
                                        <Field type="text" name="userName" autoComplete="off" placeholder="Enter your EmailID/ Phone Number" className="form-control" style={{ padding: '14px 20px' }} />
                                        <ErrorMessage name="userName" component="p" className="errMsg" />
                                    </Form.Group>

                                    <Button
                                        variant="primary" type="submit" className="btn" style={{ backgroundColor: '#007bff', marginTop: '20px' }}
                                        disabled={isSubmitting || SuccessMsg ? true : false}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </Button>
                                </Col>
                            </Row>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default ForgotPassword;