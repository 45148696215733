import { API, graphqlOperation } from "aws-amplify";
import { getUser, listUsers } from "../../../graphql/queries";
import { createUser, deleteUser, updateUser } from "../../../graphql/mutations";



const PharmacyServices = () => {

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }
    const updatePharmacyByData = async (data: any) => {

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);
        try {
            // console.log("data", data);
            // const filterVar = {
            //     filter:{
            //         input: data,
            //         condition: null 
            //     }
            // }
            const getResponse: any = await API.graphql(graphqlOperation(updateUser, { input: sanitizedUserInfo }));
            console.log(getResponse, "getResponse");
            return getResponse;
        } catch (error) {
            console.log(error);
        }
    }


    const ListPharmacyById = async (id: string) => {
        console.log("id", id)
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    filter: {
                        id: { eq: id },
                        Status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken
                };

                // console.log("filter", filterVar);

                const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;

                console.log(result, "result");

                const users = result?.data?.listUsers?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listUsers?.nextToken;
            } while (nextToken);

            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }
    const getcreateUser = async (userData: any) => {
        try {
            const getResponse: any = await API.graphql(graphqlOperation(createUser, { input: userData }));

            delete getResponse.data.createUser.Password;
            // delete getResponse.data.createDentalRegister.Otp;
            console.log("getResponse.data.createUser", getResponse.data.createUser);

            const userRespData = getResponse.data.createUser;

            if (userRespData?.EmailId) {
                let response;

                const dataSend = {
                    requestBody: {
                        email: userRespData?.EmailId,
                        userId: userRespData?.id,
                        type: "registration"
                    }
                };

                const options = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json, text/plain, */*"
                    },
                    body: JSON.stringify(dataSend)
                };

                try {
                    // response = await fetch(`${process.env.REACT_APP_SEND_EMAIL_API}`, options);
                    // const responseData = await response.json();
                    // console.log('Response registration:', responseData);

                    return { msg: 'User created successfully', data: getResponse.data, response, status: 200 };
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            }

            console.log('Registration successful:', getResponse);
        } catch (error) {
            console.error('Error creating registration:', error);
        }
    };
    const ListPharmacy = async (Role: string) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    filter: {
                        Role: { eq: Role },
                        // Status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken
                };

                // console.log("filter", filterVar);

                const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;

                console.log(result, "result");

                const users = result?.data?.listUsers?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listUsers?.nextToken;
            } while (nextToken);

            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }
    const deletePharmacy = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteUser, { input: { id } }));
            console.log(result);
            return result;
        } catch (error) {
            console.log(error);
        }
    }
    const getuserSingle = async (userId: any) => {
        const getuser = await API.graphql(graphqlOperation(getUser, { id: userId }));
        console.log('getuser', getuser);
        return getuser
    }

    return {
        ListPharmacyById,
        updatePharmacyByData,
        getcreateUser,
        ListPharmacy,
        deletePharmacy,
        getuserSingle
    }
}


export default PharmacyServices;
