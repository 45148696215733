import React, { useEffect, useRef, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
// import axios from 'axios';
import StarRatingComponent from 'react-star-rating-component';
import { ReviewvalidationSchema } from './ReviewvalidationSchema';
import './style.css';
// import userService from '../Services/user/user.service';
import ReviewUtils from '../../utills/users/Reviews';
import { useNavigate, useLocation } from 'react-router-dom';
// import CameraComponent from './webcam';
import CameraComponent from './webcam';
import { FileUpload } from '../shared/fileupload';
import pages from '../../utills/pageUtils';
import { Alert } from 'react-bootstrap';
import Loader from '../shared/Loader';
import LazyLoad from 'react-lazyload';

interface Review {
    Name: string;
    EmailId: string;
    Rating: any;
    Comment: string;
    Upload: any;
}

const initialValues: Review = {
    Name: '',
    EmailId: '',
    Rating: '',
    Comment: '',
    Upload: ''
};

const ReviewForm: React.FC = () => {

    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [pageInfo, setPageInfo] = useState<any>()
    const [bannerName, setBannerName] = useState<any>()
    const [caption, setCaption] = useState<any>()
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [alertMsg, setAlertMsg] = useState<any>('');

    const fieldRefs: any = useRef({
        Name: null as HTMLDivElement | null,
        EmailId: null as HTMLDivElement | null,
        Rating: null as HTMLDivElement | null,
        Comment: null as HTMLDivElement | null
    });



    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            // Simulate fetching data
            const result = await pages(location?.pathname)
            result?.map((item: any) => {
                setPageInfo(item?.Banner);
                setBannerName(item?.Title)
                setCaption(item?.Description)
            })
        };
        fetchData();
        setLoading(false)
    }, [])

    const handleSubmit: any = async (values: Review, { setSubmitting, resetForm }: any) => {

        try {
            setLoading(true);
            const submit: any = await ReviewUtils().handleSubmit(values, resetForm, file);
            if (submit) {
                setAlertMsg("Review submitted successfully");
                // setSuccessMessage("Review submitted successfully");
                // Reset the form
                resetForm();
                // setTimeout(() => {
                //     setSuccessMessage('');
                // }, 5000);
                // return { data: submit, status: 200 };
            }
        } catch (error) {
            setLoading(false);
            console.error("Error submitting form", error);
        } finally {
            setSubmitting(false);
            setLoading(false); // Ensure setSubmitting is called
        }
    };


    const getEmojiAndMessage = (rating: number) => {
        switch (rating) {
            case 1:
                return { emoji: '😞', message: 'Poor' };
            case 2:
                return { emoji: '😐', message: 'Fair' };
            case 3:
                return { emoji: '😊', message: 'Good' };
            case 4:
                return { emoji: '😃', message: 'Very Good' };
            case 5:
                return { emoji: '😍', message: 'Excellent' };
            default:
                return { emoji: '', message: '' };
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (


        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <section className="home-slider owl-carousel">
                        <LazyLoad>
                        <div
                            className="slider-item bread-item"
                            // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
                            style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})` : 'images/bg_1.jpg' }}
                            data-stellar-background-ratio="0.5"
                        >
                            <div className="overlay"></div>
                            <div className="container" data-scrollax-parent="true">
                                <div className="row banner-text align-items-center">
                                    <div className="col-md-12 col-sm-12 ftco-animate">
                                        <h1 className="banner-ttl">{bannerName}</h1>
                                        <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </LazyLoad>
                    </section>
                    <div>
                        <ul className="ullist" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                            <li>
                                <a className="aColor" href="/">Home  /&nbsp;</a>
                            </li>
                            <li>Review Us
                            </li>
                        </ul>
                    </div>

                    {
                        alertMsg &&
                        <div className='alertMessage'>
                            <Alert variant="success" onClick={() => setAlertMsg('')} dismissible>
                                {alertMsg}
                            </Alert>
                        </div>
                    }

                    <Formik
                        initialValues={initialValues}
                        validationSchema={ReviewvalidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, setFieldValue, values, resetForm,errors }: any) => {
                              const errorCount = Object.keys(errors).length;
                              console.log({ errorCount })

                              if (isSubmitting && errorCount > 0) {

                                  for (const key in errors) {
                                      console.log({ key })
                                      if (fieldRefs.current[key]) {
                                          fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                          break; // Stop at the first error we find
                                      }
                                  }


                              }

                            return (

                                <div className="container">
                                    {/* <h2 className="text-center">Review Us</h2> */}
                                    {/* {successMessage && <div className="text-success mt-2 text-center">{successMessage}</div>} */}
                                    <Form autoComplete="off" className="container" >
                                        <div className="row mt-5 justify-content-center">

                                            <div className="col-md-8 mb-3"  ref={(el) => (fieldRefs.current.Name = el)}>
                                                <label htmlFor="Name" className="form-label">Name*</label>
                                                <Field
                                                    type="text"
                                                    className="form-control"
                                                    id="Name"
                                                    name="Name"
                                                    placeholder="Name"
                                                />
                                                <ErrorMessage name="Name" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-8"  ref={(el) => (fieldRefs.current.EmailId = el)}>
                                                <label htmlFor="EmailId" className="form-label">Email*</label>
                                                <Field
                                                    type="EmailId"
                                                    className="form-control"
                                                    id="EmailId"
                                                    name="EmailId"
                                                    placeholder="Email"
                                                />
                                                <ErrorMessage name="EmailId" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-8"   ref={(el) => (fieldRefs.current.Rating = el)}>
                                                <div><label htmlFor="Rating" style={{ textAlign: 'left', marginBottom: '30px' }} className="form-label">How would you rate your experience*</label></div>
                                                <div className="d-flex align-items-center">
                                                    <div className="star-rating" style={{ fontWeight: '900' }}>
                                                        <StarRatingComponent
                                                            name="Rating"
                                                            starCount={5}
                                                            value={values?.Rating}
                                                            onStarClick={(nextValue: any) => setFieldValue('Rating', nextValue)}
                                                        />
                                                    </div>
                                                    {values?.Rating > 0 && (
                                                        <>
                                                            <span style={{ fontSize: '24px' }}>
                                                                {getEmojiAndMessage(values?.Rating).emoji}
                                                            </span>
                                                            <p>{getEmojiAndMessage(values?.Rating).message}</p>
                                                        </>
                                                    )}
                                                </div>
                                                <ErrorMessage name="Rating" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-8 mb-3"   ref={(el) => (fieldRefs.current.Comment = el)}>
                                                <label htmlFor="Comment" className="form-label">Comment*</label>
                                                <Field
                                                    style={{ resize: 'none', rows: '60', columns: '15' }}
                                                    as="textarea"
                                                    className="form-control"
                                                    id="Comment"
                                                    name="Comment"
                                                    placeholder="Can you tell us more about your experience?"
                                                    rows="5"
                                                    cols="20"
                                                />
                                                <ErrorMessage name="Comment" component="div" className="text-danger" />
                                            </div>
                                            <div className="col-md-8 mb-3 mt-2" >
                                                <label htmlFor="Upload" className="form-label">Profile Upload</label>
                                                <div className="d-flex">
                                                    <div className="form-check me-5">
                                                        <Field
                                                            type="radio"
                                                            name="Upload"
                                                            value="desktop"
                                                            className="form-check-input"
                                                            id="desktop"
                                                            onClick={(e: any) => setFieldValue('Upload', "desktop")}
                                                        />
                                                        <label className="form-check-label" htmlFor="desktop">
                                                            Upload
                                                        </label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <Field
                                                            type="radio"
                                                            name="Upload"
                                                            value="webcam"
                                                            className="form-check-input"
                                                            id="webcam"
                                                            onClick={(e: any) => setFieldValue('Upload', "webcam")}
                                                        />
                                                        <label className="form-check-label" htmlFor="online">
                                                            Capture
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="Upload" component="div" className="errMsg" />
                                            </div>
                                            {values?.Upload === "webcam" ? (
                                                <CameraComponent setFile={setFile} />
                                            ) : values?.Upload === "desktop" ? (
                                                <div className="col-md-8 mb-3 mt-2">
                                                    <FileUpload label={"Upload profile"} setDirectory={setDirectory} setFile={setFile} />
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* </div> */}
                                        <div className="col-md-12 text-center">
                                            <button type="submit" className="btn btn-primary mt-3" disabled={isSubmitting} onReset={resetForm}>
                                                Submit Review
                                            </button>
                                        </div>

                                    </Form>
                                </div>

                            )
                        }}
                    </Formik>
                </>
            )}
        </>
    );
};

export default ReviewForm;