import { Card, Tab, Tabs, Col, Row, Form as BootstrapForm } from 'react-bootstrap';


const ModelComponent = (props: any) => {
    const { setCurrentOTP, SuccessMsg, timer, OTPErr, handleOTPSubmit, ResendEmail, closeModal, getModal
    } = props;

    // console.log({getModal})

    return (
        
        <div className={`modal ${getModal ? "open" : ""}`} style={{ display: getModal ? "block" : "none" }}>
            <div className="cancelotpheight" style={{ marginTop: '100px', height: '50px', width: '400px' }}>
                <span className="close new" onClick={closeModal} style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}>
                    &times;
                </span>
                <p className="text-success" style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>{SuccessMsg}</p>
                <h4 style={{ color: '#007bff', marginBottom: '20px' }}>OTP</h4>
                {timer > 0 ?
                    <>
                        <BootstrapForm.Group controlId="password" style={{ textAlign: 'left' }}>
                            <BootstrapForm.Label style={{ fontWeight: 'bold' }}>Enter OTP</BootstrapForm.Label>
                            <p>Timer: {timer} seconds</p>
                            <BootstrapForm.Control type="text" name="otp" placeholder="Enter OTP" onChange={(e: any) => setCurrentOTP(e?.target?.value)} style={{ padding: '14px 20px' }} />
                            <p className='errMsg'>{OTPErr}</p>
                        </BootstrapForm.Group>
                        <button type="submit" className="btn btn-primary" style={{ marginRight: '30px', backgroundColor: '#007bff', borderColor: '#007bff' }} onClick={handleOTPSubmit}>
                            Check
                        </button>
                    </>
                    :
                    <p>The OTP has expired. Please click on  Regenerate.
                        <br />
                        <br />
                        <button className='btn btn-primary btn-sm text-center text-font' onClick={ResendEmail}
                        // onClick={(e: any) => { ResendEmail(); 
                        // setTimer(60) }}
                        >Regenerate</button>
                    </p>
                }
            </div>
        </div>
    )
}
export default ModelComponent;