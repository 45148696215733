import * as Yup from 'yup';

export const UpdateValidationSchema = Yup.object().shape({

    Password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
   confirmPassword: Yup.string()
    .oneOf([Yup.ref('Password')], 'Passwords must match')
    .required('Confirm Password is required')
})