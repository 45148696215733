import React, { useEffect, useState } from 'react';
import { createServiceCategory } from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { listServiceCategories } from '../../../graphql/queries';
import Multiselect from 'multiselect-react-dropdown';
import Homeservices from '../../Services/homeServices';
import './style.css';

interface service {
  label: string;
  value: string;
}

function ServiceComponent(props: any) {
  const [getService, setService] = useState<string | undefined>();
  const [serviceData, setServiceData] = useState<service[]>([]);
  const [ServiceRes, setServiceRes] = useState(true);

  const [selectedCats, setSelectedCats] = useState<string[]>([]);

  let transformedOptions: any, getObjects: any;

  useEffect(() => {
    async function fetchService() {
      try {
        // const serv = await Homeservices().serviceCategoryList();
        // const Allservice = await API.graphql(graphqlOperation(listServiceCategories)) as any;
        const Allservice = await Homeservices().serviceCategoryList();
        const speciality = Allservice?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        }));
        setServiceData(speciality);
        setServiceRes(true);
      } catch (err) {
        console.log('error fetching speciality', err);
      }
    }
    fetchService();
  }, [ServiceRes]);


  transformedOptions = serviceData;
  const selectedCategories = selectedCats?.length > 0 ? selectedCats : props?.editValue

  console.log('selectedCategories', selectedCategories);

  getObjects = selectedCategories && typeof selectedCategories[0] === 'string' ? transformedOptions?.filter((option: any) => selectedCategories[0]?.split(',').some((value: string) => option?.value === value)) : [];


  const InsertSpeciality = async () => {
    if (getService) {
      try {
        const createdBlog = await API.graphql(
          graphqlOperation(createServiceCategory, { input: { name: getService } })
        ) as any;

        const newGroup = {
          label: createdBlog?.data?.createServiceCategory?.name,
          value: createdBlog?.data?.createServiceCategory?.id,
        };

        const currentCategories = props?.InputDetails?.category
          ? Array.isArray(props?.InputDetails?.category)
            ? props?.InputDetails?.category
            : props?.InputDetails?.category.split(',')
          : [];

        const updatedCategory: any = [...currentCategories, newGroup.value].join(',');
        // setSelectedCats(updatedCategory);

        props.setInputDetails((prevState: any) => ({
          ...prevState,
          category: updatedCategory,
        }));

        props.setFieldValue('category', updatedCategory);
        setSelectedCats([updatedCategory])

        setServiceRes(false);
        setService(undefined);
      } catch (err) {
        console.log('error creating blog', err);
      }
    }
  };

  const handleSearch = (e: string) => {
    const specialityExists = transformedOptions.some((option: any) => option.label === e);
    setService(specialityExists ? undefined : e);
  };

  const handleSelect = (selectedList: any, selectedItem: any) => {
    const currentCategories = props?.InputDetails?.category
      ? Array.isArray(props?.InputDetails?.category)
        ? props?.InputDetails?.category
        : props?.InputDetails?.category.split(',')
      : [];

    console.log('selectedOne', { 'currentCategories': currentCategories, 'selectedItem': selectedItem?.value })

    const updatedCategory = [...currentCategories, selectedItem?.value].join(',');

    console.log('updatedCategory', updatedCategory)

    props?.setInputDetails((prevState: any) => ({
      ...prevState,
      category: [updatedCategory],
    }));

    setSelectedCats([updatedCategory])

    props.setFieldValue('category', updatedCategory);
  };

  const handleRemove = (selectedList: any, removedItem: any) => {
    const currentCategories = props?.InputDetails?.category ? props?.InputDetails?.category : [];
    // const catArr: any = currentCategories[0]?.split(',')
    const catArr: string[] = Array.isArray(currentCategories) && currentCategories[0] 
    ? (currentCategories[0].includes(',') ? currentCategories[0].split(',') : [currentCategories[0]])
    : [];
   const updatedCategory = catArr?.filter((id: string) => id !== removedItem?.value).join(',');
   const finalCategory = updatedCategory ? [updatedCategory] : [];
    props.setInputDetails((prevState: any) => ({
      ...prevState,
      category: finalCategory,
    }));
    setSelectedCats(finalCategory)
    props.setFieldValue('category', updatedCategory);
  };

  return (
    <div className='form-group'>
      <label>Select Category*:</label>
      <div className='row col-md-12'>
        <div className='col-md-10' style={{ paddingRight: '0px' }}>
          <Multiselect
            className="custom-multiselect-container w-100"
            style={{ borderRadius: '0px' }}
            isObject={true}
            onRemove={handleRemove}
            onSearch={handleSearch}
            onSelect={handleSelect}
            options={transformedOptions}
            displayValue='label'
            // showCheckbox
            selectedValues={getObjects}
          />
        </div>
        <div className='col-md-2'>
          {getService && (
            <button type='button' className='btn btn-dark btn-md' onClick={InsertSpeciality}>
              Add
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServiceComponent;