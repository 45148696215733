import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import getspeciality from '../../Services/specialities';
// import getCareer from '../../../services/Careers';
import { Helmet } from 'react-helmet';
import Loader from '../shared/Loader';
import LazyLoad from 'react-lazyload';


interface compProps {
  title: string;
  description: string;
}

const SpecialityDetails: React.FC<compProps> = ({ title, description }) => {
  const navigate = useNavigate();
  // const location = useLocation();
  const { slug } = useParams();

  const [singleSpeciality, setSingleSpeciality] = useState<any>();
  const [specialityItems, setSpecialityItems] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const listCareers = async () => {
    try {
      const response = await getspeciality().ActivespecialityList();
      // console.log("filteredCareer", filteredCareer);
      const categoryname = await getspeciality().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.category.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category?.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });
      setSpecialityItems(updatedResponse);
      setCategories(await getspeciality().CategoryList());
    } catch (error) {
      console.error('Error fetching Careers:', error);
    }
  };

  async function ProvInfo() {
    setLoading(true);
    // const SingleCareer = await getspeciality().getSinglespeciality(location?.state?.id);
    const Singlespeciality: any = (await getspeciality().specialityList()).find((speciality: any) => speciality?.Slug === slug);
    setSingleSpeciality(Singlespeciality);
    setLoading(false);
  }

  useEffect(() => {
    ProvInfo();
    listCareers();
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const countByCategory = specialityItems?.reduce((acc: any, career: any) => {
    const categoryIds = career?.category[0] || [];
    const splitString = Array.isArray(categoryIds) ? categoryIds : categoryIds.split(',');
    splitString.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });
    return acc;
  }, {});

  const bgImageStyle = {
    backgroundImage: `url(${process.env.REACT_APP_HOST}images/bg_1.jpg)`
  };

  const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/blogsingle.jpg`

  return (
    <div className="page-wrapper">  
    {loading ? (
        <Loader />
      ) : (
      <><Helmet>
            {/* <title>{title}</title> */}
            <title>{` ${singleSpeciality?.title || ''}`}</title>
            <meta name="description" content={description} />
          </Helmet>
          <section className="home-slider owl-carousel">
            <LazyLoad>
              <div
                className="slider-item bread-item"
                // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
                style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/speciality.jpg` }}
              >
                <div className="overlay"></div>
                <div className="container" data-scrollax-parent="true">
                  <div className="row banner-text align-items-center">
                    <div className="col-md-12 col-sm-12 ftco-animate">


                      <h1 className="banner-ttl">{singleSpeciality?.title}</h1>
                      {/* <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div> */}
                      <div className="captionName" style={{ textAlign: 'center' }}>Your trusted partner for comprehensive healthcare. </div>
                    </div>
                  </div>
                </div>
              </div>
              </LazyLoad>
            </section><div>
             
            <ul className="breadCrumb">
              <li>
                <a role="button" className="aColor" onClick={() => navigate('/')}>Home /</a>
              </li>
              <li>
                <a role="button" className="aColor" onClick={() => navigate('/Specialities')}>Specialities /</a>
              </li>
              <li className="nocursor">{singleSpeciality?.title}</li>
            </ul>
            </div><section className="blog-section section style-four style-five">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-sm-12 col-xs-12">
                    <div className="left-side">
                      <div className="item-holder">
                        <div className="image-box">
                          <figure>
                            {/* <a href="Singleblog"> */}
                            <LazyLoad>
                            <img className='mediaqueriimagesize'
                              src={singleSpeciality?.specialityImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleSpeciality?.specialityImage[0]}` : bgImageStylesingle}
                              alt={singleSpeciality?.title}
                              width="57%"
                              style={{ marginBottom: '9px' }} />
                              </LazyLoad>
                            {/* </a> */}
                          </figure>
                        </div>
                        <div className="content-text">
                          {/* <a href="Singleblog"> */}
                          <p><strong>{singleSpeciality?.title}</strong></p>
                          {/* <p>{singleSpeciality?.createdAt}</p> */}
                          {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
      {removeHtmlTags(singleSpeciality?.description)}
    </p> */}
                          <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleSpeciality?.description }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="right-side">
                      <div className="categorise-menu">
                        <div className="text-title">
                          <h6>Categories</h6>
                        </div>
                        <div className="categories-container">
                          <ul className="categorise-list">
                            <li style={{ cursor: 'pointer' }} onClick={() => navigate('/Specialities')}>All</li>
                            {categories?.map((item: any) => {
                              const blogCount = countByCategory[item?.id] || 0;

                              //   console.log("blogCount",countByCategory[item?.id] )
                              return (
                                <li  className="capitalTxt" key={item?.id} onClick={() => { navigate(`/Specialities`, { state: { category: item?.id } }); } } style={{ cursor: 'pointer' }}>
                                  {item?.name.toLowerCase()}  <span>({blogCount})</span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section></>
      )}
      {/* <div className="scroll-to-top scroll-to-target" data-target=".header-top">
        <span className="icon fa fa-angle-up"></span>
      </div> */}
    </div>
  );
};

export default SpecialityDetails;


// import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation, useParams } from "react-router-dom";
// import getspeciality from '../../Services/specialities';
// // import getCareer from '../../../services/Careers';
// import { Helmet } from 'react-helmet';
// import Loader from '../shared/Loader';


// interface compProps {
//   title: string;
//   description: string;
// }

// const SpecialityDetails: React.FC<compProps> = ({ title, description }) => {
//   const navigate = useNavigate();
//   // const location = useLocation();
//   const { slug } = useParams();

//   const [singleSpeciality, setSingleSpeciality] = useState<any>();
//   const [specialityItems, setSpecialityItems] = useState<any[]>([]);
//   const [categories, setCategories] = useState<any[]>([]);
//   const [loading, setLoading] = useState(true);

//   const listCareers = async () => {
//     try {
//       const response = await getspeciality().specialityList();
//       // console.log("filteredCareer", filteredCareer);
//       const categoryname = await getspeciality().getcategory();
//       const updatedResponse = response?.map((item: any) => {
//         const categoryNames = item?.category.map((cateId: any) => {
//           const category = categoryname.find((cate: any) => cate.id === cateId);
//           return category ? category?.name : cateId;
//         });
//         return {
//           ...item,
//           catergoryName: categoryNames
//         };
//       });
//       setSpecialityItems(updatedResponse);
//       setCategories(await getspeciality().CategoryList());
//     } catch (error) {
//       console.error('Error fetching Careers:', error);
//     }
//   };

//   async function ProvInfo() {
//     setLoading(true);
//     // const SingleCareer = await getspeciality().getSinglespeciality(location?.state?.id);
//     const Singlespeciality: any = (await getspeciality().specialityList()).find((speciality: any) => speciality?.Slug === slug);
//     setSingleSpeciality(Singlespeciality);
//     setLoading(false);
//   }

//   useEffect(() => {
//     ProvInfo();
//     listCareers();
//   }, [slug]);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const countByCategory = specialityItems?.reduce((acc: any, career: any) => {
//     const categoryIds = career?.category || [];
//     const splitString = Array.isArray(categoryIds) ? categoryIds : categoryIds.split(',');
//     splitString.forEach((catId: any) => {
//       if (!acc[catId]) {
//         acc[catId] = 0;
//       }
//       acc[catId]++;
//     });
//     return acc;
//   }, {});

//   const bgImageStyle = {
//     backgroundImage: `url(${process.env.REACT_APP_HOST}images/bg_1.jpg)`
//   };

//   const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/blogsingle.jpg`

//   return (
//     <div className="page-wrapper">  
//     {loading ? (
//         <Loader />
//       ) : (
//       <><Helmet>
//             {/* <title>{title}</title> */}
//             <title>{` ${singleSpeciality?.title || ''}`}</title>
//             <meta name="description" content={description} />
//           </Helmet>
//           <section className="home-slider owl-carousel">
//               <div
//                 className="slider-item bread-item"
//                 // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
//                 style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/speciality.jpg` }}
//               >
//                 <div className="overlay"></div>
//                 <div className="container" data-scrollax-parent="true">
//                   <div className="row banner-text align-items-center">
//                     <div className="col-md-12 col-sm-12 ftco-animate">


//                       <h1 className="banner-ttl">{singleSpeciality?.title}</h1>
//                       {/* <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div> */}
//                       <div className="captionName" style={{ textAlign: 'center' }}>Your trusted partner for comprehensive healthcare. </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </section><div>
             
//             <ul className="breadCrumb">
//               <li>
//                 <a role="button" className="aColor" onClick={() => navigate('/')}>Home /</a>
//               </li>
//               <li>
//                 <a role="button" className="aColor" onClick={() => navigate('/Specialities')}>Specialities /</a>
//               </li>
//               <li className="nocursor">{singleSpeciality?.title}</li>
//             </ul>
//             </div><section className="blog-section section style-four style-five">
//               <div className="container" style={{ marginTop: '90px' }}>
//                 <div className="row">
//                   <div className="col-md-9 col-sm-12 col-xs-12">
//                     <div className="left-side">
//                       <div className="item-holder">
//                         <div className="image-box">
//                           <figure>
//                             {/* <a href="Singleblog"> */}
//                             <img className='mediaqueriimagesize'
//                               src={singleSpeciality?.specialityImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleSpeciality?.specialityImage[0]}` : bgImageStylesingle}
//                               alt={singleSpeciality?.title}
//                               width="57%"
//                               style={{ marginBottom: '9px' }} />
//                             {/* </a> */}
//                           </figure>
//                         </div>
//                         <div className="content-text">
//                           {/* <a href="Singleblog"> */}
//                           <p><strong>{singleSpeciality?.title}</strong></p>
//                           {/* <p>{singleSpeciality?.createdAt}</p> */}
//                           {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
//       {removeHtmlTags(singleSpeciality?.description)}
//     </p> */}
//                           <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleSpeciality?.description }} />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-3 col-sm-12 col-xs-12">
//                     <div className="right-side">
//                       <div className="categorise-menu">
//                         <div className="text-title">
//                           <h6>Categories</h6>
//                         </div>
//                         <div className="categories-container">
//                           <ul className="categorise-list">
//                             <li style={{ cursor: 'pointer' }} onClick={() => navigate('/Specialities')}>All</li>
//                             {categories?.map((item: any) => {
//                               const blogCount = countByCategory[item?.id] || 0;

//                               //   console.log("blogCount",countByCategory[item?.id] )
//                               return (
//                                 <li  className="capitalTxt" key={item?.id} onClick={() => { navigate(`/Specialities`, { state: { category: item?.id } }); } } style={{ cursor: 'pointer' }}>
//                                   {item?.name.toLowerCase()}  <span>({blogCount})</span>
//                                 </li>
//                               );
//                             })}
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </section></>
//       )}
//       {/* <div className="scroll-to-top scroll-to-target" data-target=".header-top">
//         <span className="icon fa fa-angle-up"></span>
//       </div> */}
//     </div>
//   );
// };

// export default SpecialityDetails;
