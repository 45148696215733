import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import userService from '../../Services/user/user.service';
import { SHA256 } from 'crypto-js';
import { registerValidationSchema } from './registerValidationSchema';
import { useIdleTimeout } from '../shared/SessionExpire';
import Dentist from './../Gallary/images/dentist.jpg';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from '../shared/fileupload';
import Users from '../../utills/users';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatFile } from '../../utills/Fileformat';
import { Helmet } from 'react-helmet';
import PaymentServices from '../../Services/PatientAppointment services';
import Loader from '../shared/Loader';
import { Alert } from 'react-bootstrap';


interface RegisterDetails {
    id: string;
    FirstName: string;
    LastName: string;
    Gender: string;
    Specialization: string;
    Age: string;
    Role: string;
    PhoneNumber: string;
    EmailId: string;
    Password: string;
    countryCode: string;
    confirmPassword: string;
    [key: string]: string | number | undefined;
    userProfile: string;
    Description: string;
    qualification: string;
    MaritalStatus: string;
    Address: string;
    City: string;
    pinCode: string
    userType: string
    resetPWD: string

}

const initialState: RegisterDetails = {
    id: '',
    FirstName: '',
    LastName: '',
    Gender: '',
    Age: '',
    Specialization: 'N/A',
    EmailId: '',
    PhoneNumber: '',
    Password: '',
    Role: 'Patient',
    confirmPassword: '',
    countryCode: '',
    userProfile: '',
    Description: 'NoDescription',
    Slug: 'N/A',
    qualification: 'N/A',
    MaritalStatus: '',
    Address: '',
    City: '',
    pinCode: '',
    userType: '',
    resetPWD: ''
};

interface compProps {
    title: string;
    description: string;
    keywords: string;
}


const Register: React.FC<compProps> = ({ title, description, keywords }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [InputDetails, setInputDetails] = useState<RegisterDetails>(initialState);
    const [err, setErr] = useState('');
    const isIdle = useIdleTimeout(1000 * 900);
    const isActive = useIdleTimeout(1000 * 900);
    const [ErrMsgMatch, setErrMsgMatch] = useState('');
    const [errMsg, setErrMsg] = useState(initialState);
    const [ErrMsgMatchpwd, setErrMsgMatchpwd] = useState('');
    const [ErrMsgPwd, setErrMsgpwd] = useState('');
    const [errMsgEmail, setErrMsgEmail] = useState('');
    const [token, setToken] = useState<any>();
    const [successMessage, setSuccessMessage] = useState('');
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const [Updatepatient, setUpdatepatient] = useState(false);
    const [patientDetails, setpatientDetails] = useState<any>([]);
    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [errors, setErrors] = useState({});
    const [currentUserName, setCurrentUserName] = useState('');
    const [existingData, setExistingData] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [alertMsg, setAlertMsg] = useState<any>('');

    const fieldRefs: any = useRef({
        FirstName: null as HTMLDivElement | null,
        LastName: null as HTMLDivElement | null,
        Age: null as HTMLDivElement | null,
        Gender: null as HTMLDivElement | null,
        EmailId: null as HTMLDivElement | null,
        Specialization: null as HTMLDivElement | null,
        PhoneNumber: null as HTMLDivElement | null,
        Password: null as HTMLDivElement | null,
        confirmPassword: null as HTMLDivElement | null,
        countryCode: null as HTMLDivElement | null,
        userProfile: null as HTMLDivElement | null,
        qualification: null as HTMLDivElement | null,
        MaritalStatus: null as HTMLDivElement | null,
        Address: null as HTMLDivElement | null,
        City: null as HTMLDivElement | null,
        pinCode: null as HTMLDivElement | null,
    });





    const hashPassword = (password: string) => {
        try {
            // Use SHA256 function from crypto-js
            const hashedPassword = SHA256(password).toString();
            return hashedPassword;
        } catch (error) {
            console.error('Error hashing password:', error);
            return ''; // or handle the error in an appropriate way
        }
    };

    useEffect(() => {
        getExistingUser();
    }, [location?.state]);

    const getExistingUser = async () => {
        const getPatientDetails = await userService().ListpatientById(location?.state?.patientId);
        if (getPatientDetails?.length > 0) {
            setInputDetails(getPatientDetails[0]);
            setUpdatepatient(true);
        }
        setpatientDetails(getPatientDetails[0]);
    };

    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.userProfile, setInputDetails, 'userProfile', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };

    const handleUpdate = async (values: RegisterDetails, { setSubmitting ,resetForm}: any) => {
        console.log("existingData", existingData);
        console.log("InputDetails", InputDetails);
        if (existingData) {
            values.id = existingData?.id;
            values.userType = existingData?.userType
            values.resetPWD =  !logUserObject ? 'No' : existingData?.resetPWD
        }
        console.log("values", values);
        window.scrollTo(0, 0);
        const hashed = hashPassword(values?.Password);
        console.log("hashed", hashed);
        try {
            setLoading(true);
            const userEmail = await userService().validateEmail(values?.EmailId.toLowerCase());
            const email = userEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.id
            const userPhoneNumber = await userService().validatePhonenumber(values.PhoneNumber);
            const phn = userPhoneNumber?.existingPhoneUser?.data?.listUsers?.items[0]?.id
            // console.log('detailvalues', userPhoneNumber?.existingPhoneUser?.data?.listUsers?.items[0]?.id)

            if (userEmail?.status === 1 && userPhoneNumber?.status === 1 && email != values?.id && phn != values?.id) {
                setErrMsgMatch('Email address and phone number are already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userEmail?.status === 1 && email != values?.id) {
                setErrMsgMatch('Email address is already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userPhoneNumber?.status === 1 && phn != values?.id) {
                setErrMsgMatch('Phone number is already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else {
                // setTimeout(()=>setErrMsgMatch(''),3000);
                setErrMsgMatch('')
            }
            // let newFilename: any;
            // if (file?.name) {

            //     const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            //     newFilename = replaceSpecialChars(filename);
            const newFilename = file?.name ? await FormatFile(file) : '';
            file?.name && await updDelFile(values?.userProfile, "images");
            file?.name && await SendFile(file, newFilename, directory);

            const formatEmail = values?.EmailId.toLowerCase();
            // }
            const GetInsertDataUpdate = {
                id: values?.id,
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender,
                Age: values?.Age,
                Specialization: values?.Specialization,
                EmailId: formatEmail,
                PhoneNumber: values?.PhoneNumber,
                // Password: values?.Password,
                Password: (InputDetails?.Password === "NA" || InputDetails?.Password === "" )? hashed : InputDetails?.Password,
                Status: "1",
                Role: "Patient",
                countryCode: values?.countryCode,
                userProfile: file?.name ? newFilename : values?.userProfile, 
                Description: values?.Description,
                Slug: 'N/A',
                qualification: 'N/A',
                MaritalStatus: values?.MaritalStatus,
                Address: values?.Address,
                City: values?.City,
                pinCode: values?.pinCode,
                // resetPWD: values?.userType === "E" ? "Yes" : "No",
                resetPWD: !logUserObject ? 'No' : values?.resetPWD,
                // userType: "R"
                userType: values?.userType === "E" || values?.userType === "G" ? "R" : values?.userType
            };
            const userResponse = await userService().updateDoctorByData(GetInsertDataUpdate)
            const updateResponse = userResponse?.data?.updateUser;
            try {
                const updateAppointment = await PaymentServices().updateuserType(updateResponse?.id, updateResponse?.PhoneNumber, updateResponse?.userType);
            } catch (err) {
                console.log("err updating userType", err);
                setLoading(false);
            }
            if (updateResponse?.EmailId && !location?.state?.patientId) {
                try {
                    const SMSresponse = await Users().generateEmailSMS(
                        updateResponse?.EmailId,
                        `<html><body><div>Welcome to Grurram Dental Hospitals!<br /><br />Thank you for registering with us.<br /><a href=${process.env.REACT_APP_HOST}login>Click here</a> to log your account using your Email: ${updateResponse?.EmailId} or Phone Number: ${updateResponse?.PhoneNumber}, and pwd: ${values?.newPassword}<br />Best Regards,<br />Team</div></body></html>`,
                        'Grurram Dental Hospitals',
                        'You are registered successfully'
                    );
                } catch (error) {
                    console.error("Error sending Email:", error);
                    setLoading(false);
                }
            }
            if (!location?.state?.patientId) {
                try {
                    const MobileSMSResponse = await Users().generateMobileMsgBulk(updateResponse?.countryCode + updateResponse?.PhoneNumber, `Thank you for registering with us. Log in using your Email: ${updateResponse?.EmailId} or Phone Number: ${updateResponse?.PhoneNumber}, and pwd: ${values?.newPassword} - CLUSTERIT.`, "success"
                    )
                } catch (error) {
                    console.error("Error sending SMS:", error);
                    setLoading(false);
                }
            }
            if (updateResponse && updateResponse?.id) {
                
                setLoading(false);
                
                setAlertMsg("Patient Updated successfully!");
                resetForm();
                // setTimeout(() => {
                //     if(!logUserObject){
                //         navigate('/login')
                //     }else{
                //         navigate('/ListRegisteredPatients')
                //     }
                // }, 200);
              
              
             
                // navigate("/ListDoctor");
                setSubmitting(false);
            }
            // navigate("/ListRegisteredPatients")
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
        setSubmitting(false);
    }


    const handleSubmit = async (values: RegisterDetails, { setSubmitting, resetForm }: any) => {
        window.scrollTo(0, 0);
        // if (existingData?.userType === "E") {
        //     values.id = existingData?.id;
        //     values.newPassword = values?.Password
        //     values.userType = existingData?.userType
        //     values.Password = hashPassword(values?.Password);
        //     await handleUpdate(values, setSubmitting);
        // } else {
        try {
            setLoading(true);
            // Check if passwords match
            if (values?.Password !== values?.confirmPassword) {
                setErrMsgMatchpwd('Passwords do not match');
                setSubmitting(false);
                return;
            }
            // Validate email and phone number
            const userEmail = await userService().validateEmail(values?.EmailId.toLowerCase());
            const userPhoneNumber = await userService().validatePhonenumber(values?.PhoneNumber);

            // Check for existing user based on email and phone number
            if (userEmail?.status === 1 && userPhoneNumber?.status === 1) {
                setErrMsgMatch('Email address and phone number are already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userEmail?.status === 1) {
                setErrMsgMatch('Email address is already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else if (userPhoneNumber?.status === 1) {
                setErrMsgMatch('Phone number is already in use');
                setLoading(false);
                setSubmitting(false);
                return;
            } else {
                // setTimeout(()=>setErrMsgMatch(''),3000);
                setErrMsgMatch('')
            }
            const PassWord = values?.Password;
            // let newFilename: any;
            // Upload the profile image
            // const fileName = Date.now() + "_" + file?.name;
            // if (file?.name) {
            //     const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            //     newFilename = replaceSpecialChars(filename);
            const newFilename = file?.name ? await FormatFile(file) : '';
            file?.name && await SendFile(file, newFilename, directory);
            // }
            const formatEmail = values?.EmailId.toLowerCase();
            // Prepare data for creating a new user
            const GetInsertDataUpdate = {
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender,
                Age: values.Age,
                Specialization: 'N/A',
                EmailId: formatEmail,
                PhoneNumber: values?.PhoneNumber,
                Password: hashPassword(values?.Password),
                Status: '1',
                Role: 'Patient',
                countryCode: "+91",
                userProfile: newFilename,
                Description: 'NoDescription',
                Slug: 'N/A',
                qualification: 'N/A',
                MaritalStatus: values?.MaritalStatus,
                Address: values?.Address,
                pinCode: values?.pinCode,
                City: values?.City,
                userType: "R",
                resetPWD: logUserObject ? "Yes" : "No"
            };

            // Create a new user
            const userResponse = await userService().getcreateUser(GetInsertDataUpdate);
            const userRegResponse = userResponse?.data?.createUser;

            // // Perform auto-login after successful registration
            // if (userResponse) {
            //     if (logUserObject?.Role === "MasterAdmin") {
            //         navigate("/ListRegisteredPatients")
            //     }
            // }

            if (userResponse?.status === 200) {
                // Send confirmation emails and SMS
                try {
                    const SMSresponse = await Users().generateEmailSMS(
                        userRegResponse?.EmailId,
                        `<html><body><div>Welcome to Grurram Dental Hospitals!<br /><br />Thank you for registering with us.<br /><a href=${process.env.REACT_APP_HOST}login>Click here</a> to log your account using your Email: ${userRegResponse?.EmailId} or Phone Number: ${userRegResponse?.PhoneNumber}, and pwd: ${PassWord}<br />Best Regards,<br />Team</div></body></html>`,
                        'Grurram Dental Hospitals',
                        'You are registered successfully'
                    );
                } catch (error) {
                    console.error("Error sending SMS:", error);
                    setLoading(false);
                }

                try {
                    const MobileSMSResponse = await Users().generateMobileMsgBulk(userRegResponse?.countryCode + userRegResponse?.PhoneNumber, `Thank you for registering with us. Log in using your Email: ${userRegResponse?.EmailId} or Phone Number: ${userRegResponse?.PhoneNumber}, and pwd: ${PassWord} - CLUSTERIT.`, "success"
                    )
                } catch (error) {
                    console.error("Error sending SnS:", error);
                    setLoading(false);
                }

                if (userRegResponse) {
                    setLoading(false);
                    resetForm();
                    setLoading(false);
                    // if (userResponse) {
                    if (logUserObject?.Role === "MasterAdmin") {
                        setAlertMsg("User Account Created successfully!");
                        // navigate("/ListRegisteredPatients")
                    }
                    // }
                }


                if (!logUser) {

                    const loginResponse: any = await Users().handleLogin(
                        { userName: values?.EmailId, password: values?.Password },
                        setErrors, setErr, location?.state?.url, navigate
                    );

                    // console.log("loginResponse", loginResponse);
                    if (loginResponse?.data?.accessToken && !logUserObject) {
                        const { accessToken, details, userRole, PhoneNumber, userName } = loginResponse?.data;
                        const userData = { accessToken, userId: details, Role: userRole, PhoneNumber, userName };
                        localStorage.setItem('userData', JSON.stringify(userData));
                        navigate('/DashBoard');
                    }

                    if (loginResponse?.status === 200) {
                        // Navigate based on user role after successful login
                        if (logUserObject?.Role === "MasterAdmin") {
                            navigate('/ListRegisteredPatients', {
                                state: {
                                    userEmail: userRegResponse?.EmailId,
                                    userPwd: GetInsertDataUpdate?.Password,
                                    userId: userRegResponse?.id
                                }
                            });
                        } else {
                            navigate('/Dashboard'); // Redirect to the user's dashboard or homepage
                        }

                        // Send confirmation emails and SMS

                    }
                }


            }
        } catch (error) {
            console.error('Error during form submission:', error);
        } finally {
            setSubmitting(false);
        }
        // }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleEmailChange = async (event: any, setFieldValue: Function, values: any) => {
        console.log("values", values);
        const email = event;
        if (existingData) {
        } else if (email) { // Only fetch if email is not empty
            const getData = await userService().getExistingpatients("Patient", "", email);
            console.log("getData", getData);
            setExistingData(getData[0]);
            if (getData?.length > 0 && getData[0]?.Role === "Patient" && (getData[0]?.userType === "G" || getData[0]?.userType === "E")) {
                setFieldValue("FirstName", getData[0]?.FirstName);
                setFieldValue("LastName", getData[0]?.LastName === "NA" ? "" : getData[0]?.LastName);
                setFieldValue("PhoneNumber", getData[0]?.PhoneNumber);
                setFieldValue("EmailId", getData[0]?.EmailId);
                setFieldValue("Password", getData[0]?.Password === "NA" ? "" : getData[0]?.Password);
                values.id = getData[0]?.id;
            }
            // else {
            //     setFieldValue("FirstName", "");
            //     setFieldValue("LastName", "");
            //     setFieldValue("PhoneNumber", "");
            //     setFieldValue("EmailId", "");
            // }
        } else {
            console.log("Email is empty, skipping fetch.");
        }
    };

    const handlePhoneChange = async (event: any, setFieldValue: Function, values: any) => {
        const phone = event;
        if (existingData) {
        } else if (phone) { // Only fetch if phone number is not empty
            const getData = await userService().getExistingpatients("Patient", phone, "");
            setExistingData(getData[0]);
            if (getData?.length > 0 && getData[0]?.Role === "Patient" && (getData[0]?.userType === "G" || getData[0]?.userType === "E")) {
                setFieldValue("FirstName", getData[0]?.FirstName);
                setFieldValue("LastName", getData[0]?.LastName === "NA" ? "" : getData[0]?.LastName);
                setFieldValue("PhoneNumber", getData[0]?.PhoneNumber);
                setFieldValue("EmailId", getData[0]?.EmailId);
                setFieldValue("Password", getData[0]?.Password === "NA" ? "" : getData[0]?.Password);
                values.id = getData[0]?.id;
            }
            // else {
            //     setFieldValue("FirstName", "");
            //     setFieldValue("LastName", "");
            //     setFieldValue("PhoneNumber", "");
            //     setFieldValue("EmailId", "");
            // }
        } else {
            console.log("Phone number is empty, skipping fetch.");
        }
    };
    // const handleClose: any = () => {
    //     if (location?.state?.patientID && location?.state?.path) {
    //         navigate("/ViewAppointment", { state: { patientID: location?.state?.patientID } });
    //     } else if (logUserObject?.userId) {
    //         navigate('/ListRegisteredPatients');
    //     } else {
    //         navigate("/");
    //     }
    // }

    return (
        <>
            {/* {loading ? (
                <Loader />
            ) : ( */}
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="keywords" content={keywords} />
                    <meta name="description" content={description} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta name="author" content="Gurram Dental Care" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </Helmet>

                <section className="admin-section">
                    <div
                        className="slider-item bread-item"
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <h1>
                                        Patient Registration
                                    </h1>

                                </div>
                            </div>
                        </div>
                    </div>
                </section><div>
                    <ul className="paginationbanner">
                        <li>
                            <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                        </li>
                        <li>Patient Registration</li>
                    </ul>
                </div>
                {
                    alertMsg &&
                    <div className='alertMessage'>
                        <Alert variant="success" onClose={() => { logUserObject ? navigate('/ListRegisteredPatients') : navigate('/login')}} dismissible>
                            {alertMsg}
                        </Alert>
                        {/* <Alert variant="success" onClose={handleClose()} dismissible>
                                {alertMsg}
                            </Alert> */}
                    </div>
                }
                <div className="container-fluid">
                    <div className="row" style={{ padding: '23px' }}>
                        <div className="col-md-5">
                            <Formik
                                initialValues={InputDetails}
                                validationSchema={registerValidationSchema(location?.state?.patientId)}
                                onSubmit={Updatepatient || existingData?.id ? handleUpdate : handleSubmit}
                                enableReinitialize
                            >
                                {({ isSubmitting, values, setFieldValue, errors }) => {
                                    const errorCount = Object.keys(errors).length;
                                    console.log({ errorCount })

                                    if (isSubmitting && errorCount > 0) {

                                        for (const key in errors) {
                                            console.log({ key })
                                            if (fieldRefs.current[key]) {
                                                fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                                break; // Stop at the first error we find
                                            }
                                        }


                                    }
                                    return (
                                        <Form autoComplete="off">
                                            {successMessage && (
                                                <div className="alert alert-success" role="alert" style={{ color: 'green' }}>
                                                    {successMessage}
                                                    {loading ? (
                                                        <Loader />
                                                    ) : (null)}
                                                </div>
                                            )}
                                            <p style={{ color: 'red' }}>{ErrMsgMatch ? ErrMsgMatch : ''}</p>
                                            <h3 className="mb-4"><strong>Patient Registration</strong></h3>

                                            <div className="mb-1" ref={(el) => (fieldRefs.current.FirstName = el)}>
                                                <label htmlFor="FirstName" className="form-label">First Name*</label>
                                                <Field name="FirstName" type="text" className="form-control" value={values?.FirstName}
                                                    onChange={(e: any) => setFieldValue("FirstName", e?.target?.value)} />
                                                <ErrorMessage name="FirstName" component="div" className="errMsg" />
                                            </div>

                                            <div className="mb-1" ref={(el) => (fieldRefs.current.LastName = el)}>
                                                <label htmlFor="LastName" className="form-label">Last Name*</label>
                                                <Field name="LastName" type="text" className="form-control" value={values?.LastName} onChange={(e: any) => setFieldValue("LastName", e?.target?.value)} />
                                                <ErrorMessage name="LastName" component="div" className="errMsg" />
                                            </div>

                                            <div className="mb-1" ref={(el) => (fieldRefs.current.EmailId = el)}>
                                                <label htmlFor="EmailId" className="form-label">Email ID*</label>
                                                <Field name="EmailId" type="email" className="form-control" value={values?.EmailId}
                                                    onChange={(e: any) => {
                                                        setFieldValue("EmailId", e?.target?.value);
                                                        handleEmailChange(e?.target?.value, setFieldValue, values);

                                                        // if (logUserObject?.Role === "MasterAdmin") {
                                                        //     handleEmailChange(e?.target?.value, setFieldValue);
                                                        // }
                                                    }} />
                                                <ErrorMessage name="EmailId" component="div" className="errMsg" />
                                                <p className='errMsg'>{errMsg?.EmailId}</p>
                                            </div>

                                            <div className="mb-1" ref={(el) => (fieldRefs.current.Age = el)}>
                                                <label htmlFor="Age" className="form-label">Age*</label>
                                                <Field name="Age" type="tel" className="form-control" autoComplete='off' value={values?.Age}
                                                    onChange={(e: any) => setFieldValue("Age", e?.target?.value)} />
                                                <ErrorMessage name="Age" component="div" className="errMsg" />
                                                <p className='errMsg'>{errMsg?.Age}</p>
                                            </div>

                                            <div className="mb-1" ref={(el) => (fieldRefs.current.MaritalStatus = el)}>
                                                <label htmlFor="MaritalStatus" className="form-label">Marital Status</label>
                                                <Field as="select" name="MaritalStatus" className="form-control" value={values?.MaritalStatus}
                                                    onChange={(e: any) => setFieldValue("MaritalStatus", e?.target?.value)}>
                                                    <option value="" label="Select marital status" />
                                                    <option value="Single" label="Single" />
                                                    <option value="Married" label="Married" />
                                                    <option value="Divorced" label="Divorced" />
                                                    <option value="Widowed" label="Widowed" />
                                                </Field>
                                                {/* <ErrorMessage name="MaritalStatus" component="div" className="errMsg" /> */}

                                            </div>

                                            <div className="mb-1">
                                                <div className="row">
                                                    <div className="col-md-5" ref={(el) => (fieldRefs.current.countryCode = el)}>
                                                        <label htmlFor="countryCode" className="form-label">Country Code*</label>
                                                        <Field name="countryCode" type="tel" value="+91"
                                                            readOnly className="form-control" />
                                                        <ErrorMessage name="countryCode" component="div" className="errMsg" />
                                                    </div>

                                                    <div className="col-md-7" ref={(el) => (fieldRefs.current.PhoneNumber = el)}>
                                                        <label htmlFor="PhoneNumber" className="form-label">Phone Number*</label>
                                                        <Field name="PhoneNumber" type="tel" min={0} className="form-control" value={values?.PhoneNumber}
                                                            onChange={(e: any) => {
                                                                setFieldValue("PhoneNumber", e?.target?.value);
                                                                // if (logUserObject?.Role === "MasterAdmin") {
                                                                handlePhoneChange(e?.target?.value, setFieldValue, values);
                                                                // }
                                                            }} />
                                                        <ErrorMessage name="PhoneNumber" component="div" className="errMsg" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-1" ref={(el) => (fieldRefs.current.Address = el)}>
                                                <label htmlFor="Address" className="form-label">Address</label>
                                                <Field name="Address" type="Address" className="form-control" autoComplete='off' value={values?.Address}
                                                    onChange={(e: any) => setFieldValue("Address", e?.target?.value)} />
                                            </div>

                                            <div className="mb-1">
                                                <div className="row">
                                                    <div className="col-md-6" ref={(el) => (fieldRefs.current.City = el)}>
                                                        <label htmlFor="City" className="form-label">City</label>
                                                        <Field name="City" type="City" value={values?.City} className="form-control" autoComplete='new-City'
                                                            onChange={(e: any) => setFieldValue("City", e?.target?.value)} />
                                                    </div>
                                                    <div className="col-md-6" ref={(el) => (fieldRefs.current.pinCode = el)}>
                                                        <label htmlFor="pinCode" className="form-label">pinCode</label>
                                                        <Field name="pinCode" type="number" autoComplete='new-pinCode' className="form-control" value={values?.pinCode}
                                                            onChange={(e: any) => setFieldValue("pinCode", e?.target?.value)} />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="mb-1" ref={(el) => (fieldRefs.current.Gender = el)}>
                                                <label className="form-label">Gender*</label>
                                                <div style={{ display: 'flex' }}>
                                                    <div className="form-check" style={{ marginRight: '10px' }}>
                                                        <Field name="Gender" type="radio" value="Male" className="form-check-input" id="genderMale" />
                                                        <label htmlFor="genderMale" className="form-check-label">Male</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <Field name="Gender" type="radio" value="Female" className="form-check-input" id="genderFemale" />
                                                        <label htmlFor="genderFemale" className="form-check-label">Female</label>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="Gender" component="div" className="errMsg" />
                                            </div>

                                            {(InputDetails?.Password === "NA") ? (
                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <div className="mb-3" ref={(el) => (fieldRefs.current.Password = el)}>
                                                            <label htmlFor="Password" className="form-label">Password*</label>
                                                            <Field
                                                                name="Password"
                                                                type="password"
                                                                className="form-control"
                                                            // value={InputDetails?.Password === "NA" ? "" : InputDetails?.Password}
                                                            />
                                                            <ErrorMessage name="Password" component="div" className="errMsg" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3" ref={(el) => (fieldRefs.current.confirmPassword = el)}>
                                                            <label htmlFor="confirmPassword" className="form-label">Confirm Password*</label>
                                                            <Field
                                                                name="confirmPassword"
                                                                type="password"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="confirmPassword" component="div" className="errMsg" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : !Updatepatient ? (
                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <div className="mb-3" ref={(el) => (fieldRefs.current.Password = el)}>
                                                            <label htmlFor="Password" className="form-label">Password*</label>
                                                            <Field
                                                                name="Password"
                                                                type="password"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="Password" component="div" className="errMsg" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3" ref={(el) => (fieldRefs.current.confirmPassword = el)}>
                                                            <label htmlFor="confirmPassword" className="form-label">Confirm Password*</label>
                                                            <Field
                                                                name="confirmPassword"
                                                                type="password"
                                                                className="form-control"
                                                            />
                                                            <ErrorMessage name="confirmPassword" component="div" className="errMsg" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {/* {!Updatepatient && (
                                                <>
                                                    <div className="mb-1">
                                                        <label htmlFor="Password" className="form-label">Password*</label>
                                                        <Field
                                                            name="Password"
                                                            type="password"
                                                            className="form-control"
                                                            value={values?.Password}
                                                            onChange={(e: any) => setFieldValue("Password", e?.target?.value)} />
                                                        <ErrorMessage name="Password" component="div" className="errMsg" />
                                                    </div>

                                                    <div className="mb-1">
                                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password*</label>
                                                        <Field
                                                            name="confirmPassword"
                                                            type="password"
                                                            className="form-control"
                                                            value={values?.confirmPassword}
                                                            onChange={(e: any) => setFieldValue("confirmPassword", e?.target?.value)} />
                                                        <ErrorMessage name="confirmPassword" component="div" className="errMsg" />
                                                    </div>
                                                </>
                                            )} */}
                                            <br />

                                            {/* {InputDetails?.userProfile !== null && Updatepatient ?
                                                    <><img src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.userProfile}`} alt="Profile" style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }} />
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                            <p style={{ margin: 0, lineHeight: '24px' }}><strong>FileName:</strong>{InputDetails?.userProfile}</p>
                                                            <span
                                                                style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                                                onClick={handleRemove}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </span>
                                                        </div>
                                                    </>
                                                    : <strong>No Profile Photo</strong>} */}
                                            <div ref={(el) => (fieldRefs.current.userProfile = el)}>
                                                {InputDetails?.userProfile &&
                                                    <>
                                                        <img src={InputDetails?.userProfile && `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.userProfile}`} alt="Profile" style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }} />
                                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                                            {InputDetails?.userProfile &&

                                                                <>
                                                                    <p style={{ margin: 0, lineHeight: '24px' }}><strong>FileName:</strong>{InputDetails?.userProfile}</p>
                                                                    <span
                                                                        style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                                                        onClick={handleRemove}
                                                                    >
                                                                        <FontAwesomeIcon icon={faTimes} />
                                                                    </span>
                                                                </>}


                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <FileUpload label={Updatepatient ? "Update Profile Image" : "Choose Profile"} setDirectory={setDirectory} setFile={setFile} />

                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                    {Updatepatient || existingData?.id ? "Update" : "Register"}
                                                </button>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-6">
                            <img src={Dentist} alt="signupImage" style={{ width: '100%', height: '92%', objectFit: 'cover' }} />
                        </div>
                    </div>
                </div></>
            {/* )} */}
        </>
    );
}

export default Register;