import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import forgotPasswordServices from '../../Services/forgotPasswordServices';
import { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { SpinningCircles } from 'react-loading-icons';
import { UpdateValidationSchema } from './UpdatevalidationScheme';
import { SHA256 } from 'crypto-js';

function UpdatePassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const [ResendMsg, setResendMsg] = useState('');
    const [Loader, setLoader] = useState(false);
    const [Show, setShow] = useState(true);
    const [userName, setUserName] = useState("");

    const initialValues = {
        Password: '',
        confirmPassword: '',
    };
    
// backup code
    // const handleSubmit = async (values: any, { setSubmitting }: any) => {
    //     // console.log("values", values);
    //     try {
    //         setUserName(values?.userName);
    //         if (values?.Password && values?.confirmPassword) {
    //             const userResponse = await forgotPasswordServices().UpdatePassword(location?.state.phoneNumber, values?.Password, location?.state.restPWD);
    //             // console.log("userResponse", userResponse);

    //             if (userResponse) {
    //                 navigate('/login');
    //             }
    //         } else {
    //             setResendMsg('Enter Password and Confirm Password');
    //         }
    //     } catch (error) {
    //         console.error('Error updating password:', error);
    //     } finally {
    //         setSubmitting(false);
    //     }
    // };


    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        console.log('values',{values})
      
        try {
            
            if (location?.state.restPWD === "Yes") {
                console.log('hello')
                const password = location?.state?.userget?.data?.getUser?.Password;
                // Hash the new password entered by the user
                const hashedNewPassword = SHA256(values?.Password).toString();
                // Compare new password with the existing password
                if (hashedNewPassword === password) {
                    setResendMsg('The current password is same as the old password. Please enter a new password.');
                    return
                } 
                else {
                    setUserName(values?.userName);
                    if (values?.Password && values?.confirmPassword) {
                        const userResponse = await forgotPasswordServices().UpdatePassword(location?.state.phoneNumber, values?.Password, location?.state.restPWD);
                        console.log('userResponse0',userResponse)
                        
                        
                        if (userResponse) {
                            navigate('/login');
                        }
                    } else {
                        setResendMsg('Enter Password and Confirm Password');
                    }
                }
            }else{
                if (values?.Password && values?.confirmPassword) {
                    const userResponse = await forgotPasswordServices().UpdatePassword(location?.state.phoneNumber, values?.Password, location?.state.restPWD);
                    console.log('userResponse0',userResponse)
                    
                    
                    if (userResponse) {
                        navigate('/login');
                    }
                } else {
                    setResendMsg('Enter Password and Confirm Password');
                }
            }
        } catch (error) {
            console.error('Error updating password:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const ResendEmail = async () => {
        setLoader(true);
        try {
            // setUserName(values?.userName);
            const getResponse = await forgotPasswordServices().SendEmail(userName);
            console.log("getResponse", getResponse);
            if (getResponse.status === 200) {
                setResendMsg('Please check your email account for the mail, update your password, and the mail will expire in 60 minutes.');
            }
        } catch (error) {
            console.error('Error resending email:', error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <>
            <section className="home-slider owl-carousel">
                <div
                    className="slider-item bread-item"
                    style={{ backgroundImage: `url('images/bg_1.jpg')` }}
                    data-stellar-background-ratio="0.5"
                >
                    <div className="overlay"></div>
                    <div className="container" data-scrollax-parent="true">
                        <div className="row banner-text align-items-center">
                            <div className="col-md-12 col-sm-12 ftco-animate mb-5 mt-3">
                                <h2 style={{ textAlign: 'center' }}>
                                    Update Password
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="ullist">
                    <li>
                        <a className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</a>
                    </li>
                    <li>Update Password</li>
                </ul>
            </div>

            <div className="container-fluid">
                {Loader && (
                    <Modal size="sm" centered show={Loader}>
                        <Modal.Body>
                            <div className="form-group">
                                <h5>
                                    <SpinningCircles fill="#b3b3b3" style={{ marginLeft: '20%', marginRight: '5%' }} />Loading
                                </h5>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}

                <Row className="mt-5" style={{ marginBottom: '80px' }}>
                    <Col md={4}></Col>

                    <Col md={4} style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        {Show ? (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={UpdateValidationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        {/* <h4 style={{ color: '#007bff', marginBottom: '20px' }}>Update Password</h4> */}
                                        <p style={{ color: 'red' }}>{ResendMsg ? ResendMsg : ''}</p>
                                        <Field
                                            type="password"
                                            name="Password"
                                            placeholder="Enter New Password"
                                            className="form-control"
                                        />
                                        <ErrorMessage name="Password" component="div" className="errMsg" />

                                        <Field style={{ marginTop: '20px' }}
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Enter Confirm Password"
                                            className="form-control"
                                        />
                                        <ErrorMessage name="confirmPassword" component="div" className="errMsg" />
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="btn btn-primary mt-3"
                                            style={{ marginRight: '30px', backgroundColor: '#2cbcbc', borderColor: '#2cbcbc' }}
                                            disabled={isSubmitting}
                                        >
                                            Update
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        ) : (
                            <>
                                {ResendMsg ? (
                                    <h5 className="text-success">{ResendMsg}</h5>
                                ) : (
                                    <h5 className="errMsg text-center">
                                        The password update has expired. Please click the link to resend the email.
                                        <br />
                                        <br />
                                        <button className="btn btn-primary btn-sm text-center" onClick={ResendEmail}>
                                            Click here to resend
                                        </button>
                                    </h5>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default UpdatePassword;