// import React, { useState, useEffect} from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';  // Import useNavigate
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import { Grid, TextField, Button, Alert, Box, Container } from '@mui/material';
// import PaymentServices from '../../Services/PatientAppointment services';
// import { Helmet } from 'react-helmet';
// import pages from "../../utills/pageUtils";

// const validationSchema = Yup.object({
//     appointmentId: Yup.string().required('Appointment ID is required'),
// });

// interface compProps {
//     title: string;
//     description: string;
//   }

// const AppointmentTrack: React.FC<compProps> = ({ title, description }) => {
//     const [errorMessage, setErrorMessage] = useState<string | null>(null);
//     const navigate = useNavigate();  // Initialize useNavigate
//     const [pageInfo, setPageInfo] = useState<any>();
//     const [bannerName, setBannerName] = useState<any>()
//     const [caption, setCaption] = useState<any>()
//     const location = useLocation();

//     useEffect(() => {
//         const fetchData = async () => {
//           const result = await pages(location?.pathname);
//           result?.map((item: any) => {
//             setPageInfo(item?.Banner);
//             setBannerName(item?.Title)
//             setCaption(item?.Description)
//           });
//         };
//         fetchData();
//       }, [location?.pathname]);

//     const handleSearch = async (Appointmentid: any) => {
//         try {
//             const response = await PaymentServices().appointmentListByappointmentID(Appointmentid?.appointmentId);
//             if (response) {
//                 setErrorMessage(null);
//                 navigate('/appointmentHistory', { state: { appointmentDetails: response } });
//             } else {
//                 throw new Error('Appointment not found');
//             }
//         } catch (error) {
//             setErrorMessage('Appointment not found');
//         }
//     };

//     useEffect(() => {
//         window.scrollTo(0, 0);
//       }, []);

//     return (
//         <>
//            <Helmet>
//             <title>{title}</title>
//             <meta name="description" content={description} />
//           </Helmet><section className="home-slider owl-carousel">
//             <div
//               className="slider-item bread-item"
//               style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})` : 'images/bg_1.jpg' }}
//               data-stellar-background-ratio="0.5"
//             >
//               <div className="overlay"></div>
//               <div className="container" data-scrollax-parent="true">
//                 <div className="row banner-text align-items-center">
//                   <div className="col-md-12 col-sm-12 ftco-animate mb-5">
//                     {/* <h2 style={{ textAlign: 'center', marginBottom: '100px' }}>
//     {bannerName}
//     </h2>
//     <span>{caption}</span> */}
//                     <h1 className="banner-ttl">{bannerName}</h1>
//                     <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//           <div>
//             <ul className="ullist">
//               <li>
//                 <a className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</a>
//               </li>
//               <li>{bannerName}</li>
//             </ul>
//           </div>

//             <Container>
//                 <Grid container justifyContent="center">
//                     <Grid item xs={8} md={8}>
//                         <Formik
//                             initialValues={{ appointmentId: '' }}
//                             validationSchema={validationSchema}
//                             onSubmit={handleSearch}
//                         >
//                             {({ isSubmitting }) => (
//                                 <Form autoComplete="off">
//                                     <Box sx={{ mb: 3 }}>
//                                         <Field
//                                             as={TextField}
//                                             fullWidth
//                                             label="Appointment ID"
//                                             name="appointmentId"
//                                             variant="outlined"
//                                             placeholder="Enter Appointment ID"
//                                         />
//                                         <ErrorMessage name="appointmentId" component="div" className="text-danger mt-1" />
//                                     </Box>
//                                     <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
//                                         Search
//                                     </Button>
//                                 </Form>
//                             )}
//                         </Formik>
//                         {errorMessage && <Alert severity="error" sx={{ mt: 3 }}>{errorMessage}</Alert>}
//                     </Grid>
//                 </Grid>
//             </Container>
//         </>
//     );
// };

// export default AppointmentTrack;





import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';  // Import useNavigate
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, Button, Alert, Box, Container } from '@mui/material';
import PaymentServices from '../../Services/PatientAppointment services';
import { Helmet } from 'react-helmet';
import pages from "../../utills/pageUtils";
import userService from '../../Services/user/user.service';
import { Card, Tab, Tabs, Col, Row, Modal, Form as BootstrapForm } from 'react-bootstrap';
import forgotPasswordServices from '../../Services/forgotPasswordServices';
import OTPServices from '../../Services/OTPServices';
import Users from '../../utills/users';
import { SHA256 } from 'crypto-js';
import Loader from "../shared/Loader";
import LazyLoad from 'react-lazyload';


const validationSchema = Yup.object({
  PhoneNumber: Yup.string()
    .required('Phone Number is required')
    .test(
      'phone-validation',
      'Phone number must have exactly 10 digits',
      (value) => {
        const inputValue = value.replace(/\D/g, '');
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(inputValue);
      }
    )
  // PhoneNumber: Yup.string()
  //   .matches(/^\d{10}$/, "PhoneNumber must be exactly 10 digits") // Ensures it's exactly 10 digits
  //   .required('PhoneNumber is required')
});

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

const AppointmentTrack: React.FC<compProps> = ({ title, description, keywords }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();  // Initialize useNavigate
  const [pageInfo, setPageInfo] = useState<any>();
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()
  const location = useLocation();
  const [getModal, setModel] = useState(false);
  const [CurrentOTP, setCurrentOTP] = useState("");
  const [timer, setTimer] = useState<number>(60);
  const [OTPErr, setOtpError] = useState("");
  const [SuccessMsg, setSuccessMsg] = useState("");
  const [appointment, setAppointment] = useState("");
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const fieldRefs: any = useRef({
    PhoneNumber: null as HTMLDivElement | null,

  });
  useEffect(() => {
    async function ProvInfo() {
      if (location?.state?.PhoneNumber) {
        await handleSearch(location?.state?.PhoneNumber)
      }
    }
    ProvInfo()
  }, [location?.state && location?.state?.PhoneNumber])


  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isTimerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerRunning(false);
    }
    // Clean up the interval when the component unmounts or the timer is stopped
    return () => clearInterval(interval);
  }, [isTimerRunning, timer]);


  useEffect(() => {
    const fetchData = async () => {
      const result = await pages(location?.pathname);
      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      });
    };
    fetchData();
  }, [location?.pathname]);

  const handleSearch = async (PhoneNumber: any) => {
    setErrorMessage("");
    setLoading(true);
    setPhoneNumber(PhoneNumber);
    try {
      const response: any = await PaymentServices().appointmentListByPhoneNumber(PhoneNumber?.PhoneNumber);
      if (response && response?.length > 0) {
        setAppointment(response[0]);
        setErrorMessage(null);
        // navigate('/appointmentHistory', { state: { appointmentDetails: [response[0]] } });
        // navigate('/appointmentHistory', { state: { appointmentDetails: [response[0]], path: "/TrackAppointment", PhoneNumber: PhoneNumber } });
        navigate('/ViewAppointment', { state: { appointmentDetails: [response[0]], path: "/TrackAppointment", PhoneNumber: PhoneNumber, patientID: response[0]?.patientID } });

        // const existing: any = await userService().validatePhonenumber(response[0]?.PhoneNumber);
        // const user = existing?.existingPhoneUser?.data?.listUsers?.items[0];
        // const dataSend = {
        //   action: "sendOTP",
        //   phoneNumber: user?.PhoneNumber,
        //   userId: user?.id,
        // }
        // const options = {
        //   method: 'POST',
        //   headers: {
        //     "Content-Type": "application/json",
        //     "Accept": "application/json, text/plain, */*"
        //   },
        //   body: JSON.stringify(dataSend)
        // }
        // const APIresponse: any = await fetch(`${process.env.REACT_APP_FORGOTPASSWORD_API}`, options);
        // const data = await APIresponse.json();
        // if (data?.otp) {
        //   setLoading(false);
        //   setSuccessMsg("OTP sent to your PnoneNumber");
        //   localStorage.setItem("trackOTP", data?.otp);
        //   setModel(true);
        //   setIsTimerRunning(true);
        //   setTimer(60);
        // }
      } else {
        setLoading(false);
        setErrorMessage("No Appointments found");
        // throw new Error('Appointment not found');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Appointment not found');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOTPSubmit = async (resetform: any) => {
    setOtpError("");
    try {
      if (timer === 0) {
        setOtpError("OTP has expired. Please click on resend.");
        // resetform();
        return; // Exit the function early since the OTP is expired
      }
      const newOTP = localStorage.getItem('trackOTP');
      const hashOTP = await SHA256(CurrentOTP).toString();
      if (newOTP === hashOTP) {
        navigate('/appointmentHistory', { state: { appointmentDetails: [appointment] } });
        localStorage.removeItem('trackOTP');
        setIsTimerRunning(false);
        setTimer(60);
      } else {
        setOtpError("Invalid OTP");
        // resetform();
      }
    } catch (error) {
      console.error('Error during OTP validation:', error);
      setOtpError("An error occurred during OTP validation");
    }
  };
  const ResendEmail = async () => {
    setOtpError("");
    handleSearch(phoneNumber);
  };

  const closeModal = () => {
    setModel(false);
    // setModalHeading("");
  };

  return (
    <>
      <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta name="author" content="Gurram Dental Care" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>
      {
        loading ? (
          <Loader />
        ) : (
          <><section className="home-slider owl-carousel">
            <LazyLoad>
            <div
              className="slider-item bread-item"
              style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})` : 'images/bg_1.jpg' }}
              data-stellar-background-ratio="0.5"
            >
              <div className="overlay"></div>
              <div className="container" data-scrollax-parent="true">
                <div className="row banner-text align-items-center">
                  <div className="col-md-12 col-sm-12 ftco-animate trackappointmentMob">

                    <h1 className="banner-ttl">{bannerName}</h1>
                    <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
                  </div>
                </div>
              </div>
            </div>
            </LazyLoad>
          </section><div>
              <ul className="ullist">
                <li>
                  <a className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</a>
                </li>
                <li>{bannerName}</li>
              </ul>
            </div><Container>
              <Grid container justifyContent="center">
                <Grid item xs={8} md={8}>
                  <Formik
                    initialValues={{ PhoneNumber: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSearch}
                  >
                    {({ isSubmitting, errors }) => {

                      const errorCount = Object.keys(errors).length;
                      console.log({ errorCount })

                      if (isSubmitting && errorCount > 0) {

                        for (const key in errors) {
                          console.log({ key })
                          if (fieldRefs.current[key]) {
                            fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                            break; // Stop at the first error we find
                          }
                        }


                      }


                      return (

                        <><Form autoComplete="off">
                          <Box sx={{ mb: 3 }} className="form-field" ref={(el) => (fieldRefs.current.PhoneNumber = el)}>
                            <Field
                              as={TextField}
                              fullWidth
                              label="PhoneNumber"
                              name="PhoneNumber"
                              variant="outlined"
                              placeholder="Enter PhoneNumber" />
                            <ErrorMessage name="PhoneNumber" component="div" className="text-danger mt-1" />
                          </Box>
                          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                            Search
                          </Button>
                        </Form></>)
                    }}
                  </Formik>
                  {errorMessage && <Alert severity="error" sx={{ mt: 3 }}>{errorMessage}</Alert>}
                </Grid>
              </Grid>
            </Container></>
        )}

      {getModal &&
        <Modal size='sm' centered show={getModal}>
          <Modal.Body>
            <div className="form-group">
              <span className="close new" onClick={closeModal} style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}>
                &times;
              </span>
              <p className="text-success" style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>{SuccessMsg}</p>
              <h4 style={{ color: '#007bff', marginBottom: '20px' }}>OTP</h4>
              {timer > 0 ?
                <>
                  <BootstrapForm.Group controlId="password" style={{ textAlign: 'left' }}>
                    <BootstrapForm.Label style={{ fontWeight: 'bold' }}>Enter OTP</BootstrapForm.Label>
                    <p>Timer: {timer} seconds</p>
                    <BootstrapForm.Control type="text" name="otp" placeholder="Enter OTP" onChange={(e: any) => setCurrentOTP(e?.target?.value)} style={{ padding: '14px 20px' }} />
                    <p className='errMsg'>{OTPErr}</p>
                  </BootstrapForm.Group>
                  <button type="submit" className="btn btn-primary" style={{ marginRight: '30px', backgroundColor: '#007bff', borderColor: '#007bff' }} onClick={handleOTPSubmit}>
                    Check
                  </button>
                </>
                :
                <p>The OTP has expired. Please click the link to resend the OTP.
                  <br />
                  <br />
                  <button className='btn btn-primary btn-sm text-center text-font' onClick={ResendEmail}
                  // onClick={(e: any) => { ResendEmail(); 
                  // setTimer(60) }}
                  >Click here to resend</button>
                </p>
              }
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default AppointmentTrack;

