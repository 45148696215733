import React, { useEffect, useState } from 'react';
import Sidebar from './sidebar';
import MainContent from './mainContent';
import { useNavigate } from 'react-router-dom';
import { FaWindows } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import Users from '../../utills/users';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';

interface User {
    userName: string;
    photoURL: string;
    email: string;
    Role: string;
    token: string;
}

export default function Dashboard() {
    const [token, setToken] = React.useState<any>();
    const [user, setUser] = React.useState<User | null>(null);
    const navigate = useNavigate();
    // const [logUserObject, setLogUserObject] = useState<any>(null);

    // const logUser: any = localStorage.getItem('userData');
    // const logUserObject = JSON.parse(logUser);
    // // useAuthRedirect(logUserObject);

    React.useEffect(() => {

        const fetchData = async () => {

            // const userData = localStorage.getItem('userData');
            // const parsedUser = JSON.parse(userData);

        const parsedUser = await Users().userSessionData() as any;
        if (parsedUser) {
            
            console.log({parsedUser})
            setUser(parsedUser);
            setToken(parsedUser.accessToken);
        }
            
        }
        

        fetchData();

        window.scrollTo(0, 0);
    }, []);

    return (

        <>
            <Helmet>
                <title>Dashboard</title>
                <meta name="description" content="This is the Gallery page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Dashboard
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li> Dashboard</li>
                </ul>
            </div>
            <div className="container">
                <div className="row" >
                    {/* <div className="col-md-2" style={{marginTop:'50px'}}>
                <Sidebar />
                </div> */}
                    <div className="col-md-12" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>


                        <MainContent user={user} />

                    </div>
                    {/* <div className="col-md-4"></div> */}
                </div>
            </div>

            <div style={styles.dashboardContainer}>
                {token ? (
                    <>

                    </>
                ) :
                    (
                        // <div style={styles.linkBtn}>hello</div>
                        null
                    )
                }
            </div>
        </>
    );
}

const styles = {
    dashboardContainer: {
        display: 'flex',
        height: 'auto',
    },
    linkBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '24px',
        color: '#333',
    },
};
