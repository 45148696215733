import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { createUser, updateUser } from '../../../graphql/mutations';
import { listUsers } from '../../../graphql/queries';



const BulkServices = () => {

    const createBulkUser = async (userData: any) => {
        try {
            const getResponse: any = await API.graphql(graphqlOperation(createUser, { input: userData }));
            return getResponse
        } catch (error) {
            console.error('Error creating bulkuser:', error);
        }
    };

    const BulkUserUpdate = async (userData: any) => {
        try {
            const getResponse: any = await API.graphql(graphqlOperation(updateUser, { input: userData }));
            return getResponse
        } catch (error) {
            console.error('Error updating bulkuser:', error);
        }
    };


    const checkExistingUsers = async (email: any, phone: any) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;
            do {

                const filterVar = {
                    filter: {
                        or: [
                            { EmailId: { eq: email } },
                            { PhoneNumber: { eq: phone } }
                        ],
                        // userType: { eq: "E" }
                    },
                    limit: limit,
                    nextToken: nextToken
                };
                // console.log("filter", filterVar);
                const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;
                const users = result?.data?.listUsers?.items || [];
                allUsers.push(...users);
                nextToken = result?.data?.listUsers?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }

    return {
        createBulkUser,
        checkExistingUsers,
        BulkUserUpdate
    }

}
export default BulkServices;