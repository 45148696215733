
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './style.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { validationSchema } from './validationSchema';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from '../shared/fileupload';
import { useLocation, useNavigate } from 'react-router-dom';
import SpecialityComponent from './createSpecialityCategory';
// import getCareer from '../../../services/Careers';
import getspeciality from '../../Services/specialities';
import CKEditorComponent from '../../utills/CKEditor';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { FormatFile } from '../../utills/Fileformat';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';

const Createspeciality: React.FC = () => {
    const location = useLocation();

    const initialValues = {
        category: '',
        title: '',
        description: '',
        specialityImage: [],
        Slug: '',

    };

    const navigate = useNavigate();

    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [InputDetails, setInputDetails] = useState(initialValues);
    const [updateSpeciality, setUpdateSpeciality] = useState<boolean>(false);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const [ErrMsgSlug, setErrMsgSlug] = useState("");
    // useAuthRedirect(logUserObject);

    const fieldRefs: any = useRef({
        category: null as HTMLDivElement | null,
        title: null as HTMLDivElement | null,
        Slug: null as HTMLDivElement | null,
        description: null as HTMLDivElement | null
    });



    async function provInfo() {
        const service = await getspeciality().getSinglespeciality(location?.state?.id);

        setInputDetails(service);
        setUpdateSpeciality(true);
    }

    // console.log("file", file);

    useEffect(() => {
        provInfo();
    }, [location?.state]);

    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.specialityImage[0], setInputDetails, 'specialityImage', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };
    const handleSubmit = async (values: any, { resetForm }: any) => {

        try {
            const generateSlug = (name: any) => name.replace(/\s+/g, '_').toLowerCase();
            const slug = generateSlug(values?.Slug)
            let slugError = false;

            if (values?.id) {
                const SlugDetails = await getspeciality().validateSlug(slug);


                if (SlugDetails?.msg) {
                    SlugDetails?.data?.map((item: any) => {
                        if (item?.id !== values?.id) {
                            slugError = true;
                        }
                    });
                    if (slugError) {
                        setErrMsgSlug("Slug is already in use");
                        return;
                    } else {
                        setErrMsgSlug("");
                    }
                }
            } else {
                const SlugDetails = await getspeciality().validateSlug(slug);
                if (SlugDetails?.msg) {
                    setErrMsgSlug("Slug is already in use");
                    return;
                }
            }

            // let newFilename: any;
            // if (file?.name) {
            // fileName = Date.now() + "_" + file?.name;
            // const updatedFileName = file?.name?.replace(/ /g, '_');
            // fileName = Date.now() + "_" + updatedFileName;
            // const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            // newFilename = replaceSpecialChars(filename);
            // }


            const newFilename = await FormatFile(file)
            if (values?.id) {
                file?.name && await updDelFile(values?.specialityImage, "images");
            }
            file?.name && await SendFile(file, newFilename, "images");

            const SpecialityData = {
                id: values?.id,
                title: values?.title,
                category: values?.category,
                description: values?.description,
                specialityImage: file?.name ? newFilename : values?.specialityImage,
                Slug: slug,
                status: 1,
                userID: logUserObject?.userId,
            };
            console.log("Speciality", SpecialityData);

            const CarrerResponse = values?.id
                ? await getspeciality().specialityUpdate(SpecialityData)
                : await getspeciality().Createspeciality(SpecialityData);

            if (CarrerResponse) {
                navigate('/listSpeciality');
            }

        } catch (error) {
            console.error('Error submitting speciality:', error);
            throw new Error('Failed to submit speciality');
        }
    };
    // const handleSubmit = async (values: any, { resetForm }: any) => {
    //     console.log("values", values)
    //     try {
    //         let fileName: any
    //         if (file) {
    //             fileName = Date.now() + "_" + file?.name;
    //             await SendFile(file, fileName, directory);
    //         }

    //         const SlugDetails = await getspeciality().validateSlug(values?.Slug);
    //         if (SlugDetails) {
    //             setErrMsgSlug("Slug is already in use");
    //             // return;
    //         }

    //         const specialityData = {
    //             title: values?.title,
    //             category: values?.category,
    //             description: values?.description,
    //             specialityImage: fileName,
    //             userID: logUserObject?.userId,
    //             Slug: values?.Slug,
    //             status: "1",
    //         };

    //         const serviceResponse = await getspeciality().Createspeciality(specialityData);
    //         console.log(serviceResponse, "serviceResponse")
    //         if (serviceResponse) {
    //             navigate('/listSpeciality');
    //         }
    //     } catch (error) {
    //         console.error('Error submitting Speciality:', error);
    //         throw new Error('Failed to submit Speciality');
    //     }
    // };

    // const handleUpdate = async (values: any, { resetForm }: any) => {
    //     console.log("updatevalues", values?.category?.length)

    //     let SlugDetails: any;


    //     if (values?.Slug !== InputDetails?.Slug) {

    //         SlugDetails = await getspeciality().validateSlug(values?.Slug);

    //     }


    //     try {
    //         let fileName: any;
    //         if (file?.name) {
    //             fileName = Date.now() + "_" + file?.name;
    //             console.log("fileName", fileName);
    //             await updDelFile(values?.specialityImage, "images");
    //             await SendFile(file, fileName, "images");
    //         }

    //         const specialityData = {
    //             id: values?.id,
    //             title: values?.title,
    //             Slug: values?.Slug,
    //             category: values?.category,
    //             description: values?.description,
    //             userID: logUserObject?.userId,
    //             specialityImage: fileName || values?.specialityImage,
    //             status: "1"
    //         };

    //         // const SlugDetails = await getspeciality().validateSlug(values?.Slug);
    //         // if (SlugDetails) {
    //         //     setErrMsgSlug("Slug is already in use");
    //         //     // return;
    //         // }

    //         if (SlugDetails?.msg !== '') {
    //             setErrMsgSlug(SlugDetails?.msg);
    //         } else {

    //             const serviceResponse = await getspeciality().specialityUpdate(specialityData);
    //             serviceResponse && navigate('/listSpeciality');

    //             // if (serviceResponse) {
    //             //     navigate('/listSpeciality');
    //             // }
    //         }
    //     } catch (error) {
    //         console.error('Error submitting Speciality:', error);
    //         throw new Error('Failed to submit Speciality');
    //     }
    // };

    return (
        <>
            <Helmet>
                <title>Specialities</title>
                <meta name="description" content="This is the Specialities page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1 className="section-title">{!location?.state?.id ? "Create Speciality" : "Update Speciality"}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <a onClick={() => navigate('/')} className="aColor">Home  /&nbsp;</a>
                    </li>
                    <li> <span className="section-title">{!location?.state?.id ? "Create Speciality" : "Update Speciality"}</span>
                    </li>
                </ul>
            </div>


            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <Formik
                            initialValues={location?.state?.id ? InputDetails : initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({ isSubmitting, setFieldValue, values,errors }) => {
                                const errorCount = Object.keys(errors).length;
                                console.log({ errorCount })

                                if (isSubmitting && errorCount > 0) {

                                    for (const key in errors) {
                                        console.log({ key })
                                        if (fieldRefs.current[key]) {
                                            fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                            break; // Stop at the first error we find
                                        }
                                    }


                                }


                                return (


                                    <Form className="blog-form" autoComplete='off'>
                                        {/* <h3 className="section-title">{!location?.state?.id ? "Create Speciality" : "Update Speciality"}</h3> */}

                                        <div className="input-container" ref={(el) => (fieldRefs.current.category = el)}>
                                            <SpecialityComponent
                                                InputDetails={InputDetails}
                                                setInputDetails={setInputDetails}
                                                setFieldValue={setFieldValue}
                                                editValue={values.category}

                                            />
                                            <ErrorMessage name="category" component="div" className="error-message" />
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.title = el)}>
                                                <label htmlFor="title">Speciality Title:*</label>
                                                <Field
                                                    type="text"
                                                    id="title"
                                                    name="title"
                                                    value={values?.title}
                                                    onChange={(e: any) => setFieldValue("title", e?.target?.value)}
                                                />
                                                <ErrorMessage name="title" component="div" className="error-message" />
                                            </div>
                                        </div>


                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.Slug = el)}>
                                                <label htmlFor="Slug">Slug Name:*</label>
                                                <Field
                                                    type="text"
                                                    id="Slug"
                                                    name="Slug"
                                                    value={values?.Slug}
                                                    onChange={(e: any) => setFieldValue("Slug", e?.target?.value)}
                                                    disabled={location?.state?.id ? true : false}
                                                />
                                                <p style={{ color: 'red' }}>{ErrMsgSlug ? ErrMsgSlug : ''}</p>
                                                <ErrorMessage name="Slug" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container" ref={(el) => (fieldRefs.current.description = el)}>
                                                <label htmlFor="description">Speciality Description:*</label>
                                                <Field name="description">
                                                    {({ field, form }: any) => (
                                                        <CKEditorComponent
                                                            data={field.value}
                                                            onDataChange={(newContent: string) => setFieldValue('description', newContent)}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="description" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-container">
                                                {InputDetails?.specialityImage?.length > 0 && updateSpeciality ? (
                                                    <>
                                                        <img
                                                            src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.specialityImage}`}
                                                            alt="blogImage"
                                                            style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }}
                                                        />
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                            <p className="fileNameMob"><strong>FileName:</strong>{InputDetails?.specialityImage}</p>
                                                            <span
                                                                style={{ cursor: 'pointer', color: 'red', fontSize: '14px', marginLeft: '10px' }}
                                                                onClick={handleRemove}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <strong>No Profile Photo</strong>
                                                )}
                                                <FileUpload
                                                    label={location?.state?.id ? "Update image" : "Choose image"}
                                                    setDirectory={setDirectory}
                                                    setFile={setFile}
                                                />
                                                <ErrorMessage name="blogImage" component="div" className="error-message" />
                                            </div>
                                        </div>

                                        <button className='btn btn-primary' type="submit" disabled={isSubmitting} >
                                            {!location?.state?.id ? "Create Speciality" : "Update Speciality"}
                                        </button>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </>
    );
};

export default Createspeciality;





