import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import getCareer from '../../Services/Careers';
import { Helmet } from 'react-helmet';
import Loader from '../shared/Loader';
import LazyLoad from 'react-lazyload';


interface compProps {
  title: string;
  description: string;
}

const CareerDetails: React.FC<compProps> = ({ title, description }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [singleCareer, setSingleCareer] = useState<any>();
  const [careerItems, setCareerItems] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);

  const listCareers = async () => {
    try {
      const response = await getCareer().ActiveCareerList();
      // console.log("filteredCareer", filteredCareer);
      const categoryname = await getCareer().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.category.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category?.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });
      setCareerItems(updatedResponse);
      setCategories(await getCareer().CategoryList());
    } catch (error) {
      console.error('Error fetching Careers:', error);
    }
  };

  async function ProvInfo() {
    setLoading(true)
    // const SingleCareer = await getCareer().getSingleCareer(location?.state?.id);
    const singleCareer: any = (await getCareer().CareerList()).find((career: any) => career?.Slug === slug);
    setSingleCareer(singleCareer);
    setLoading(false)

  }

  useEffect(() => {
    ProvInfo();
    listCareers();
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const countByCategory = careerItems?.reduce((acc: any, career: any) => {
    const categoryIds = career?.category[0] || [];
    const splitString = Array.isArray(categoryIds) ? categoryIds : categoryIds.split(',');
    splitString.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });
    return acc;
  }, {});

  // const bgImageStyle = {
  //   backgroundImage: `url(${process.env.REACT_APP_HOST}images/bg_1.jpg)`
  // };

  // const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/career.jpg`;

  console.log({ singleCareer })

  return (
    <div className="page-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <><><Helmet>
          {/* <title>{title}</title> */}
          <title>{`${singleCareer?.title ?? ''}`}</title>
          <meta name="description" content={description} />
        </Helmet><section className="home-slider owl-carousel">
            <LazyLoad>
              <div
                className="slider-item bread-item"
                // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
                style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/singlecareers.jpg` }}
              >
                <div className="overlay"></div>
                <div className="container" data-scrollax-parent="true">
                  <div className="row banner-text align-items-center">
                    <div className="col-md-12 col-sm-12 ftco-animate">


                      <h1 className="banner-ttl">{singleCareer?.title}</h1>
                      {/* <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div> */}
                      <div className="captionName" style={{ textAlign: 'center' }}>A Guide to Healthcare Professions </div>
                    </div>
                  </div>
                </div>
              </div>
            </LazyLoad>
          </section>
          <div>
            {/* <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                <li>
                  <a className="aColor" onClick={() => navigate('/')}>Home  /&nbsp;</a>
                </li>
                <li>
                  <a className="aColor" href="/careers">Careers  /&nbsp;</a>
                  {singleCareer?.title}
                </li>
              </ul> */}

            <ul className="breadCrumb">
              <li>
                <a role="button" className="aColor" onClick={() => navigate('/')}>Home /</a>
              </li>
              <li>
                <a role="button" className="aColor" onClick={() => navigate('/careers')}>Careers /</a>
              </li>
              <li className="nocursor">{singleCareer?.title}</li>
            </ul>

          </div></>
          <section className="blog-section section style-four style-five">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-sm-12 col-xs-12">
                  <div className="left-side">
                    <div className="item-holder">
                      <div className="image-box">
                        <figure>
                          <a href="Singleblog">

                            {/* <img className="mediaqueriimagesize"
                                            src={`${singleCareer?.careerImage !== null ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleCareer?.bgImageStylesingle}` : `${process.env.REACT_APP_HOST}images/bg_1.jpg`}`}
                                            alt={singleCareer?.title}
                                            width="53%"
                                            style={{ marginBottom: '20px', maxWidth: '350px', maxHeight: '300px' }} /> */}
                            <LazyLoad>
                              <img
                                className="flaticon-tooth imgcareer mediaqueriimagesize"
                                src={singleCareer?.careerImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleCareer?.careerImage[0]}` : `${process.env.REACT_APP_HOST}images/career.jpg`}
                                alt={singleCareer?.title}
                                width="57%"
                                style={{ marginBottom: '9px' }} />
                            </LazyLoad>
                          </a>
                        </figure>
                      </div>
                      <div className="content-text">
                        <p><strong>{singleCareer?.title}</strong></p>
                        {/* <p>{singleCareer?.createdAt}</p> */}
                        {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
      {removeHtmlTags(singleCareer?.description)}
    </p> */}
                        <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleCareer?.description }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <div className="right-side">
                    <div className="categorise-menu">
                      <div className="text-title">
                        <h6>Categories</h6>
                      </div>
                      <div className="categories-container">
                        <ul className="categorise-list">
                          <li style={{ cursor: 'pointer' }} onClick={() => navigate('/careers')}>All</li>
                          {categories?.map((item: any) => {
                            const blogCount = countByCategory[item?.id] || 0;

                            //   console.log("blogCount",countByCategory[item?.id] )
                            return (
                              <li className="capitalTxt" key={item?.id} onClick={() => { navigate(`/careers`, { state: { category: item?.id } }); }} style={{ cursor: 'pointer' }}>
                                {item?.name.toLowerCase()} <span>({blogCount})</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section></>
      )}
    </div>
  );
};

export default CareerDetails;





// import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation, useParams } from "react-router-dom";
// import getCareer from '../../Services/Careers';
// import { Helmet } from 'react-helmet';
// import Loader from '../shared/Loader';


// interface compProps {
//   title: string;
//   description: string;
// }

// const CareerDetails: React.FC<compProps> = ({ title, description }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [singleCareer, setSingleCareer] = useState<any>();
//   const [careerItems, setCareerItems] = useState<any[]>([]);
//   const [categories, setCategories] = useState<any[]>([]);
//   const { slug } = useParams();
//   const [loading, setLoading] = useState(true);

//   const listCareers = async () => {
//     try {
//       const response = await getCareer().CareerList();
//       // console.log("filteredCareer", filteredCareer);
//       const categoryname = await getCareer().getcategory();
//       const updatedResponse = response?.map((item: any) => {
//         const categoryNames = item?.category.map((cateId: any) => {
//           const category = categoryname.find((cate: any) => cate.id === cateId);
//           return category ? category?.name : cateId;
//         });
//         return {
//           ...item,
//           catergoryName: categoryNames
//         };
//       });
//       setCareerItems(updatedResponse);
//       setCategories(await getCareer().CategoryList());
//     } catch (error) {
//       console.error('Error fetching Careers:', error);
//     }
//   };

//   async function ProvInfo() {
//     setLoading(true)
//     // const SingleCareer = await getCareer().getSingleCareer(location?.state?.id);
//     const singleCareer: any = (await getCareer().CareerList()).find((career: any) => career?.Slug === slug);
//     setSingleCareer(singleCareer);
//     setLoading(false)

//   }

//   useEffect(() => {
//     ProvInfo();
//     listCareers();
//   }, [slug]);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const countByCategory = careerItems?.reduce((acc: any, career: any) => {
//     const categoryIds = career?.category || [];
//     const splitString = Array.isArray(categoryIds) ? categoryIds : categoryIds.split(',');
//     splitString.forEach((catId: any) => {
//       if (!acc[catId]) {
//         acc[catId] = 0;
//       }
//       acc[catId]++;
//     });
//     return acc;
//   }, {});

//   // const bgImageStyle = {
//   //   backgroundImage: `url(${process.env.REACT_APP_HOST}images/bg_1.jpg)`
//   // };

//   // const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/career.jpg`;

//   console.log({singleCareer})

//   return (
//     <div className="page-wrapper">
//       {loading ? (
//         <Loader />
//       ) : (
//         <><><Helmet>
//           {/* <title>{title}</title> */}
//           <title>{`${singleCareer?.title ?? ''}`}</title>
//           <meta name="description" content={description} />
//         </Helmet><section className="home-slider owl-carousel">
//             <div
//               className="slider-item bread-item"
//               // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
//               style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/singlecareers.jpg` }}
//             >
//               <div className="overlay"></div>
//               <div className="container" data-scrollax-parent="true">
//                 <div className="row banner-text align-items-center">
//                   <div className="col-md-12 col-sm-12 ftco-animate">


//                     <h1 className="banner-ttl">{singleCareer?.title}</h1>
//                     {/* <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div> */}
//                     <div className="captionName" style={{ textAlign: 'center' }}>A Guide to Healthcare Professions </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//           <div>
//             {/* <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
//                 <li>
//                   <a className="aColor" onClick={() => navigate('/')}>Home  /&nbsp;</a>
//                 </li>
//                 <li>
//                   <a className="aColor" href="/careers">Careers  /&nbsp;</a>
//                   {singleCareer?.title}
//                 </li>
//               </ul> */}

//             <ul className="breadCrumb">
//               <li>
//                 <a role="button" className="aColor" onClick={() => navigate('/')}>Home /</a>
//               </li>
//               <li>
//                 <a role="button" className="aColor" onClick={() => navigate('/careers')}>Careers /</a>
//               </li>
//               <li className="nocursor">{singleCareer?.title}</li>
//             </ul>

//           </div></>
//           <section className="blog-section section style-four style-five" style={{ marginTop: '90px' }}>
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-9 col-sm-12 col-xs-12">
//                   <div className="left-side">
//                     <div className="item-holder">
//                       <div className="image-box">
//                         <figure>
//                           <a href="Singleblog">

//                           {/* <img className="mediaqueriimagesize"
//                                             src={`${singleCareer?.careerImage !== null ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleCareer?.bgImageStylesingle}` : `${process.env.REACT_APP_HOST}images/bg_1.jpg`}`}
//                                             alt={singleCareer?.title}
//                                             width="53%"
//                                             style={{ marginBottom: '20px', maxWidth: '350px', maxHeight: '300px' }} /> */}

//                             <img
//                               className="flaticon-tooth imgcareer mediaqueriimagesize"
//                               src={singleCareer?.careerImage.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleCareer?.careerImage[0]}` : `${process.env.REACT_APP_HOST}images/career.jpg`}
//                               alt={singleCareer?.title}
//                               width="57%"
//                               style={{ marginBottom: '9px' }} />
//                           </a>
//                         </figure>
//                       </div>
//                       <div className="content-text">
//                         <p><strong>{singleCareer?.title}</strong></p>
//                         {/* <p>{singleCareer?.createdAt}</p> */}
//                         {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
//       {removeHtmlTags(singleCareer?.description)}
//     </p> */}
//                         <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleCareer?.description }} />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-md-3 col-sm-12 col-xs-12">
//                   <div className="right-side">
//                     <div className="categorise-menu">
//                       <div className="text-title">
//                         <h6>Categories</h6>
//                       </div>
//                       <div className="categories-container">
//                         <ul className="categorise-list">
//                           <li style={{ cursor: 'pointer' }} onClick={() => navigate('/careers')}>All</li>
//                           {categories?.map((item: any) => {
//                             const blogCount = countByCategory[item?.id] || 0;

//                             //   console.log("blogCount",countByCategory[item?.id] )
//                             return (
//                               <li className="capitalTxt" key={item?.id} onClick={() => { navigate(`/careers`, { state: { category: item?.id } }); }} style={{ cursor: 'pointer' }}>
//                                 {item?.name.toLowerCase()} <span>({blogCount})</span>
//                               </li>
//                             );
//                           })}
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section></>
//       )}
//     </div>
//   );
// };

// export default CareerDetails;
