

// formik code implementation

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { SHA256 } from "crypto-js";
import { useIdleTimeout } from "../shared/SessionExpire";
import userService from "../../Services/user/user.service";
import { loginValidationSchema } from '../Login/loginValidationSchema';
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  IconButton,
  Modal,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Users from "../../utills/users";
import forgotPasswordServices from "../../Services/forgotPasswordServices";
import OTPServices from "../../Services/OTPServices";
// import OTPServices from "../../Services/OTPServices";
import './Login.css';

import { Helmet } from 'react-helmet';
import Loader from "../shared/Loader";
import pages from "../../utills/pageUtils";

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

interface FormValues {
  userName: string;
  password: string;
}

const initialValues: FormValues = {
  userName: "",
  password: "",
};

// function Login() {
const Login: React.FC<compProps> = ({ title, description, keywords }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [err, setErr] = useState("");
  const [errors, setErrors] = useState({});
  const isIdle = useIdleTimeout(1000 * 900);
  const isActive = useIdleTimeout(1000 * 900);
  // console.log("isIdle", isIdle);
  const [token, setToken] = useState<any>();
  const logUser: any = localStorage.getItem("userData");
  const [successMsg, setSuccessMsg] = useState("");
  const logUserObject = JSON.parse(logUser);
  const [registerAdmin, setRegisterAdmin] = useState(true);
  const [errSubmitMsg, setSubmitMsg] = useState("");
  const [getModal, setModal] = useState(false);
  const [timer, setTimer] = useState<number>(60);
  const [currentUserName, setCurrentUserName] = useState('');
  const [response, setResponse] = useState<any>();
  const [otpError, setOtpError] = useState("");
  const [otp, setOtp] = useState<string>("");
  const [userloginDetails, setUserLoginDetails] = useState<any>([]);
  const [showMsg, setShowMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [pageInfo, setPageInfo] = useState<any>()
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()


  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      })
    };
    fetchData();
  }, [])
  
  useEffect(() => {
    const adminRegister = async () => {
      if (registerAdmin === true) {
        setRegisterAdmin(false);
      } else {
        await userService().MasterAdmin();
        // console.log("AdminResponse", adminResponse);
        // console.log("RegisterAdmin", registerAdmin);
      }
    };
    adminRegister();
  }, [registerAdmin]); // Removed registerAdmin from the dependency array


  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isTimerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerRunning(false);
    }
    // Clean up the interval when the component unmounts or the timer is stopped
    return () => clearInterval(interval);
  }, [isTimerRunning, timer]);


  const userhandleLogin = async (values: FormValues) => {
    let type: any;
    setLoading(true);
    const userLoginResp: any = await Users().handleLogin(values, setErrors, setErr, location?.state?.url, navigate);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[1-9]\d{1,14}$/; // This is a simple example, adapt as needed for your phone number format
    if (emailPattern.test(userLoginResp?.userName)) {
      type = 'email';
    } else if (phonePattern.test(userLoginResp?.userName)) {
      type = 'phone';
    }
    // console.log("type", type)
    if (userLoginResp) {
      const sendOtpEmail: any = await forgotPasswordServices().SendEmail(userLoginResp?.userName, userLoginResp?.userName, type);
      setShowMsg(sendOtpEmail?.msg);
      setLoading(true);
      setErr('')
      sendOtpEmail?.status === 200 && setLoading(false); setModal(true);
      setIsTimerRunning(true);
      setTimer(60);
      // setTimerDetails();
      setCurrentUserName(userLoginResp?.userName);
    } else {
      setLoading(false);
    }


  };


 
  const handleOTPSubmit = async (resetform: any) => {
    setOtpError("");
    try {
      setLoading(true);
      setErr('');
      // Check if the OTP has expired
      if (timer === 0) {
        setLoading(false);
        setOtpError("OTP has expired. Please click on resend.");
        resetform(); // Reset the form
        return; // Exit the function early since the OTP is expired
      }
      const getOTP: any = await OTPServices().validateotpTwoStep(currentUserName, otp);
      setLoading(false);
      if (getOTP && getOTP?.data?.listUsers.items[0]?.Otp === otp) {
        const { accessToken, details, userRole, PhoneNumber, userName } = getOTP.data.listUsers.items[0];
        const userData = { accessToken, userId: details, Role: userRole, PhoneNumber, userName };
        // console.log("userData", userData);
        localStorage.setItem('userData', JSON.stringify(userData));
        const userTwoAuth: any = await Users().handleTwoStepOTP({ userName: currentUserName, otp: otp }, setErrors, setErr, location?.state?.url, navigate);
        setLoading(false);
        setModal(false);
        setIsTimerRunning(true);
        setTimer(60);
        // setTimerDetails();
        // console.log("userResps", userTwoAuth);
        if (userTwoAuth) {
          const userResps: any = await Users().handleLogin({ userName: currentUserName, password: '' },
            setErrors, setErr, location?.state?.url, navigate);
          // console.log("userResps",userResps)
          if (userResps) {
            navigate('/DashBoard');
          }
        }
      } else {
        setOtpError("Invalid OTP");
        setLoading(false);
        resetform(); // Reset the form
      }
    } catch (error) {
      console.error('Error during OTP validation:', error);
      setOtpError("An error occurred during OTP validation");
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // Function to handle the Enter key press
  //   const handleKeyDown = (event: { key: string; }) => {
  //     if (event.key === 'Enter') {
  //       handleOTPSubmit(resetForm); // Call the submit function when Enter is pressed
  //     }
  //   };

  //   // Add event listener
  //   document.addEventListener('keydown', handleKeyDown);

  //   // Clean up the event listener
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [handleOTPSubmit]);



  const ResendEmail = async () => {
    try {
      setOtp("");
      const userResponse: any = await forgotPasswordServices().SendEmail(currentUserName);
      console.log("userResponse", userResponse);
      // console.log("userResponse", userResponse);
      setIsTimerRunning(true);
      setTimer(60);
      // setTimerDetails();
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false)
  }, [])


  return (
    <>
      <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta name="author" content="Gurram Dental Care" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>
      {/* {loading ? (
          <Loader />
        ) : ( */}

          <>

            <section className="home-slider owl-carousel">
              <div
                className="slider-item bread-item"
                // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
                style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})` : 'images/bg_1.jpg' }}
                data-stellar-background-ratio="0.5"
              >
                <div className="overlay"></div>
                <div className="container" data-scrollax-parent="true">
                  <div className="row banner-text align-items-center">
                    <div className="col-md-12 col-sm-12 ftco-animate">

                      <h1 className="banner-ttl">{bannerName}</h1>
                      <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>

                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="container-fluid loginfluid">
              <Container style={{ fontFamily: "helvetica" }}>
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: "40px", marginBottom: "40px" }}
                >
                  <Grid item xs={12} md={12} lg={5}>
                    <Box textAlign="center" color="red">
                      {location?.state && (
                        <Typography variant="body1" style={{ color: "red" }}>
                          {location.state.setHasExpired}
                        </Typography>
                      )}
                      {err && (
                        <Typography
                          variant="body1"
                          style={{ color: "red", fontSize: "18px", fontWeight: "600" }}
                        >
                          {err}
                          {loading ? (
          <Loader />
        ) : (null)}
                        </Typography>
                      )}
                    </Box>
                    <Box textAlign="center" mb={2}>
                    </Box>
                    <Box style={{ padding: "40px" }}>
                      <Formik
                        initialValues={{ userName: "", password: "" }}
                        validationSchema={loginValidationSchema}
                        onSubmit={userhandleLogin}
                      >
                        {({ handleSubmit, isSubmitting, errors, touched }) => (
                          <Form autoComplete="off" onSubmit={handleSubmit} style={{ textAlign: "center" }}>
                            <Box mb={2} className="form-field">
                              <Field
                                name="userName"
                                as={TextField}
                                label="Email/Phone"
                                variant="outlined"
                                fullWidth
                                error={touched.userName && !!errors.userName}
                                helperText={
                                  <ErrorMessage name="userName" component="span" />
                                }
                              />
                            </Box>
                            <Box mb={2} className="form-field">
                              <Field
                                name="password"
                                as={TextField}
                                label="Password"
                                type="password"
                                variant="outlined"
                                fullWidth
                                error={touched.password && !!errors.password}
                                helperText={
                                  <ErrorMessage name="password" component="span" />
                                }
                              />
                            </Box>
                            <Button
                              variant="contained"

                              type="submit"
                              disabled={isSubmitting}
                              fullWidth
                              style={{ padding: "10px", fontSize: "16px", backgroundColor: '#2cbcbc' }}
                            >
                              Login
                            </Button>
                            <Box display="flex" justifyContent="space-between" mt={2}>
                              <Link
                                onClick={() => navigate("/Register")}
                                style={{
                                  color: '#2cbcbc',
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                              >
                                Create Account
                              </Link>
                              <Link
                                onClick={() => navigate("/forgotPassword")}
                                style={{
                                  color: '#2cbcbc',
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                              >
                                Forgot Password?
                              </Link>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </div>

            <Modal open={getModal} onClose={closeModal}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: '8px', textAlign: 'center', maxWidth: 400 }}>
                  <span className="close new" onClick={closeModal} style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}>
                    &times;
                  </span>
                  {/* <Typography variant="h6" style={{ color: '#2cbcbc', marginBottom: '20px' }}>OTP Validation</Typography> */}
                  <p className='text-success text-center' style={{ fontSize: '15px' }}>{showMsg}</p>
                  {otpError && (
                    <Typography color="error" sx={{ mt: 2 }}>
                      {otpError}
                    </Typography>
                  )}
                  <TextField className="form-field"
                    label="Enter Your OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    fullWidth
                    sx={{ mt: 2,  '&.Mui-focused fieldset': {
                      borderColor: '#2cbcbc', // Border color when focused
                    }, }}
                    autoComplete="off"
                />
                  {timer > 0 ? (
                    <>
                      <Typography sx={{ mt: 2 }}>
                        Resend OTP in {timer} seconds
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                        <Button onClick={handleOTPSubmit} sx={{ backgroundColor: '#2cbcbc', color: 'black' }}>
                          Submit
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ mt: 2 }}>
                      <Typography color="textSecondary">The OTP has expired. Please click the link to resend the OTP.</Typography>
                      <Button variant="outlined" sx={{ mt: 2, borderColor: '#2cbcbc', color: '#2cbcbc' }} onClick={() => { ResendEmail(); setTimer(60); }} >
                        Click here to resend
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Modal>

          </>

        {/* )
      }  */}
    </>
  );
}

export default Login;




