import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Homeservices from "../../Services/homeServices";
import './style.css';
import { Helmet } from 'react-helmet';
import LazyLoad from "react-lazyload";


interface compProps {
  title: string;
  description: string;
}

const ServicePage: any = ({ title, description }: any) => {
  const navigate = useNavigate();
  const faqRef = useRef<HTMLDivElement>(null);
  const [singleService, setSingleService] = useState<any>();
  const { slug } = useParams();
  const [categories, setCategories] = useState<any[]>([]);
  const [galleryItems, setGalleryItems] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  // console.log("singleService", singleService)

  async function ProvInfo() {
    setLoading(true);
    // const singleService = await Homeservices().getServiceSingle(slug);
    const singleService: any = (await Homeservices().servicesList()).find((service: any) => service?.Slug === slug);
    setSingleService(singleService);
    const response: any = await Homeservices().FooterservicesList();
    const sortedServices = response.sort((a: any, b: any) => {
      const dateA = new Date(a.updatedAt || a.createdAt).getTime();
      const dateB = new Date(b.updatedAt || b.createdAt).getTime();
      return dateB - dateA; // Sort in descending order
    });
    const latestServices = sortedServices.slice(0, 6);
    setServiceItems(latestServices);
    setLoading(false);
  }

  useEffect(() => {
    ProvInfo();
  }, [slug])
  const removeHtmlTags = (str: any) => {
    return str && str?.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const [serviceItems, setServiceItems] = useState<any>([]);
  // console.log("serviceItems", serviceItems);
  useEffect(() => {
    async function fetchGalleryItems() {
      const response = await Homeservices().ActiveservicesList();
      console.log("activeResponse",response)
      setGalleryItems(response);
      const catResponse = await Homeservices().serviceCategoryList();
      setCategories(catResponse);

    }
    fetchGalleryItems();
  }, []);
  const countByCategory = galleryItems && galleryItems.reduce((acc: any, blog: any) => {
    const categoryIds = blog?.category[0]
    const splitString = categoryIds?.includes(',') ? categoryIds.split(',') : [categoryIds];
    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });
    return acc;
  }, {});


  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [])

  const serviceByCategory = async (event: any) => {
    navigate("/services", { state: { categoryID: event?.id } });
  };

  const bgImageStyle = {
    backgroundImage: `url(${process.env.REACT_APP_HOST}images/bg_1.jpg)`
  };
  const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/tooth.jpg`;

  return (
    <>
      <Helmet>
        <title>{singleService?.Title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <section className="home-slider owl-carousel">
        <LazyLoad>
        <div
          className="slider-item bread-item"
          // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
          style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/services.jpg` }}
        >
          <div className="overlay"></div>
          <div className="container" data-scrollax-parent="true">
            <div className="row banner-text align-items-center">
              <div className="col-md-12 col-sm-12 ftco-animate mb-5">
                <h1 className="banner-ttl capitalTxt">{singleService?.Title.toLowerCase()}</h1>
                {/* <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div> */}
                <div className="captionName" style={{ textAlign: 'center' }}>Your Health, Our Priority</div>
              </div>
            </div>
          </div>
        </div>
        </LazyLoad>
      </section>
      <div>
        <ul className="ullist" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
          <li>
            <a className="aColor" href="javascript:void(0);" onClick={() => navigate('/')}>Home  /&nbsp;</a>
          </li>
          <li>
            <a href="javascript:void(0);" onClick={() => navigate('/services')} style={{ cursor: 'pointer' }}> 
               <span className="aColor">services</span>  </a>
            /<span className="titletext capitalTxt">{singleService?.Title.toLowerCase()}</span>

          </li>
        </ul>
      </div>

      <section className="ftco-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div>
                <h3 className="heading heading-section ftco-animate titletext fsheading capitalTxt">{singleService?.Title.toLowerCase()}</h3>
              </div>
              {/* <div className="icon d-flex "> */}
              <div className="icon">
                <LazyLoad>
                  <img
                     className="mediaqueriimagesize"
                    src={
                      singleService?.Profile?.length > 0
                        ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleService?.Profile}`
                        : bgImageStylesingle
                    }
                    alt={singleService?.name || 'Default Image'}
                    style={{ marginBottom: '9px', width: '30%', height: '200px' }}
                  />
           </LazyLoad>
                {/* <img
                  className=""
                  src={singleService?.Profile?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleService?.Profile}` : bgImageStylesingle}
                  alt={singleService?.name}
                  width="57%"
                  style={{ marginBottom: '9px', width: '30%', height: '200px' }}
                /> */}
              </div>
              <div className="d-flex align-self-stretch ftco-animate">
                <div className="media block-6 services d-block">
                  <div className="media-body mt-3">
                    {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
                      {removeHtmlTags(singleService?.Description)}
                    </p> */}
                    <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleService?.Description }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1">
            </div>
            <div className="col-md-3">
              <h4>Categories</h4>
              <div className="categories-container">
                <li
                  className="d-flex align-self-stretch heading listyle"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate('/services')}
                >
                  <a href="javascript:void(0);" className="serviceTitle" style={{ width: '100%', display: 'block',marginLeft: '50px' }}>
                    All
                  </a>
                </li>
                {
                  categories?.map((item: any) => {
                    const blogCount = countByCategory[item?.id] || 0;
                    return (
                      <div
                        className="d-flex align-self-stretch"
                        key={item?.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() => serviceByCategory(item)}
                      >
                        <div className="media-body mt-3 categories">
                          <li className="heading listyle ">
                            <a href="javascript:void(0);" className="serviceTitle capitalTxt">
                              {item?.name.toLowerCase()} ({blogCount})
                            </a>
                          </li>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              {/* {serviceItems?.map((item: any) => {
                return (
                  <div className="d-flex align-self-stretch" key={item?.id || item?.Title} >
                    <div className="media block-6 services d-block text-center">
                      <div className="media-body mt-3 categories">
                        <li className="heading listyle" onClick={() => setSingleService(item)}><a href="javascript:void(0);" className="serviceTitle">{item?.Title}</a></li>
                      </div>
                    </div>
                  </div>
                );
              })} */}
            </div>

          </div>

        </div>

      </section >
    </>
  )
}
export default ServicePage;





// import React, { useRef, useEffect, useState } from "react";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import Homeservices from "../../Services/homeServices";
// import './style.css';
// import { Helmet } from 'react-helmet';


// interface compProps {
//   title: string;
//   description: string;
// }

// const ServicePage: any = ({ title, description }: any) => {
//   const navigate = useNavigate();
//   const faqRef = useRef<HTMLDivElement>(null);
//   const [singleService, setSingleService] = useState<any>();
//   const { slug } = useParams();
//   const [categories, setCategories] = useState<any[]>([]);
//   const [galleryItems, setGalleryItems] = useState<any>([]);
//   const [loading, setLoading] = useState(true);

//   // console.log("singleService", singleService)

//   async function ProvInfo() {
//     setLoading(true);
//     // const singleService = await Homeservices().getServiceSingle(slug);
//     const singleService: any = (await Homeservices().servicesList()).find((service: any) => service?.Slug === slug);
//     setSingleService(singleService);
//     const response: any = await Homeservices().FooterservicesList();
//     const sortedServices = response.sort((a: any, b: any) => {
//       const dateA = new Date(a.updatedAt || a.createdAt).getTime();
//       const dateB = new Date(b.updatedAt || b.createdAt).getTime();
//       return dateB - dateA; // Sort in descending order
//     });
//     const latestServices = sortedServices.slice(0, 6);
//     setServiceItems(latestServices);
//     setLoading(false);
//   }

//   useEffect(() => {
//     ProvInfo();
//   }, [slug])
//   const removeHtmlTags = (str: any) => {
//     return str && str?.replace(/<\/?[^>]+(>|$)/g, "");
//   };

//   const [serviceItems, setServiceItems] = useState<any>([]);
//   // console.log("serviceItems", serviceItems);
//   useEffect(() => {
//     async function fetchGalleryItems() {
//       const response = await Homeservices().servicesList();
//       setGalleryItems(response);
//       const catResponse = await Homeservices().serviceCategoryList();
//       setCategories(catResponse);

//     }
//     fetchGalleryItems();
//   }, []);
//   const countByCategory = galleryItems && galleryItems.reduce((acc: any, blog: any) => {
//     const categoryIds = blog?.category[0]
//     const splitString = categoryIds?.includes(',') ? categoryIds.split(',') : [categoryIds];
//     splitString?.forEach((catId: any) => {
//       if (!acc[catId]) {
//         acc[catId] = 0;
//       }
//       acc[catId]++;
//     });
//     return acc;
//   }, {});


//   useEffect(() => {
//     window?.scrollTo(0, 0)
//   }, [])

//   const serviceByCategory = async (event: any) => {
//     navigate("/services", { state: { categoryID: event?.id } });
//   };

//   const bgImageStyle = {
//     backgroundImage: `url(${process.env.REACT_APP_HOST}images/bg_1.jpg)`
//   };
//   const bgImageStylesingle = `${process.env.REACT_APP_HOST}images/tooth.jpg`;

//   return (
//     <>
//       <Helmet>
//         <title>{singleService?.Title}</title>
//         <meta name="description" content={description} />
//       </Helmet>

//       <section className="home-slider owl-carousel">
//         <div
//           className="slider-item bread-item"
//           // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
//           style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}images/services.jpg` }}
//         >
//           <div className="overlay"></div>
//           <div className="container" data-scrollax-parent="true">
//             <div className="row banner-text align-items-center">
//               <div className="col-md-12 col-sm-12 ftco-animate mb-5">
//                 <h1 className="banner-ttl">{singleService?.Title}</h1>
//                 {/* <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div> */}
//                 <div className="captionName" style={{ textAlign: 'center' }}>Your Health, Our Priority</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <div>
//         <ul className="" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
//           <li>
//             <a className="aColor" href="javascript:void(0);" onClick={() => navigate('/')}>Home  /&nbsp;</a>
//           </li>
//           <li>
//             <a href="javascript:void(0);" onClick={() => navigate('/services')} style={{ cursor: 'pointer' }}>  <span className="aColor">services</span>  </a>
//             /<span className="titletext">{singleService?.Title}</span>

//           </li>
//         </ul>
//       </div>

//       <section className="ftco-section">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-8">
//               <div>
//                 <h3 className="heading heading-section ftco-animate titletext">{singleService?.Title}</h3>
//               </div>
//               <div className="icon d-flex ">
//                 {loading ? (
//                   <div className="loading-container">
//                     {/* Display your loading icon here */}
//                     <i className="fas fa-spinner fa-spin"></i>
//                   </div>
//                 ) : (
//                   <img
//                      className="mediaqueriimagesize"
//                     src={
//                       singleService?.Profile?.length > 0
//                         ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleService?.Profile}`
//                         : bgImageStylesingle
//                     }
//                     alt={singleService?.name || 'Default Image'}
//                     style={{ marginBottom: '9px', width: '30%', height: '200px' }}
//                   />
//                 )}
//                 {/* <img
//                   className=""
//                   src={singleService?.Profile?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleService?.Profile}` : bgImageStylesingle}
//                   alt={singleService?.name}
//                   width="57%"
//                   style={{ marginBottom: '9px', width: '30%', height: '200px' }}
//                 /> */}
//               </div>
//               <div className="d-flex align-self-stretch ftco-animate">
//                 <div className="media block-6 services d-block">
//                   <div className="media-body mt-3">
//                     {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
//                       {removeHtmlTags(singleService?.Description)}
//                     </p> */}
//                     <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleService?.Description }} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-1">
//             </div>
//             <div className="col-md-3">
//               <h4>Categories</h4>
//               <div className="categories-container">
//                 <li
//                   className="d-flex align-self-stretch heading listyle"
//                   style={{ cursor: 'pointer' }}
//                   onClick={() => navigate('/services')}
//                 >
//                   <a href="javascript:void(0);" className="serviceTitle" style={{ width: '100%', display: 'block' }}>
//                     All
//                   </a>
//                 </li>
//                 {
//                   categories?.map((item: any) => {
//                     const blogCount = countByCategory[item?.id] || 0;
//                     return (
//                       <div
//                         className="d-flex align-self-stretch"
//                         key={item?.id}
//                         style={{ cursor: 'pointer' }}
//                         onClick={() => serviceByCategory(item)}
//                       >
//                         <div className="media-body mt-3 categories">
//                           <li className="heading listyle">
//                             <a href="javascript:void(0);" className="serviceTitle">
//                               {item?.name} ({blogCount})
//                             </a>
//                           </li>
//                         </div>
//                       </div>
//                     );
//                   })
//                 }
//               </div>
//               {/* {serviceItems?.map((item: any) => {
//                 return (
//                   <div className="d-flex align-self-stretch" key={item?.id || item?.Title} >
//                     <div className="media block-6 services d-block text-center">
//                       <div className="media-body mt-3 categories">
//                         <li className="heading listyle" onClick={() => setSingleService(item)}><a href="javascript:void(0);" className="serviceTitle">{item?.Title}</a></li>
//                       </div>
//                     </div>
//                   </div>
//                 );
//               })} */}
//             </div>

//           </div>

//         </div>

//       </section >
//     </>
//   )
// }
// export default ServicePage;