import { API, graphqlOperation } from "aws-amplify";
import { createPayment, updatePatientAppointment } from "../../../graphql/mutations";
import { listPatientAppointments, listPayments, listUsers } from "../../../graphql/queries";
import { equal } from "assert";
import userService from "../user/user.service";
import { SHA256 } from 'crypto-js';




const PaymentServices = () => {

    const PaymentDetails = async (paymentData: any) => {
        try {
            const response: any = await API.graphql(graphqlOperation(createPayment, { input: paymentData }));
            console.log('Payment details stored:', response?.data?.createPayment);
            return response?.data?.createPayment;
        } catch (error) {
            console.error('Error storing payment details:', error);
            throw error;
        }
    };


    const ListPayments = async (appID: any) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            do {
                const filterVar = {
                    filter: {
                        appointmentId: { eq: appID }
                    },
                    limit: limit,
                    nextToken: nextToken
                };

                // console.log("filter", filterVar);

                const result = await API.graphql(graphqlOperation(listPayments, filterVar)) as any;

                // console.log(result, "filteredResp");

                const users = result?.data?.listPayments?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listPayments?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }
    const generateAppointmentID = async () => {
        const options = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json, text/plain, */*"
            }
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_GENERATE_APPOINTMENTID}`, options);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return { status: response.status, data };
        } catch (error) {
            console.error('Error fetching appointment ID:', error);
            return { status: 400, data: null };
        }
    };
    const encodeBase64 = async (input: any) => {
        return btoa(input);
    }

    const decodeBase64 = async (encoded: any) => {
        return atob(encoded);
    };

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }
    const updatePatientPayment = async (data: any) => {
        try {
            const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
            const sanitizedUserInfo = removeFields(data, fieldsToRemove);
            const getResponse: any = await API.graphql(graphqlOperation(updatePatientAppointment, { input: sanitizedUserInfo }));
            // console.log(getResponse, "getResponse");
            return getResponse;
        } catch (error) {
            console.log(error);
        }
    }

    const appointmentListByPhoneNumber = async (PhoneNumber: any) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                filter: {
                    PhoneNumber: { eq: PhoneNumber },
                },
                limit: limit,
                nextToken: nextToken
            };
            do {
                const result = await API.graphql(graphqlOperation(listPatientAppointments, filterVar)) as any;
                const users = result?.data?.listPatientAppointments?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listPatientAppointments?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }

    const appointmentListByappID = async (appID: any) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                filter: {
                    id: { eq: appID },
                },
                limit: limit,
                nextToken: nextToken
            };
            do {
                const result = await API.graphql(graphqlOperation(listPatientAppointments, filterVar)) as any;
                const users = result?.data?.listPatientAppointments?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listPatientAppointments?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }

    const updateuserType = async (id: any, PhoneNumber: any, userType: any) => {
        const appointments = await userService().ListPatientAppointmentsBypatientID("");
        const filteredAppointments = appointments?.filter((element: any) => {
            // return element?.EmailId === EmailId || element?.PhoneNumber === PhoneNumber;
            return element?.patientID === id;
        });
        filteredAppointments?.length > 0 && filteredAppointments?.map(async (item: any) => {
            const input = {
                id: item?.id,
                userType: userType
            }
            const updateApp = await userService().updatePatientAppointmentByData(input);
            return updateApp;
        })
    }

    const checkExistingUsers = async (email: any, phone: any) => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;
            do {

                const filterVar = {
                    filter: {
                        and: [
                            { EmailId: { eq: email } },
                            { PhoneNumber: { eq: phone } }
                        ],
                        // userType: { eq: "E" }
                    },
                    limit: limit,
                    nextToken: nextToken
                };
                // console.log("filter", filterVar);
                const result = await API.graphql(graphqlOperation(listUsers, filterVar)) as any;
                const users = result?.data?.listUsers?.items || [];
                allUsers.push(...users);
                nextToken = result?.data?.listUsers?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching users:', error);
            return []; // Return an empty array in case of error
        }
    }

    const handleOTPSubmitService = async (setOtpError: any, timer: any, CurrentOTP: any, setModel: any, handleAppoinmentDelete: any, appointmentID: any, setIsTimerRunning: any, setTimer: any, getPatients: any, setCancel: any, key: any) => {
        console.log("key at handleOTPSubmitService", key);
        setOtpError("");
        try {
            if (timer === 0) {
                setOtpError("The OTP has expired. Please click on  Regenerate.");
                // resetform();
                return; // Exit the function early since the OTP is expired
            }
            const newOTP = localStorage.getItem('trackOTP');
            const hashOTP = SHA256(CurrentOTP).toString();
            if (newOTP === hashOTP) {
                setModel(false);
                setTimeout(() => {
                    handleAppoinmentDelete(appointmentID, key, "offline");
                }, 500)
                // await getPatients();
                setCancel(false);
                localStorage.removeItem('trackOTP');
                setIsTimerRunning(false);
                setTimer(60);
            } else {
                setOtpError("Invalid OTP");
                // resetform();
            }
        } catch (error) {
            console.error('Error during OTP validation:', error);
            setOtpError("An error occurred during OTP validation");
        }
    };

    // const ResendEmail = async () => {
    //     setOtpError("");
    //     handleOTPFormOpen(appointmentID, "offline");
    // };

    const handleOTPSubmitUpdate = async (setOtpError: any, timer: any, CurrentOTP: any, getvalues: any, setModel: any, handleUpdate: any, setIsTimerRunning: any, setTimer: any) => {
        setOtpError("");
        try {
            if (timer === 0) {
                setOtpError("The OTP has expired. Please click on  Regenerate.");
                // resetform();
                return; // Exit the function early since the OTP is expired
            }
            const newOTP = localStorage.getItem('trackOTP');
            const hashOTP = await SHA256(CurrentOTP).toString();
            const { values, form } = getvalues;
            const { setSubmitting, resetForm } = form;
            if (newOTP === hashOTP) {
                setModel(false);
                // if (confirm === "Delete") {
                //     handleAppoinmentDelete(appointmentID, "offline");
                // } else if (confirm === "Edit") {
                handleUpdate(values, { setSubmitting, resetForm });
                // }
                // navigate('/appointmentHistory', { state: { appointmentDetails: [appointment] } });
                localStorage.removeItem('trackOTP');
                setIsTimerRunning(false);
                setTimer(60);
            } else {
                setOtpError("Invalid OTP");
                // resetform();
            }
        } catch (error) {
            console.error('Error during OTP validation:', error);
            setOtpError("An error occurred during OTP validation");
        }
    };

    const handleOTPFormOpenService = async (appID: string, PaymentMode: string, setAppointmentID: any, logUserObject: any, setLoading: any, setSuccessMsg: any, setModel: any, setIsTimerRunning: any, setTimer: any, handleAppoinmentDelete: any, key: any) => {
        console.log("key at service", key);
        setAppointmentID(appID);
        if (!logUserObject) {
            const existing: any = await PaymentServices().appointmentListByappID(appID);
            const user = existing[0];
            const dataSend = {
                action: "sendOTP",
                phoneNumber: user?.PhoneNumber,
                userId: user?.patientID,
                type: "appointment"
            }
            const options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text/plain, */*"
                },
                body: JSON.stringify(dataSend)
            }
            const APIresponse: any = await fetch(`${process.env.REACT_APP_FORGOTPASSWORD_API}`, options);
            const data = await APIresponse.json();
            console.log("data", data);
            if (data?.otp) {
                setLoading(false);
                // setconfirm("Delete");
                setSuccessMsg("OTP sent to your PhoneNumber");
                localStorage.setItem("trackOTP", data?.otp);
                localStorage.setItem("key", key);
                setModel(true);
                setIsTimerRunning(true);
                setTimer(60);
            }
        } else {
            await handleAppoinmentDelete(appID, key, PaymentMode)
        }
    }

    const OTPgenerate = async (formatEmail: any) => {
        const otp: any = Math.floor(100000 + Math.random() * 900000);
        console.log(otp, "otp")
        // console.log(typeof (otp), "type generated otp");
        // const encrypted = SHA256(otp.toString()).toString();
        // console.log("encrypted", encrypted);
        localStorage.setItem("appointmentOTP", otp);

        const message = `${otp} is Your OTP for account login/update. Do not share OTP with anyone for security purpose - CLUSTERIT.`;
        const subject = 'OTP for Appointment';
        const responseMsg = 'success'

        const dataSend = {
            email: formatEmail,
            message: message,
            subject: subject,
            responseMsg: responseMsg,
        };

        const options = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json, text/plain, */*",
            },
            body: JSON.stringify(dataSend),
        };

        try {
            const userResp = await fetch(`${process.env.REACT_APP_SEND_EMAIL_FUNCTION}`, options);
            const data = await userResp.json();
            console.log(data, "data");
            return userResp;

        } catch (error) {
            console.log(error);
        }
    }
    const handleUpdateCheck = async (values: any, logUserObject: any, setLoading: any, setSuccessMsg: any, setModel: any, setIsTimerRunning: any, setTimer: any, handleUpdate: any, { setSubmitting, resetForm }: any) => {

        if (!logUserObject) {
            const existing: any = await PaymentServices().appointmentListByappID(values?.id);
            console.log("existing", existing);
            const user = existing[0];
            // const getUser = await userService().getuserSingle(existing[0]?.PatientID);
            // console.log("getUser", getUser);
            const dataSend = {
                action: "sendOTP",
                phoneNumber: user?.PhoneNumber,
                userId: user?.patientID,
                type: 'appointment'
            }
            const options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json, text/plain, */*"
                },
                body: JSON.stringify(dataSend)
            }
            const APIresponse: any = await fetch(`${process.env.REACT_APP_FORGOTPASSWORD_API}`, options);
            const data = await APIresponse.json();
            console.log("data", data);
            if (data?.otp) {
                setLoading(false);
                setSuccessMsg("OTP sent to your PhoneNumber");
                localStorage.setItem("trackOTP", data?.otp);
                setModel(true);
                setIsTimerRunning(true);
                setTimer(60);
            }
        } else {
            await handleUpdate(values, { setSubmitting, resetForm })
        }
    }

    return {
        PaymentDetails,
        ListPayments,
        generateAppointmentID,
        encodeBase64,
        decodeBase64,
        updatePatientPayment,
        appointmentListByPhoneNumber,
        updateuserType,
        checkExistingUsers,
        appointmentListByappID,
        handleOTPSubmitService,
        handleOTPSubmitUpdate,
        handleOTPFormOpenService,
        OTPgenerate,
        handleUpdateCheck
    }
}
export default PaymentServices;