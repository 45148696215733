// import * as Yup from 'yup';

// export const validationSchema = Yup.object().shape({
//   // foodDiet: Yup.string().required('Food Diet is required'),
//   file: Yup.array().of(
//     Yup.object().shape({
//       type: Yup.string().required('File upload type is required'),
//       fileType: Yup.string().required('File type is required'),
//       name: Yup.array().min(1, 'At least one file must be uploaded').required('File is required'),
//       description: Yup.string().required('Description is required'),
//     })
//   ),
// });

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  file: Yup.array().of(
    Yup.lazy(value => {
      // Assuming 'type' field is always present
      const type = value?.type;

      return Yup.object().shape({
        type: Yup.string().required('File upload type is required'),
        fileType: Yup.string().required('File type is required'),
        name: type === 'upload'
          ? Yup.array().min(1, 'At least one file must be uploaded').required('File is required')
          : Yup.array().notRequired(),
        description: type === 'written'
          ? Yup.string().required('Description is required')
          : Yup.string().notRequired(),
      });
    })
  ),
});