import React, { useState } from 'react';
import AWS from 'aws-sdk';
import GalleryServices from '../../../Services/galleryServices';
import GalleryUtils from '../../../utills/galleryUploads';
import ReportServices from '../../../Services/reportservices';
import PrescriptionServices from '../../../Services/prescriptionServices';
import './style.css';
interface FileObject {
    fileType: string;
    name: string[];
    __typename?: string; // Optional since it will be removed
}

export function FileUpload(props: any): React.ReactElement {
    const [errMsg, setErrorMsg] = useState('');

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e?.target?.files?.[0];


        if (file) {

            let fExt = file.name.split('.').pop()?.toLowerCase() || "";


            if (['jpg', 'jpeg', 'png'].includes(fExt)) {

                // Check file size
                if (file.size <= 500 * 1024) {

                    props?.setFile(file)

                    props?.setDirectory("images");
                    setErrorMsg("");
                } else {
                    setErrorMsg('Image size should be less than 500KB.');
                }
            } else {
                setErrorMsg('Invalid file type. Please upload the correct file.');
            }
        } else {
            setErrorMsg('No file selected.');
        }
    };


    return (
        <>
            <div className="form-group">
                <label>{props?.label}:</label>
                <input type="file" className="inputFiled form-control text-secondary" name={props?.label} onChange={handleFileChange}

                />
                <p style={{ color: 'red' }}>{errMsg}</p>
            </div>
        </>
    );
}


// export function MultipleFileUpload(props: any): React.ReactElement {
//     console.log("props", props);
//     const [errMsg, setErrorMsg] = useState('');


//     const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         const AllFiles: any = e?.target?.files;
//         console.log("AllFiles", AllFiles);

//         if (AllFiles) {
//             const filesArray = Array.from(AllFiles);
//             console.log("filesArray", filesArray);
//             filesArray.forEach((file: any) => {
//                 const fExt: any = file?.name.split('.').pop().toLowerCase();
//                 console.log("fExt", fExt);
//                 if (['jpg', 'jpeg', 'png'].includes(fExt)) {
//                     // Check file size
//                     if (file?.size <= 500 * 1024) {
//                         const existingFiles = props?.InputDetails?.Files || [];
//                         const updatedFilesArray = [...existingFiles, ...filesArray];

//                         props?.setInputDetails((prevDetails: any) => ({
//                             ...prevDetails,
//                             Files: updatedFilesArray,
//                         }));
//                         if (props?.setFieldValue) {
//                             props?.setFieldValue('Files', updatedFilesArray);
//                         }
//                         props?.setDirectory("images");
//                         setErrorMsg("");
//                     } else {
//                         setErrorMsg('Image size should be less than 500KB.');
//                     }
//                 } else {
//                     setErrorMsg('Invalid file type. Please upload the correct file.');
//                 }
//             })
//         } else {
//             setErrorMsg('No file selected.');
//         }
//     };


//     return (
//         <div className="form-group mt-3">
//             <label>{props?.label}:</label>
//             <input type="file" className="inputFiled form-control text-secondary" name={props?.label} onChange={handleFileChange} multiple accept='image/*'

//             />
//             <p style={{ color: 'red' }}>{errMsg}</p>
//         </div>
//     );
// }


export function MultipleFileUpload(props: any): React.ReactElement {
    console.log("props", props);
    const [errMsg, setErrorMsg] = useState('');

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const AllFiles: any = e?.target?.files;

        if (AllFiles) {
            const filesArray = Array.from(AllFiles);
            filesArray.forEach((file: any) => {
                const fExt: any = file?.name.split('.').pop().toLowerCase();
                console.log("fExt", fExt);
                // if (props?.locationID && props?.InputDetails?.fileType !== props?.type) {
                //     if (props.type === 'image') {
                //         if (['jpg', 'jpeg', 'png', 'gif'].includes(fExt)) {
                //             // Check file size
                //             if (file?.size <= 500 * 1024) {
                //                 const existingFiles = props?.newState?.Files || [];
                //                 const updatedFilesArray = [...existingFiles, ...filesArray];

                //                 props?.setNewState((prevDetails: any) => ({
                //                     ...prevDetails,
                //                     Files: updatedFilesArray,
                //                 }));
                //                 if (props?.setFieldValue) {
                //                     props?.setFieldValue('Files', updatedFilesArray);
                //                 }
                //                 props?.setDirectory("images");
                //                 setErrorMsg("");
                //             } else {
                //                 setErrorMsg('Image size should be less than 500KB.');
                //             }
                //         } else {
                //             setErrorMsg('Invalid file type. Please upload an image file.');
                //         }
                //     } else if (props.type === 'video') {
                //         if (['mp4'].includes(fExt)) {
                //             const existingFiles = props?.newState?.Files || [];
                //             const updatedFilesArray = [...existingFiles, ...filesArray];

                //             props?.setNewState((prevDetails: any) => ({
                //                 ...prevDetails,
                //                 Files: updatedFilesArray,
                //             }));
                //             if (props?.setFieldValue) {
                //                 props?.setFieldValue('Files', updatedFilesArray);
                //             }
                //             props?.setDirectory("videos");
                //             setErrorMsg("");
                //         } else {
                //             setErrorMsg('Invalid file type. Please upload a video file.');
                //         }
                //     } else {
                //         if (['jpg', 'jpeg', 'png', 'gif'].includes(fExt)) {
                //             // Check file size
                //             if (file?.size <= 500 * 1024) {
                //                 const existingFiles = props?.newState?.Files || [];
                //                 const updatedFilesArray = [...existingFiles, ...filesArray];

                //                 props?.setNewState((prevDetails: any) => ({
                //                     ...prevDetails,
                //                     Files: updatedFilesArray,
                //                 }));
                //                 if (props?.setFieldValue) {
                //                     props?.setFieldValue('Files', updatedFilesArray);
                //                 }
                //                 props?.setDirectory("images");
                //                 setErrorMsg("");
                //             } else {
                //                 setErrorMsg('Image size should be less than 500KB.');
                //             }
                //         } else {
                //             setErrorMsg('Invalid file type. Please upload an image file.');
                //         }
                //     }

                // } else {
                if (props?.type === 'image') {
                    if (['jpg', 'jpeg', 'png', 'gif'].includes(fExt)) {
                        // Check file size
                        if (file?.size <= 500 * 1024) {
                            const existingFiles = Array.isArray(props?.InputDetails?.Files) ? props.InputDetails.Files : [];
                            const updatedFilesArray = props?.type === props?.InputDetails?.fileType
                                ? [...existingFiles, ...filesArray] // Append new files to existing ones if fileType matches
                                : [...filesArray]; // Otherwise, start fresh with the new files

                            if (props?.setFieldValue) {
                                props.setFieldValue('Files', updatedFilesArray);
                            }
                            props?.setDirectory("images");
                            setErrorMsg("");
                        } else {
                            setErrorMsg('Image size should be less than 500KB.');
                        }
                    } else {
                        setErrorMsg('Invalid file type. Please upload an image file.');
                    }
                } else if (props?.type === 'video') {
                    if (['mp4'].includes(fExt)) {
                        const existingFiles = Array.isArray(props?.InputDetails?.Files) ? props.InputDetails.Files : [];
                        const updatedFilesArray = props?.type === props?.InputDetails?.fileType
                            ? [...existingFiles, ...filesArray] // Append new files to existing ones if fileType matches
                            : [...filesArray]; // Otherwise, start fresh with the new files

                        if (props?.setFieldValue) {
                            props.setFieldValue('Files', updatedFilesArray);
                        }
                        props?.setDirectory("videos");
                        setErrorMsg("");
                    } else {
                        setErrorMsg('Invalid file type. Please upload a video file.');
                    }
                } else {
                    if (['jpg', 'jpeg', 'png', 'gif'].includes(fExt)) {
                        // Check file size
                        if (file?.size <= 500 * 1024) {
                            const existingFiles = Array.isArray(props?.InputDetails?.Files) ? props.InputDetails.Files : [];
                            const updatedFilesArray = props?.type === props?.InputDetails?.fileType
                                ? [...existingFiles, ...filesArray] // Append new files to existing ones if fileType matches
                                : [...filesArray]; // Otherwise, start fresh with the new files

                            if (props?.setFieldValue) {
                                props.setFieldValue('Files', updatedFilesArray);
                            }
                            props?.setDirectory("images");
                            setErrorMsg("");
                        } else {
                            setErrorMsg('Image size should be less than 500KB.');
                        }
                    } else {
                        setErrorMsg('Invalid file type. Please upload an image file.');
                    }
                }
                // }
            });
            // } else {
            //     setErrorMsg('Invalid file type. Please upload the correct file.');
            // }
            // })
        } else {
            setErrorMsg('No file selected.');
        }
    };
    const acceptType = props?.type === 'image' ? 'image/*' : 'video/mp4';

    return (
        <div className="form-group mt-3">
            <label>{props?.label}:</label>
            <input type="file" className="inputFiled form-control text-secondary" name={props?.label} onChange={handleFileChange} multiple accept={acceptType}

            />
            <p style={{ color: 'red' }}>{errMsg}</p>
        </div>
    );
}


export function ReportsFileUpload(props: any): React.ReactElement {
    
    const [errMsg, setErrorMsg] = useState('');

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const AllFiles: any = e?.target?.files;

        if (AllFiles) {
            const filesArray = Array.from(AllFiles);
            const updatedFilesArray = [...(props?.Files || []), ...filesArray];
            filesArray?.forEach((file: any) => {
                const fExt: any = file?.name.split('.').pop().toLowerCase();
                if (['jpg', 'jpeg', 'png', 'pdf'].includes(fExt)) {
                    
                    // Check file size
                    if (file?.size <= 500 * 1024) {
                        // const existingFiles = props?.InputDetails?.Files || [];
                        // const existingFiles = props?.Files || [];
                        // console.log("existingFiles", existingFiles);

                        // const updatedFilesArray = [...existingFiles, ...filesArray];

                        // props?.setInputDetails((prevDetails: any) => ({
                        //     ...prevDetails,
                        //     Files: updatedFilesArray,
                        // }));
                        props?.setFile(updatedFilesArray);
                        // props?.setDirectory("images");
                        setErrorMsg("");
                    } else {
                        setErrorMsg('Image size should be less than 500KB.');
                    }
                } else {
                    setErrorMsg('Invalid file type. Please upload the image/pdf file.');
                }
            })
        } else {
            setErrorMsg('No file selected.');
        }
    };


    return (
        <div className="form-group mt-3">
            <label>{props?.label}:</label>
            <input type="file" className="inputFiled form-control text-secondary inputbox" name={props?.label} onChange={handleFileChange} multiple accept='image/pdf'
            />
            <p style={{ color: 'red' }}>{errMsg}</p>
        </div>
    );
}

export async function SendFile(file: any, fileName: any, folderName: any) {
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_INSTANCE_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_INSTANCE_SECRET_KEY
    });

    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const params: any = {
        Bucket: S3_BUCKET,
        Key: `${folderName}/${fileName}`,
        Body: file,
    };

    try {

        const resp = await s3.putObject(params).promise().then(async (response) => {

                const uploadedFilename = await params.Key;
                console.log('Uploaded Filename:', uploadedFilename);

                return { status: 200, msg: 'Uploaded successfully', fileData: uploadedFilename };
            })
            .catch((error) => {
                console.error('Error uploading object:', error);
            });

        return resp;

    } catch (err) {
        console.error('Error uploading file:', err);
        return { status: 500, msg: 'Upload failed' };
    }
}

export async function updDelFile(fileName: any, folderName: any) {
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_INSTANCE_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_INSTANCE_SECRET_KEY
    });

    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const params: any = {
        Bucket: S3_BUCKET,
        Key: `${folderName}/${fileName}`,
    };

    console.log("params", params)

    try {
        const data = await s3.deleteObject(params).promise();
        console.log('File deleted successfully.', data);
        return { status: 200, msg: 'Deleted successfully' };
    } catch (err) {
        console.error('Error deleting file:', err);
        return { status: 500, msg: 'Deletion failed' };
    }
}


export const RemovePrevFile = async (fileName: any, fileIndex: number, InputDetails: any, setInputDetails: any, directory: any, setFieldValue: any, values: any) => {
    // console.log("directory", directory);
    // console.log("fileName", fileName);

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_INSTANCE_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_INSTANCE_SECRET_KEY,
        region: process.env.REACT_APP_REGION
    });
    const s3 = new AWS.S3();

    const params: any = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: `${directory}/${fileName}`
    };

    try {
        const resp = await s3.headObject(params).promise();
        // console.log("resp", resp);

        if (window.confirm('Are you sure you want to remove this file?')) {

            const fileresp = await updDelFile(fileName, directory);
            //         let getSingleUser: any, listtaxfiles: any;

            if (fileresp?.status === 200 && InputDetails?.Files?.length > 0) {
                const singleGallery = await GalleryServices().getSingleGallery(InputDetails?.id);


                if (singleGallery?.Files?.length > 0) {
                    // singleGallery?.Files?.forEach((file: any) => {

                    const updatedFiles = InputDetails?.Files?.filter((_: any, index: any) => index !== fileIndex);

                    const updateGalleryInput = {
                        id: InputDetails?.id,
                        ...InputDetails,
                        Files: updatedFiles
                    };

                    const update = await GalleryUtils().galleryUpdateUtilatfileremove(updateGalleryInput);
                    if (update) {
                        // if (file?.includes(fileName)) {
                        // if (file?.length > 1) {
                        setInputDetails((prevDetails: any) => ({
                            ...prevDetails,
                            // Files: prevDetails?.Files?.filter((_: any, index: any) => index !== fileIndex),
                            Files: updatedFiles
                        }));
                        if (setFieldValue) {
                            setFieldValue('Files', updatedFiles);
                        }
                    }
                    // }
                }
            }
        }
    } catch (error: any) {
        if (error.code === 'NotFound') {
            if (window.confirm('Are you sure you want to remove this file?')) {
                // setInputDetails((prevDetails: any) => ({
                //     ...prevDetails,
                //     Files: prevDetails?.Files?.filter((_: any, index: any) => index !== fileIndex),
                // }));
                const updatedFiles = values?.Files?.filter((_: any, index: any) => index !== fileIndex);
                if (setFieldValue) {
                    setFieldValue('Files', updatedFiles);
                }
            }
            // nonExistentFiles.push(fileName);
        } else {
            console.error("Error checking file existence:", error);
            throw error; // Re-throw the error if it's not a NotFound error
        }
    }
}

const getSingleItem = async (id: any, type: any) => {
    if (type === 'report') {
        return await ReportServices().getReportSingle(id);
    } else {
        return await PrescriptionServices().getPrescriptionSingle(id);
    }
};

const updateItem = async (data: any, type: any) => {
    if (type === 'report') {
        return await ReportServices().ReportUpdate(data);
    } else {
        return await PrescriptionServices().PrescrptiontUpdate(data);
    }
};

const removeTypename = (files: any[]): any[] => {
    return files?.map(({ __typename, ...rest }) => rest);
};

const removeTypenameObject = (data: any) => {
    const { __typename, createdAt, updatedAt, ...rest } = data;
    return rest;
};

export const handleRemoveFile = async (index: any, fileIndex: any, values: any, setFieldValue: any, fileName: any, type: any) => {

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_INSTANCE_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_INSTANCE_SECRET_KEY,
        region: process.env.REACT_APP_REGION
    });

    const s3 = new AWS.S3();
    const params: any = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: `images/${fileName?.name || fileName}`
    };

    console.log("params", params);

    try {
        const resp = await s3.headObject(params).promise();
        console.log("resp", resp);
        // if (window.confirm('Are you sure you want to remove this file?')) {
        const fileresp = await updDelFile(fileName, "images");
        if (fileresp?.status === 200 && values) {
            const report: any = await getSingleItem(values?.id, type);
            console.log("report", report);

            let updatedFiles: any = [];
            if (report?.file?.length > 0) {
                report?.file?.forEach((file: any) => {
                    let shouldUpdateFile = false;

                    if (file?.name?.includes(fileName)) {
                        if (file?.name?.length > 1) {
                            file.name = file?.name?.filter((name: string) => name !== fileName);
                            shouldUpdateFile = true;
                        }
                    } else {
                        shouldUpdateFile = true;
                    }

                    file?.name?.forEach((innerFile: any) => {
                        if (innerFile.name?.length <= 0) {
                            delete innerFile.FileType;
                            delete innerFile.name;
                            shouldUpdateFile = true;
                        }
                    });

                    if (shouldUpdateFile) {
                        updatedFiles.push(file);
                    }
                });

                console.log("updatedFiles", updatedFiles);

                const cleanedFiles = removeTypename(updatedFiles);
                console.log("cleanedFiles", cleanedFiles);

                const updateUser = {
                    id: report?.id,
                    ...report,
                    file: cleanedFiles
                };
                console.log("updateUser", updateUser);

                const cleanUserData = removeTypenameObject(updateUser);
                console.log("cleanUserData", cleanUserData);

                const updateResponse1 = await updateItem(cleanUserData, type);
                console.log("updateResponse", updateResponse1);

                if (updateResponse1) {
                    const newFileUploads = await updateResponse1?.data?.updateReports?.file?.map((upload: any) => {
                        console.log("upload", upload);
                        if (upload?.name?.includes(fileName)) {
                            return {
                                ...upload,
                                fileType: upload?.fileType,
                                name: upload.name?.filter((file: any) => file !== fileName)
                            };
                        }
                        return upload;
                    });
                    console.log("newFileUploads", newFileUploads);
                    const updatedFiles = values?.file[index].name.filter((_: any, idx: any) => idx !== fileIndex);
                    setFieldValue(`file[${index}].name`, updatedFiles);
                }
            }
        }
        // }
    } catch (error: any) {
        if (error.code === 'NotFound') {
            if (window.confirm('Are you sure you want to remove this file?')) {
                const updatedFiles = values?.file[index].name.filter((_: any, idx: any) => idx !== fileIndex);
                setFieldValue(`file[${index}].name`, updatedFiles);
            }
        } else {
            console.error("Error checking file existence:", error);
            throw error; // Re-throw the error if it's not a NotFound error
        }
    }
};

export const handleRemoveFileUpload = async (index: any, values: any, setFieldValue: any) => {
    const fileItems = values?.file[index]?.name;
    const fileType = values?.file[index]?.fileType;
    const reportresp = values?.type === "reports" ? await ReportServices().reportsByappIDFileType(values?.appointmentID, fileType) : await PrescriptionServices().prescriptionByappIDFileType(values?.appointmentID, fileType);
    const deletePromises: any = reportresp?.map(async (item: any) => {
        const updatedFiles = item.file?.filter((f: any) => f?.fileType !== fileType);
        console.log("updatedFiles", updatedFiles);

        // if (updatedFiles?.length === 0) {
        //     // If no file objects are left, delete the entire record
        //     try {
        //         const deleteResp = await ReportServices().ReportDelete(item?.id);
        //         return deleteResp;
        //     } catch (error) {
        //         console.error("Error deleting report:", error);
        //         throw error;
        //     }
        // } else 
        if (updatedFiles.length !== item?.file?.length) {
            // If the file array is not empty, update the record with the filtered file array
            const updatedItem = {
                ...item,
                id: item?.id,
                file: updatedFiles
            };

            try {
                const updateResp = values?.type === "reports" ? await ReportServices().ReportUpdate(updatedItem) : await PrescriptionServices().PrescrptiontUpdate(updatedItem);
                return updateResp;
            } catch (error) {
                console.error("Error updating report:", error);
                throw error;
            }
        }
    });
    await Promise.all(deletePromises);
    // console.log("fileType", fileType);
    if (fileItems?.length > 0) {
        if (window.confirm('Are you sure you want to remove this file object?')) {
        for (const fileName of fileItems) {
            try {
            
                    AWS.config.update({
                        accessKeyId: process.env.REACT_APP_INSTANCE_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_INSTANCE_SECRET_KEY,
                        region: process.env.REACT_APP_REGION
                    });
                    const s3 = new AWS.S3();

                    // Define your S3 delete parameters
                    const params: any = {
                        Bucket: process.env.REACT_APP_S3_BUCKET,
                        Key: `images/${fileName}`
                    };

                    // Check if the file exists in S3
                    const resp = await s3.headObject(params).promise();
                    if (resp) {
                        // File exists, so delete it from S3
                        const fileresp = await updDelFile(fileName, "images");
                        if (fileresp?.status === 200) {
                            // deletePromises();
                            await Promise.all(deletePromises);
                            setFieldValue('file', values?.file?.filter((_: any, i: any) => i !== index));
                        }
                    }
                // }
            } catch (error: any) {
                if (error.code === 'NotFound') {
                    setFieldValue('file', values?.file?.filter((_: any, i: any) => i !== index));
                } else {
                    console.error("Error deleting file:", error);
                }
            }
        }
    }
    } else if (fileItems?.length === 0) {
        // deletePromises();
        await Promise.all(deletePromises);
        setFieldValue('file', values?.file?.filter((_: any, i: any) => i !== index));
    } else {
        setFieldValue('file', values?.file?.filter((_: any, i: any) => i !== index));
    }

};


export const RemoveProfileforAll = async (fileName: string, setInputDetails: any, key: string, directory: string) => {
    try {
        console.log("setFile", setInputDetails);
        const delFiles = await updDelFile(fileName, directory);
        console.log("delFiles", delFiles);

        if (delFiles?.status === 200) {
            setInputDetails((prevDetails: any) => {
                const updatedDetails = { ...prevDetails };

                // Check if the key's value is an array
                if (Array.isArray(prevDetails[key])) {
                    // Remove the file from the array
                    updatedDetails[key] = prevDetails[key].filter((item: string) => item !== fileName);
                } else {
                    // If it's a string, just set it to an empty string
                    updatedDetails[key] = null;
                }

                return updatedDetails;
            });
        }
    } catch (error) {
        console.error("Error removing file:", error);
        alert("Failed to remove file");
    }
};