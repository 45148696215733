import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import userService from "../../Services/user/user.service";
import { Helmet } from 'react-helmet';
import Loader from "../shared/Loader";
import LazyLoad from "react-lazyload";

interface compProps {
    title: string;
    description: string;
}


const SingleDoctor: React.FC<compProps> = ({ title, description }) => {

    //     const location = useLocation();
    //   const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const navigate = useNavigate();
    const [singleDoctor, setSingleDoctor] = useState<any>();
    const [doctorList, setDoctorList] = useState<any>([]);
    const { slug } = useParams();
    const [loading, setLoading] = useState(true);
    const [Doctors, setActiveDoctors] = useState<any>([]);


    async function ProvInfo() {
        setLoading(true);
        const singleDoctor: any = (await userService().ListDoctor("Doctor")).find((doctor: any) => doctor?.Slug === slug);
        const specializationtList = await userService().getlistSpecializations() as any;
        const specName = specializationtList?.find((spec: any) => spec?.id === singleDoctor?.Specialization);
        if (specName?.name) {
            singleDoctor.Specialization = specName?.name;
        }
        // const userSingle: any = singleDoctor?.data?.getUser;
        setSingleDoctor(singleDoctor);
        setLoading(false);
        // const response: any = await Homeservices().FooterservicesList();
        // const sortedServices = response.sort((a: any, b: any) => {
        //   const dateA = new Date(a.updatedAt || a.createdAt).getTime();
        //   const dateB = new Date(b.updatedAt || b.createdAt).getTime();
        //   return dateB - dateA; // Sort in descending order
        // });
        // const latestServices = sortedServices.slice(0, 6);
        // setServiceItems(latestServices);
    }


    useEffect(() => {
        ProvInfo();
    }, [slug])



    useEffect(() => {
        async function fetchDoctors() {
            const response: any = await userService().ActiveDoctors('Doctor');
            setActiveDoctors(response);
            const specializationtList = await userService().getlistSpecializations() as any;
            response?.map(async (item: any, index: number) => {
                const specName = specializationtList?.find((spec: any) => spec?.id === item?.Specialization);
                item.Specialization = specName?.name
            })
            setDoctorList(response);
        }
        fetchDoctors();
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // const bgImageStyle = {
    //     backgroundImage: `url(${process.env.REACT_APP_HOST}images/bg_1.jpg)`
    // };
    const bgImageStyle = {
        backgroundImage: `url(${process.env.REACT_APP_HOST}images/doctorImage.jpg`
    };


    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <><Helmet>
                    {/* <title> Dr. {singleDoctor?.FirstName}</title> */}
                    <title>{`Dr. ${singleDoctor?.FirstName || ''}`}</title>
                    <meta name="description" content={description} />
                </Helmet><section className="home-slider owl-carousel">
                        <LazyLoad>
                            <div
                                className="slider-item bread-item"
                                style={bgImageStyle}
                                data-stellar-background-ratio="0.5"
                            >
                                <div className="overlay"></div>
                                <div className="container" data-scrollax-parent="true">
                                    <div className="row banner-text align-items-center">
                                        <div className="col-md-12 col-sm-12 ftco-animate">
                                            <p className="breadcrumbs">
                                            </p><br />
                                            <h2 style={{ textAlign: 'center', marginBottom: '100px' }}>
                                                Dr. {singleDoctor?.FirstName}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LazyLoad>
                    </section><div>

                        <ul className="breadCrumb">
                            <li>
                                <a role="button" className="aColor" onClick={() => navigate('/')}>Home /</a>
                            </li>
                            <li>
                                <a role="button" className="aColor" onClick={() => navigate('/Doctors')}>Doctors /</a>
                            </li>
                            <li className="nocursor">Dr. {singleDoctor?.FirstName}</li>
                        </ul>

                        {/* <ul className="title-menu clearfix" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px' }}>
        <li>
            <a onClick={() => navigate("/")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Home  /&nbsp;</a>
        </li>
        <li>
            <a onClick={() => navigate("/doctor")} href="javascript:void(0)" className="aColor" style={{ textDecoration: 'none' }}>Doctors  /&nbsp;</a>
        </li>
    </ul> */}
                    </div><section className="ftco-section">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-7 text-center heading-section ftco-animate">
                                    <h2>{singleDoctor?.firstName}</h2>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <div>
                                        <h3 className="serviceTitles heading-section ftco-animate fontname">{singleDoctor?.FirstName + " " + singleDoctor?.LastName}</h3>
                                    </div>

                                    {/* <div className="icon d-flex  align-items-center"> */}
                                    <div className="icon">
                                        <LazyLoad>
                                            <img className="mediaqueriimagesize"
                                                src={`${singleDoctor?.userProfile !== null ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${singleDoctor?.userProfile}` : `${process.env.REACT_APP_HOST}images/profile.jpg`}`}
                                                alt={singleDoctor?.firstName}
                                                width="53%"
                                                style={{ marginBottom: '20px', maxWidth: '350px', maxHeight: '300px' }} />
                                        </LazyLoad>
                                    </div>
                                    <h4 className="splzfont">{singleDoctor?.Specialization}</h4>
                                    <div className="d-flex align-self-stretch ftco-animate">
                                        <div className="media block-6 services d-block">
                                            <div className="media-body mt-3">
                                                {/* <p className="media-body1" style={{ textAlign: 'justify' }}>
        {removeHtmlTags(singleDoctor?.Description)}
    </p> */}
                                                <span className="blog-content" dangerouslySetInnerHTML={{ __html: singleDoctor?.Description }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1">
                                </div>
                                <div className="col-md-3 categories-container" style={{ marginTop: '50px' }}>
                                    <h4>Doctors</h4>
                                    {doctorList?.map((item: any) => {

                                        return (
                                            <div className="d-flex align-self-stretch" key={item?.id || item?.firstName}>
                                                <div className="media block-6 services d-block text-center">
                                                    <div className="media-body categories">
                                                        <li className="listyle" style={{ backgroundColor: singleDoctor === item ? '#2cbcbc' : 'transparent', color: singleDoctor === item ? 'white' : 'black', cursor: 'pointer' }}
                                                            onClick={() => { setSingleDoctor(item); window.scrollTo(0, 0); }}><span className="serviceTitle capitalTxt">{item?.FirstName?.toLowerCase()}- {item?.Specialization?.toLowerCase()}</span></li>
                                                        {/* <span className="heading serviceTitle">{item?.Title}</span> */}
                                                    </div>
                                                </div>
                                            </div>

                                        );
                                    })}
                                </div>
                            </div>

                        </div>

                    </section></>
            )}
        </>
    )
}
export default SingleDoctor;



