
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './style.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { validationSchema } from './validationSchema';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from '../shared/fileupload';
import { useLocation, useNavigate } from 'react-router-dom';
import CareerComponent from './createCategory';
import getCareer from '../../Services/Careers';
import CKEditorComponent from '../../utills/CKEditor';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { FormatFile } from '../../utills/Fileformat';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';

// import * as Yup from 'yup';



const CreateCarrer: React.FC = () => {
    const location = useLocation();

    const initialValues = {
        category: '',
        title: '',
        description: '',
        careerImage: [],
        Slug: '',

    };

    const navigate = useNavigate();

    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    const [InputDetails, setInputDetails] = useState(initialValues);
    const [updateCarrer, setUpdateCarrer] = useState<boolean>(false);
    const [ErrMsgSlug, setErrMsgSlug] = useState("");
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const[errrmsg,setErrrmsg]= useState<any>('')
    // useAuthRedirect(logUserObject);
    const formRef : any = useRef(null); // Create a ref for the form
    const [hasErrors, setHasErrors] = useState(false); // State to track errors

        // // Create refs for specific fields
        // const titleRef = useRef<HTMLDivElement | null>(null);
        // const slugRef = useRef<HTMLDivElement | null>(null);
        // const descriptionRef = useRef<HTMLDivElement | null>(null);
        // const categoryRef = useRef<HTMLDivElement | null>(null);

        const fieldRefs: any = useRef({
            category: null as HTMLDivElement | null,
            title: null as HTMLDivElement | null,
            Slug: null as HTMLDivElement | null,
            description: null as HTMLDivElement | null            
        });


        

    async function provInfo() {
        const service = await getCareer().getSingleCareer(location?.state?.id);
        setInputDetails(service);
        setUpdateCarrer(true);
    }

    useEffect(() => {
        provInfo();
    }, [location?.state]);

    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.careerImage[0], setInputDetails, 'careerImage', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };

    const handleSubmit = async (values: any, { resetForm }: any) => {

        

        window.scrollTo(0, 0);
        try {
            const generateSlug = (name: any) => name.replace(/\s+/g, '_').toLowerCase();
            const slug = generateSlug(values?.Slug)
            let slugError = false;
            if (values?.id) {
                const SlugDetails = await getCareer().validateSlug(slug);

                if (SlugDetails?.msg) {
                    SlugDetails?.data?.map((item: any) => {
                        if (item?.id !== values?.id) {
                            slugError = true;
                        }
                    });
                    if (slugError) {
                        setErrMsgSlug("Slug is already in use");
                        return;
                    } else {
                        setErrMsgSlug("");
                    }
                }
            } else {
                const SlugDetails = await getCareer().validateSlug(slug);
                if (SlugDetails?.msg) {
                    setErrMsgSlug("Slug is already in use");
                    return;
                }
            }

            // let newFilename: any;
            // if (file?.name) {
                // fileName = Date.now() + "_" + file?.name;
                // const updatedFileName = file?.name?.replace(/ /g, '_');
                // fileName = Date.now() + "_" + updatedFileName;
                // const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
                // newFilename = replaceSpecialChars(filename);
                const  newFilename = await FormatFile(file)
                console.log("newFilename",newFilename)
                if (values?.id) {
                    file?.name && await updDelFile(values?.careerImage, "images");
                }
                file?.name &&  await SendFile(file, newFilename, "images");
            // }
            const CareerData = {
                id: values?.id,
                title: values?.title,
                category: values?.category,
                description: values?.description,
                careerImage: file?.name ? newFilename : values?.careerImage,
                userID: logUserObject?.userId,
                Slug: slug,
                status: "1",
            };
            const CarrerResponse = values?.id
                ? await getCareer().CareerUpdate(CareerData)
                : await getCareer().CreateCareer(CareerData);

            if (CarrerResponse) {
                navigate('/listCareer');
            }
        } catch (error) {
            console.error('Error submitting Career:', error);
            throw new Error('Failed to submit Career');
        }
    };
    console.log('errrmsg',errrmsg)
    // Use effect to scroll when errrmsg changes to true
    // useEffect(() => {
    //     if (errrmsg) {
    //         window.scrollTo(0, 0);
    //         setErrrmsg(false)
    //     }
    // }, [errrmsg === true]);

    return (
        <>
         <Helmet>
                <title>Create Career</title>
                <meta name="description" content="This is the Career page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                            <h1 className="section-title">{!location?.state?.id ? "Create Career" : "Update Career"}</h1>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                    <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li> <span className="section-title">{!location?.state?.id ? "Create Career" : "Update Career"}</span>
                    </li>
                </ul>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <Formik
                            initialValues={location?.state?.id ? InputDetails : initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            
                            enableReinitialize
                        >
                            {({ isSubmitting, setFieldValue, values, errors }) => { 
                                const errorCount = Object.keys(errors).length;
                                console.log({errorCount})
                                
                                if (isSubmitting && errorCount > 0) {

                                    for (const key in errors) {
                                        console.log({key})
                                        if (fieldRefs.current[key]) {
                                            fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                            break; // Stop at the first error we find
                                        }
                                    }

                                    // if (errors?.category) {
                                    //     categoryRef.current?.scrollIntoView({ behavior: 'smooth' });
                                    // } else if (errors?.title) {
                                    //     titleRef.current?.scrollIntoView({ behavior: 'smooth' });
                                    // } else if (errors?.Slug) {
                                    //     slugRef.current?.scrollIntoView({ behavior: 'smooth' });
                                    // }else if (errors?.description){
                                    //     descriptionRef.current?.scrollIntoView({ behavior: 'smooth' });
                                    // }
                                }

                                return (
                                <Form className="blog-form" autoComplete='off'>
                                    {/* <h3 className="section-title">{!location?.state?.id ? "Create Career" : "Update Career"}</h3> */}

                                    <div className="input-container" ref={(el) => (fieldRefs.current.category = el)}>
                                        <CareerComponent
                                            InputDetails={InputDetails}
                                            setInputDetails={setInputDetails}
                                            setFieldValue={setFieldValue}
                                            editValue={values.category}

                                        />
                                        <ErrorMessage name="category" component="div" className="error-message" />
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-container"  ref={(el) => (fieldRefs.current.title = el)}>
                                            <label htmlFor="title">Career Title:*</label>
                                            <Field
                                                type="text"
                                                id="title"
                                                name="title"
                                                value={values?.title}
                                                onChange={(e: any) => setFieldValue("title", e?.target?.value)}
                                            />
                                            <ErrorMessage name="title" component="div" className="error-message" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-container" ref={(el) => (fieldRefs.current.Slug = el)}>
                                            <label htmlFor="Slug">Slug Name:*</label>
                                            <Field
                                                type="text"
                                                id="Slug"
                                                name="Slug"
                                                value={values?.Slug}
                                                onChange={(e: any) => setFieldValue("Slug", e?.target?.value)}
                                                disabled={location?.state?.id ? true : false}
                                            />
                                            <p style={{ color: 'red' }}>{ErrMsgSlug ? ErrMsgSlug : ''}</p>
                                            <ErrorMessage name="Slug" component="div" className="error-message" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-container" ref={(el) => (fieldRefs.current.description = el)}>
                                            <label htmlFor="description">Career Description:*</label>
                                            <Field name="description">
                                                {({ field, form }: any) => (
                                                    <CKEditorComponent
                                                        data={field.value}
                                                        onDataChange={(newContent: string) => setFieldValue('description', newContent)}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name="description" component="div" className="error-message" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-container">
                                            {InputDetails?.careerImage?.length > 0 && updateCarrer ? (
                                                <>
                                                    <img
                                                        src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.careerImage}`}
                                                        alt="blogImage"
                                                        style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }}
                                                    />
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                        <p className="fileNameMobCareers"  style={{ margin: 0 , fontSize: '14px' }}>
                                                            <strong>FileName:</strong>{InputDetails?.careerImage}
                                                        </p>
                                                        <span
                                                            style={{ cursor: 'pointer', color: 'red', fontSize: '14px' }}
                                                            onClick={handleRemove}
                                                        >
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </span>
                                                    </div>
                                                </>
                                            ) : (
                                                <strong>No Profile Photo</strong>
                                            )}
                                            <FileUpload
                                                label={location?.state?.id ? "Update image" : "Choose image"}
                                                setDirectory={setDirectory}
                                                setFile={setFile}
                                            />
                                            <ErrorMessage name="blogImage" component="div" className="error-message" />
                                        </div>
                                    </div>

                                    <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                        {!location?.state?.id ? "Create Career" : "Update Career"}
                                    </button>
                                </Form>
                            )}}
                        </Formik>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </>
    );
};

export default CreateCarrer;