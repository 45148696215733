import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { contactValidationSchema } from './contactValidationSchema';
import './ContactForm.css'; // Import the CSS file
import ContactUtils from '../../utills/users/Contact';
import pages from '../../utills/pageUtils';
import { Helmet } from 'react-helmet';
import Loader from '../shared/Loader';
import { Alert } from 'react-bootstrap';

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

interface Contact {
  Name: string;
  emailID: string;
  phoneNumber: string;
  Message: string;
}

const initialValues: Contact = {
  Name: '',
  emailID: '',
  phoneNumber: '',
  Message: ''
};

const ContactForm: React.FC<compProps> = ({ title, description, keywords }) => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [pageInfo, setPageInfo] = useState<any>();
  const [bannerName, setBannerName] = useState<any>();
  const [caption, setCaption] = useState<any>();
  
  const { slug } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [alertMsg, setAlertMsg] = useState<any>('');
  
  const formRef = useRef<HTMLDivElement | null>(null); // Create ref for the form container
  const fieldRefs: any = useRef({
    Name: null as HTMLDivElement | null,
    emailID: null as HTMLDivElement | null,
    phoneNumber: null as HTMLDivElement | null,
    Message: null as HTMLDivElement | null
});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // Simulate fetching data
      const result = await pages(location?.pathname);
      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title);
        setCaption(item?.Description);
      });
    };
    fetchData();
    setLoading(false);
  }, [location?.pathname]);

  const handleSubmit = async (values: Contact, { setSubmitting, resetForm }: { setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void }) => {

    try {
      setLoading(true)  
      const submit = await ContactUtils().handleSubmit(values);
      if (submit) {
        window.scrollTo(0, 0);
        setAlertMsg("Contact form submitted successfully");
        // setSuccessMessage("Your Feedback is Sent");
        // setTimeout(() => {
        //   setSuccessMessage("");
        // }, 5000);
        resetForm(); // Reset the form
        return { data: submit, status: 200 }; // Optionally return data and status
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setLoading(false)
    } finally {
      setSubmitting(false);
      setLoading(false) // Ensure setSubmitting is called
       window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const scrollToTopOnErrors = (isValid: boolean) => {
    if (!isValid && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    window.scroll(0,0)
  },[location.pathname])

  return (
    <>     
        
          <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta name="author" content="Gurram Dental Care" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>
          <section className="home-slider owl-carousel">
            <div
              className="slider-item bread-item"
              style={{
                backgroundImage: pageInfo
                  ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})`
                  : 'images/bg_1.jpg'
              }}
              data-stellar-background-ratio="0.5"
            >
              <div className="overlay"></div>
              <div className="container" data-scrollax-parent="true">
                <div className="row banner-text align-items-center">
                  <div className="col-md-12 col-sm-12 ftco-animate">
                    <h1 className="banner-ttl">{bannerName}</h1>
                    <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div>
            <ul className="ullist">
              <li>
                <a className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                  Home /&nbsp;
                </a>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div>

          {
            alertMsg &&
            <div className='alertMessage'>
              <Alert variant="success"  onClick={() => setAlertMsg('')} dismissible>
                {alertMsg}
              </Alert>
            </div>
          }

          {/* contact section */}

          {loading ? (
        <Loader />
      ) : (

          <section className="ftco-section contact-section ftco-degree-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-6 contact-info-box">
                  <h1 className="h4">Contact Us</h1>
                  <p>
                    Gurram Dental Care, Zp Centre,
                    <br /> Jammibanda Road, Khammam - 507002
                    <br />
                    Telangana - India{' '}
                  </p>
                  <p>
                    <span>Phone:</span>{' '}
                    <a href="tel:+91 9949533357">+91 99495 33357</a>
                  </p>
                  <p>
                    <span>Email:</span>{' '}
                    <a href="mailto:contactus@gurramdentalcare.com">
                      contactus@gurramdentalcare.com
                    </a>
                  </p>
                </div>
                <div className="col-md-6">
                  {successMessage && (
                    <div className="text-success mt-2">{successMessage}</div>
                  )}
                  <div className="contact-form-box">
                    <Formik autoComplete="off"
                      initialValues={initialValues}
                      validationSchema={contactValidationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ isSubmitting, isValid ,errors}) => {
                        scrollToTopOnErrors(isValid);
                        
                        const errorCount = Object.keys(errors).length;
                        console.log({ errorCount })

                        if (isSubmitting && errorCount > 0) {

                            for (const key in errors) {
                                console.log({ key })
                                if (fieldRefs.current[key]) {
                                    fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                    break; // Stop at the first error we find
                                }
                            }


                        }

                        return (
                          <Form autoComplete="off">
                            <div className="form-group" ref={(el) => (fieldRefs.current.Name = el)}>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="Name"
                              />
                              <ErrorMessage
                                name="Name"
                                component="div"
                                className="error-message"
                              />
                            </div>
                            <div className="form-group"  ref={(el) => (fieldRefs.current.emailID = el)}>
                              <Field 
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                name="emailID"
                              />
                              <ErrorMessage
                                name="emailID"
                                component="div"
                                className="error-message"
                              />
                            </div>
                            <div className="form-group"   ref={(el) => (fieldRefs.current.phoneNumber = el)}>
                              <Field
                                type="tel"
                                className="form-control"
                                placeholder="Phone Number"
                                name="phoneNumber"
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="error-message"
                              />
                            </div>
                            <div className="form-group"  ref={(el) => (fieldRefs.current.Message = el)}>
                              <Field
                                as="textarea"
                                name="Message"
                                id="Message"
                                cols={30}
                                rows={7}
                                className="form-control"
                                placeholder="Message"
                              />
                              <ErrorMessage
                                name="Message"
                                component="div"
                                className="error-message"
                              />
                            </div>
                            <div className="form-group">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-sm py-2 px-2"
                              >
                                Send Message
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
              <div className="row block-9">
                <div className="col-md-12 map-container">
                  <iframe
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{ border: '0' }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3810.4191074251257!2d80.14908347493137!3d17.246947083616448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3459d04c091ad9%3A0x52d278ac655c5427!2sGurram%20Dental%20Care!5e0!3m2!1sen!2sin!4v1719056762127!5m2!1sen!2sin"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
          )}
        
      
    </>
  );
};

export default ContactForm;
