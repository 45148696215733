import Papa from 'papaparse';
import BulkServices from '../../Services/bulkuploadservices';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { listUsers } from '../../../graphql/queries';


const BulkUploadUtils: any = () => {

    const handleFileChange: any = (event: any, setCSVData: any, setErrFile: any) => {
        const file = event?.target?.files?.[0];
        const getPopVal = file?.name.split('.').pop();
        if (file && getPopVal === 'csv') {
            setErrFile("")
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e?.target?.result as string;
                Papa.parse(content, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (result: any) => {
                        setCSVData(result?.data);
                    },
                    error: (error: any) => {
                        console.error('CSV parsing error:', error.message);
                    },
                });
            };

            reader.readAsText(file);
        } else {
            setErrFile("Upload only csv file")
        }
    };

    const updateBulkuser = async (data: any) => {
        const updateUser = await BulkServices().BulkUserUpdate(data);
        return updateUser
    }

    const createBulkUser = async (data: any) => {

        const createUser = await BulkServices().createBulkUser(data);
        return createUser;
    }

    return {
        handleFileChange,
        createBulkUser,
        updateBulkuser
    }

}

export default BulkUploadUtils;