import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { createReview, deleteReview, updateReview } from '../../../graphql/mutations';
import { getReview, listReviews } from '../../../graphql/queries';


const ReviewServices = () => {

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }
    const ReviewCreate = async (RatingData: any) => {
        try {
            const result = await API.graphql(graphqlOperation(createReview, { input: RatingData })) as any;
            return result?.data?.createReview;
        } catch (error) {
            console.error('Error creating creatingReview:', error);
            return null;
        }
    }
    const reviewList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listReviews, filter)) as any;
                const specializations = result?.data?.listReviews?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listReviews?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listReviews:', error);
            return [];
        }
    }
    const ActivereviewList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];
            do {
                const filter = {
                    filter: {
                        Status: { eq: "1" }
                    },
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listReviews, filter)) as any;
                const specializations = result?.data?.listReviews?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listReviews?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching listReviews:', error);
            return [];
        }
    }
    const reviewUpdate = async (data: any) => {

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updateReview, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateReview;
        } catch (error) {
            console.error('Error updating updateReview:', error);
            return null;
        }
    }
    const getreviewSingle = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getReview, { id: id })) as any;
            return result?.data?.getReview;
        } catch (error) {
            console.error('Error in getReview :', error);
            return null;
        }
    }
    const reviewdelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteReview, { input: { id: id } })) as any;
            return result?.data?.deleteReview;
        } catch (error) {
            console.error('Error in deleteReview:', error);
            return null;
        }
    }
    
    return {
        ReviewCreate,
        reviewList,
        reviewUpdate,
        getreviewSingle,
        reviewdelete,
        ActivereviewList
    }
}
export default ReviewServices;