import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import userService from '../../Services/user/user.service';
// import SearchIcon from '@mui/icons-material/Search';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import { Helmet } from 'react-helmet';
import './style.css';
import Users from '../../utills/users';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';


interface Doctor {
  id: string;
  FirstName: string;
  LastName: string;
}

interface Patient {
  id: string;
  PatientName: string;
  // patientID: string;
  PhoneNumber: string;
  DoctorID: string;
  EmailId: string;
  AppointmentDate: string;
  // PaymentMode: string;
  Amount: string;
  AppointmentID: string;
  userType: string;
}

const AppoinementList = () => {

  const initialState: Patient = {
    id: '',
    PatientName: '',
    // patientID: '',
    PhoneNumber: '',
    DoctorID: '',
    EmailId: '',
    AppointmentDate: '',
    // PaymentMode: '',
    Amount: '',
    AppointmentID: '',
    userType: ''
  };

  const navigate = useNavigate();
  const [data, setData] = useState<Patient>(initialState);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [patientList, setPatientList] = useState<Patient[]>([]);
  const [doctorList, setDoctorList] = useState<Doctor[]>([]);
  // const [logUserObject, setLogUserObject] = useState<any>(null);

  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);

  // let logUserObject: any;

  const mergeAppointments = (appointments: any[]) => {
    const mergedMap = new Map();
    appointments?.forEach((appointment) => {
      if (!mergedMap.has(appointment.PhoneNumber)) {
        // Initialize the map entry with the current appointment
        mergedMap.set(appointment.PhoneNumber, {
          ...appointment,
          appointments: [appointment]
        });
      } else {
        // Merge the current appointment with the existing one
        const existingEntry = mergedMap.get(appointment?.PhoneNumber);
        existingEntry.appointments.push(appointment);
        mergedMap.set(appointment?.PhoneNumber, existingEntry);
      }
    });

    return Array.from(mergedMap.values())?.map((entry) => {
      // Combine the appointment details as needed
      return {
        ...entry,
        appointments: entry?.appointments
      };
    });
  };
  const getPatients = async () => {
    let response: any
    if (logUserObject?.Role === "Doctor") {
      response = await userService().ListPatientAppointments(logUserObject?.userId);
    } else if (logUserObject?.Role === "MasterAdmin" || logUserObject?.Role === "Lab" || logUserObject?.Role === "Pharmacy") {
      response = await userService().ListPatientAppointments("");
    }
    else {
      const patientList = await userService().ListPatientAppointments("");
      response = patientList?.filter((user: any) => user?.patientID === logUserObject?.userId)
    }

    const sortedAppointments = response.sort((a: any, b: any) => {
      const dateA = new Date(a.updatedAt || a.createdAt).getTime();
      const dateB = new Date(b.updatedAt || b.createdAt).getTime();
      return dateB - dateA; // Sort in descending order
    });
    const uniquePatientList = mergeAppointments(sortedAppointments);
    setPatientList(uniquePatientList);
  };




  const getDoctors = async () => {
    const response = await userService().ListDoctor('Doctor');
    setDoctorList(response);

    // const logUser: any = await localStorage.getItem('userData');
    //  logUserObject = JSON.parse(logUser);

    // to manage the userSession data
    // const userData = await Users().userSessionData();
    // userData && setLogUserObject(userData);
  };

  useEffect(() => {
    getPatients();
    getDoctors();
  }, []);

  const handleDelete = async (id: string) => {
    const confirmBox = window.confirm("Are you sure you want to delete the selected patient?");
    if (confirmBox) {
      await userService().Deletepatient(id);
      getPatients();
    } else {
      alert('Deletion canceled.');
    }
  };
  const handleUpload = async (row: any) => {
    navigate("/addReports", { state: { appointmentId: row?.id, patientID: row?.patientID } });
  };

  const handleHistory = async (row: any, index: any) => {
    navigate("/ViewAppointment", { state: { patientID: row?.patientID, PhoneNumber: row?.PhoneNumber, patientList: patientList, index: index } })
  }

  const highlightText = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text?.split(regex);
    return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
  };

  const getDoctorNameById = (doctorId: string) => {
    const doctor = doctorList?.find(doc => doc?.id === doctorId);
    return doctor ? `${doctor?.FirstName} ${doctor?.LastName}` : 'Unknown Doctor';
  };

  const filteredData: any = patientList?.filter((row: Patient) =>
    Object?.values(row)?.some((value: any) => value?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredData?.length / pageSize);
  const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleSearch = () => {
    if (!searchTerm) {
      setData(initialState);
    } else {
      setData(filteredData);
    }
    setCurrentPage(1);
  };
  const handleSubmit = (setUpdate: boolean) => {
    navigate('/PatientAppointment', { state: { setUpdate: false } });
  }
  useEffect(() => {
    setSearchTerm('');
  }, [currentPage]);

  const handleEdit = (row: Patient) => {
    navigate("/PatientAppointment", { state: { patientId: row.id } });
  }





  return (

    <div>
      <Helmet>
        <title>Appointments</title>
        <meta name="description" content="This is the Appointments page description" />
      </Helmet>
      <section className="admin-section">
        <div
          className="slider-item bread-item"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h1>
                  Appointments
                </h1>

              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <ul className="paginationbanner">
          <li>
            <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
          </li>
          <li>Appointments</li>
        </ul>
      </div>

      <div className="container">

        <div className="row tablefs">
          {/* <div className="col-md-2" style={{ marginTop: '50px' }}>
            <Sidebar />
          </div> */}
          <div className="col-md-12">
            <div>
              <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="page-size-dropdown">
                  <label style={{ padding: '5px' }}>Show</label>
                  <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label style={{ padding: '5px' }}>entries</label>
                </div>
                <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                  {/* {logUserObject?.Role === "MasterAdmin" ? ( */}
                  <div className="pb-3">
                    <Button
                      onClick={() => handleSubmit(false)} // Pass 'false' as the argument
                      style={{ marginRight: '10px', float: 'right' }}
                      className='btn btn-primary'
                    >
                      Add
                    </Button>
                  </div>
                  {/* // ) : null} */}
                  <p style={{ marginRight: '10px' }}>Search</p>
                  <div>
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e?.target?.value)}
                      className="search-bar"
                    />
                  </div>
                </div>
              </div>

              <div className="" style={{ overflowX: 'auto' }}>
                <table className="table table-bordered table-striped table-hover table-responsive listAppointments">
                  <thead>
                    <tr style={{ borderBottom: '1px solid #ccc' }}>
                      <th className="sno">S.No</th>
                      <th className='mdl'>Patient Name</th>
                      {/* <th>AppointmentID(Date)</th> */}
                      <th className='mdl'>EmailId</th>
                      {/* <th>Appointment Date</th> */}
                      <th className='mdl'>Phone Number</th>
                      {/* <th className='mdl'>Doctor Name</th> */}
                      <th className='mdl'>userType</th>
                      <th className='actions'>Actions</th>

                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((row: Patient, index: number) => {
                      let sno = (currentPage - 1) * pageSize + index + 1;
                      let ind = sno - 1;
                      return (
                        <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                          <td>{sno}</td>
                          <td>{highlightText(row?.PatientName)}</td>
                          <td>{highlightText(row?.EmailId)}</td>
                          {/* <td>{highlightText(row?.AppointmentDate)}</td> */}
                          <td>{highlightText(row?.PhoneNumber)}</td>
                          {/* <td>{highlightText(getDoctorNameById(row?.DoctorID))}</td> */}
                          <td>{highlightText(row?.userType)}</td>
                          {/* <td style={{ padding: '10px' }}>{highlightText(row?.PaymentMode)}</td> */}
                          {/* <td style={{ padding: '10px' }}>{highlightText(row?.Amount)}</td> */}
                          <td className='actions'>
                            <Button title='View history'
                              onClick={() => handleHistory(row, ind)}
                              startIcon={<VisibilityIcon style={{ color: 'green' }} />}
                            >
                            </Button>
                          </td>
                        </tr>
                      )
                    }
                    )


                    }
                  </tbody>
                </table>
              </div>
              {/* <div style={{ marginBottom: '40px' }}>
                Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries

                <div style={{ display: 'flex', justifyContent: 'right', float: 'right' }}>
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    variant="contained"
                    style={{ marginRight: '10px' }}
                  >
                    Previous
                  </Button>
                  <div className="pageNumb">{currentPage}</div>
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    variant="contained"
                    style={{ marginLeft: '10px' }}
                  >
                    Next
                  </Button>
                </div>
              </div> */}
              <div className='mttable'>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                <div className="paginationstyle mttable1">
                  <div
                    style={{
                      cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                      marginRight: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage > 1 ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage > 1) {
                        handlePageChange(currentPage - 1);
                        window.scrollTo(0, 0); // Scroll to top
                      }
                    }}
                    className={currentPage === 1 ? 'disabled' : ''}
                  >
                    Previous
                  </div>
                  <div className="pageNumb">{currentPage}</div>
                  <div
                    style={{
                      cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                      marginLeft: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage < totalPages) {
                        handlePageChange(currentPage + 1);
                        window.scrollTo(0, 0);
                      }
                    }}
                    className={currentPage === totalPages ? 'disabled' : ''}
                  >
                    Next
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default AppoinementList;