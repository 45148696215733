// import * as Yup from 'yup';

// export const validationSchema = Yup.object().shape({
//     Category: Yup.string().required('Category is required'),
//     fileType: Yup.string().required('File type is required'),
//     Files: Yup.mixed().when('fileType', {
//         is: 'url',
//         then: Yup.string().required('URL is required').url('Please enter a valid URL'),
//         otherwise: Yup.array()
//             .of(Yup.mixed().required('File is required'))
//             .min(1, 'At least one file is required'),
//     }),
// });

// import * as Yup from 'yup';

// export const validationSchema = Yup.object().shape({
//     Category: Yup.string().required('Category is required'),
//     fileType: Yup.string().required('File type is required'),
//     Files: Yup.mixed().when('fileType', (fileType: any, schema) => {
//         if (fileType === 'url') {
//             return Yup.string().required('URL is required').url('Please enter a valid URL');
//         }
//         return Yup.array()
//             .of(Yup.mixed().required('File is required'))
//             .min(1, 'At least one file is required');
//     }),
// });

// import * as Yup from 'yup';

// export const validationSchema = Yup.object().shape({
//     Category: Yup.string().required('Category is required'),
//     fileType: Yup.string().required('File type is required'),
//     Files: Yup.mixed().when('fileType', {
//         is: 'url',
//         then: Yup.string().required('URL is required').url('Please enter a valid URL'),
//         otherwise: Yup.array()
//             .of(Yup.mixed().required('File is required'))
//             .min(1, 'At least one file is required'),
//     }),
// });

import * as Yup from 'yup';

export const validationSchema: any = Yup.object().shape({
    Category: Yup.string().required('Category is required'),
    fileType: Yup.string().required('File type is required'),
    Files: Yup.mixed().test('fileTypeCheck', 'Please provide a valid input', function (value) {
        const { fileType } = this.parent;

        if (fileType === 'url') {
            // Validate that the value is a string and a valid URL
            return Yup.string()
            .required('URL is required')
            .matches(
                /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/,
                'Please enter a valid YouTube URL'
            )
            .isValidSync(value);
        } else {
            // Validate that the value is an array with at least one file
            return Yup.array()
                .of(Yup.string().required('File is required'))
                .min(1, 'At least one file is required')
                .isValidSync(value);
        }
    }),
});