import { useState, useEffect } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dayjs from "dayjs";
import { CiCalendarDate } from "react-icons/ci";
import "dayjs/locale/es";
import isBetween from "dayjs/plugin/isBetween";
import userService from "../../../Services/user/user.service";
// import './style.css';
import { SlotInfo } from 'react-big-calendar';
import './styles.css';
import { Helmet } from 'react-helmet';
// import useAuthRedirect from "../../../utills/Redirect/useAuthRedirect";
import Loader from "../../shared/Loader";

dayjs.extend(isBetween);
dayjs.locale("en");

function AvailabilityList() {
  const localizer = dayjsLocalizer(dayjs);
  const [selectedDates, setSelectedDates] = useState<{ Date: string; Timings: string[] }[]>([]);
  const [selectedDateTime, setSelectedDateTime] = useState<{ Date: string; Time: string } | undefined>(undefined);
  const [timeSlot, setTimeSlot] = useState<any>('');
  const [deletedSlots, setDeletedSlots] = useState<{ [key: string]: string[] }>({});
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('week');
  const [availableDate, setAvailableDate] = useState('');
  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [doctors, setDoctors] = useState<any>('');
  const [doctorDate, setdoctorDate] = useState<string[]>([]);
  // useAuthRedirect(logUserObject);
  
  useEffect(() => {
    const fetchDoctorDates = async () => {
      try {
        setLoading(true); 
        const doctorDates = await userService().getlistDoctorAvailableDates(selectedDoctor);
        const datesMap: any = doctorDates.reduce((acc: any, date: any) => {
          // debugger
          const dateString = dayjs(date?.Date).format("YYYY-MM-DD");
          const timeString = date?.Time;
          if (!acc[dateString]) {
            acc[dateString] = [];
          }
          acc[dateString].push(timeString);
          return acc;
        }, {});

        selectedDoctor && setSelectedDates(
          Object.entries(datesMap).map(([Date, Timings]) => ({
            Date,
            Timings: Timings as string[],
          }))
        );
      } catch (error) {
        console.error('Error fetching doctor dates:', error);
      }finally {
        setLoading(false); // Stop loading after fetching completes
      }
    };
    fetchDoctorDates();
  }, [selectedDoctor]);


  const handleDoctorChange = async (event: any) => {
    setSelectedDoctor(event?.target?.value);
    const response: any = await userService().ListDoctorsById(event?.target?.value);
    return setTimeSlot(response[0]?.TimeSlot || 15);
  };
  const getDoctor = async () => {
    try {
      const time = await userService().ListDoctor('Doctor');
      const doctorIds = time.map(doctor => doctor.id);
      setdoctorDate(doctorIds);
      setDoctors(time);
      const doctorId = time.map(doctor => doctor.id);
    } catch (error) {
      console.error('Error fetching doctors:', error);
    }
  };

  useEffect(() => {
    getDoctor();
  }, []);


  // const handleSelectSlot = async (slotInfo: SlotInfo) => {
    
  //   const startDate = dayjs(slotInfo.start);
  //   const dateString = startDate.format("YYYY-MM-DD");
  //   const timeString = startDate.format("HH:mm");

  //   try {
  //     const isDateInRange = startDate.isBetween(dayjs(), dayjs().add(30, "day"), 'day', '[]');
  //     const isTimeDisabled = startDate.isBefore(dayjs(), 'minute');
  //     if (!isDateInRange || isTimeDisabled) {
  //       return;
  //     }

  //     if (selectedDoctor && selectedDoctor != 'Choose Doctor') {
  //       setSelectedDateTime(prevState => ({ ...prevState, Date: dateString, Time: timeString }));
  //       try {
  //         const doctorDates = await userService().getlistDoctorAvailableDates(selectedDoctor);
  //         const dateRecord = doctorDates.find((date: any) =>
  //           dayjs(date.Date).format("YYYY-MM-DD") === dateString
  //         );
  //         if (view === 'month') {
  //           const times: any = [];
  //           const timeSlotMig = parseInt(timeSlot);
  //           for (let hour = 10; hour <= 19; hour++) {
  //             for (let minutes = 0; minutes < 60; minutes += timeSlotMig) {
  //               times.push(dayjs().hour(hour).minute(minutes).format("HH:mm"));
  //             }
  //           }
  //           const existingRecord = selectedDates.find(dateObj => dateObj?.Date === dateString);
  //           if (existingRecord) {
  //             const newTimes = existingRecord.Timings.filter((time: string) => !times.includes(time));

  //             if (newTimes.length < 10) {
  //               await userService().DeleteDoctorAvaiableDate(dateRecord.id);
  //               setSelectedDates(prev => prev.filter(dateObj => dateObj?.Date !== dateString));
  //             } else {
  //               await userService().UpdateDoctorAvaiableDate({
  //                 id: dateRecord?.id,
  //                 DoctorID: selectedDoctor,
  //                 Date: dateString,
  //                 Time: newTimes,
  //               });

  //               setSelectedDates(prev => prev.map(dateObj =>
  //                 dateObj.Date === dateString ? { ...dateObj, Timings: newTimes } : dateObj
  //               ));
  //             }
  //           } else {
  //             await userService().CreateDoctorAvailableDate({
  //               DoctorID: selectedDoctor,
  //               Date: dateString,
  //               Time: times,
  //             });
  //             setSelectedDates(prev => [...prev, { Date: dateString, Timings: times }]);
  //           }
  //         } else if (view === 'week') {

  //           if (dateRecord) {
  //             const existingDate = selectedDates.find(dateObj => dateObj.Date === dateString);
  //             // const timeExists = existingDate?.Timings.includes(timeString);
  //             const timeExists = existingDate?.Timings.flat()
  //             const exist = timeExists?.includes(timeString)
  //             if (exist) {
  //               // const newTimes = existingDate?.Timings.filter((time: string) => time !== timeString) || [];
  //               const existTime = existingDate?.Timings.flat()
  //               const newTimes = existTime?.filter((time: string) => time !== timeString) || [];
  //               if (newTimes.length === 0) {
  //                 await userService().DeleteDoctorAvaiableDate(dateRecord.id);
  //                 setSelectedDates(prev => prev.filter(dateObj => dateObj.Date !== dateString));
  //               } else {
  //                 await userService().UpdateDoctorAvaiableDate({
  //                   id: dateRecord.id,
  //                   DoctorID: selectedDoctor,
  //                   Date: dateRecord.Date,
  //                   Time: newTimes,
  //                 });
  //                 setSelectedDates(prev => prev.map(dateObj =>
  //                   dateObj.Date === dateString ? { ...dateObj, Timings: newTimes } : dateObj
  //                 ));
  //               }
  //               setDeletedSlots(prev => ({
  //                 ...prev,
  //                 [dateString]: [...(prev[dateString] || []), timeString],
  //               }));
  //             } else {
  //               const newTimes = [...(timeExists || []), timeString];
  //               await userService().UpdateDoctorAvaiableDate({
  //                 id: dateRecord.id,
  //                 DoctorID: selectedDoctor,
  //                 Date: dateRecord.Date,
  //                 Time: newTimes,
  //               });
  //               setSelectedDates(prev => prev.map(dateObj =>
  //                 dateObj.Date === dateString ? { ...dateObj, Timings: newTimes } : dateObj
  //               ));
  //               setDeletedSlots(prev => {
  //                 const newDeletedSlots = { ...prev };
  //                 if (newDeletedSlots[dateString]) {
  //                   newDeletedSlots[dateString] = newDeletedSlots[dateString].filter(time => time !== timeString);
  //                   if (newDeletedSlots[dateString].length === 0) {
  //                     delete newDeletedSlots[dateString];
  //                   }
  //                 }
  //                 return newDeletedSlots;
  //               });
  //             }
  //           } else {
  //             await userService().CreateDoctorAvailableDate({
  //               DoctorID: selectedDoctor,
  //               Date: dateString,
  //               Time: [timeString],
  //             });
  //             setSelectedDates(prev => [...prev, { Date: dateString, Timings: [timeString] }]);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error updating doctor availability:", error);
  //       }

  //     } else {
  //       alert("please select doctor")
  //     }
  //   } catch (error) {
  //     console.error("Error updating doctor availability:", error);
  //   }
  // };

  

  const handleSelectSlot = async (slotInfo: SlotInfo) => {
    
    const startDate = dayjs(slotInfo.start);
    const dateString = startDate.format("YYYY-MM-DD");
    const timeString = startDate.format("HH:mm");

    try {
      const isDateInRange = startDate.isBetween(dayjs(), dayjs().add(30, "day"), 'day', '[]');
      const isTimeDisabled = startDate.isBefore(dayjs(), 'minute');
      if (!isDateInRange || isTimeDisabled) {
        return;
      }

      if (selectedDoctor && selectedDoctor != 'Choose Doctor') {
        setSelectedDateTime(prevState => ({ ...prevState, Date: dateString, Time: timeString }));
        try {
          const doctorDates = await userService().getlistDoctorAvailableDates(selectedDoctor);
          const dateRecord = doctorDates.find((date: any) =>
            dayjs(date.Date).format("YYYY-MM-DD") === dateString
          );
          if (view === 'month') {
            const times: any = [];
            const timeSlotMig = parseInt(timeSlot);
            for (let hour = 10; hour <= 19; hour++) {
              for (let minutes = 0; minutes < 60; minutes += timeSlotMig) {
                times.push(dayjs().hour(hour).minute(minutes).format("HH:mm"));
              }
            }
            const existingRecord = selectedDates.find(dateObj => dateObj?.Date === dateString);
            if (existingRecord) {
              const newTimes = existingRecord.Timings.filter((time: string) => !times.includes(time));

              if (newTimes.length < 10) {
                await userService().DeleteDoctorAvaiableDate(dateRecord.id);
                setSelectedDates(prev => prev.filter(dateObj => dateObj?.Date !== dateString));
              } else {
                await userService().UpdateDoctorAvaiableDate({
                  id: dateRecord?.id,
                  DoctorID: selectedDoctor,
                  Date: dateString,
                  Time: newTimes,
                });

                setSelectedDates(prev => prev.map(dateObj =>
                  dateObj.Date === dateString ? { ...dateObj, Timings: newTimes } : dateObj
                ));
              }
            } 

            else {
              // Check if the date already exists in the selectedDates
              const existingDate = selectedDates.find(dateObj => dateObj?.Date === dateString);
              
              if (!existingDate) {
                // Create a new record only if it doesn't exist
                await userService().CreateDoctorAvailableDate({
                  DoctorID: selectedDoctor,
                  Date: dateString,
                  Time: times,
                });
                
                // Directly update the state with a new object
                setSelectedDates(prev => {
                  const updatedDates = [...prev, { Date: dateString, Timings: times }];
                  return updatedDates;
                });
              }
            }
            
            
            // else {
            //   await userService().CreateDoctorAvailableDate({
            //     DoctorID: selectedDoctor,
            //     Date: dateString,
            //     Time: times,
            //   });
            //   setSelectedDates(prev => [...prev, { Date: dateString, Timings: times }]);
            // }

          } else if (view === 'week') {

            if (dateRecord) {
              const existingDate = selectedDates.find(dateObj => dateObj.Date === dateString);
              // const timeExists = existingDate?.Timings.includes(timeString);
              const timeExists = existingDate?.Timings.flat()
              const exist = timeExists?.includes(timeString)
              if (exist) {
                // const newTimes = existingDate?.Timings.filter((time: string) => time !== timeString) || [];
                const existTime = existingDate?.Timings.flat()
                const newTimes = existTime?.filter((time: string) => time !== timeString) || [];
                if (newTimes.length === 0) {
                  await userService().DeleteDoctorAvaiableDate(dateRecord.id);
                  setSelectedDates(prev => prev.filter(dateObj => dateObj.Date !== dateString));
                } else {
                  await userService().UpdateDoctorAvaiableDate({
                    id: dateRecord.id,
                    DoctorID: selectedDoctor,
                    Date: dateRecord.Date,
                    Time: newTimes,
                  });
                  setSelectedDates(prev => prev.map(dateObj =>
                    dateObj.Date === dateString ? { ...dateObj, Timings: newTimes } : dateObj
                  ));
                }
                setDeletedSlots(prev => ({
                  ...prev,
                  [dateString]: [...(prev[dateString] || []), timeString],
                }));
              } else {
                const newTimes = [...(timeExists || []), timeString];
                await userService().UpdateDoctorAvaiableDate({
                  id: dateRecord.id,
                  DoctorID: selectedDoctor,
                  Date: dateRecord.Date,
                  Time: newTimes,
                });
                setSelectedDates(prev => prev.map(dateObj =>
                  dateObj.Date === dateString ? { ...dateObj, Timings: newTimes } : dateObj
                ));
                setDeletedSlots(prev => {
                  const newDeletedSlots = { ...prev };
                  if (newDeletedSlots[dateString]) {
                    newDeletedSlots[dateString] = newDeletedSlots[dateString].filter(time => time !== timeString);
                    if (newDeletedSlots[dateString].length === 0) {
                      delete newDeletedSlots[dateString];
                    }
                  }
                  return newDeletedSlots;
                });
              }
            } else {
              await userService().CreateDoctorAvailableDate({
                DoctorID: selectedDoctor,
                Date: dateString,
                Time: [timeString],
              });
              setSelectedDates(prev => [...prev, { Date: dateString, Timings: [timeString] }]);
            }
          }
        } catch (error) {
          console.error("Error updating doctor availability:", error);
        }

      } else {
        alert("please select doctor")
      }
    } catch (error) {
      console.error("Error updating doctor availability:", error);
    }
  };
  
  
  const navigate = (action: 'PREV' | 'NEXT') => {
    setCurrentDate(prevDate =>
      action === 'PREV' ? prevDate.subtract(1, 'month') : prevDate.add(1, 'month')
    );
  };

  const components = {
    event: (props: any) => {
      const { data } = props.event;
      return (
        <div style={{ background: data.x > 15 ? "white" : "green" }}>
          <CiCalendarDate />
          {props.title}
        </div>
      );
    },
  };

  const maxDate = currentDate.add(60, "day");
  const stepValue = timeSlot ? parseInt(timeSlot) : 15;
  // console.log("selectedDoctor",selectedDoctor)
  console.log(doctors, "doctors")
  return (
    <>
    {loading ? ( // Show loader while loading is true
        <Loader />
      ) : (
      <><Helmet>
            <title>Doctor Availability</title>
            <meta name="description" content="This is the Doctor Availability page description" />
          </Helmet><section className="admin-section">
              <div
                className="slider-item bread-item"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <h1>
                        Doctor Availability
                      </h1>

                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div>
              <ul className="paginationbanner">
                <li>
                <span style={{ cursor: 'pointer' }} onClick={() => window.location.href="/"} className="aColor">Home  /&nbsp;</span>
                </li>
                <li>Doctor Availability</li>
              </ul>
            </div><div className="container"
              style={{
                height: "85vh",
                width: "90vw", marginBottom: '80px',
              }}
            >

              <div className="col-md-4 " style={{ display: "flex", justifyContent: "left", marginTop: "40px", marginBottom: '20px' }}>
                <select onChange={handleDoctorChange} value={selectedDoctor} className="form-select">
                  <option value="">Choose Doctor</option>
                  {Array.isArray(doctors) && doctors.map((doctor: any) => (
                    <option key={doctor.id} value={doctor.id}>
                      {doctor.FirstName + " " + doctor.LastName}
                    </option>
                  ))}
                </select>
              </div>
              {/* <button onClick={() => navigate('PREV')}>Previous Month</button>
  <button onClick={() => navigate('NEXT')}>Next Month</button> */}
              <Calendar
                localizer={localizer}
                date={currentDate.toDate()}
                toolbar={true}
                defaultView="week"
                views={["month", "week"]}
                onNavigate={(date, view) => setCurrentDate(dayjs(date))}
                selectable={true}
                onSelectSlot={handleSelectSlot}
                onView={(view) => setView(view)}
                timeslots={1}
                step={stepValue}
                min={dayjs().hour(10).minute(0).toDate()}
                max={dayjs().hour(20).minute(0).toDate()}
                formats={{
                  dayHeaderFormat: (date: any) => dayjs(date).format("ddd, MMM D"),
                  dayFormat: (date: any) => dayjs(date).format("D"),
                  weekdayFormat: (date: any) => dayjs(date).format("dddd"),
                  timeGutterFormat: (date: any) => dayjs(date).format("HH:mm"),
                  agendaTimeFormat: (date: any) => dayjs(date).format("HH:mm"),
                  agendaDateFormat: (date: any) => dayjs(date).format("dddd, MMMM DD, YYYY"),
                }}
                components={components}
                dayPropGetter={(date: Date, time: any) => {
                  const dateString = dayjs(date).format("YYYY-MM-DD");
                  const isDisabled = !dayjs(date).isBetween(dayjs(), dayjs().add(30, "day"), 'day', '[]');
                  const hasAvailability = selectedDates.find(dateObj => dateObj.Date === dateString);
                  return {
                    style: {
                      backgroundColor: hasAvailability ? "#90ee90" : "white",
                      pointerEvents: isDisabled ? 'none' : undefined,
                      opacity: isDisabled ? 0.5 : 1,
                      ...(isDisabled ? { backgroundColor: "#ddd" } : {}),
                    },
                  };
                } }
                slotPropGetter={(date: Date) => {
                  const dateString = dayjs(date).format("YYYY-MM-DD");
                  const timeString = dayjs(date).format("HH:mm");
                  const now = dayjs();

                  const isDateInRange = dayjs(date).isBetween(dayjs(), dayjs().add(30, "day"), 'day', '[]');
                  const isTimeDisabled = dayjs(date).isBefore(now, 'minute');

                  const isSelectedSlot = selectedDates?.find(dateObj => {
                    if (dateObj?.Date === dateString) {
                      const flattenedTimings = dateObj?.Timings.flat();
                      return flattenedTimings?.includes(timeString);
                    }
                    return false;
                  });

                  const isDeletedSlot = deletedSlots[dateString]?.includes(timeString);

                  return {
                    style: {
                      backgroundColor: isSelectedSlot ? "#90ee90" : "white",
                      pointerEvents: isDateInRange && !isTimeDisabled ? 'auto' : 'none',
                      opacity: isDateInRange && !isTimeDisabled ? 1 : 0.5,
                      ...(isDateInRange && !isTimeDisabled ? {} : { backgroundColor: "#ddd" }),
                    },
                  };
                } } />
            </div></>
      )}
    </>
  );
}

export default AvailabilityList;






