import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import userService from '../../Services/user/user.service';
import { TextField, Button, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { updDelFile } from '../shared/fileupload';
import GalleryUtils from '../../utills/galleryUploads';
import Sidebar from '../DashBoard/sidebar';
import GalleryServices from '../../Services/galleryServices';
import { Helmet } from 'react-helmet';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';


interface Doctor {
    PhoneNumber: string;
    EmailId: string;
    Specialization: string;
    id: string;
    FirstName: string;
    LastName: string;
    Status: any;
}

const ListGallery = () => {

    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [gallery, setGalleryList] = useState<Doctor[]>([]);
    const [specializationtList, setspecializationtList] = useState<Doctor[]>([]);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // useAuthRedirect(logUserObject);

    const galleryList = async () => {
        const response = await GalleryUtils().handleList();
        setGalleryList(response);
    };

    useEffect(() => {
        galleryList();
    }, []);

    const handleDelete = async (id: string) => {

        const confirmBox = window.confirm("Are you sure you want to delete the selected record?");
        if (confirmBox) {
            const delGallery = await GalleryUtils().galleryDelete(id);
            console.log("delGallery", delGallery);
            // if (delGallery) {
            galleryList();
        } else {
            alert('Deletion canceled.');
        }
    };


    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts.map((part, index) => (regex.test(part) ? <span key={index} className="highlighted-text">{part}</span> : part));
    };

    const filteredData = gallery?.filter((doctor: any) =>
        Object.values(doctor).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData?.length / pageSize);
    const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setCurrentPage(1);
    };

    useEffect(() => {
        console.log(logUserObject?.Role)
        setSearchTerm('');
    }, [currentPage]);

    async function handleEdit(event: any) {
        navigate('/galleryUpload', { state: { id: event?.id } });
    }

    const handleSubmit = (setUpdate: boolean) => {


        navigate('/galleryUpload', { state: { setUpdate: false } });
    }
    // const handleStatusToggle = (rowData: any) => async (event: any) => {
    //     const updatedStatus = event?.target?.checked === true ? "1" : "0";
    //     const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
    //     try {
    //         const updateStatus = await GalleryUtils().galleryUpdateUtil(updatedRowData);
    //         setGalleryList((prevRows: any) =>
    //             prevRows?.map((gallery: any) =>
    //                 gallery.id === rowData.id ? { ...gallery, Status: updatedStatus } : gallery
    //             )
    //         );
    //     } catch (error) {
    //         console.error('Error updating gallery status:', error);
    //         // alert('Failed to update status.');
    //     }
    // };

    const handleStatusToggle = async (rowData: any) => {
        const updatedStatus =  rowData?.Status === "1" ? "0" : "1";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        try {
            const updateStatus = await GalleryUtils().galleryUpdateUtil(updatedRowData);
            setGalleryList((prevRows: any) =>
                prevRows?.map((gallery: any) =>
                    gallery.id === rowData.id ? { ...gallery, Status: updatedStatus } : gallery
                )
            );
        } catch (error) {
            console.error('Error updating gallery status:', error);
            // alert('Failed to update status.');
        }
    };



    return (

        <>
            <Helmet>
                <title>Gallery List</title>
                <meta name="description" content="This is the Gallery page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Gallery
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                    <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Gallery</li>
                </ul>
            </div>



            <div className="container">
                <div className="row tablefs">
                    {/* <div className="col-md-2" >
            <Sidebar />
          </div> */}
                    <div className="col-md-12"  >
                        <div className="row">
                            <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="page-size-dropdown">
                                    <label style={{ padding: '5px' }}>Show</label>
                                    <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                    </select>
                                    <label style={{ padding: '5px' }}>entries</label>
                                </div>
                                <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                                    {logUserObject?.Role === "MasterAdmin" ? (
                                        <div className="pb-3">
                                            <Button
                                                onClick={() => handleSubmit(false)} // Pass 'false' as the argument
                                                style={{ marginRight: '10px', float: 'right' }}
                                                className='btn btn-primary'
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    ) : null}
                                    <p style={{ marginRight: '10px' }}>Search</p>
                                    <div>
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e?.target?.value)}
                                            className="search-bar"
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12" style={{ overflowX: 'auto' }}>
                            <table className="table table-bordered table-striped table-hover table-responsive listGallery">
                                    <thead>
                                        <tr>
                                            <th className="sno">S.No</th>
                                            <th className='mdl'>Category</th>
                                            <th className='mdl'>File Type</th>
                                            {/* <th>File Type</th>
                            <th>Email Id</th> */}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((doctor: any, index: any) => (
                                            <tr key={doctor?.id} className="highlighted-row">
                                                {/* <td>{index + 1}</td> */}
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>{highlightText(doctor?.Category)}</td>
                                                <td>{highlightText(doctor?.fileType)}</td>
                                                {/* <td>{highlightText(doctor.PhoneNumber)}</td>
                                <td>{highlightText(doctor.EmailId)}</td> */}
                                                <td className="actions" >

                                                    <Button title="Edit"
                                                        onClick={() => handleEdit(doctor)}
                                                        startIcon={<EditIcon style={{ color: 'green' }} />}
                                                    >

                                                    </Button>
                                                 
                                                    <Button title="Status"
                                                        onClick={() => handleStatusToggle(doctor)}
                                                        className={doctor?.Status === '1' ? 'statusSuccess' : 'statusDanger'}
                                                        startIcon={<VisibilityIcon />}
                                                    >
                                                    </Button>
                                                    <Button title="Delete"
                                                        onClick={() => handleDelete(doctor.id)}
                                                        startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                    >

                                                    </Button>

                                                    {/* <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={doctor?.Status === "1"}
                                                                onChange={handleStatusToggle(doctor)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={
                                                            <span className={doctor?.Status === "1" ? 'Status-active' : 'Status-inactive'}>
                                                                {doctor?.Status === "1" ? 'Active' : 'Inactive'}
                                                            </span>
                                                        }
                                                    /> */}
                                                </td>
                                                {/* <td>
                <i className="fas fa-edit" style={{ color: 'green' }} onClick={() => handleEdit(doctor)}></i>
                <i className="fas fa-trash" style={{ marginLeft: '20px', color: 'red' }} onClick={() => handleDelete(doctor.id)}></i>
              </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div  className='mttable' style={{ marginBottom: '40px' }}>
                                Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries


                                {/* <div style={{ display: 'flex', justifyContent: 'right', float: 'right' }}>
                                    <Button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        variant="contained"
                                    >
                                        Prev
                                    </Button>
                                    <div className="pageNumb">{currentPage}</div>
                                    <Button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                        variant="contained"
                                    >
                                        Next 
                                    </Button>
                                </div> */}

                                <div className="paginationstyle mttable1">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default ListGallery;



