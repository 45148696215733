import * as Yup from "yup";

export const validationSchema = Yup.object({
    bulkupload: Yup.mixed()
        .required("File is required")
        .test("fileSize", "File size is too large. Maximum size is 500KB", (value: any) => {
            return value && value.size <= 500 * 1024; // 500KB size limit
        })
        .test("fileFormat", "Only CSV files are allowed", (value: any) => {
            return value && ["text/csv"].includes(value.type); // Check for CSV format
        }),
});