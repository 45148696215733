import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HomeIcon from '@mui/icons-material/Home';
// import EyeIcon from '@mui/icons-material/Eye';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './listServices.css';
import Homeservices from '../../Services/homeServices';
import { updDelFile } from '../shared/fileupload';
import { Helmet } from 'react-helmet';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';

interface Services {
    Title: string;
    Description: string;
    Status: any;
    ShowInHome: string;
    category: string[];
}

const ListService = () => {
    const initialState: Services = {
        Title: '',
        Description: '',
        Status: '',
        ShowInHome: '',
        category: [],
    };

    const navigate = useNavigate();
    const [data, setData] = useState<Services>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [reviewsList, setReviewList] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('PDF Viewer Modal');
    const [singleReview, setSingleReview] = useState<any>({});

    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // useAuthRedirect(logUserObject);

    const listServices = async () => {
        const response = await Homeservices().servicesList();
        const categoryname = await Homeservices().serviceCategoryList();
        const updatedResponse = response?.map((item: any) => {
            const categoryItems = Array.isArray(item?.category) && item?.category?.length > 0
                ? item.category[0]?.split(',')
                : [];

            const categoryNames = categoryItems?.map((cateId: string) => {
                const category = categoryname?.find((cate: any) => cate?.id === cateId);
                return category ? category?.name : cateId;
            });
            // const categoryNames = item?.catergory?.map((cateId: string) => {
            // const category = categoryname?.find((cate: any) => cate?.id === cateId);
            // return category ? category?.name : cateId;
            // });

            return {
                ...item,
                categoryName: categoryNames
            };
        });
        setReviewList(updatedResponse);
    };

    useEffect(() => {
        listServices();
    }, []);

    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected Services?");
        if (confirmBox) {
            const service: any = await Homeservices().getServiceSingle(id);
            const filedel = await updDelFile(service?.Profile[0], "images");
            console.log("filedel", filedel);
            if (filedel?.status === 200) {
                await Homeservices().serviceDelete(id);
                listServices();
            }
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const filteredData: any = reviewsList?.filter((row: Services) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalHeading("");
    };

    const handleShowteam = async (rowData: any) => {
        console.log({ rowData })

        const updatedStatus = rowData?.ShowInHome === "1" ? "0" : "1";
        const updatedRowData = { ...rowData, id: rowData?.id, ShowInHome: updatedStatus };
        delete updatedRowData?.categoryName;
        const update = await Homeservices().ServiceUpdate(updatedRowData);

        setReviewList((prevRows: any) =>
            prevRows?.map((Service: any) =>
                Service?.id === rowData?.id ? { ...Service, ShowInHome: updatedStatus } : Service
            )
        );
    };

    const handleStatusToggle = async (rowData: any) => {
        const updatedStatus = rowData?.Status === "1" ? "0" : "1";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        delete updatedRowData?.categoryName;
        const update = await Homeservices().ServiceUpdate(updatedRowData);

        setReviewList((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, Status: updatedStatus } : row
            )
        );
    };

    // const handleShowteam = (rowData: any) => async (event: any) => {
    //     const updatedStatus = event?.target?.checked === true ? "1" : "0";
    //     // console.log('updatedStatus', updatedStatus);
    //     const updatedRowData = { ...rowData, id: rowData?.id, ShowInHome: updatedStatus };
    //     // console.log("updatedRowData", updatedRowData);
    //     delete updatedRowData?.categoryName;
    //     const update = await Homeservices().ServiceUpdate(updatedRowData);
    //     // console.log("update", update);

    //     setReviewList((prevRows: any) =>
    //         prevRows?.map((Service: any) =>
    //             Service?.id === rowData?.id ? { ...Service, ShowInHome: updatedStatus } : Service
    //         )
    //     );
    // };

    // const handleStatusToggle = (rowData: any) => async (event: any) => {
    //     const updatedStatus = event?.target?.checked === true ? "1" : "0";
    //     const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
    //     delete updatedRowData?.categoryName;
    //     const update = await Homeservices().ServiceUpdate(updatedRowData);

    //     setReviewList((prevRows) =>
    //         prevRows?.map((row) =>
    //             row?.id === rowData?.id ? { ...row, Status: updatedStatus } : row
    //         )
    //     );
    // };

    async function handleEdit(event: any) {
        navigate('/addServices', { state: { id: event?.id } });
    }

    const handleSubmit = (setUpdateService: boolean) => {
        navigate('/addServices', { state: { setUpdateService: false } });
    };

    const truncateText = (text: any) => {
        if (!text) return '';
        const truncatedText = text?.length > 10 ? text?.substring(0, 10) + '...' : text;
        return truncatedText;
    };

    const removeHtmlTags = (str: any) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    return (
        <>
            <Helmet>
                <title>Services</title>
                <meta name="description" content="This is the Services page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Services
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Services</li>
                </ul>
            </div>

            <div className="container">
                <div className="row tablefs">
                    <div>
                        <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div className="page-size-dropdown">

                                <label style={{ padding: '5px' }}>Show</label>
                                <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                </select>
                                <label style={{ padding: '5px' }}>entries</label>
                            </div>

                            <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                                {logUserObject?.Role === "MasterAdmin" ? (<div className="pb-3">
                                    <Button
                                        onClick={() => handleSubmit(false)}
                                        style={{ marginRight: '10px', float: 'right' }}
                                        // startIcon={<EditIcon style={{ color: 'green' }}  />}
                                        className='btn btn-primary'
                                    >
                                        Add
                                    </Button>
                                </div>) : null}
                                <p style={{ marginRight: '10px' }}>Search</p>
                                <div>
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="search-bar"
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="col-md-12" style={{ overflowX: 'auto' }}>
                            <table className="table table-bordered table-striped table-hover table-responsive listServices">
                                <thead>
                                    <tr style={{ borderBottom: '1px solid #ccc' }}>
                                        <th className="sno">S.No</th>
                                        <th className='mdl'>Title</th>
                                        {/* <th className='mdl'>Description</th> */}
                                        <th className='mdl'>Category</th>
                                        <th className='actions'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData?.map((row: any, index: number) => (
                                        <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                            <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                            <td>{highlightText(row?.Title)}</td>
                                            {/* <td style={{ padding: '10px' }} dangerouslySetInnerHTML={{ __html: row?.Description }} /> */}
                                            {/* <td
                                                dangerouslySetInnerHTML={{
                                                    __html: row?.Description?.substring(0, 30) + (row?.Description?.length > 30 ? "..." : "")
                                                }}
                                            /> */}

                                            <td>
                                                {row?.categoryName?.map((catName: any, index: number) => (
                                                    <div key={index} style={{ marginRight: '5px' }}>
                                                        {catName}{index < row.categoryName.length - 1 ? ',' : ''}
                                                    </div>
                                                ))}
                                            </td>

                                            {/* <td style={{ padding: '10px' }}>{highlightText(row?.Rating)}</td>
                                            <td style={{ padding: '10px' }}>{highlightText(truncateText(row?.Comment))}</td> */}
                                            <td className="actions">

                                                <Button title="Edit"
                                                    onClick={() => handleEdit(row)}
                                                    startIcon={<EditIcon style={{ color: 'green' }} />}
                                                >

                                                </Button>
                                               

                                                <Button title="Status"
                                                    onClick={() => handleStatusToggle(row)}
                                                    className={row?.Status === '1' ? 'statusSuccess' : 'statusDanger'}
                                                    startIcon={<VisibilityIcon />}
                                                >
                                                </Button>

                                                <Button title="Show in Home"
                                                    onClick={() => handleShowteam(row)}
                                                    className={row?.ShowInHome === '1' ? 'statusSuccess' : 'statusDanger'}
                                                    startIcon={<HomeIcon />}
                                                >
                                                </Button>

                                                <Button title="Delete"
                                                    onClick={() => handleDelete(row?.id)}
                                                    startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                >
                                                </Button>

                                                <br />

                                                {/* <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={row.Status === "1"}
                                                            onChange={handleStatusToggle(row)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <span className={row.Status === "1" ? 'Status-active' : 'Status-inactive'}>
                                                            {row.Status === "1" ? 'Active' : 'Inactive'}
                                                        </span>
                                                    }

                                                    className='switch-btn'
                                                /> */}

                                                {/* <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={row?.ShowInHome === "1"}
                                                            onChange={handleShowteam(row)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <span className={row?.ShowInHome === "1" ? 'Status-active' : 'Status-inactive'}>
                                                            {row?.ShowInHome === "1" ? 'Show In Home' : 'Not In Home'}
                                                        </span>
                                                    }
                                                /> */}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>


                        <div className='mttable'>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                            <div className="paginationstyle mttable1">
                                <div
                                    style={{
                                        cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                        marginRight: '10px',
                                        borderBottom: '1px solid red',
                                        fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                    }}
                                    onClick={() => {
                                        if (currentPage > 1) {
                                            handlePageChange(currentPage - 1);
                                            window.scrollTo(0, 0); // Scroll to top
                                        }
                                    }}
                                    className={currentPage === 1 ? 'disabled' : ''}
                                >
                                    Previous
                                </div>
                                <div className="pageNumb">{currentPage}</div>
                                <div
                                    style={{
                                        cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                        marginLeft: '10px',
                                        borderBottom: '1px solid red',
                                        fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                    }}
                                    onClick={() => {
                                        if (currentPage < totalPages) {
                                            handlePageChange(currentPage + 1);
                                            window.scrollTo(0, 0);
                                        }
                                    }}
                                    className={currentPage === totalPages ? 'disabled' : ''}
                                >
                                    Next
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ListService;

