import { API, graphqlOperation } from "aws-amplify";
import { createCareeres, deleteCareerCategory, deleteCareeres, updateCareerCategory, updateCareeres } from "../../../graphql/mutations";
import { getCareerCategory, getCareeres, listCareerCategories, listCareeres } from "../../../graphql/queries";

const getCareer = () => {
    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }

    const CreateCareer = async (userData: any) => {
        console.log("userData", userData);
        try {
            const result = await API.graphql(graphqlOperation(createCareeres, { input: userData })) as any;
            return result?.data?.createCareeres;
        } catch (error) {
            console.error('Error creatingCarrer:', error);
            return null;
        }
    }


    const CareerUpdate = async (data: any) => {
        console.log("data", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updateCareeres, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateCareeres;
        } catch (error) {
            console.error('Error updatingCarrer:', error);
            return null;
        }
    }

    const CareerDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteCareeres, { input: { id: id } })) as any;
            return result?.data?.deleteCareeres;
        } catch (error) {
            console.error('Error in deleteCarrer:', error);
            return null;
        }
    }

    const getSingleCareer = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getCareeres, { id: id })) as any;
            return result?.data?.getCareeres;
        } catch (error) {
            console.error('Error in getCarrer:', error);
            return null;
        }
    }
    const CareerList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listCareeres, filter)) as any;
                const specializations = result?.data?.listCareeres?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listCareeres?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching Carrer:', error);
            return [];
        }
    }

    const getcategoryname = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getCareerCategory, { id: id })) as any;
            return result?.data?.getCareerCategory;
        } catch (error) {
            console.error('Error in getCarrerCategory :', error);
            return null;
        }
    }

    const CategoryList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {

                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listCareerCategories, filter)) as any;
                const specializations = result?.data?.listCareerCategories?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listCareerCategories?.nextToken;
            } while (nextToken);
            console.log("reviews", reviews)
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;

        } catch (error) {
            console.error('Error fetching listCarrer:', error);
            return [];
        }
    }

    const getcategory = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let allCategories: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listCareerCategories, filter)) as any;
                // console.log("result",result)
                const Categories = result?.data?.listCareerCategories?.items || [];
                allCategories.push(...Categories);
                nextToken = result?.data?.listCareerCategories?.nextToken;
            } while (nextToken);
            allCategories.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allCategories;
        } catch (error) {
            console.error('Error fetching Categories:', error);
            return []; // Return an empty array in case of error
        }
    }

    const validateSlug = async (slug: string) => {
        console.log("slug", slug);
        try {
            const filterVar = {
                filter: {
                    Slug: { eq: slug }
                }
            };
            console.log("filterVar", filterVar);

            const existingSlugs: any = await API.graphql(graphqlOperation(listCareeres, filterVar));

            // console.log("existingSlugs", existingSlugs)

            if (existingSlugs && existingSlugs?.data?.listCareeres?.items?.length > 0) {
                return { msg: 'slug already exists with this slug', status: 1, data: existingSlugs?.data?.listCareeres?.items };
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating slug:', error);
            return { msg: 'Error validating slug', status: 1 };
        }
    };
    const ActiveCareerList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    filter: {
                        status: { eq: 1 }
                    },
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listCareeres, filter)) as any;
                const specializations = result?.data?.listCareeres?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listCareeres?.nextToken;
            } while (nextToken);
            reviews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return reviews;
        } catch (error) {
            console.error('Error fetching Carrer:', error);
            return [];
        }
    }
    const CareerCategoryUpdate = async (data: any) => {
        console.log("careerData", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        console.log('sanitizedUserInfo', sanitizedUserInfo)

        try {
            const result = await API.graphql(graphqlOperation(updateCareerCategory, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateCareerCategory;
        } catch (error) {
            console.error('Error updatingCarrer:', error);
            return null;
        }
    }
    const CareerCategoryDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteCareerCategory, { input: { id: id } })) as any;
            return result?.data?.deleteCareerCategory;
        } catch (error) {
            console.error('Error in deleteCarrer:', error);
            return null;
        }
    }

    return {
        CreateCareer,
        CareerUpdate,
        CareerDelete,
        getSingleCareer,
        CareerList,
        getcategoryname,
        CategoryList,
        getcategory,
        validateSlug,
        ActiveCareerList,
        CareerCategoryUpdate,
        CareerCategoryDelete

    }
}

export default getCareer