import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const dummyData = [
  {
    doc_id: 1,
    doc_img: "https://via.placeholder.com/256",
    name: "John Doe",
    specialize: "Cardiology",
  },
  {
    doc_id: 2,
    doc_img: "https://via.placeholder.com/256",
    name: "Jane Smith",
    specialize: "Neurology",
  },
  {
    doc_id: 3,
    doc_img: "https://via.placeholder.com/256",
    name: "Emily Johnson",
    specialize: "Pediatrics",
  },
  {
    doc_id: 4,
    doc_img: "https://via.placeholder.com/256",
    name: "Michael Brown",
    specialize: "Orthopedics",
  },
];

interface Expert {
  doc_id: number;
  doc_img: string;
  name: string;
  specialize: string;
}

interface OurExpertsState {
  ourExperts: Expert[];
}

class OurExperts extends React.Component<{}, OurExpertsState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      ourExperts: [],
    };
  }

  componentDidMount() {
    const experts = dummyData;

    if (experts.length > 1) {
      const serv = experts.slice(0, 3);
      this.setState({ ourExperts: serv });
    }
  }

  render() {
    const { ourExperts } = this.state;

    return (
      <Box
        sx={{
          bgcolor: "#fce4ec",
          color: "primary.main",
          p: 2,
          mb: 2,
          textAlign: "center",
        }}
      >
        <Container maxWidth="xl">
          <Typography sx={{ mt: 2, mb: 2, fontWeight: 600 }} variant="h6">
            Meet Our Expert Team
          </Typography>

          <Typography sx={{ mb: 8, fontWeight: 600 }} variant="h5">
            We are committed to ensuring you the best service
          </Typography>

          {ourExperts.length > 0 && (
            <Grid container spacing={3}>
              {ourExperts.map((expert) => (
                <Grid
                  key={expert.doc_id}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ mx: "auto" }}
                >
                  <Card
                    sx={{
                      mx: "auto",
                      boxShadow: 10,
                      maxWidth: 345,
                      transition: "0.5s all ease-in-out",
                      ":hover": {
                        color: "#e91e63",
                        boxShadow: 1,
                      },
                      img: { transition: "0.5s all ease-in-out" },
                      ":hover img": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <CardActionArea>
                      <Avatar
                        alt="doctor image"
                        src={expert.doc_img}
                        sx={{
                          width: 256,
                          height: 256,
                          mx: "auto",
                        }}
                      />

                      <CardContent sx={{ display: "flex", mx: "auto", my: 2 }}>
                        <Typography gutterBottom variant="h5" component="div">
                          Specialist in {expert.specialize}
                        </Typography>
                      </CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Dr. {expert.name}
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          <Typography sx={{ mx: 2, p: 2, textAlign: "end" }}>
            <a href="#doctors" className="text-style" color="primary">
              {" "}
              Meet Our Expert Team{" "}
            </a>
          </Typography>
        </Container>
      </Box>
    );
  }
}

export default OurExperts;
