import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import userService from '../../Services/user/user.service';
import { AppointmnetValidationSchema } from './AppointmentValidationSchema';
import { useIdleTimeout } from '../shared/SessionExpire';
import Users from '../../utills/users';
import './style.css';
import { Alert } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from '../DashBoard/sidebar';
import PaymentServices from '../../Services/PatientAppointment services';
import { FaCalendarAlt } from "react-icons/fa";
import Loader from '../shared/Loader';
// import './DatePickerStyles.css';
import { Helmet } from 'react-helmet';
import forgotPasswordServices from '../../Services/forgotPasswordServices';
import { Card, Tab, Tabs, Col, Row, Form as BootstrapForm } from 'react-bootstrap';
import { SHA256 } from 'crypto-js';
import ModelComponent from '../shared/Model';
import ModelComponentAppoinment from '../shared/appoinmentOTP';

interface compProps {
    title: string;
    description: string;
    keywords: string;
}


interface Kyc {
    pastHealthConditions: String;
    pastHealthConditionsDescription: String;
    foodAllergies: String;
    foodAllergiesDescription: String;
    CurrentMedications: String;
    CurrentMedicationsDescription: String;
    ChronicIllnesses: String;
    ChronicIllnessesDescription: String;
    SmokingStatus: String;
    AlcoholConsumption: String;
    heardFrom: String;
    sugar: String,
    bloodPressure: String,
    heartAttack: String,
    pregnacy: String,
}
interface PatientAppoinment {
    id?: string,
    PatientName: string,
    patientID: string,
    PhoneNumber: string,
    DoctorID: string,
    EmailId: string,
    Message: string,
    AppointmentDate: string,
    AppointmentTime: string,
    PaymentMode: string,
    PaymentStatus: string,
    Amount: string | undefined,
    Address: String,
    City: String,
    Street: String,
    pinCode: String,
    DOB: String,
    HouseNumber: String,
    MaritalStatus: String,
    userType: String,
    KYC: Kyc[],
    AppointmentID: String
    Status: String
}

const PatientAppointment: React.FC<compProps> = ({ title, description, keywords }) => {
    // function PatientAppointment() {

    const navigate = useNavigate();
    const [ErrMsgMatch, setErrMsgMatch] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const logUser: any = localStorage.getItem('userData');
    const [doctors, setDoctors] = useState<PatientAppoinment[]>([]);
    const [doctorID, setDoctorID] = useState('');
    const [doctAvail, setDoctAvail] = useState<any>([]);
    const [patientDetails, setPatientDetails] = useState<any>();
    const location = useLocation();
    const isIdle = useIdleTimeout(1000 * 900);
    const isActive = useIdleTimeout(1000 * 900);
    const logUserObject = JSON.parse(logUser);
    const [showRazorpay, setShowRazorpay] = useState(false);
    const today = new Date();
    const todayString = today.toISOString().split('T')[0];
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    let [updateDoctor, setUpdateDoctor] = useState<boolean>(false);
    const [doctorSelected, setDoctorSelected] = useState(false);
    const [doctorDate, setdoctorDate] = useState<string[]>([]);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [timeSlot, setTimeSlot] = useState<any>(15)
    const [availableTimes, setAvailableTimes] = useState([]);
    // const [appointmentDate, setAppointmentDate] = useState('');
    const [appointmentTime, setAppointmentTime] = useState<any>('');
    const [storePaymentDetails, setStorePaymentDetails] = useState<any>([]);
    const [UserDetails, setUserDetails] = useState<any>();
    const [appDecId, setAppDecId] = useState<any>('')
    const { slug } = useParams();
    const [update, setUpdate] = useState(false);
    const lastDayOfMonthString = new Date().toISOString().split('T')[0];
    const setFieldValueRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);
    const [doctorUnavailableMessage, setDoctorUnavailableMessage] = useState('');
    const [alertMsg, setAlertMsg] = useState<any>('');
    const [availableMessage, setAvailableMessage] = useState('');
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [otpStatus, setOtpStatus] = useState<any>(false);
    const [SuccessMsg, setSuccessMsg] = useState("");
    const [timer, setTimer] = useState<number>(60);
    // const [getModal, setModel] = useState(false);
    const [CurrentOTP, setCurrentOTP] = useState("");
    const [OTPErr, setOtpError] = useState("");
    const [getvalues, setValues] = useState<any>({ values: "", form: "" });
    const [getappointmentModal, setAppointmentModal] = useState(false);
    const [getappointmentvalues, setAppointmenttvalues] = useState<any>('');
    const [appointmentSuccessMsg, setAppointmentSuccessMsg] = useState("")


    console.log('UserDetails', UserDetails)
    const initialState: PatientAppoinment = {
        PatientName: UserDetails ? UserDetails?.FirstName : "",
        patientID: 'NA',
        PhoneNumber: UserDetails ? UserDetails?.PhoneNumber : "",
        DoctorID: '',
        EmailId: UserDetails ? UserDetails?.EmailId : "",
        Message: '',
        AppointmentDate: '',
        AppointmentTime: '',
        PaymentMode: '',
        PaymentStatus: '',
        Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
        Address: '',
        City: '',
        Street: '',
        pinCode: '',
        DOB: '',
        HouseNumber: '',
        MaritalStatus: '',
        userType: (logUserObject && logUserObject?.Role === "Patient") ? 'R' : 'G',
        KYC: [{
            pastHealthConditions: '0',
            foodAllergies: '0',
            CurrentMedications: '0',
            ChronicIllnesses: '0',
            SmokingStatus: '',
            AlcoholConsumption: '',
            heardFrom: '',
            pastHealthConditionsDescription: '',
            foodAllergiesDescription: '',
            CurrentMedicationsDescription: '',
            ChronicIllnessesDescription: '',
            sugar: '',
            bloodPressure: '',
            heartAttack: '',
            pregnacy: '',
        }],
        AppointmentID: '',
        Status: "open"
    };
    console.log({ initialState })
    const [InputDetails, setInputDetails] = useState<PatientAppoinment>(initialState);
    const [initialValues, setInitialValues] = useState<PatientAppoinment>(initialState);
    const [existMsg, setExistMsg] = useState("");
    const [errSubmitMsg, setSubmitMsg] = useState("");
    const [getModal, setModel] = useState(false);
    const [getExistingPhone, setExistingPhone] = useState<any>('');
    const [getExistingEmail, setExistingEmail] = useState<any>('');
    const [getcheckPhone, setCheckPhone] = useState<any>('');
    const [getcheckEmail, setCheckEmail] = useState<any>('');
    const [OTPErro, setOTPErr] = useState("");

    const formRef = useRef<HTMLDivElement | null>(null);


    const fieldRefs: any = useRef({
        PatientName: null as HTMLDivElement | null,
        PhoneNumber: null as HTMLDivElement | null,
        DoctorID: null as HTMLDivElement | null,
        EmailId: null as HTMLDivElement | null,
        Message: null as HTMLDivElement | null,
        AppointmentDate: null as HTMLDivElement | null,
        AppointmentTime: null as HTMLDivElement | null,
        Amount: null as HTMLDivElement | null | undefined,
        Address: null as HTMLDivElement | null,
        City: null as HTMLDivElement | null,
        Street: null as HTMLDivElement | null,
        pinCode: null as HTMLDivElement | null,
        DOB: null as HTMLDivElement | null,
        HouseNumber: null as HTMLDivElement | null,
        MaritalStatus: null as HTMLDivElement | null,
        KYC: null as HTMLDivElement | null,

    });




    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerRunning(false);
        }
        // Clean up the interval when the component unmounts or the timer is stopped
        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);


    const validateForm = (values: any) => {
        const requiredFields = ['AppointmentDate'];
        const isComplete = requiredFields?.every(field => values[field]);
        setIsFormComplete(isComplete);
    };

    const getUserDetails = async () => {
        console.log('logUserObject?.userId', logUserObject?.userId)
        console.log("logUserObject?.Role", logUserObject?.Role)
        if (logUserObject?.userId && (logUserObject?.Role === "Patient")) {
            const userDetails: any = await userService().getuserSingle(logUserObject?.userId);
            console.log({ userDetails })
            if (userDetails) {
                setUserDetails(userDetails?.data?.getUser);
            }
        }
        if (slug) {
            const encodSlug: any = await PaymentServices().decodeBase64(slug)
            // console.log('encodSlug', { slug, encodSlug })
            setAppDecId(encodSlug)
        }
    }

    useEffect(() => {
        const today = new Date();
        const maxDay = new Date();
        maxDay.setDate(today.getDate() + 7);
        setMinDate(today);
        setMaxDate(maxDay);
    }, []);


    useEffect(() => {
        getDoctor();
        getUserDetails();
    }, [InputDetails]);

    useEffect(() => {
        const getExistingUser = async () => {

            const today = new Date();
            today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds

            if (location?.state?.patientId || appDecId) {

                console.log('location?.state?.patientId', location?.state?.patientId)
                let getPatientDetails = await userService().ListPatientAppointmentsById(location?.state?.patientId, appDecId);

                getPatientDetails[0].AppointmentDate = getPatientDetails[0].AppointmentDate+'T'+getPatientDetails[0].AppointmentTime;

                console.log({ getPatientDetails });

                if (getPatientDetails[0]?.DoctorID) {
                    // handleDoctorChange('', getPatientDetails[0]?.DoctorID);
                    handleDoctorChange('', getPatientDetails[0]?.DoctorID, '');

                    // Check if the appointment date is today or in the future
                    const appointmentDate = new Date(getPatientDetails[0]?.AppointmentDate);


                    // if (appointmentDate >= today) {
                    //     if (setFieldValueRef.current) {
                    //         handleAppoinmentDate(appointmentDate);
                    //     }
                    // }l
                    if (appointmentDate >= today) {
                        handleDateChange(appointmentDate, setFieldValueRef.current);
                    }
                }
                console.log('getPatientDetails[0]?.AppointmentDate',getPatientDetails[0]?.AppointmentDate)
                getPatientDetails && setInputDetails(getPatientDetails[0]);
                // setInitialValues(getPatientDetails[0]);
                setTimeout(() => {
                    setInitialValues(getPatientDetails[0]);
                }, 200);


                setUpdateDoctor(true);
                setUpdate(true);
            } else {
                console.log('appointment', "else create appoinment")
                handleDateChange(today, setFieldValueRef.current);
            }

        };
        getExistingUser();
    }, [location?.state?.patientId, appDecId]);

    const getDoctor = async () => {
        try {
            const response = await userService().ListDoctor('Doctor');
            // console.log("response", response);
            const doctorIds = response?.map(doctor => doctor?.id);
            setdoctorDate(doctorIds);
            setDoctors(response);
        } catch (error) {
            console.error('Error fetching doctors:', error);
        }
    };

    const handleDoctorChange = async (event: any, doctorId: any, setFieldValue: any) => {
        const selectedDoctorID = event?.target?.value || doctorId;

        setDoctorID(selectedDoctorID);
        setDoctorSelected(selectedDoctorID && selectedDoctorID !== 'Choose Doctor');
        setFieldValue && setFieldValue('AppointmentDate', '');

        if (!selectedDoctorID || selectedDoctorID === 'Choose Doctor') {

            const today = new Date();
            today.setHours(0, 0, 0, 0);
            setAvailableMessage('');
            setDoctorUnavailableMessage('')

            // const today = new Date();
            const availableTimes = getDefaultAvailableTimes(today);
            setAvailableTimes(formatAvailableTimes(availableTimes, today));

            handleDateChange(today, setFieldValueRef.current);

            return;
        }

        const doctorAppointments = await userService().ListPatientAppointments(selectedDoctorID);
        const appointmentTimes = doctorAppointments.map(item => ({
            date: item?.AppointmentDate,
            time: item?.AppointmentTime,
        }));

        setAppointmentTime(appointmentTimes);
        const doctAvailable = await userService().getlistDoctorAvailableDates(selectedDoctorID);
        if (Array.isArray(doctAvailable) && doctAvailable.length > 0) {
            const today = new Date();
            const todayStr = today.toISOString().split('T')[0];
            const ninetyDaysLater = new Date();
            ninetyDaysLater.setDate(today.getDate() + 90);
            const ninetyDaysLaterStr = ninetyDaysLater.toISOString().split('T')[0];
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
            const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            const year = lastDayOfMonth.getFullYear();
            const month = String(lastDayOfMonth.getMonth() + 1).padStart(2, '0');
            const day = String(lastDayOfMonth.getDate()).padStart(2, '0');
            const formattedLastDay = `${year}-${month}-${day}`;
            const futureAvailableDates = doctAvailable.filter(item => {
                const availableDate = new Date(item?.Date).toISOString().split('T')[0];
                return availableDate >= todayStr && availableDate <= ninetyDaysLaterStr;
            });

            const availableDatesForCurrentMonth = futureAvailableDates.filter(item => {
                const availableDate = new Date(item?.Date).toISOString().split('T')[0];
                return availableDate >= firstDayOfMonth && availableDate <= formattedLastDay;
            });

            if (futureAvailableDates.length > 0) {
                const availableDates = futureAvailableDates.map(item => ({ date: item?.Date, times: item?.Time }));
                const isTodayAvailable = futureAvailableDates.some(item => new Date(item?.Date).toISOString().split('T')[0] === todayStr);

                setDoctAvail(availableDates);
                setDoctorUnavailableMessage('');

                if (!location?.state?.patientId && isTodayAvailable) {
                    const todayTimes = availableDates?.find(item => item.date === todayStr)?.times || [];
                    const currentTime = new Date();
                    const filteredTimes = todayTimes.filter((time: any) => {
                        const [hours, minutes] = time.split(':').map(Number);
                        const timeDate = new Date(todayStr);
                        timeDate.setHours(hours, minutes, 0, 0);
                        return timeDate > currentTime;
                    });
                    const formattedTimes: any = formatAvailableTimes(filteredTimes, todayStr);
                    todayTimes?.length > 0 && setAvailableTimes(formattedTimes);
                } else {
                    setAvailableTimes([]);
                }
                if (availableDatesForCurrentMonth?.length === 0) {
                    const firstAvailableDate = futureAvailableDates.find(item => new Date(item?.Date) > today);
                    if (firstAvailableDate) {
                        const firstAvailableDateStr = new Date(firstAvailableDate.Date).toLocaleDateString();
                        setAvailableMessage(`This doctor is not available for the current month. Next available date is ${firstAvailableDateStr}.`);
                    } else {
                        setDoctorUnavailableMessage('This doctor is not available for the next few months.');
                    }
                    setAvailableTimes([]);
                } else {
                    setAvailableMessage('');
                }
            } else {
                setDoctAvail([]);
                setAvailableMessage('');
                setDoctorUnavailableMessage('This doctor is not available.');
                setAvailableTimes([]);
            }
        } else {
            setDoctAvail([]);
            setDoctorUnavailableMessage('This doctor is not available.');
            setAvailableTimes([]);
            setAvailableMessage('');
        }
        const doctorDetails = await userService().ListDoctorsById(selectedDoctorID);
        setTimeSlot(doctorDetails?.[0]?.TimeSlot || 15);
    };

    const isDateAvailable = (date: any) => {
        const moment = require('moment');
        const today = moment().startOf('day');
        const oneWeekLater = moment().add(7, 'days').startOf('day');
        if (!doctorSelected || doctorID === '' || doctorID === 'Choose Doctor') {
            return date >= today && date <= oneWeekLater;
        }
        const previousDay = new Date(date);
        previousDay.setDate(date.getDate() + 1);
        const formattedDate = previousDay.toISOString().split("T")[0];
        // console.log("isDateAvailable", formattedDate, doctAvail);
        // console.log("doctAvail", doctAvail);
        return doctAvail?.some((item: any) => item?.date === formattedDate);
    };

    const getDefaultAvailableTimes = (date: any) => {
        const times = [];
        const start = new Date(date);
        start.setHours(10, 0, 0, 0);
        const end = new Date(date);
        end.setHours(20, 0, 0, 0);
        while (start < end) {
            times.push(`${start.getHours()}:${start.getMinutes() === 0 ? '00' : start.getMinutes()}`);
            start.setMinutes(start.getMinutes() + timeSlot);
        }
        return times;
    };
    const getAvailableTimes = (date: any) => {
        const formattedDate = format(date, "yyyy-MM-dd");
        const dateAvailability = doctAvail.find((item: any) => item?.date === formattedDate);
        let availableTimes = dateAvailability ? dateAvailability.times : getDefaultAvailableTimes(date);
        const bookedTimesForDate = appointmentTime && appointmentTime
            .filter((item: any) => item?.date === formattedDate)
            .map((item: any) => item?.time);
        availableTimes = availableTimes.filter((time: any) => !bookedTimesForDate.includes(time));
        const today = new Date();
        if (formattedDate === format(today, "yyyy-MM-dd")) {
            const currentTime = `${today.getHours()}:${today.getMinutes() < 10 ? '0' : ''}${today.getMinutes()}`;
            availableTimes = availableTimes.filter((time: any) => {
                const [hours, minutes] = time.split(":");
                const formattedTime = `${parseInt(hours)}:${parseInt(minutes) < 10 ? '0' : ''}${parseInt(minutes)}`;
                return formattedTime >= currentTime;
            });
        }
        return availableTimes;
    };

    const formatAvailableTimes: any = (times: string[], date: Date) => {
        return times?.map(time => {
            // console.log("times", time);
            const [hours, minutes] = time?.split(":");
            const newDate = new Date(date);
            newDate.setHours(parseInt(hours), parseInt(minutes));
            return newDate;
        });
    };

    // const handleDateChange = (date: any, setFieldValue: any) => {

    //     const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm");
    //     // console.log({formattedDate});
    //     setFieldValue("AppointmentDate", formattedDate);
    //     const doctorAvailableTimes = getAvailableTimes(date);
    //     const formattedTimes: any = formatAvailableTimes(availableTimes, date);
    //       doctorAvailableTimes?.length > 0 && setAvailableTimes(formattedTimes);
    // };

    const handleDateChange = (date: any, setFieldValue: any) => {
        const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm");
        console.log({ formattedDate });
        setFieldValue("AppointmentDate", formattedDate);
        const doctorAvailableTimes = getAvailableTimes(date);
        const formattedTimes: any = formatAvailableTimes(doctorAvailableTimes, date);
        console.log('formattedTimes', typeof (doctorAvailableTimes))
        doctorAvailableTimes?.length > 0 && setAvailableTimes(formattedTimes);
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
            (day % 10 === 2 && day !== 12) ? 'nd' :
                (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

        return `${day}${suffix} ${month} ${year}`;
    };

    const handleEditOTPForm = async (values: PatientAppoinment, { setSubmitting, resetForm }: any) => {
        // setValues(values, { setSubmitting, resetForm });
        setValues({
            values: values,  // or values?.fieldName if you want to update specific fields
            form: { setSubmitting, resetForm } // You can store form-related methods or values here
        });
        await PaymentServices().handleUpdateCheck(values, logUserObject, setLoading, setSuccessMsg, setModel, setIsTimerRunning, setTimer, handleUpdate, { setSubmitting, resetForm });
    }

    const handleUpdate = async (values: PatientAppoinment, { setSubmitting, resetForm }: any) => {
        console.log("handleUpdate", values);
        // const isEditing = !!values?.id;
        window.scrollTo(0, 0);
        const [date, time] = values?.AppointmentDate?.split('T');
        try {
            setLoading(true);
            const GetInsertDataUpdate = {
                id: values?.id,
                PatientName: values?.PatientName,
                patientID: values?.patientID,
                // patientID: logUserObject?.Role === "Patient" ? logUserObject?.userId : InputDetails?.patientID ? InputDetails?.patientID : null,
                PhoneNumber: values?.PhoneNumber,
                DoctorID: values?.DoctorID,
                EmailId: values?.EmailId,
                Message: values?.Message,
                AppointmentDate: date,
                AppointmentTime: time,
                PaymentMode: values?.PaymentMode,
                Amount: values?.PaymentMode === 'online' ? 500 : values?.Amount,
                Address: values?.Address,
                City: values?.City,
                Street: values?.Street,
                pinCode: values?.pinCode,
                DOB: values?.DOB,
                HouseNumber: values?.HouseNumber,
                MaritalStatus: values?.MaritalStatus,
                userType: values?.userType,
                KYC: values?.KYC,
                AppointmentID: values?.AppointmentID,
                PaymentStatus: values?.PaymentStatus,
                Status: values?.Status
            };
            const userResponse = await userService().updatePatientAppointmentByData(GetInsertDataUpdate);
            // console.log("userResponse", userResponse);
            const appDate = formatDate(userResponse?.data?.updatePatientAppointment?.AppointmentDate)

            const appEncId = userResponse && await PaymentServices().encodeBase64(userResponse?.data?.updatePatientAppointment?.AppointmentID)

            if (userResponse) {
                if (userResponse?.data?.updatePatientAppointment?.EmailId) {
                    try {
                        const EmailResponse: any = await Users().generateEmailSMS(
                            userResponse?.data?.updatePatientAppointment
                                ?.EmailId,
                            `<html><body><div>Welcome to Dental Hospitals!<br /><br />Thank you for contacting us,your appointment has been updated succesfully on ${appDate} at ${userResponse?.data?.updatePatientAppointment
                                ?.AppointmentTime} and Your Appointment ID is ${userResponse?.data?.updatePatientAppointment?.AppointmentID}. We appreciate your interest and will get back to you shortly.<br/> Please <a href='${process.env.REACT_APP_HOST}PatientAppointment/${appEncId}'> click here</a> to Re-Schedule your Appointment<br /> Please <a href='${process.env.REACT_APP_HOST}appointmentHistory/${appEncId}'> click here </a>to cancle your Appointment<br/><br />Best Regards,<br />Dental HospitalsTeam</div></body></html>`,
                            'Dental Hospitals,Patient Appointment',
                            'Your appointment created successfully'
                        );
                    } catch (err) {
                        console.log("Error sendign Email", err);
                    }
                }
                try {
                    const MobileSMSResponse = await Users().generateMobileMsgBulk("+91" + userResponse?.data?.updatePatientAppointment?.PhoneNumber, `Thank you! Your appointment for a consultation has been successfully rescheduled on ${appDate} at ${userResponse?.data?.updatePatientAppointment?.AppointmentTime}. Your appointment ID is ${userResponse?.data?.updatePatientAppointment?.AppointmentID} - CLUSTERIT`, "success")
                } catch (err) {
                    console.log("Error sending SMS", err)
                }

                if (userResponse?.data?.updatePatientAppointment) {
                    setLoading(false);
                    // setSuccessMessage("Patient updated successfully!");
                    resetForm();
                    setLoading(false);
                    // alert("Appointment updated successfully!");
                    setAlertMsg("Appointment updated successfully!")

                    // if (logUserObject?.userId) {
                    //     navigate('/AppointmentList');
                    // } else {
                    //     navigate('/');
                    // }
                }
                // navigate("/AppointmentList");
            } else {
                setLoading(false);
                setErrMsg("Patient update failed!");
            }
            setSubmitting(false);
            // if (logUserObject?.userId) {
            //     setLoading(false);
            //     alert("Appointment update successfully!");
            //     navigate('/AppointmentList');
            // } else {
            //     setLoading(false);
            //     alert("Appointment update successfully!");
            //     navigate('/');
            // }
            // navigate('/AppointmentList');
            //   logUserObject?.userId ? navigate('/AppointmentList') : navigate('/');
        } catch (error) {
            setLoading(false);
            setErrMsg("An error occurred while updating the patient appointment.");
            alert("An error occurred. Please try again.");
            console.log(error);
        }
    };

    // const maskEmail = (email: any) => {
    //     const emailParts = email.split("@");
    //     const localPart = emailParts[0]; // The part before '@'
    //     const domainPart = emailParts[1]; // The part after '@'

    //     // Get first 4 letters of the local part
    //     const firstFour = localPart.substring(0, 4);

    //     // Get last 2 letters of the local part
    //     const lastTwoBeforeAt = localPart.slice(-2);

    //     // Mask the middle portion with '*'
    //     const maskedPart = "*".repeat(localPart.length - 6); // 6 because 4 characters are shown at the start and 2 at the end

    //     // Construct the final masked email
    //     return `${firstFour}${maskedPart}${lastTwoBeforeAt}@${domainPart}`;
    // };

    const maskEmail = (email: any) => {
        const emailParts = email.split("@");
        const localPart = emailParts[0]; // The part before '@'
        const domainPart = emailParts[1]; // The part after '@'

        // Get first 4 letters of the local part
        // const firstFour = localPart?.substring(0, 4);
        const firstFour = localPart?.substring(0, 4);

        // Get last 2 letters of the local part
        const lastTwoBeforeAt = localPart?.slice(-2);

        const middleLength = localPart.length - 6;

        // Mask the middle portion with '*'
        // const maskedPart = "*".repeat(localPart?.length - 6); // 6 because 4 characters are shown at the start and 2 at the end
        const maskedPart = middleLength > 0 ? "*".repeat(middleLength) : "";
        // Construct the final masked email
        return `${firstFour}${maskedPart}${lastTwoBeforeAt}@${domainPart}`;
    };

    const maskPhoneNumber = (phone: any) => {
        const lastFourDigits = phone.slice(-4);  // Get last 4 digits
        const maskedPart = "*".repeat(6);        // Mask the first 6 digits

        // Construct the final masked phone number
        return `${maskedPart}${lastFourDigits}`;
    };


    const handleSubmit = async (values: PatientAppoinment, { setSubmitting, resetForm }: any) => {
        // window.scrollTo(0, 0);
        setAppointmenttvalues(values);
        let errorMessage: any, maskedEmail: any, maskedPhone: any, GetInsertDataUpdate: any, appDate: any, appEncId: any;
        setExistMsg("");
        let check: any;
        const checkEmail: any = await userService().validateEmail(values?.EmailId.toLowerCase());
        const checkPhone: any = await userService().validatePhonenumber(values?.PhoneNumber);
        setCheckPhone(checkPhone);
        const emailAndPhone: any = await PaymentServices().checkExistingUsers(values?.EmailId, values?.PhoneNumber);
        console.log({ checkEmail, checkPhone, emailAndPhone, values })
        // if (!emailAndPhone && emailAndPhone?.length === 0 && !checkEmail && checkEmail?.length === 0 && !checkPhone && checkPhone?.length === 0) {
        if (emailAndPhone?.length === 0 && checkEmail?.length === 0 && checkPhone?.length === 0) {
            // check = emailAndPhone;
            check = [];
        } else if (checkEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.EmailId === values?.EmailId && checkPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.PhoneNumber !== values?.PhoneNumber) {
            check = 'exist';
        } else {
            check = checkPhone?.existingPhoneUser?.data?.listUsers?.items;
        }
        let userResponse: any;
        const [date, time] = values?.AppointmentDate.split('T');

        try {
            setLoading(true);
            let newId;
            const appIDResponse = await PaymentServices().generateAppointmentID();
            if (appIDResponse?.status === 400) {
                newId = "GDL001";
            } else if (appIDResponse?.status === 200) {
                const generatedID = appIDResponse?.data?.AppointmentID;
                // newId = generatedID + 1;
                const prefix = generatedID.slice(0, 5);
                const numericPart = generatedID.slice(5);
                const newNumericPart = (parseInt(numericPart, 10) + 1).toString().padStart(3, '0');
                newId = prefix + newNumericPart;
            }
            values.PaymentMode = 'offline';
            const formatEmail = values?.EmailId.toLowerCase();

            // const userResponse = await userService().getcreateAppointment(GetInsertDataUpdate);
            if (values?.PaymentMode === 'offline') {
                try {
                    // const check: any = await BulkServices().checkExistingUsers(userResponse?.data?.createPatientAppointment?.EmailId, userResponse?.data?.createPatientAppointment?.PhoneNumber);
                    let create: any, errmsg: any;
                    if (check?.length === 0) {
                        const createData = {
                            EmailId: formatEmail ? formatEmail : 'NA',
                            PhoneNumber: values?.PhoneNumber,
                            FirstName: values?.PatientName,
                            LastName: "NA",
                            userType: "G",
                            Role: "Patient",
                            resetPWD: "Yes",
                            Specialization: "NA",
                            Password: "NA",
                            countryCode: "+91",
                            Slug: "NA",
                            Status: 1,
                        }
                        create = !errorMessage && await userService().getcreateUser(createData);
                        // update the userType and patientID ( which is primary key reference in user table)
                        if (create) {
                            GetInsertDataUpdate = {
                                PatientName: values.PatientName,
                                // patientID: logUserObject?.Role === "Patient" ? logUserObject?.userId : 'NA',
                                // patientID: check?.length > 0 ? check[0]?.id : 'NA',
                                patientID: create?.data?.createUser?.id,
                                PhoneNumber: values?.PhoneNumber,
                                DoctorID: values?.DoctorID,
                                EmailId: formatEmail || 'N/A',
                                Message: values?.Message,
                                AppointmentDate: date,
                                AppointmentTime: time,
                                PaymentMode: 'offline',
                                // PaymentMode: values?.PaymentMode,
                                // Amount: values?.Amount,
                                Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
                                Address: values?.Address,
                                City: values?.City,
                                Street: values?.Street,
                                pinCode: values?.pinCode,
                                DOB: values?.DOB,
                                HouseNumber: values?.HouseNumber,
                                MaritalStatus: values?.MaritalStatus,
                                KYC: values?.KYC,
                                AppointmentID: newId,
                                // userType: values?.userType,
                                userType: check?.length > 0 ? check[0]?.userType : "G",
                                // PaymentStatus: values?.PaymentMode === 'online' ? '1' : '0'
                                PaymentStatus: '0',
                                Status: "open"
                            };
                            // creating the appointmnet
                            userResponse = await userService().getcreateAppointment(GetInsertDataUpdate);
                        }
                        setAlertMsg("Patient Appointment Booked successfully!");
                        setLoading(false);
                    }
                    //check the email and phone number
                    else if (check?.length === 1) {
                        const existingPhone = checkPhone?.existingPhoneUser?.data?.listUsers?.items[0];
                        // setExistingPhone(existingPhone);
                        if (formatEmail && existingPhone?.EmailId !== formatEmail) {
                            const existingEmail = checkEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.EmailId;
                            setExistingEmail(existingEmail);

                            if (existingEmail === formatEmail) {
                                setSubmitMsg("User already exists with this email ID. Please provide another email ID.");
                                // errmsg = "User already exists with this email ID. Please provide another email ID.";
                                // setLoading(false);
                                setSubmitting(false);
                                return;
                            } else {
                                // Send OTP to the new email
                                const userResp = await PaymentServices().OTPgenerate(formatEmail)
                                try {
                                    if (!userResp?.ok) {
                                        throw new Error(`Error: ${userResp?.statusText}`);
                                    } else {
                                        setLoading(false)
                                        setAppointmentModal(true);
                                        setSubmitting(false);
                                        setOtpStatus(true);
                                        // setAppointmentSuccessMsg("OTP sent to your EmailId");
                                        setAppointmentSuccessMsg(`Please provide the OTP Sent to your email ${formatEmail}`);
                                        setSubmitMsg("");
                                        setIsTimerRunning(true);
                                        // setUserName(formatEmail);
                                    }
                                } catch (error) {
                                    console.error(error)
                                }
                                // }
                            }
                        }
                        else {
                            const updateData = {
                                id: check[0]?.id,
                                userType: check[0]?.userType === "G" ? "E" : check[0]?.userType
                            }
                            // update userType in users tables
                            const userUpdate = await userService().updateDoctorByData(updateData);
                            GetInsertDataUpdate = {
                                PatientName: values.PatientName,
                                // patientID: logUserObject?.Role === "Patient" ? logUserObject?.userId : 'NA',
                                // patientID: check?.length > 0 ? check[0]?.id : 'NA',
                                patientID: check[0]?.id,
                                PhoneNumber: values?.PhoneNumber,
                                DoctorID: values?.DoctorID,
                                EmailId: formatEmail ? formatEmail : 'N/A',
                                Message: values?.Message,
                                AppointmentDate: date,
                                AppointmentTime: time,
                                PaymentMode: 'offline',
                                // PaymentMode: values?.PaymentMode,
                                // Amount: values?.Amount,
                                Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
                                Address: values?.Address,
                                City: values?.City,
                                Street: values?.Street,
                                pinCode: values?.pinCode,
                                DOB: values?.DOB,
                                HouseNumber: values?.HouseNumber,
                                MaritalStatus: values?.MaritalStatus,
                                KYC: values?.KYC,
                                AppointmentID: newId,
                                // userType: values?.userType,
                                userType: userUpdate?.data?.updateUser?.userType,
                                // PaymentStatus: values?.PaymentMode === 'online' ? '1' : '0'
                                PaymentStatus: '0'
                            };
                            // create user appointment
                            userResponse = await userService().getcreateAppointment(GetInsertDataUpdate);
                            // update the user appointments
                            if (userUpdate) {
                                const updateAppointment = await PaymentServices().updateuserType(userUpdate?.data?.updateUser?.id, values?.PhoneNumber, userUpdate?.data?.updateUser?.userType);
                            }
                            setAlertMsg("Patient Appointment Booked successfully!");
                            setLoading(false);
                        }
                    }
                    // check exist email id
                    else if (check === 'exist') {
                        maskedEmail = maskEmail(checkEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.EmailId);
                        setExistMsg(`User already exists with ${maskedEmail}`);
                        // errorMessage = "User already exists with this email id";
                        setLoading(false);
                    }
                }
                catch (err) {
                    console.log("error creating user", err);
                    setLoading(false);
                }
                // after getting the reponse send sms,email and push notification
                if (userResponse?.data?.createPatientAppointment?.EmailId) {


                    const appDate = formatDate(userResponse?.data?.createPatientAppointment?.AppointmentDate)
                    const appEncId = await PaymentServices().encodeBase64(userResponse?.data?.createPatientAppointment?.AppointmentID)
                    try {
                        const mailResponse: any = await Users().generateEmailSMS(
                            userResponse?.data?.createPatientAppointment?.EmailId,
                            `<html><body><div>Welcome to Dental Hospital!<br /><br />Thank you for contacting us,your appointment has been  booked successfully on ${appDate} at ${userResponse?.data?.createPatientAppointment?.AppointmentTime} and Your AppointmentId is ${userResponse?.data?.createPatientAppointment?.AppointmentID}. We appreciate your interest and will get back to you shortly.
                    <br/>Please <a href='${process.env.REACT_APP_HOST}PatientAppointment/${appEncId}'>click here</a> to Re-Schedule your Appointment<br />
                     Please <a href='${process.env.REACT_APP_HOST}appointmentHistory/${appEncId}'>click here</a> to cancle your Appointment<br/><br />Best Regards,<br />Dental Hospital Team</div></body></html>`,
                            'Dental Hospital,Patient Appointment',
                            'Your appointment created successfully'
                        );

                    } catch (err) {
                        console.error("Error sending email:", err);
                        setLoading(false);
                    }
                }

                try {
                    await Users().generateMobileMsgBulk(
                        "+91" + userResponse?.data?.createPatientAppointment?.PhoneNumber,
                        `Thank you! Your appointment for a doctor consultation has been successfully scheduled on ${appDate} at ${userResponse?.data?.createPatientAppointment?.AppointmentTime}. Your appointment ID is ${userResponse?.data?.createPatientAppointment?.AppointmentID} - CLUSTERIT`,
                        'Your appointment created successfully'
                    )
                } catch (err) {
                    console.error("Error sending SMS:", err);
                    setLoading(false);
                }

                if (userResponse && userResponse?.data) {
                    setLoading(true)
                    setAlertMsg("Patient Appointment Booked successfully!");
                    setLoading(false);
                }
                resetForm();
                setSubmitting(false);
                // logUserObject?.userId ? navigate('/listpatients') : navigate('/');
                //check if payment mode is online
            } else if (values?.PaymentMode === 'online') {
                const userPaymentresponse: any = await handlePay(GetInsertDataUpdate, setSubmitting, resetForm);
                // console.log({ userPaymentresponse });
            }
        } catch (error) {
            setLoading(false);
            console.error('Error during form submission:', error);
            setSubmitting(false);
        }
    };

    const handleAppoinmentOTPSubmit = async () => {
        try {
            // Initial declarations
            let GetInsertDataUpdate: any, userResponse: any;
            let newId = ""; // Initialize `newId` with an empty string
            const [date, time] = getappointmentvalues?.AppointmentDate?.split('T');
            const formatEmail = getappointmentvalues?.EmailId?.toLowerCase();
            // Encrypt and retrieve local OTP
            // const encrypted = SHA256(CurrentOTP.toString()).toString();
            const localOTP = localStorage.getItem("appointmentOTP");
            // Compare the stored OTP with the encrypted OTP
            if (localOTP === CurrentOTP) {
                setAppointmentModal(false);
                localStorage.removeItem("appointmentOTP");
                const emailUpdate = await forgotPasswordServices().updateEmail(
                    getappointmentvalues?.EmailId,
                    getcheckPhone.existingPhoneUser?.data.listUsers.items[0].id
                );
                const existingPhone = getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.PhoneNumber;
                // Generate a new Appointment ID
                const appIDResponse = await PaymentServices().generateAppointmentID();
                if (appIDResponse?.status === 400) {
                    newId = "GDL001";
                } else if (appIDResponse?.status === 200) {
                    const generatedID = appIDResponse?.data?.AppointmentID;
                    const prefix = generatedID.slice(0, 5);
                    const numericPart = generatedID.slice(5);
                    const newNumericPart = (parseInt(numericPart, 10) + 1).toString().padStart(3, '0');
                    newId = prefix + newNumericPart;
                }
                const updateData = {
                    id: getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.id,
                    userType: getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.userType === "G" ? "E" : getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.userType,
                };
                // Update userType in users table
                const userUpdate = await userService().updateDoctorByData(updateData);
                // Prepare appointment data for insertion
                GetInsertDataUpdate = {
                    PatientName: getappointmentvalues?.PatientName,
                    patientID: getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.id,
                    PhoneNumber: getappointmentvalues?.PhoneNumber,
                    DoctorID: getappointmentvalues?.DoctorID,
                    EmailId: formatEmail ? formatEmail : 'N/A',
                    Message: getappointmentvalues?.Message,
                    AppointmentDate: date,
                    AppointmentTime: time,
                    PaymentMode: 'offline',
                    Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
                    Address: getappointmentvalues?.Address,
                    City: getappointmentvalues?.City,
                    Street: getappointmentvalues?.Street,
                    pinCode: getappointmentvalues?.pinCode,
                    DOB: getappointmentvalues?.DOB,
                    HouseNumber: getappointmentvalues?.HouseNumber,
                    MaritalStatus: getappointmentvalues?.MaritalStatus,
                    KYC: getappointmentvalues?.KYC,
                    AppointmentID: newId,
                    userType: userUpdate?.data?.updateUser?.userType,
                    PaymentStatus: '0',
                };
                // Create user appointment
                await userService().getcreateAppointment(GetInsertDataUpdate);

                // Update the user appointments if the user update was successful
                if (userUpdate) {
                    await PaymentServices().updateuserType(
                        userUpdate?.data?.updateUser?.id,
                        getappointmentvalues?.PhoneNumber,
                        userUpdate?.data?.updateUser?.userType
                    );
                }
                setAlertMsg("Patient Appointment Booked successfully!");
                setLoading(false);
                // Check if the phone number exists and the email does not match
                // if (existingPhone?.EmailId !== formatEmail) {
                //     const existingEmail = getcheckEmail?.existingEmailUsers?.data?.listUsers?.items[0]?.EmailId;
                //     console.log("existingEmail", existingEmail);

                //     if (existingEmail === formatEmail) {
                //         setSubmitMsg("User already exists with this email ID. Please provide another email ID.");
                //         setLoading(false);
                //         // setSubmitting(false);
                //         return;
                //     }
                // } else {
                //     // Generate a new Appointment ID
                //     const appIDResponse = await PaymentServices().generateAppointmentID();

                //     if (appIDResponse?.status === 400) {
                //         newId = "GDL001";
                //     } else if (appIDResponse?.status === 200) {
                //         const generatedID = appIDResponse?.data?.AppointmentID;
                //         const prefix = generatedID.slice(0, 5);
                //         const numericPart = generatedID.slice(5);
                //         const newNumericPart = (parseInt(numericPart, 10) + 1).toString().padStart(3, '0');
                //         newId = prefix + newNumericPart;
                //     }

                //     const updateData = {
                //         id: getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.id,
                //         userType: getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.userType === "G" ? "E" : getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.userType,
                //     };

                //     // Update userType in users table
                //     const userUpdate = await userService().updateDoctorByData(updateData);

                //     // Prepare appointment data for insertion
                //     GetInsertDataUpdate = {
                //         PatientName: getappointmentvalues?.PatientName,
                //         patientID: getcheckPhone?.existingPhoneUser?.data?.listUsers?.items[0]?.id,
                //         PhoneNumber: getappointmentvalues?.PhoneNumber,
                //         DoctorID: getappointmentvalues?.DoctorID,
                //         EmailId: formatEmail ? formatEmail : 'N/A',
                //         Message: getappointmentvalues?.Message,
                //         AppointmentDate: date,
                //         AppointmentTime: time,
                //         PaymentMode: 'offline',
                //         Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
                //         Address: getappointmentvalues?.Address,
                //         City: getappointmentvalues?.City,
                //         Street: getappointmentvalues?.Street,
                //         pinCode: getappointmentvalues?.pinCode,
                //         DOB: getappointmentvalues?.DOB,
                //         HouseNumber: getappointmentvalues?.HouseNumber,
                //         MaritalStatus: getappointmentvalues?.MaritalStatus,
                //         KYC: getappointmentvalues?.KYC,
                //         AppointmentID: newId,
                //         userType: userUpdate?.data?.updateUser?.userType,
                //         PaymentStatus: '0',
                //     };

                //     // Create user appointment
                //     userResponse = await userService().getcreateAppointment(GetInsertDataUpdate);

                //     // Update the user appointments if the user update was successful
                //     if (userUpdate) {
                //         await PaymentServices().updateuserType(
                //             userUpdate?.data?.updateUser?.id,
                //             getappointmentvalues?.PhoneNumber,
                //             userUpdate?.data?.updateUser?.userType
                //         );
                //     }

                //     setAlertMsg("Patient Appointment Booked successfully!");
                //     setLoading(false);
                // }
            } else {
                setOTPErr("Invalid OTP. Please check the OTP and try again.");
            }
        } catch (error) {
            console.error("Error in OTP submission:", error);
            setLoading(false);
            setOTPErr("An error occurred while submitting OTP. Please try again.");
        }
    };

    const fields = [
        { name: 'pastHealthConditions', label: 'Past Health Conditions' },
        { name: 'foodAllergies', label: 'Allergies' },
        { name: 'CurrentMedications', label: 'Current Medications' },
        { name: 'ChronicIllnesses', label: 'Chronic Illnesses' },
        { name: 'SmokingStatus', label: 'Smoking' },
        { name: 'AlcoholConsumption', label: 'Alcohol Consumption' },
        { name: 'sugar', label: 'Diabetes' },
        { name: 'bloodPressure', label: 'Blood Pressure' },
        { name: 'heartAttack', label: 'Heart Attack' },
        { name: 'pregnacy', label: 'Pregnant' }
    ];

    const handlePayment = async (options: any) => {
        // Ensure Razorpay is loaded
        if (!(window as any).Razorpay) {
            console.error("Razorpay is not loaded");
            return;
        }
        try {
            const rzp = new (window as any).Razorpay(options);
            console.log("rzp", rzp)
            rzp.open();
        } catch (error) {
            console.error("Error in Razorpay payment:", error);
        }
    };

    const handlePay = async (GetInsertData: any, setSubmitting: any, resetForm: any) => {

        // if (logUserObject?.accessToken) {
        try {
            const amt: any = process.env.REACT_APP_APPOINTMENT_AMOUNT;
            const options: any = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                amount: amt * 100,
                currency: 'INR',
                name: 'Gurram Dental Care',
                description: 'Course Description',
                handler: async (response: any) => {
                    // alert('Payment successful!');
                    // const razorpay_payment_id = response;
                    setStorePaymentDetails(response?.razorpay_payment_id);
                    // console.log("response", { response, storePaymentDetails })
                    // Create appointment for online payment
                    const userResponse = response?.razorpay_payment_id && await userService().getcreateAppointment(GetInsertData);
                    // create payment data
                    const paymentData = {
                        appointmentId: userResponse?.data?.createPatientAppointment?.id,
                        razorpay_payment_id: response?.razorpay_payment_id,
                        Amount: userResponse?.data?.createPatientAppointment?.Amount,
                    }
                    const PyResponse: any = await PaymentServices().PaymentDetails(paymentData);
                    const appDate = formatDate(userResponse?.data?.createPatientAppointment?.AppointmentDate)
                    const appEncId = await PaymentServices().encodeBase64(userResponse?.data?.createPatientAppointment?.AppointmentID)
                    if (userResponse?.status === 200) {
                        // alert('appointment booked succesfully')
                        if (userResponse?.data?.createPatientAppointment?.EmailId) {
                            try {
                                const SMSresponse: any = await Users().generateEmailSMS(
                                    userResponse?.data?.createPatientAppointment?.EmailId,
                                    `<html><body><div>Welcome to Gurram Dental Hospitals!<br /><br />Thank you for contacting us,your appointment booked succesfully on ${appDate} at ${userResponse?.data?.createPatientAppointment?.AppointmentTime} and your appointment id is ${userResponse?.data?.createPatientAppointment?.AppointmentID}. We appreciate your interest and will get back to you shortly.<br/>Please <a href='${process.env.REACT_APP_HOST}PatientAppointment/${appEncId}'>click here</a> to Re-Schedule your Appointment<br />click here to cancle your Appointmnet <a href='${process.env.REACT_APP_HOST}appointmentHistory/${appEncId}'>click here</a><br/><br />Best Regards,<br />Gurram Dental Hospital Team</div></body></html>`,
                                    'Gurram Dental Hospitals,Patient Appointment',
                                    'Your appointment created successfully'
                                );
                            } catch (err) {
                                console.log("Error sending email", err);
                            }
                        }
                        try {
                            const MobileSMSResponse = await Users().generateMobileMsgBulk("+91" + userResponse?.data?.createPatientAppointment?.PhoneNumber, `Thank you! Your appointment for a doctor consultation has been successfully scheduled on ${appDate} at ${userResponse?.data?.createPatientAppointment?.AppointmentTime}. Your appointment ID is ${userResponse?.data?.createPatientAppointment?.AppointmentID} - CLUSTERIT`, "Your appointment created successfully");
                        } catch (err) {
                            console.log("Error sending SMS", err)
                        }

                        if (PyResponse?.razorpay_payment_id) {
                            if (userResponse?.data?.createPatientAppointment) {
                                setLoading(false)
                                // resetForm();
                                // setAlertMsg(true)
                                // setTimeout(() => {
                                // alert('Appointment booked successfully');

                                setAlertMsg('Appointment booked successfully')

                                // if (logUserObject?.userId) {
                                //     navigate('/AppointmentList');
                                // } else {
                                //     navigate('/');
                                // }

                            }
                        }
                    }
                    // navigate("/AppointmentList");
                },
                prefill: {
                    email: 'Gurram Dental Care',
                    contact: '9848022338',
                },
                notes: {
                    address: 'Gurram Dental Care',
                },
                theme: {
                    color: '#528FF0',
                },
            };

            handlePayment(options);
            // }
        } catch (error) {
            console.error('Error payment:', error);
        }
        // }
        // else {
        //     navigate("/")
        // }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [errSubmitMsg, existMsg, alertMsg]);

    // existMsg && console.log("existMsg", existMsg);

    const handleOTPSubmit = async (resetform: any) => {
        await PaymentServices().handleOTPSubmitUpdate(setOtpError, timer, CurrentOTP, getvalues, setModel, handleUpdate, setIsTimerRunning, setTimer);
    };

    const ResendEmail = async () => {
        setOtpError("");
        const { values, form } = getvalues;
        const { setSubmitting, resetForm } = form;
        handleEditOTPForm(values, { setSubmitting, resetForm });
    };

    const ResendEmailAppoinment = async () => {
        setOtpError("");
        const otpResp = await PaymentServices().OTPgenerate(getappointmentvalues?.EmailId);
        // console.log("otpResp",otpResp)
        if (!otpResp?.ok) {
            throw new Error(`Error: ${otpResp?.statusText}`);
        }
        else {
            setLoading(false)
            setAppointmentModal(true);
            setCurrentOTP("");
            setOtpStatus(true);
            setAppointmentSuccessMsg("OTP sent to your EmailId");
            setSubmitMsg("");
            setIsTimerRunning(true);
            setTimer(60);
        }
    }

    const closeModal = () => {
        setModel(false);
        setAppointmentModal(false);
        // setModalHeading("");
    };
    // const handleClose = () => {
    //     if (logUserObject?.userId) {
    //         // navigate('/AppointmentList');
    //         navigate("/ViewAppointment", { state: { phoneNumber: location?.state?.phoneNumber, patientID: location?.state?.patientID } })
    //     } else if (location?.state?.path) {
    //         navigate(`${location?.state?.path}`, { state: { PhoneNumber: location?.state?.PhoneNumber } })
    //     } else {
    //         navigate('/')
    //     }
    // }
    const handleClose = (alertMsg: any) => {
        if (logUserObject?.userId && alertMsg === "Appointment updated successfully!") {
            navigate("/ViewAppointment", { state: { phoneNumber: location?.state?.phoneNumber, patientID: location?.state?.patientID } })
        } else if (logUserObject?.userId) {
            navigate('/AppointmentList');
        } else {
            navigate('/');
        }
    }

    const scrollToTopOnErrors = (isValid: boolean) => {
        if (!isValid && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    console.log('initialValues', initialValues)
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
            <>


                <Helmet>
                    <title>{title}</title>
                    <meta name="keywords" content={keywords} />
                    <meta name="description" content={description} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta name="author" content="Gurram Dental Care" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </Helmet>
                <section className="admin-section">
                    <div
                        className="slider-item bread-item"
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <h1>
                                        Appointments
                                    </h1>
                                    <p className="captionName" style={{ textAlign: 'center', color: 'white' }}>Your Path to Timely and Compassionate Care.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div>
                    <ul className="paginationbanner">
                        <li>
                            <span onClick={() => navigate('/')} className="aColor">Home  /&nbsp;</span>
                        </li>
                        <li>Appointments</li>
                    </ul>
                </div>

                {
                    alertMsg &&
                    <div className='alertMessage'>
                        <Alert variant="success" onClose={() => { handleClose(alertMsg) }} dismissible>
                            {alertMsg}
                            {loading ? (
                                <Loader />
                            ) : (null)}
                        </Alert>
                    </div>
                }
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-2" style={{ marginTop: '50px' }}>
                                <Sidebar />
                            </div> */}
                        <div className="col-md-12">
                            <div className="row" style={{ overflow: 'clip' }}>
                                <div className="col-md-1"></div>
                                <div className="col-md-10 ">
                                    <Formik
                                        // initialValues={(location?.state?.patientId || slug) ? InputDetails : initialState}
                                        // initialValues={initialValues}
                                        initialValues={(location?.state?.patientId || slug) ? initialValues : initialState}
                                        // validationSchema={location?.state?.patientId ? null : AppointmnetValidationSchema}
                                        validationSchema={AppointmnetValidationSchema(location?.state?.patientId)}
                                        // onSubmit={(location?.state?.patientId || slug) ? handleEditOTPForm : handleSubmit}

                                        onSubmit={location?.state?.patientId ? handleEditOTPForm : handleSubmit}
                                        enableReinitialize
                                    >
                                        {({ isSubmitting, handleChange, values, setFieldValue, setSubmitting, resetForm, isValid, errors }) => {

                                            console.log('updatedValues', values)

                                            scrollToTopOnErrors(isValid);
                                            let combinedDateTime: any;

                                            if (values?.AppointmentDate && values?.AppointmentTime) {

                                                const appDate: any = values?.AppointmentDate;
                                                const appTime: any = values?.AppointmentTime;
                                                const updDateTime = appDate.includes('T') ? appDate : appDate + 'T' + appTime;
                                                combinedDateTime = new Date(updDateTime);
                                            }
                                            setFieldValueRef.current = setFieldValue;
                                            const errorCount = Object.keys(errors).length;
                                            console.log({ errorCount })

                                            if (isSubmitting && errorCount > 0) {

                                                for (const key in errors) {
                                                    console.log({ key })
                                                    if (fieldRefs.current[key]) {
                                                        fieldRefs.current[key]?.scrollIntoView({ behavior: 'smooth' });
                                                        break; // Stop at the first error we find
                                                    }
                                                }


                                            }
                                            console.log('combinedDateTime', combinedDateTime)
                                            return (
                                                <Form onChange={() => validateForm(values)} autoComplete='off'>
                                                    {successMessage && (
                                                        <div className="alert alert-success" role="alert" style={{ color: 'green' }}>
                                                            {successMessage}

                                                        </div>
                                                    )}
                                                    <p style={{ color: 'red' }}>{ErrMsgMatch ? ErrMsgMatch : ''}</p>
                                                    <p style={{ color: 'red' }}>{errSubmitMsg ? errSubmitMsg : ''} </p>

                                                    {/* <h4 className="mb-4">Patient Appointment</h4> */}
                                                    {/* {existMsg} */}
                                                    <p style={{ color: 'red' }}>{existMsg ? existMsg : ''}</p>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="mb-3" ref={(el) => (fieldRefs.current.PatientName = el)}>
                                                                <label htmlFor="PatientName" className="form-label">Patient Name*</label>
                                                                <Field name="PatientName" type="text" className="form-control frmControl"
                                                                    readOnly={logUserObject?.Role === "Patient" ? true : false} />
                                                                <ErrorMessage name="PatientName" component="div" className="errMsg" />
                                                            </div>

                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="mb-3" ref={(el) => (fieldRefs.current.EmailId = el)}>
                                                                <label htmlFor="EmailId" className="form-label">Email Id</label>
                                                                <Field
                                                                    name="EmailId"
                                                                    type="text"
                                                                    className="form-control frmControl"
                                                                    readOnly={logUserObject?.Role === "Patient" ? true : false} />
                                                                <ErrorMessage name="EmailId" component="div" className="errMsg" />

                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="mb-3" ref={(el) => (fieldRefs.current.DoctorID = el)}>
                                                                <div className="trigger open">Please select your dentist</div>
                                                                <Field
                                                                    as="select"
                                                                    name="DoctorID"
                                                                    className="form-select"
                                                                    disabled={logUserObject?.Role === "Doctor"}
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                        // handleDoctorChange(e, '');
                                                                        handleDoctorChange(e, '', setFieldValue);
                                                                    }}
                                                                >
                                                                    <option value="" label="Select a dentist" />
                                                                    {doctors?.map((doctor: any) => (
                                                                        <option key={doctor?.id} value={doctor?.id}>
                                                                            {doctor?.FirstName + ' ' + doctor?.LastName}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                {doctorUnavailableMessage && (
                                                                    <div style={{ fontSize: '12px', marginTop: '5px', color: 'red' }}>
                                                                        {doctorUnavailableMessage}
                                                                    </div>
                                                                )}
                                                                {/* <ErrorMessage name="DoctorID" component="div" className="errMsg" /> */}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6" ref={(el) => (fieldRefs.current.AppointmentDate = el)}>
                                                            <label style={{ marginBottom: '0px' }} htmlFor="AppointmentDate" className="    ">Select Appointment*</label>
                                                            {availableMessage && (
                                                                <div style={{ fontSize: '12px', color: 'green' }}>
                                                                    {availableMessage}
                                                                </div>
                                                            )}
                                                            <div className="mobwidthdate" style={{ width: '193%' }}>

                                                                <DatePicker
                                                                    selected={combinedDateTime ? combinedDateTime : values?.AppointmentDate ? new Date(values?.AppointmentDate) : null}                                                                    
                                                                    onChange={(date) => handleDateChange(date, setFieldValue)}
                                                                    minDate={new Date()}
                                                                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                                                                    filterDate={isDateAvailable}
                                                                    dateFormat="yyyy-MM-dd'T'HH:mm"
                                                                    placeholderText="Select your appointment date and time *"
                                                                    showTimeSelect
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={timeSlot}
                                                                    timeCaption="time"
                                                                    includeTimes={availableTimes}
                                                                    name="AppointmentDate"
                                                                    autoComplete="off"
                                                                    className="form-select selectui"
                                                                    customInput={<div className="input-group mt-10">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control frmControl"
                                                                            placeholder="Select your appointment date and time *"
                                                                            value={combinedDateTime
                                                                                ? combinedDateTime.toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', ' ')
                                                                                : values?.AppointmentDate
                                                                                    ? new Date(values?.AppointmentDate).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', ' ')
                                                                                    : ''}
                                                                             />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">
                                                                                <FaCalendarAlt />
                                                                            </span>
                                                                        </div>
                                                                    </div>} />
                                                                {/* <i class="fa fa-calendar-plus-o" aria-hidden="true"></i> */}
                                                            </div>
                                                            <ErrorMessage name="AppointmentDate" component="div" className="errMsg" />
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="mb-6" ref={(el) => (fieldRefs.current.PhoneNumber = el)}>
                                                                <label htmlFor="PhoneNumber" className="form-label">Phone Number*</label>
                                                                <Field
                                                                    name="PhoneNumber"
                                                                    type="tel"
                                                                    min={0}
                                                                    className="form-control frmControl"
                                                                    readOnly={logUserObject?.Role === "Patient" ? true : false} />
                                                                <ErrorMessage name="PhoneNumber" component="div" className="errMsg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3 mbmob-0" ref={(el) => (fieldRefs.current.DOB = el)}>
                                                                <label htmlFor="DateOfbirth" className="form-label">Date Of Birth</label>
                                                                <Field
                                                                    name="DOB"
                                                                    type="date"
                                                                    className="form-control frmControl"
                                                                    placeholder="DateOfbirth"
                                                                    max={lastDayOfMonthString} />
                                                                {/* <ErrorMessage name="DOB" component="div" className="errMsg" /> */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-6">
                <div className="mb-3">
                    <label htmlFor="HouseNumber" className="form-label">House Number</label>
                    <Field
                        name="HouseNumber"
                        type="text"
                        className="form-control"
                        placeholder="House Number"
                    />
                    <ErrorMessage name="HouseNumber" component="div" className="errMsg" />
                </div>
            </div> */}

                                                        <div className="col-md-12">
                                                            <div className="mb-3 mbmob-0" ref={(el) => (fieldRefs.current.Message = el)}>
                                                                <label htmlFor="Describe Your Problem" className="form-label">Describe Your Problem</label>
                                                                <Field
                                                                    name="Message"
                                                                    as="textarea"
                                                                    cols="40"
                                                                    rows="3"
                                                                    className="form-control frmControl"
                                                                    aria-invalid="false"
                                                                    style={{ resize: 'none' }} />
                                                                {/* <ErrorMessage name="Message" component="div" className="errMsg" /> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3 mbmob-0" ref={(el) => (fieldRefs.current.City = el)}>
                                                                <label htmlFor="City" className="form-label">City</label>
                                                                <Field
                                                                    name="City"
                                                                    type="text"
                                                                    className="form-control frmControl"
                                                                    autoComplete="new-city" />
                                                                {/* <ErrorMessage name="City" component="div" className="errMsg" /> */}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="mb-3 mbmob-0" ref={(el) => (fieldRefs.current.PinCode = el)}>
                                                                <label htmlFor="PinCode" className="form-label">PinCode</label>
                                                                <Field
                                                                    name="pinCode"
                                                                    type="text"
                                                                    className="form-control frmControl" autoComplete="new-pincode" />
                                                                {/* <ErrorMessage name="pinCode" component="div" className="errMsg" /> */}
                                                            </div>
                                                        </div>

                                                        {/* <div className="col-md-6">
                <div className="mb-3">
                    <label htmlFor="Street" className="form-label">Street</label>
                    <Field
                        name="Street"
                        type="text"
                        className="form-control"
                        placeholder="Street"
                    />
                    <ErrorMessage name="Street" component="div" className="errMsg" />
                </div>
            </div> */}

                                                        <div className="col-md-12">
                                                            <div className="mb-3 mbmob-0" ref={(el) => (fieldRefs.current.Address = el)}>
                                                                <label htmlFor="Address" className="form-label">Address</label>
                                                                <Field
                                                                    name="Address"
                                                                    type="text"
                                                                    className="form-control frmControl" autoComplete="new-address" />
                                                                {/* <ErrorMessage name="Address" component="div" className="errMsg" /> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3 mbmob-0" ref={(el) => (fieldRefs.current.MaritalStatus = el)}>
                                                                <label htmlFor="MaritalStatus" className="form-label">Marital Status</label>
                                                                <Field as="select" name="MaritalStatus" className="form-select" value={values?.MaritalStatus}
                                                                    onChange={(e: any) => setFieldValue("MaritalStatus", e?.target?.value)}>
                                                                    <option value="" label="Select marital status" />
                                                                    <option value="Single" label="Single" />
                                                                    <option value="Married" label="Married" />
                                                                    <option value="Divorced" label="Divorced" />
                                                                    <option value="Widowed" label="Widowed" />
                                                                </Field>
                                                                {/* <ErrorMessage name="MaritalStatus" component="div" className="errMsg" /> */}

                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-11">
                                                            <label className="pr-10" htmlFor="KYC[0].heardFrom">Where have you heard about us?</label>
                                                            <Field as="select" name="KYC[0].heardFrom" className="form-select">
                                                                <option value="">Select an option</option>
                                                                <option value="Friends">Friends</option>
                                                                <option value="Social Media">Social Media</option>
                                                                <option value="Family members">Family members</option>
                                                                <option value="None of the above">None of the above</option>
                                                            </Field>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="KYC" className="form-label">KYC</label>
                                                        <FieldArray name="KYC">
                                                            {() => (
                                                                <div className="row"> <br />
                                                                    {fields?.map((field, index) => {

                                                                        const fieldName = field?.name;
                                                                        const descriptionFieldName = `${fieldName}Description`;

                                                                        // Type definition for KYCField
                                                                        type KYCField = 'pastHealthConditions' | 'foodAllergies' | 'CurrentMedications' | 'ChronicIllnesses' | 'SmokingStatus' | 'AlcoholConsumption' | 'sugar' | 'bloodPressure' | 'heartAttack' | 'pregnacy';

                                                                        // Handle rendering for SmokingStatus and AlcoholConsumption as radio buttons
                                                                        if (['SmokingStatus', 'AlcoholConsumption', 'sugar', 'bloodPressure', 'heartAttack', 'pregnacy'].includes(fieldName)) {
                                                                            return (


                                                                                <div key={index} className="col-md-4" style={{ top: '20px', marginBottom: '10px' }}>
                                                                                    <label>{field?.label}</label> <br />
                                                                                    <label style={{ marginRight: '10px' }}>
                                                                                        <Field
                                                                                            type="radio"
                                                                                            name={`KYC[0].${fieldName}`}
                                                                                            value="1"
                                                                                            checked={String(values?.KYC?.[0]?.[fieldName as KYCField]) === '1'}
                                                                                            onChange={() => setFieldValue(`KYC[0].${fieldName as KYCField}`, '1')}
                                                                                            className="mr-1" />
                                                                                        Yes
                                                                                    </label>
                                                                                    <label>
                                                                                        <Field
                                                                                            type="radio"
                                                                                            name={`KYC[0].${fieldName}`}
                                                                                            value="0"
                                                                                            checked={String(values?.KYC?.[0]?.[fieldName as KYCField]) === '0'}
                                                                                            onChange={() => setFieldValue(`KYC[0].${fieldName as KYCField}`, '0')}
                                                                                            className="mr-1" />
                                                                                        No
                                                                                    </label>
                                                                                </div>

                                                                            );
                                                                        }

                                                                        return (
                                                                            <div key={index} className='col-md-12'>
                                                                                <label>
                                                                                    <Field
                                                                                        type="checkbox"
                                                                                        style={{ width: '15px', height: '15px', display: 'inline', marginRight: '10px', marginTop: '20px' }}
                                                                                        name={`KYC[0].${field?.name}`}
                                                                                        checked={String(values?.KYC?.[0]?.[field?.name as KYCField]) === '1'}
                                                                                        onChange={() => {
                                                                                            const newValue = String(values?.KYC?.[0]?.[field?.name as KYCField]) === '1' ? '0' : '1';
                                                                                            setFieldValue(`KYC[0].${field?.name as KYCField}`, newValue);
                                                                                        }} />
                                                                                    {field?.label}
                                                                                </label>
                                                                                {String(values?.KYC?.[0]?.[field?.name as KYCField]) === '1' && !['SmokingStatus', 'AlcoholConsumption']?.includes(fieldName) && (
                                                                                    <div>
                                                                                        {/* <label htmlFor={`KYC[0].${descriptionFieldName}`}>
        Description for {field?.label}</label><br/> */}
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            name={`KYC[0].${descriptionFieldName}`}
                                                                                            placeholder={`Enter description for ${field?.label}`}
                                                                                            rows={5}
                                                                                            style={{ resize: 'none', width: '100%' }} />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            )}
                                                        </FieldArray>
                                                    </div>
                                                    {/* <div className="mb-3 mbmob-0">
                                                            <label htmlFor="PaymentMode" className="form-label">Make Payment*</label>
                                                            <div className="d-flex">
                                                                <div className="form-check me-5">
                                                                    <Field
                                                                        type="radio"
                                                                        name="PaymentMode"
                                                                        value="offline"
                                                                        className="form-check-input"
                                                                        id="offline"
                                                                        onClick={() => {
                                                                            setShowRazorpay(false);
                                                                        }}
                                                                        disabled={!isFormComplete || update} // Disable if form is incomplete
                                                                    />
                                                                    <label className="form-check-label" htmlFor="offline">
                                                                        Offline
                                                                    </label>
                                                                </div> */}

                                                    {/* online payment mode */}

                                                    {/* <div className="form-check me-3">
                                                                    <Field
                                                                        type="radio"
                                                                        name="PaymentMode"
                                                                        value="online"
                                                                        className="form-check-input"
                                                                        id="online"
                                                                        onClick={() => {
                                                                            setShowRazorpay(true);
                                                                        }}
                                                                        disabled={!isFormComplete || update} />
                                                                    <label className="form-check-label" htmlFor="online">
                                                                        Online
                                                                    </label>
                                                                </div> */}


                                                    {/* {showRazorpay && (
                <div id="razorpay-container">
                    <button onClick={() => handlePay(values, setSubmitting, resetForm)} type="button" className="btn btn-primary">
                        Make Payment Here
                    </button>
                </div>
            )} */}
                                                    {/* </div>
                                                            <ErrorMessage name="PaymentMode" component="div" className="errMsg" />
                                                        </div> */}
                                                    {/* {values?.PaymentMode === 'offline' && (
                                                            <div className="form-group">
                                                                <Field
                                                                    type="number"
                                                                    name="Amount"
                                                                    className="form-control frmControl"
                                                                    placeholder="Enter amount*"
                                                                    value={process.env.REACT_APP_APPOINTMENT_AMOUNT}
                                                                    disabled={update} />
                                                                <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                            </div>
                                                        )} */}
                                                    {/* {values?.PaymentMode === 'online' && (
                                                            <div className="form-group">
                                                                <Field
                                                                    type="number"
                                                                    name="Amount"
                                                                    className="form-control frmControl"
                                                                    placeholder="Amount"
                                                                    value={process.env.REACT_APP_APPOINTMENT_AMOUNT} // Set a fixed value or dynamically calculate it if needed
                                                                    disabled />
                                                                <ErrorMessage name="Amount" component="div" className="text-danger" />
                                                            </div>
                                                        )} */}
                                                    <div className="text-center">
                                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting} onClick={() => window.scrollTo(0, 0)}   >
                                                            {(location?.state?.patientId || slug) ? 'Update Appointment' : 'Book Appointment'}
                                                        </button>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>

                        <ModelComponent setCurrentOTP={setCurrentOTP} SuccessMsg={SuccessMsg} timer={timer} OTPErr={OTPErr} handleOTPSubmit={handleOTPSubmit} ResendEmail={ResendEmail} closeModal={closeModal} getModal={getModal} />

                        {getappointmentModal &&
                            <ModelComponentAppoinment setCurrentOTP={setCurrentOTP} appointmentSuccessMsg={appointmentSuccessMsg} timer={timer} OTPErr={OTPErr} handleAppoinmentOTPSubmit={handleAppoinmentOTPSubmit} ResendEmailAppoinment={ResendEmailAppoinment} closeModal={closeModal} getappointmentModal={getappointmentModal} CurrentOTP={CurrentOTP} />}
                    </div>
                </div>
            </>
            )}

        </>
    );
}

export default PatientAppointment;