import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Grid,
    LinearProgress,
    Typography,
  } from '@mui/material';
  import React from 'react';
  import { Link } from 'react-router-dom';
  import './Whyus.css';
  
  const dummyServices = [
    {
      id: 1,
      class: 'service-class',
      service_img: 'https://via.placeholder.com/240',
      icon: 'https://via.placeholder.com/40',
      treatment: 'Cardiology',
    },
    {
      id: 2,
      class: 'service-class',
      service_img: 'https://via.placeholder.com/240',
      icon: 'https://via.placeholder.com/40',
      treatment: 'Neurology',
    },
    {
      id: 3,
      class: 'service-class',
      service_img: 'https://via.placeholder.com/240',
      icon: 'https://via.placeholder.com/40',
      treatment: 'Pediatrics',
    },
    {
      id: 4,
      class: 'service-class',
      service_img: 'https://via.placeholder.com/240',
      icon: 'https://via.placeholder.com/40',
      treatment: 'Orthopedics',
    },
  ];
  
  interface Service {
    id: number;
    class: string;
    service_img: string;
    icon: string;
    treatment: string;
  }
  
  interface WhyusState {
    ourServices: Service[];
  }
  
  class Whyus extends React.Component<{}, WhyusState> {
    constructor(props: {}) {
      super(props);
      this.state = {
        ourServices: [],
      };
    }
  
    componentDidMount() {
      const services = dummyServices;
  
      if (services.length > 0) {
        const serv = services.slice(0, 3);
        this.setState({ ourServices: serv });
      }
    }
  
    render() {
      const { ourServices } = this.state;
      const services = dummyServices;
  
      return (
        <Box
          sx={{
            bgcolor: '#fce4ec',
            color: 'primary.main',
            p: 2,
            mb: 2,
            textAlign: 'center',
          }}
        >
          <Container maxWidth="xl">
            <Typography sx={{ mt: 2, mb: 2, fontWeight: 600 }} variant="h6">
              Why Choose Our Medical
            </Typography>
            <Typography sx={{ mb: 8, fontWeight: 600 }} variant="h5">
              Breakthrough in Comprehensive, Flexible Care Delivery Models
            </Typography>
            {services.length > 1 ? (
              <Grid container spacing={3}>
                {ourServices.map((service) => (
                  <Grid
                    key={service.id}
                    className={service.class}
                    item
                    xs={12}
                    md={6}
                    lg={4}
                  >
                    <Card
                      sx={{
                        maxWidth: 345,
                        transition: '0.5s all ease-in-out',
                        mb: 2,
                        ':hover': {
                          boxShadow: 10,
                          color: '#e91e63',
                        },
                        img: { transition: '0.5s all ease-in-out' },
                        ':hover img': {
                          transform: 'scale(1.1)',
                        },
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="240"
                          image={service.service_img}
                          alt="card image of service"
                        />
                        <CardContent
                          sx={{ display: 'flex', mx: 'auto', my: 2 }}
                        >
                          <Avatar
                            alt="service icon"
                            src={service.icon}
                            sx={{
                              width: 40,
                              height: 40,
                              mx: 'auto',
                            }}
                          />
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                          >
                            Consult for {service.treatment}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions style={{ justifyContent: 'center' }}>
                        <Typography
                          sx={{ mx: 2, p: 2, textAlign: 'end' }}
                        >
                          <Link
                            className="text-style"
                            to="/services"
                            color="primary"
                          >
                            See More Details...
                          </Link>
                        </Typography>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <LinearProgress color="secondary" />
            )}
            <Typography sx={{ mx: 2, p: 2, textAlign: 'end' }}>
              <Link
                to="/services#services"
                className="text-style"
                color="primary"
              >
                See All services
              </Link>
            </Typography>
          </Container>
        </Box>
      );
    }
  }
  
  export default Whyus;
  