import React, { useEffect, useMemo, useState, useRef, Suspense } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import getspeciality from "../../Services/specialities";
// import getCareer from "../../../services/Careers";
// import getblogs from '../../../services/Blogs';
// import './BlogPage.css';
import './style.css';
import { Helmet } from 'react-helmet';
import pages from "../../utills/pageUtils";
import { Item } from "react-bootstrap/lib/Breadcrumb";
import Loader from "../shared/Loader";
import LazyLoad from "react-lazyload";

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

const SpecialityPage: React.FC<compProps> = ({ title, description, keywords }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [careerItems, setCareerItems] = useState<any>([]);
  const [filiteredSpeciality, setFiliteredSpeciality] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [blogsPerPage] = useState<number>(10);
  const [allCats, setAllCats] = useState<any>(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [pageInfo, setPageInfo] = useState<any>()
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()
  const SpecialityRef = useRef<HTMLDivElement>(null);
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const SpecialityPerPage = 6;
  const { slug } = useParams();
  // const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      // console.log("result", result)
      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
      })

    };
    fetchData();
  }, [])


  // console.log("pageInfo", pageInfo)
  const listSpeciality = async () => {
    try {
      setLoading(true);
      const response = await getspeciality().ActivespecialityList();
      // console.log("filiteredSpeciality", filiteredSpeciality);
      const categoryname = await getspeciality().getcategory();
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.category.map((cateId: any) => {
          const category = categoryname.find((cate: any) => cate.id === cateId);
          return category ? category?.name : cateId;
        });
        return {
          ...item,
          catergoryName: categoryNames
        };
      });

      // console.log("updatedResponse", response)
      setCareerItems(updatedResponse);
      setLoading(false);
      const categoryIds = updatedResponse.map((item: any) => item?.category);
      // category List
      const catResponse = await getspeciality().CategoryList();
      setCategories(catResponse);
      location?.state?.category && setSelectedCategory(location?.state?.category);
      // console.log("catResponse", catResponse);

    } catch (error) {
      console.error('Error fetching Speciality:', error);
      setLoading(false);
    }
  };

  useMemo(() => {

    listSpeciality();

    window.scrollTo(0, 0);
  }, [location?.state?.category]);

  useEffect(() => {
    // const filterCat = selectedCategory   ? selectedCategory : location?.state?.category
    const filterCat = selectedCategory  //!location?.state?.category ? selectedCategory : location?.state?.category;
    const filterSpecialities: any = filterCat && careerItems?.filter((careers: any) => {
      const categoryIds = careers?.category[0]
      const splitString = categoryIds?.includes(',') ? categoryIds?.split(',') : [categoryIds];
      // console.log("categoryIds", categoryIds)
      // console.log('splitString', splitString)
      return splitString.includes(filterCat)
    });

    // console.log("filterSpecialities", filterSpecialities);
    // console.log("careerItems", careerItems);


    filterSpecialities && setFiliteredSpeciality(filterSpecialities);
  }, [selectedCategory, location?.state?.category, careerItems]);

  const removeHtmlTags = (str: any) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };

  // const SpecialitiesFiltered = filiteredSpeciality?.length > 0 ? filiteredSpeciality : careerItems;
  const SpecialitiesFiltered: any = !selectedCategory ? careerItems : filiteredSpeciality?.length > 0 ? filiteredSpeciality : [];
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const countByCategory = careerItems && careerItems?.reduce((acc: any, careers: any) => {
    const categoryIds = careers?.category[0]
    const splitString = categoryIds.includes(',') ? categoryIds?.split(',') : [categoryIds];

    // console.log("careers", splitString)

    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });

    return acc;
  }, {});

  const handleReadMore = (slug: any) => {
    navigate(`/Specialities/${slug}`);
  };

 

  // Pagination logic
  const indexOfLastSpeciality = currentPage * SpecialityPerPage;
  const indexOfFirstSpeciality = indexOfLastSpeciality - SpecialityPerPage;
  // const currentDoctors = blogsFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const currentSpecialities = SpecialitiesFiltered?.slice(indexOfFirstSpeciality, indexOfLastSpeciality);
  const totalPages = Math?.ceil(SpecialitiesFiltered?.length / SpecialityPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (SpecialityRef.current) {
      SpecialityRef.current.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);
  }, [currentPage]);



  const handleCategoryClick = (categoryId: string) => {
    setActiveCategory(categoryId);
    setSelectedCategory(categoryId);
    setAllCats(false);
    window.scrollTo(0, 0);
  };

  console.log("currentSpecialities", currentSpecialities);
  return (
    <div className="page-wrapper">
      
      
      <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta name="author" content="Gurram Dental Care" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>
          
          <section className="home-slider owl-carousel">
            <LazyLoad>
              <div
                className="slider-item bread-item"
                // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
                style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})` : 'images/bg_1.jpg' }}
                data-stellar-background-ratio="0.5"
              >
                <div className="overlay"></div>
                <div className="container" data-scrollax-parent="true">
                  <div className="row banner-text align-items-center">
                    <div className="col-md-12 col-sm-12 ftco-animate">
                    
                      <h1 className="banner-ttl">{bannerName}</h1>
                      <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
                    </div>
                  </div>
                </div>
              </div>
              </LazyLoad>
            </section><div>
              <ul className="ullist">
                <li>
                  <a className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</a>
                </li>
                <li>Specialities
                </li>
              </ul>
            </div>

            {loading ? (
        <Loader />
      ) : (
            
            <section className="blog-section style-four section" ref={SpecialityRef} id="Speciality">
              <div className="container">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      {currentSpecialities?.length > 0 ? (
                        currentSpecialities?.map((item: any) => (
                          <div className="col-md-6" key={item?.id}>
                            <div className="left-side">
                              <div className="item-holder">
                                <div className="image-box">
                                  <figure>
                                    <LazyLoad>
                                    <img
                                      src={item?.specialityImage?.length > 0
                                        ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.specialityImage[0]}`
                                        : 'images/blogsingle.jpg'}
                                      alt={item?.title}
                                      className="img-fluid"
                                      style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
                                      </LazyLoad>
                                  </figure>
                                </div>
                                <div className="content-text">
                                  <h6>{item?.title}</h6>
                                  <p>
                                    {item?.categoryName?.map((catName: any, index: number) => (
                                      <span key={index} style={{ marginRight: '5px' }}>
                                        {catName}{index < item.categoryName.length - 1 ? ',' : ''}
                                      </span>
                                    ))}
                                  </p>
                                  {/* <p>{truncateText(removeHtmlTags(item?.description), 200)}</p> */}
                                  <p className="descheight" dangerouslySetInnerHTML={{ __html: truncateText(item?.description, 80) }} />

                                  <div className="link-btn ">
                                    <a
                                      href='javascript:void(0)'
                                      className="btn btn-sm mtbtn"
                                      onClick={() => handleReadMore(item?.Slug)}
                                    >
                                      Read More
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col-12">
                          <p style={{ textAlign: 'center', fontSize: '30px' }}>No specialities available</p>
                        </div>
                      )}
                    </div>


                  </div>
                  {/* <div className="col-md-1"></div> */}
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="right-side">
                      {/* <div className="text-title">
            <h6>Search</h6>
          </div>
          <div className="search-box">
            <form method="post" action="/">
              <input type="search" name="search" placeholder="Enter to Search" required />
            </form>
          </div> */}
                      <div className="categorise-menu">
                        <div className="text-title">
                          <h6>Categories</h6>
                        </div>
                        <div className="categories-container">
                        <ul className="categorise-list">
                          <li style={{ backgroundColor: selectedCategory === "" ? '#2cbcbc' : 'transparent', color: selectedCategory === "" ? 'white' : 'black', padding: '10px', cursor: 'pointer' }} onClick={() => setSelectedCategory("")}>All</li>
                          {categories?.map((item: any) => {
                            const blogCount = countByCategory[item?.id] || 0;
                            return (
                              <li className="capitalTxt"

                                style={{ backgroundColor: selectedCategory === item?.id ? '#2cbcbc' : 'transparent', color: selectedCategory === item?.id ? 'white' : 'black', padding: '10px', cursor: 'pointer' }}

                                key={item?.id} onClick={() => { setSelectedCategory(item?.id); setAllCats(false); window.scrollTo(0, 0); }} >
                                {item?.name.toLowerCase()} <span>({blogCount})</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      </div>
                    </div>
                  </div>
                  {/* Pagination Controls */}
                  {currentSpecialities?.length > 0 ?
                    <div className="custom-pagination">
                      <ul className="custom-pagination-list">
                        <li className={`custom-pagination-item ${currentPage === 1 ? 'disabled' : ''}`}>
                          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                          </button>
                        </li>
                        {[...Array(totalPages)].map((_, index) => (
                          <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button onClick={() => paginate(index + 1)}>
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li className={`custom-pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                    : null}
                </div>
              </div>
            </section>
      )}

            
      
      
    </div>
  );
};

export default SpecialityPage;