// import React from 'react';
import { Oval, Rings, Bars } from 'react-loading-icons'; // or use any other loader

// interface LoaderProps {
//     height: string | number;
// }

const Loader: React.FC<any> = () => (

    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
    //     <Oval stroke="#2cbcbc" width={35} height={35} />
    // </div>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <Bars stroke="#2cbcbc" width={35} height={35} />
    </div>
);

export default Loader;