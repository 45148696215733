import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { SHA256 } from 'crypto-js';
import { PharmacyValidationSchema } from './PharmacyValidationSchema';
import { useIdleTimeout } from '../shared/SessionExpire';
import { FileUpload, SendFile, updDelFile, RemoveProfileforAll } from './../../views/shared/fileupload';
import userService from '../../Services/user/user.service';
import PharmacyServices from '../../Services/pharmacyServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormatFile } from '../../utills/Fileformat';
import Users from '../../utills/users';



interface SocialIcons {
    name: string;
    icon: string;
}
interface RegisterDetails {
    FirstName: string;
    LastName: string;
    Gender: string;
    Age: string;
    Role: string;
    PhoneNumber: string;
    EmailId: string;
    Password: string;
    countryCode: string;
    confirmPassword: string;
    userProfile: string;
    Specialization: string;
    userType: string;
    resetPWD: string;
    // [key: string]: string | number | undefined;
}

const initialState: RegisterDetails = {
    FirstName: '',
    LastName: '',
    Age: 'N/A',
    Gender: '',
    EmailId: '',
    PhoneNumber: '',
    Password: '',
    Role: 'Pharmacy',
    confirmPassword: '',
    countryCode: '+91',
    userProfile: "",
    Specialization: "N/A",
    userType: "",
    resetPWD: ""
};

function PharmacyRegister() {
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const navigate = useNavigate();
    const location = useLocation();
    const [errMsg, setErrMsg] = useState(initialState);
    const isIdle = useIdleTimeout(1000 * 900);
    const isActive = useIdleTimeout(1000 * 900);
    const [errMsgMatchpwd, setErrMsgMatchpwd] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errMsgPwd, setErrMsgPwd] = useState("");
    const [errMsgMatch, setErrMsgMatch] = useState("");
    const [InputDetails, setInputDetails] = useState<RegisterDetails>(initialState);
    const [pharmacyDetails, setPharmacyDetails] = useState<any>();
    const [file, setFile] = useState<any>([]);
    const [directory, setDirectory] = useState();
    let [updatePharmacy, setUpdatePharmacy] = useState<boolean>(false);

    // console.log("inputdetails", InputDetails);


    const hashPassword = (password: string) => {
        try {
            const hashedPassword = SHA256(password).toString();
            return hashedPassword;
        } catch (error) {
            console.error("Error hashing password:", error);
            return "";
        }
    };

    useEffect(() => {
        getExistingUser();
    }, [location.state]);

    const getExistingUser = async () => {
        const getPharmacyDetails = await PharmacyServices().ListPharmacyById(location?.state?.id);
        console.log(getPharmacyDetails[0]);
        if (getPharmacyDetails?.length > 0) {
            setInputDetails(getPharmacyDetails[0]);
            setUpdatePharmacy(true);
        }
        setPharmacyDetails(getPharmacyDetails[0]);

    };
    const handleRemove = () => {
        if (window.confirm('Are you sure you want to remove this file?')) {
            RemoveProfileforAll(InputDetails?.userProfile, setInputDetails, 'userProfile', 'images'); // Pass the key and S3 directory
        } else {
            alert('Deletion canceled');
        }
    };
    const handleUpdate = async (values: RegisterDetails, { setSubmitting }: any) => {
        window.scrollTo(0, 0);
        // console.log("values", values);
        // if (values.Password !== values.confirmPassword) {
        //   setErrMsgMatchpwd("Password does not match");
        //   return;
        // }
        try {
            // let newFilename: any;
            // if (file?.name) {
            // const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            // newFilename = replaceSpecialChars(filename);
            // console.log("filename",fileName)
            const newFilename = await FormatFile(file)
            await updDelFile(values?.userProfile, "images");
            await SendFile(file, newFilename, "images");
            // }
            const GetInsertDataUpdate = {
                id: location?.state?.id,
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender,
                Age: values?.Age,
                EmailId: values?.EmailId,
                PhoneNumber: values?.PhoneNumber,
                Password: values?.Password,
                Status: "1",
                Role: "Pharmacy",
                countryCode: values.countryCode,
                userProfile: file?.name ? newFilename : values?.userProfile,
                Specialization: values?.Specialization,
                Slug: "N/A",
                userType: values?.userType,
                resetPWD: values?.resetPWD
            };
            // console.log("GetInsertDataUpdate", GetInsertDataUpdate);
            const userResponse = await PharmacyServices().updatePharmacyByData(GetInsertDataUpdate)
            // console.log("userResponse", userResponse);
            navigate("/listPharmacy")
        } catch (error) {
            console.log("error", error);
        }
        setSubmitting(false);
    }
    const handleSubmit = async (
        values: RegisterDetails,
        { setSubmitting, resetForm }: any
    ) => {
        // console.log("values", values);
        window.scrollTo(0, 0);
        if (values.Password !== values.confirmPassword) {
            setErrMsgMatchpwd("Password does not match");
            return;
        }
        const PassWord = values?.Password

        try {
            const userEmail = await userService().validateEmail(values.EmailId);
            const userPhoneNumber = await userService().validatePhonenumber(
                values.PhoneNumber
            );

            if (userEmail?.status === 1 && userPhoneNumber?.status === 1) {
                setErrMsgMatch("Email address and phone number are already in use");
                return;
            } else if (userEmail?.status === 1) {
                setErrMsgMatch("Email address is already in use");
                return;
            } else if (userPhoneNumber?.status === 1) {
                setErrMsgMatch("Phone number is already in use");
                return;
            } else {
                // setTimeout(()=>setErrMsgMatch(''),3000);
                setErrMsgMatch('')
            }
            // let newFilename: any;
            // if (file?.name) {
            //     const filename = Date.now() + "_" + file?.name?.replace(/ /g, '_');
            //     newFilename = replaceSpecialChars(filename);
            const newFilename = await FormatFile(file)
            await SendFile(file, newFilename, directory);
            // }

            const GetInsertDataUpdate = {
                FirstName: values?.FirstName,
                LastName: values?.LastName,
                Gender: values?.Gender,
                Age: values?.Age,
                EmailId: values?.EmailId,
                PhoneNumber: values?.PhoneNumber,
                Password: hashPassword(values.Password),
                Status: "1",
                Role: "Pharmacy",
                countryCode: values.countryCode,
                userProfile: newFilename,
                Specialization: values?.Specialization,
                Slug: "N/A",
                userType: "R",
                resetPWD: logUserObject ? "Yes" : "No"
            };
            const userResponse = await PharmacyServices().getcreateUser(GetInsertDataUpdate);
            const userRegResponse = userResponse?.data?.createUser;
            if (userResponse?.status === 200) {

                const SMSresponse: any = await Users().generateEmailSMS(
                    userRegResponse?.EmailId,
                    `<html><body><div>Welcome to Gurram Dental Hospitals!<br /><br />Thank you for registering with us.<br />Your Credentials for login :<br/>Email Id :${userRegResponse?.EmailId} / Phone Number :${userRegResponse?.PhoneNumber} <br />Password : ${PassWord}<br/><a href=${process.env.REACT_APP_HOST}login>Click here</a>to login your account <br />Best Regards,<br />Gurram Dental Hospitals Team</div></body></html>`,
                    'Gurram Dental Hospitals,Pharamcy Registration Details',
                    'Your Registered successfully'
                );

                const MobileSMSResponse = await Users().generateMobileMsgBulk(userRegResponse?.countryCode + userRegResponse?.PhoneNumber, `Thank you for registering with us. Log in using your Email: ${userRegResponse?.EmailId} or Phone Number: ${userRegResponse?.PhoneNumber}, and pwd: ${PassWord} - CLUSTERIT.`, "success"
                )
                if (SMSresponse && MobileSMSResponse) {
                    resetForm();
                    console.log("Pharamcy Registered successfully");
                    alert("Pharamcy Registered successfully!");
                    setSuccessMessage("Pharamcy Registered successfully!");
                    setSubmitting(false);
                    navigate("/listPharmacy");
                } else {
                    console.error("Error in registration:", userResponse);
                }
            }
        } catch (error) {
            console.error("Error during form submission:", error);
            setSubmitting(false);
        }
    }

    // useEffect(() => {
    //     const handleSessionExpiry = () => {
    //         localStorage.removeItem("userData");
    //         alert("Your session has expired. Please login again.");
    //         navigate("/");
    //     };
    //     if (!isActive) {
    //         handleSessionExpiry();
    //     }
    // }, [isActive, navigate]);

    return (
        <>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Pharmacy Registration
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Pharmacy Registration</li>
                </ul>
            </div>

            <div className="container-fluid" style={{ paddingLeft: '70px', paddingRight: '70px' }}>
                <div className="row" style={{ padding: '50px' }}>
                    <div className="page-title-heading" style={{ textAlign: 'center', marginBottom: '40px' }}>
                        <h1 className="title" style={{ color: 'black', textAlign: 'left' }}>Pharmacy Registration</h1>
                    </div>
                    <div className="col-md-6">
                        <Formik
                            initialValues={InputDetails}
                            validationSchema={PharmacyValidationSchema(location?.state?.id)}
                            onSubmit={!updatePharmacy ? handleSubmit : handleUpdate}
                            enableReinitialize
                        >
                            {({ isSubmitting, values, setFieldValue }) => (
                                <Form autoComplete='off'>
                                    {successMessage && (
                                        <div className="alert alert-success" role="alert" style={{ color: 'green' }}>
                                            {successMessage}
                                        </div>
                                    )}
                                    <p style={{ color: 'red' }}>{errMsgMatch ? errMsgMatch : ''}</p>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="FirstName" className="form-label">First Name*</label>
                                                <Field name="FirstName" type="text" className="form-control" value={values.FirstName}
                                                    onChange={(e: any) => setFieldValue("FirstName", e?.target?.value)} />
                                                <ErrorMessage name="FirstName" component="div" className="errMsg" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="LastName" className="form-label">Last Name*</label>
                                                <Field name="LastName" type="text" className="form-control" value={values.LastName}
                                                    onChange={(e: any) => setFieldValue("LastName", e.target.value)} />
                                                <ErrorMessage name="LastName" component="div" className="errMsg" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="EmailId" className="form-label">Email Address*</label>
                                                <Field name="EmailId" type="email" className="form-control" value={values.EmailId}
                                                    onChange={(e: any) => setFieldValue("EmailId", e?.target?.value)} />
                                                <ErrorMessage name="EmailId" component="div" className="errMsg" />
                                                <p className='errMsg'>{errMsg?.EmailId}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label" style={{ marginTop: '10px', display: 'block' }}>Gender</label>
                                                <div className="form-check">
                                                    <Field name="Gender" type="radio" value="Male" className="form-check-input" id="genderMale" style={{ width: '14px', height: '14px' }} />
                                                    <span className="form-label" style={{ marginRight: '30px', marginTop: '0px' }}>Male</span>
                                                    <Field name="Gender" type="radio" value="Female" className="form-check-input" id="genderFemale" style={{ width: '14px', height: '14px' }} />
                                                    <span className="form-label" style={{ marginTop: '0px' }}>Female</span>
                                                </div>
                                                <ErrorMessage name="Gender" component="div" className="errMsg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="countryCode" className="form-label">Country Code*</label>
                                                <Field name="countryCode" type="tel" min={0} placeholder="+" className="form-control" readOnly />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="PhoneNumber" className="form-label">Phone Number*</label>
                                                <Field name="PhoneNumber" type="tel" min={0} className="form-control" value={values.PhoneNumber}
                                                    onChange={(e: any) => setFieldValue("PhoneNumber", e?.target?.value)} />
                                                <ErrorMessage name="PhoneNumber" component="div" className="errMsg" />
                                            </div>
                                        </div>
                                    </div>

                                    {!updatePharmacy && (
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="Password" className="form-label">Password*</label>
                                                    <Field name="Password" type="password" className="form-control" value={values?.Password}
                                                        onChange={(e: any) => setFieldValue("Password", e?.target?.value)} />
                                                    <ErrorMessage name="Password" component="div" className="errMsg" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password*</label>
                                                    <Field name="confirmPassword" type="password" className="form-control" value={values?.confirmPassword}
                                                        onChange={(e: any) => setFieldValue("confirmPassword", e?.target?.value)} />
                                                    <ErrorMessage name="confirmPassword" component="div" className="errMsg" />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {InputDetails?.userProfile !== null && updatePharmacy ?
                                        <>
                                            <img src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${InputDetails?.userProfile}`} alt="Profile" style={{ maxWidth: '100%', height: '200px', marginBottom: '15px' }} />
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                                                <p style={{ margin: 0, lineHeight: '24px' }}>
                                                    <strong>FileName:</strong>{InputDetails?.userProfile}
                                                </p>
                                                <span
                                                    style={{ cursor: 'pointer', color: 'red', fontSize: '24px', marginLeft: '10px', lineHeight: '30px' }}
                                                    onClick={handleRemove}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </span>
                                            </div>
                                        </>
                                        : null}
                                    <FileUpload label={updatePharmacy ? "Update Profile Image" : "Choose Profile"} setDirectory={setDirectory} setFile={setFile} />

                                    <div className="mb-3" style={{ marginTop: '20px' }}>
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>{!updatePharmacy ? "Register" : "Update"}</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="col-md-6">
                        <img src='/images/registrationdr.jpg' alt="signupImage" style={{ width: '95%', height: '626px', objectFit: 'cover', marginTop: '42px', marginLeft: '73px' }} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default PharmacyRegister;