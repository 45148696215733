import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './style.css';

const Faq: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(0);

    const handleToggle = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    

    return (
        <section className="appoinment-section section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="accordion-section">
                            <div className="section-title">
                                <h3>FAQ</h3>
                            </div>
                            <div className="accordion-holder">
                                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingOne">
                                            <h4 className="panel-title">
                                                <a
                                                    role="button"
                                                    aria-expanded={activeIndex === 0}
                                                    aria-controls="collapseOne"
                                                    onClick={() => handleToggle(0)}
                                                >
                                                    Why Should I choose Medical Health
                                                    <FontAwesomeIcon
                                                        icon={activeIndex === 0 ? faChevronUp : faChevronDown}
                                                        className="chevron-icon"
                                                    />
                                                </a>
                                            </h4>
                                        </div>
                                        <div
                                            id="collapseOne"
                                            className={`panel-collapse collapse ${activeIndex === 0 ? 'show' : ''}`}
                                            role="tabpanel"
                                            aria-labelledby="headingOne"
                                        >
                                            <div className="panel-body">
                                                Medical Health is a career driven by service. It allows you to be a life-long learner. As a result, your knowledge base must follow suit. It is a profession that requires quick thinking and decision-making. People will look to you for answers, and it is up to you to provide them. It puts you in a position where your actions matter. A career in medical health is one of respect. The work you do is important to both the individual and community.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingTwo">
                                            <h4 className="panel-title">
                                                <a
                                                    role="button"
                                                    aria-expanded={activeIndex === 1}
                                                    aria-controls="collapseTwo"
                                                    onClick={() => handleToggle(1)}
                                                >
                                                    What are the Centre’s visiting hours?
                                                    <FontAwesomeIcon
                                                        icon={activeIndex === 1 ? faChevronUp : faChevronDown}
                                                        className="chevron-icon"
                                                    />
                                                </a>
                                            </h4>
                                        </div>
                                        <div
                                            id="collapseTwo"
                                            className={`panel-collapse collapse ${activeIndex === 1 ? 'show' : ''}`}
                                            role="tabpanel"
                                            aria-labelledby="headingTwo"
                                        >
                                            <div className="panel-body">
                                                You can schedule an appointment by calling our center or using our online appointment booking system on our website or app.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingThree">
                                            <h4 className="panel-title">
                                                <a
                                                    role="button"
                                                    aria-expanded={activeIndex === 2}
                                                    aria-controls="collapseThree"
                                                    onClick={() => handleToggle(2)}
                                                >
                                                    How many visitors are allowed?
                                                    <FontAwesomeIcon
                                                        icon={activeIndex === 2 ? faChevronUp : faChevronDown}
                                                        className="chevron-icon"
                                                    />
                                                </a>
                                            </h4>
                                        </div>
                                        <div
                                            id="collapseThree"
                                            className={`panel-collapse collapse ${activeIndex === 2 ? 'show' : ''}`}
                                            role="tabpanel"
                                            aria-labelledby="headingThree"
                                        >
                                            <div className="panel-body">
                                                During Covid-19, and in many places since then, there has been a limit of two visitors. In different wards and depending on the condition of the patient, there will be restrictions for the comfort and health of the patient.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
};

export default Faq;
