import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { listUsers } from '../../../graphql/queries';



const OTPServices: any = () => {


    const validateotpTwoStep = async (userName: any,Otp: any) => {
        let nextToken: string | null = null;
        const limit = 100;

        let type: any, filterVar: any;

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^\+?[1-9]\d{1,14}$/; // This is a simple example, adapt as needed for your phone number format
    
        if (emailPattern.test(userName)) {
          type = 'email';
        } else if (phonePattern.test(userName)) {
          type = 'phone';
        }
        try {
          console.log("userName", userName);

          if (type === 'email') {
            const userEmail = userName.toLowerCase();
            console.log("userEmail", userEmail);

             filterVar = {
              filter: {
                or: [
                  { EmailId: { eq: userEmail } },
                  { Otp: { eq: Otp } }
                ]
              },
              limit: limit,
              nextToken: nextToken
            }

          } else if (type === 'phone') {
            const phone = '+91' + userName;

            filterVar = {
              filter: {
                or: [
                  { PhoneNumber: { eq: phone } },
                  { Otp: { eq: Otp } }
                ]
              },
              limit: limit,
              nextToken: nextToken
            }
          }
          // const filterVar = {
          //   filter: {
          //     or: [
          //       { EmailId: { eq: userName } },
          //       { Otp: { eq: Otp } }
          //     ]
          //   },
          //   limit: limit,
          //   nextToken: nextToken
          // }
          const userResp = await API.graphql(graphqlOperation(listUsers, filterVar));
          // console.log("servicesuserResp", userResp);
        //   if (userResp?.type === "email" && userResp?.status === 200) { 

        //   }
          return userResp;
        } catch (error) {
          console.error("Error validating OTP:", error);
          throw new Error("Unable to validate OTP");
        }
    };
   
    return {
        validateotpTwoStep
    }
}
export default OTPServices;



// const AWS = require('aws-sdk');
// const dotenv = require("dotenv");
// const jwt = require("jsonwebtoken");

// dotenv.config({ path: "./config.env" });

// const dynamoDB = new AWS.DynamoDB.DocumentClient();

// exports.handler = async (event: any) => {
//     const headers = {
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Headers': '*',
//         'Access-Control-Allow-Methods': '*'
//     };

//     try {
//         if (event.httpMethod === 'POST') {
//             const { userName, otp } = await JSON.parse(event.body);

//             const params = {
//                 TableName: process.env.tableName,
//                 FilterExpression: '(#email = :value1 OR #phoneNumber = :value1) AND OR #Otp = :value2',
//                 ExpressionAttributeNames: {
//                     '#email': 'EmailId',
//                     '#phoneNumber': 'PhoneNumber',
//                     '#Otp': 'Otp'
//                 },
//                 ExpressionAttributeValues: {
//                     ':value1': userName,
//                     ':value2': otp
//                 }
//             };
            
//             const userDetails = await dynamoDB.scan(params).promise();
//             const specificItem = userDetails.Items[0];
            
//             if (userDetails.Items.length === 1) {
//                 if (specificItem.Status === '1') {
//                     const accessToken = jwt.sign({
//                         email: specificItem.EmailId,
//                         role: specificItem.Role,
//                         id: specificItem.id,
//                         username: specificItem.FirstName + " " + specificItem.LastName,
//                         status: specificItem.Status
//                     }, process.env.ACCESS_TOKEN_SECRET, {
//                         expiresIn: "10d"
//                     });

//                     const refreshToken = jwt.sign({
//                         email: specificItem.EmailId,
//                         role: specificItem.Role,
//                         id: specificItem.id
//                     }, process.env.REFRESH_TOKEN_SECRET, {
//                         expiresIn: "1d"
//                     });

//                     return {
//                         statusCode: 200,
//                         headers,
//                         body: JSON.stringify({ accessToken, details: specificItem.id, userRole: specificItem.Role, PhoneNumber: specificItem.PhoneNumber, userName: specificItem.FirstName+" "+specificItem.LastName})
//                     };
//                 } else if (specificItem.Status === '0') {
//                     return {
//                         statusCode: 201,
//                         headers,
//                         body: JSON.stringify({ msg: "Account Inactive", userId:  specificItem.id})
//                     };
//                 }
//             } 
            
//             else {
//                 return {
//                     statusCode: 400,
//                     headers,
//                     body: JSON.stringify({ msg: "Invalid Credentials" })
//                 };
//             }
//         }
//     } catch (err) {
//         return {
//             statusCode: 500,
//             headers,
//             body: JSON.stringify({ msg: err })
//         };
//     }
// };











