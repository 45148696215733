import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import './listFAQs.css';
import getFAQs from '../../Services/FAQ';
import { Helmet } from 'react-helmet';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';


interface FAQs {
    Title: string;
    Description: string;

    // Status: any;
}

const ListFAQs = () => {
    const initialState: FAQs = {
        Title: '',
        Description: '',

        // Status: ''
    };

    const navigate = useNavigate();
    const [data, setData] = useState<FAQs>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [FAQsList, setFAQsList] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('PDF Viewer Modal');
    const [singleReview, setSingleReview] = useState<any>({});


    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // useAuthRedirect(logUserObject);

    const listSliders = async () => {
        const response = await getFAQs().FAQlist();
        console.log("response", response)
        setFAQsList(response);
    };

    useEffect(() => {
        listSliders();
    }, []);

    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected FAQs?");
        if (confirmBox) {
            await getFAQs().DeleteFAQ(id);
            listSliders();
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const filteredData: any = FAQsList?.filter((row: FAQs) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleEdit = (event: any) => {
        console.log("event", event);
        navigate('/addFAQs', { state: { id: event?.id } });
    }
    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);


    const handleSubmit = () => {
        navigate('/addFAQs');
    };


    const removeHtmlTags = (str: any) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    const handleStatusToggle = async (rowData: any) => {
        const updatedStatus = rowData?.Status === "1" ? "0" : "1";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        const update = await getFAQs().UpdateFAQ(updatedRowData);

        setFAQsList((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, Status: updatedStatus } : row
            )
        );
    };

    const truncateText = (text: any) => {
        if (!text) return '';
        const truncatedText = text?.length > 10 ? text?.substring(0, 300) + '...' : text;
        return truncatedText;
    };

    return (
        <>
            <Helmet>
                <title>FAQS</title>
                <meta name="description" content="This is the FAQ page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    FAQS
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>FAQS</li>
                </ul>
            </div>
            <div className="container">
                <div className="row tablefs">
                    {/* <div className='col-md-2'>
                        < Sidebar />
                    </div> */}
                    <div className="col-md-12">
                        <div>
                            <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <div className="page-size-dropdown">

                                    <label style={{ padding: '5px' }}>Show</label>
                                    <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                    </select>
                                    <label style={{ padding: '5px' }}>entries</label>
                                </div>

                                <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                                    <div className="pb-3">
                                        <Button
                                            onClick={() => handleSubmit()}
                                            style={{ marginRight: '10px', float: 'right' }}
                                            // startIcon={<EditIcon style={{ color: 'green' }}  />}
                                            className='btn btn-primary'
                                        >
                                            Add
                                        </Button>
                                    </div>
                                    <p style={{ marginRight: '10px' }}>Search</p>
                                    <div>
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="search-bar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-bordered table-striped table-responsive listFaq">
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                            <th className='sno'>S.No</th>
                                            <th className='mdl'>Title</th>
                                            <th className='mdl'>Description</th>
                                            <th className='actions'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((row: any, index: number) => (
                                            <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>{highlightText(row?.Title)}</td>
                                                {/* <td>{highlightText(truncateText(removeHtmlTags(row?.Description)))}</td>
                                                */}
                                                <td
                                                    dangerouslySetInnerHTML={{
                                                        __html: row?.Description?.substring(0, 30) + (row?.Description?.length > 30 ? "..." : "")
                                                    }}
                                                >
                                                </td>
                                                <td className='actions'>
                                                    <Button title="Edit"
                                                        onClick={() => handleEdit(row)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<EditIcon style={{ color: 'green' }} />}
                                                    >

                                                    </Button>
                                                    <Button title="Delete"
                                                        onClick={() => handleDelete(row?.id)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                    >
                                                    </Button>

                                                    <Button title="Status"
                                                        onClick={() => handleStatusToggle(row)}
                                                        className={row?.Status === '1' ? 'statusSuccess' : 'statusDanger'}
                                                        startIcon={<VisibilityIcon />}
                                                    >
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='mttable'> Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                <div className="paginationstyle mttable1">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                handlePageChange(currentPage - 1);
                                                window.scrollTo(0, 0); // Scroll to top
                                            }
                                        }}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage < totalPages) {
                                                handlePageChange(currentPage + 1);
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div >

        </>

    );
};

export default ListFAQs;

