import { API, graphqlOperation } from "aws-amplify";
import {   createBanner,deleteBanner, updateBanner } from "../../../graphql/mutations";
import {  getBanner, getSpecialityCategory,listBanners, listSpecialityCategories } from "../../../graphql/queries";

const getSliders=()=>{
    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }

    const CreateSliders = async (userData:any) => {
        console.log("userData",userData);
        try {
            const result = await API.graphql(graphqlOperation(createBanner, { input: userData })) as any;
            return result?.data?.createBanner;
        } catch (error) {
            console.error('Error createBanner:', error);
            return null;
        }
    }
    const validateSlug = async (slug: string) => {
        console.log("slug", slug);
        try {
            const filterVar = {
                filter: {
                    Slug: { eq: slug }
                }
            };
         console.log("filterVar", filterVar);

            const existingSlugs: any = await API.graphql(graphqlOperation(listBanners, filterVar));

            // console.log("existingSlugs", existingSlugs)

            if (existingSlugs && existingSlugs?.data?.listBanners?.items?.length > 0) {
                return { msg: 'slug already exists with this slug', status: 1, data: existingSlugs?.data?.listBanners?.items};
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating slug:', error);
            return { msg: 'Error validating slug', status: 1 };
        }
    };

     
    const SliderUpdate = async (data: any) => {
        console.log("data", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updateBanner, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateBanner;
        } catch (error) {
            console.error('Error updateBanner:', error);
            return null;
        }
    }

    const SliderDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteBanner, { input: { id: id } })) as any;
            return result?.data?.deleteBanner;
        } catch (error) {
            console.error('Error in deleteBanner:', error);
            return null;
        }
    }

    const getSingleSlider = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getBanner, { id: id })) as any;
            return result?.data?.getBanner;
        } catch (error) {
            console.error('Error in getBanner:', error);
            return null;
        }
    }
    const SliderList = async () => {
        try {
            let nextToken: any = null;
            const limit = 100;
            let reviews: any[] = [];

            do {
                const filter = {
                    limit: limit,
                    nextToken: nextToken
                };
                const result = await API.graphql(graphqlOperation(listBanners, filter)) as any;
                const specializations = result?.data?.listBanners?.items || [];
                reviews.push(...specializations);
                nextToken = result?.data?.listBanners?.nextToken;
            } while (nextToken);
            return reviews;
        } catch (error) {
            console.error('Error fetching listBanners:', error);
            return [];
        }
    }



    const ActiveSliderList = async () => {
        try {
            let nextToken: string | null = null;
            const limit = 100;
            let reviews: any[] = [];
    
            do {
                const filter = {
                    filter: {
                        Status: { eq: 1 }
                    },
                    limit,
                    nextToken
                };
    
                const { data } = await API.graphql(graphqlOperation(listBanners, filter)) as { data: any };
                const { items, nextToken: newToken } = data?.listBanners || {};
    
                reviews = reviews.concat(items || []);
                nextToken = newToken;
            } while (nextToken);
    
            return reviews;
        } catch (error) {
            console.error('Error fetching listBanners:', error);
            return [];
        }
    };
    

 

    
    // const ActiveSliderList = async () => {
    //     try {
    //         let nextToken: any = null;
    //         const limit = 100;
    //         let reviews: any[] = [];

    //         do {
    //             const filter = {
    //                 filter: {
    //                     Status: { eq: 1 }
    //                 },
    //                 limit: limit,
    //                 nextToken: nextToken
    //             };
    //             const result = await API.graphql(graphqlOperation(listBanners, filter)) as any;
    //             const specializations = result?.data?.listBanners?.items || [];
    //             reviews.push(...specializations);
    //             nextToken = result?.data?.listBanners?.nextToken;
    //         } while (nextToken);
    //         return reviews;
    //     } catch (error) {
    //         console.error('Error fetching listBanners:', error);
    //         return [];
    //     }
    // }

return{
    CreateSliders,
    SliderUpdate,
    SliderDelete,
    getSingleSlider,
    SliderList,
    validateSlug,
    ActiveSliderList 
    

}
}

export default getSliders