import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import './style.css';
import userService from '../../Services/user/user.service';
import { TextField, Button, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PharmacyServices from '../../Services/pharmacyServices';
import { updDelFile } from '../shared/fileupload';

interface Pharmacy {
    PhoneNumber: string;
    EmailId: string;
    id: string;
    FirstName: string;
    LastName: string;
    Status: string;
}

const ListPharmacy = () => {

    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [pharmacyList, setPharmacyList] = useState<Pharmacy[]>([]);
    const [specializationtList, setspecializationtList] = useState<Pharmacy[]>([]);

    useEffect(() => {
        const logUser: any = localStorage?.getItem('userData');
        const logUserObject = JSON?.parse(logUser);

        if (!logUserObject?.accessToken) {
            navigate('/ListPatient');
        }
    }, [navigate]);
    //   const getspec = async () => {
    //     const response = await userService().ListSpecializations('Pharmacy') as any;


    //     setspecializationtList(response);
    //     console.log("doctorsDetails", response)
    //   };

    const getDoctors = async () => {

        const response = await PharmacyServices().ListPharmacy('Pharmacy') as any;
        console.log(response);
        const specializationtList = await userService().getlistSpecializations() as any;

        response?.map(async (item: any, index: number) => {

            const specName = specializationtList?.find((spec: any) => spec?.id === item?.Specialization);
            item.Specialization = specName?.name
        })
        // let doctorsDetails: any = response?.data?.listUsers?.items
        console.log(response, 'doctorsDetails');
        setPharmacyList(response);
        // console.log("response", response)
    };

    useEffect(() => {
        getDoctors();
        // getspec();
    }, []);
    //   const getDoctorNameById = (doctorId: string) => {
    //     const pharmacy = specializationtList.find(doc => doc?.id === doctorId);
    //     return pharmacy ? 
    //   };
    const handleDelete = async (id: string) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected pharmacy?");
        if (confirmBox) {
            const user: any = await PharmacyServices().getuserSingle(id);
            const filedel = await updDelFile(user?.data?.getUser?.userProfile, "images");
            if (filedel?.status === 200) {
                await PharmacyServices().deletePharmacy(id);
                getDoctors();
            }
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) => (regex.test(part) ? <span key={index} className="highlighted-text">{part}</span> : part));
    };

    const filteredData = pharmacyList.filter((pharmacy: Pharmacy) =>
        Object.values(pharmacy)?.some((value: any) => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
    );

    const totalPages = Math?.ceil(filteredData?.length / pageSize);
    const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

    const handleEdit = (event: any) => {
        console.log("event", event);
        navigate('/addPharmacy', { state: { id: event?.id } });
    }

    const handleSubmit = (setUpdate: boolean) => {
        navigate('/addPharmacy');
    }
  


    const handleStatusToggle = (rowData: any) => async (event: any) => {
        const updatedStatus = event?.target?.checked === true ? "1" : "0";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        console.log("updatedRowData", updatedRowData);
        const update = await PharmacyServices().updatePharmacyByData(updatedRowData);

        setPharmacyList((prevRows) =>
            prevRows?.map((pharmacy) =>
                pharmacy?.id === rowData?.id ? { ...pharmacy, Status: updatedStatus } : pharmacy
            )
        );
    };

  

    return (
        <>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Pharmacy
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                    <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Pharmacy</li>
                </ul>
            </div>
            <div className="container">
                <div className='row'>
                    {/* <div className="col-md-2">
          <Sidebar />
        </div> */}
                    <div className="col-md-12">
                        <div style={{ margin: '40px auto' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <div className='page-entry'>
                                    <Select
                                        value={pageSize}
                                        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                    entries per page
                                </div>
                                <div className='table-pagination' style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        onClick={() => handleSubmit(false)}
                                        style={{ marginRight: '10px', backgroundColor: '#98B868', color: 'white', borderStyle: 'none', outline: 'none' }}
                                        className='btn btn-primary'
                                    >
                                        Add
                                    </Button>
                                    <TextField
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search..."
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <SearchIcon />,
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Pharmacy Name</th>
                                            {/* <th>Specialization</th> */}
                                            <th>Phone Number</th>
                                            <th>Email Id</th>
                                            {/* <th>TimeSlot</th> */}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData.map((pharmacy: Pharmacy, index: number) => (
                                            <tr key={pharmacy.id} className="highlighted-row">
                                                {/* <td>{index + 1}</td> */}
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>{highlightText(`${pharmacy?.FirstName} ${pharmacy?.LastName}`)}</td>
                                                {/* <td>{highlightText(pharmacy?.Specialization)}</td> */}
                                                <td>{highlightText(pharmacy?.PhoneNumber)}</td>
                                                <td>{highlightText(pharmacy?.EmailId)}</td>
                                                {/* <td>{highlightText(pharmacy?.TimeSlot)}</td> */}
                                                <td style={{ padding: '10px' }}>

                                                    <Button title="Edit"
                                                        onClick={() => handleEdit(pharmacy)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<EditIcon style={{ color: 'green' }} />}
                                                    >

                                                    </Button>
                                                    <Button title="Delete"
                                                        onClick={() => handleDelete(pharmacy.id)}
                                                        startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                    >

                                                    </Button>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={pharmacy?.Status === "1"}
                                                                onChange={handleStatusToggle(pharmacy)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={
                                                            <span className={pharmacy?.Status === "1" ? 'status-active' : 'status-inactive'}>
                                                                {pharmacy?.Status === "1" ? 'Active' : 'Inactive'}
                                                            </span>
                                                        }
                                                    />
                                                    {/* <FormControlLabel
                        control={
                          <Switch
                            checked={pharmacy?.ShowInTeam === "1"}
                            onChange={handleShowteam(pharmacy)}
                            color="primary"
                          />
                        }
                        label={
                          <span className={pharmacy?.ShowInTeam === "1" ? 'status-active' : 'status-inactive'}>
                          {pharmacy?.ShowInTeam === "1" ? 'Show In Team' : 'Not In Team'}
                        </span>
                        }
                      /> */}

                                                </td>
                                                {/* <td>
                <i className="fas fa-edit" style={{ color: 'green' }} onClick={() => handleEdit(pharmacy)}></i>
                <i className="fas fa-trash" style={{ marginLeft: '20px', color: 'red' }} onClick={() => handleDelete(pharmacy.id)}></i>
              </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    Showing {((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'right', float: 'right' }}>
                                    <Button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        variant="contained"
                                        style={{ marginRight: '10px' }}
                                    >
                                        Previous
                                    </Button>
                                    <div className="pageNumb">{currentPage}</div>
                                    <Button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                        variant="contained"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListPharmacy;