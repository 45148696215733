import { API, graphqlOperation } from "aws-amplify";
import { getServices, listServices, listServiceCategories } from "../../../graphql/queries";
import { createServices, deleteServiceCategory, deleteServices, updateServiceCategory, updateServices } from "../../../graphql/mutations";


const Homeservices = () => {

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }
    const CreateServices = async (userData: any) => {
        console.log("userData", userData);
        try {
            const result = await API.graphql(graphqlOperation(createServices, { input: userData })) as any;
            return result?.data?.createServices;
        } catch (error) {
            console.error('Error creating creating service:', error);
            return null;
        }
    }

    const servicesList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                limit: limit,
                nextToken: nextToken
            };

            do {
                const result = await API.graphql(graphqlOperation(listServices, filterVar)) as any;
                const users = result?.data?.listServices?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listServices?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }


    const ServiceUpdate = async (data: any) => {
        console.log("data", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        try {
            const result = await API.graphql(graphqlOperation(updateServices, { input: sanitizedUserInfo })) as any;
            return result.data.updateServices;
        } catch (error) {
            console.error('Error updating update service:', error);
            return null;
        }
        // const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        // const sanitizedUserInfo = removeFields(data, fieldsToRemove);
        // try {
        //     const getResponse: any = await API.graphql(graphqlOperation(updateServices, { input: sanitizedUserInfo }));
        //     // console.log(getResponse, "getResponse");
        //     return getResponse;
        // } catch (error) {
        //     console.log(error);
        // }
    }


    const serviceDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteServices, { input: { id: id } })) as any;
            return result?.data?.deleteServices;
        } catch (error) {
            console.error('Error in delete Service:', error);
            return null;
        }
    }


    const getServiceSingle = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(getServices, { id: id })) as any;
            return result?.data?.getServices;
        } catch (error) {
            console.error('Error in get Services :', error);
            return null;
        }
    }

    const ActiveservicesList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                filter: {
                    ShowInHome: { eq: 1 },
                    Status: { eq: 1 }
                },
                limit: limit,
                nextToken: nextToken
            };


            do {
                const result = await API.graphql(graphqlOperation(listServices, filterVar)) as any;
                const users = result?.data?.listServices?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listServices?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }

    const FooterservicesList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                filter: {
                    Status: { eq: 1 }
                },
                limit: limit,
                nextToken: nextToken
            };
            do {
                const result = await API.graphql(graphqlOperation(listServices, filterVar)) as any;
                const users = result?.data?.listServices?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listServices?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }
    const validateSlug = async (slug: string) => {
        console.log("slug", slug);
        try {
            const filterVar = {
                filter: {
                    Slug: { eq: slug }
                }
            };
            console.log("filterVar", filterVar);

            const existingSlugs: any = await API.graphql(graphqlOperation(listServices, filterVar));

            // console.log("existingSlugs", existingSlugs)

            if (existingSlugs && existingSlugs?.data?.listServices?.items?.length > 0) {
                return { msg: 'slug already exists with this slug', status: 1, data: existingSlugs?.data?.listServices?.items };
            } else {
                return { msg: '', status: 0 };
            }
        } catch (error) {
            console.error('Error validating slug:', error);
            return { msg: 'Error validating slug', status: 1 };
        }
    };
    const serviceCategoryList = async () => {
        try {
            let allUsers: any[] = [];
            let nextToken: string | null = null;
            const limit = 100;

            const filterVar = {
                limit: limit,
                nextToken: nextToken
            };

            do {
                const result = await API.graphql(graphqlOperation(listServiceCategories, filterVar)) as any;
                const users = result?.data?.listServiceCategories?.items || [];
                allUsers.push(...users);

                nextToken = result?.data?.listServiceCategories?.nextToken;
            } while (nextToken);
            allUsers.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return allUsers;
        } catch (error) {
            console.error('Error fetching services:', error);
            return [];
        }
    }

    const ServiceCategoryUpdate = async (data: any) => {
        console.log("careerData", data)

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(data, fieldsToRemove);

        console.log('sanitizedUserInfo', sanitizedUserInfo)

        try {
            const result = await API.graphql(graphqlOperation(updateServiceCategory, { input: sanitizedUserInfo })) as any;
            return result?.data?.updateServiceCategory;
        } catch (error) {
            console.error('Error updateServiceCategory:', error);
            return null;
        }
    }
    const ServiceCategoryDelete = async (id: any) => {
        try {
            const result = await API.graphql(graphqlOperation(deleteServiceCategory, { input: { id: id } })) as any;
            return result?.data?.deleteServiceCategory;
        } catch (error) {
            console.error('Error in deleteServiceCategory:', error);
            return null;
        }
    }



    return {
        CreateServices,
        servicesList,
        ServiceUpdate,
        serviceDelete,
        getServiceSingle,
        ActiveservicesList,
        FooterservicesList,
        validateSlug,
        serviceCategoryList,
        ServiceCategoryUpdate,
        ServiceCategoryDelete
    }
}

export default Homeservices