import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import getCareer from '../../Services/Careers';
import { Helmet } from "react-helmet";
import './listCareer.css';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';

interface compProps {
    title: string;
    description: string;
}

interface CarrerCategory {
    id?: any;
    Title: string;
    Description: string;
    Status: any;
}

const ListCarrerCategory: React.FC<compProps> = ({ title, description }) => {
    const initialState: CarrerCategory = {
        Title: '',
        Description: '',
        Status: ''
    };

    const navigate = useNavigate();
    const [data, setData] = useState<CarrerCategory>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [CarrerCategorylist, setCarrerCategorylist] = useState<any[]>([]);
    const [isEditing, setIsEditing] = useState<string | null>(null); // Track which row is being edited
    const [editValue, setEditValue] = useState<string>(''); // Track the edited value
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // useAuthRedirect(logUserObject);



    const listCarrerCategory = async () => {
        const response = await getCareer().CategoryList();
        setCarrerCategorylist(response);
    };

    useEffect(() => {
        listCarrerCategory();
    }, []);


    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected CarrerCategory?");
        if (confirmBox) {
            await getCareer().CareerCategoryDelete(id);
            listCarrerCategory();
        } else {
            alert('Deletion cancelled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const filteredData: any = CarrerCategorylist?.filter((row: CarrerCategory) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleEdit = (row: any) => {
        setIsEditing(row.id);
        setEditValue(row.name); // Initialize with the current name value
    }

    const handleSaveEdit = async (row: any) => {
        // Call your API to update the row
        const updatedRow = { ...row, name: editValue };
        await getCareer().CareerCategoryUpdate(updatedRow);
        listCarrerCategory(); // Refresh the list
        setIsEditing(null); // Exit edit mode
    };

    const handleCancelEdit = () => {
        setIsEditing(null); // Exit edit mode without saving
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>

            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Career Category List
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Career Category List</li>
                </ul>
            </div>
            <div className="container">
                <div className="row tablefs">
                    <div className="col-md-12">
                        <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="page-size-dropdown">
                                    <label style={{ padding: '5px' }}>Show</label>
                                    <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                    </select>
                                    <label style={{ padding: '5px' }}>entries</label>
                                </div>
                                <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                                    <p className='tablefs' style={{ marginRight: '10px' }}>Search</p>
                                    <div>
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="search-bar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-bordered table-striped table-hover table-responsive listCareersCategory">
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                            <th className='sno'>S.No</th>
                                            <th className='mdl'>Name</th>
                                            <th className='actions'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((row: any, index: number) => (
                                            <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>
                                                    {isEditing === row.id ? (
                                                        <TextField
                                                            value={editValue}
                                                            onChange={(e) => setEditValue(e.target.value)}
                                                            size="small"
                                                        />
                                                    ) : (
                                                        highlightText(row?.name)
                                                    )}
                                                </td>
                                                <td className='actions'>
                                                    {isEditing === row.id ? (
                                                        <>
                                                            <Button
                                                                title="Save"
                                                                onClick={() => handleSaveEdit(row)}
                                                                style={{ marginRight: '10px', color: 'green' }}
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                title="Cancel"
                                                                onClick={handleCancelEdit}
                                                                style={{ color: 'red' }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                title="Edit"
                                                                onClick={() => handleEdit(row)}
                                                                style={{ marginRight: '10px' }}
                                                                startIcon={<EditIcon style={{ color: 'green' }} />}
                                                            />
                                                            <Button
                                                                title="Delete"
                                                                onClick={() => handleDelete(row?.id)}
                                                                style={{ marginRight: '10px' }}
                                                                startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                            />
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='mttable'>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                <div className="paginationstyle mttable1">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                handlePageChange(currentPage - 1);
                                                window.scrollTo(0, 0); // Scroll to top
                                            }
                                        }}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage < totalPages) {
                                                handlePageChange(currentPage + 1);
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListCarrerCategory;


