import React from 'react';

const NotFound: React.FC = () => {
  return (
    <>
    <section className="home-slider owl-carousel">
    <div
      className="slider-item bread-item"
      style={{backgroundColor:'lightgray' }}
      data-stellar-background-ratio="0.5"
    >
      <div className="overlay"></div>
      <div className="container" data-scrollax-parent="true">
        <div className="row banner-text align-items-center">
          <div className="col-md-12 col-sm-12 ftco-animate">
            <p
              className="breadcrumbs" style={{marginTop: '90px'}}
             
            >
              {/* <span>
              <a href="javascript:void(0);" onClick={() => navigate('/')} style={{fontSize: '17px', textDecoration: 'none'}}>Home/</a>
              </span>
              <span style={{fontSize: '17px'}}>About Us</span> */}
            </p><br/>
            <h2 style={{textAlign: 'center',marginBottom: '100px'}}>
              404 - Not Found
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
    </>
  );
};

export default NotFound;