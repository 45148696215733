import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface ModelComponentProps {
  setCurrentOTP: (otp: string) => void;
  appointmentSuccessMsg: any;
  timer: any;
  OTPErr: any;
  handleAppoinmentOTPSubmit: () => void;
  ResendEmailAppoinment: () => void;
  closeModal: () => void;
  getappointmentModal: boolean;
  CurrentOTP:any;
}


const ModelComponentAppoinment: React.FC<ModelComponentProps> = ({
  setCurrentOTP,
  appointmentSuccessMsg,
  timer,
  OTPErr,
  handleAppoinmentOTPSubmit,
  ResendEmailAppoinment,
  closeModal,
  getappointmentModal,
  CurrentOTP
}) => {
  // const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [showMsg, setShowMsg] = useState('');

  useEffect(() => {
    if (appointmentSuccessMsg) {
      setShowMsg(appointmentSuccessMsg);
    } else {
      setShowMsg('');
    }

    if (OTPErr) {
      setOtpError(OTPErr);
    } else {
      setOtpError('');
    }
  }, [appointmentSuccessMsg, OTPErr]);



  
  return (
    <Modal className='modelwidth' show={getappointmentModal} onHide={closeModal} centered>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: '8px', textAlign: 'center', maxWidth: 400 }}>
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <Typography variant="h6" style={{ color: '#2cbcbc', marginBottom: '20px' }}>Update Email</Typography>
          <p className='text-success text-center' style={{ fontSize: '15px', color: 'red' }}>{showMsg}</p>
          {otpError && (
            <Typography color="error" sx={{ mt: 2 }}>
              {otpError}
            </Typography>
          )}
          <TextField
            label="Enter Your OTP"
            value={CurrentOTP}
            onChange={(e) => setCurrentOTP(e?.target?.value)}
            fullWidth
            sx={{ mt: 2 }}
            autoComplete="off"
          />
          {timer > 0 ? (
            <>
              <Typography sx={{ mt: 2 }}>
                Resend OTP in {timer} seconds
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                <Button variant="contained" color="primary" onClick={handleAppoinmentOTPSubmit}>
                  Submit
                </Button>
              </Box>
            </>
          ) : (
            <Box sx={{ mt: 2 }}>
              {/* <Typography color="textSecondary">The OTP has expired. Please click  Bleow Button For the OTP.</Typography> */}
              <Typography color="textSecondary">The OTP has expired. Please click the below button to regenerate.</Typography>

              <Button variant="outlined" sx={{ mt: 2, borderColor: '#2cbcbc', color: '#2cbcbc' }} onClick={() => { ResendEmailAppoinment(); }}>
                Click here to resend
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModelComponentAppoinment;
