import React, { useEffect, useState, useRef, Suspense } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import getblogs from '../../Services/BlogServices';
import { Helmet } from 'react-helmet';
import pages from '../../utills/pageUtils';
import Loader from '../shared/Loader';
import LazyLoad from 'react-lazyload';

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

const BlogDetails: React.FC<compProps> = ({ title, description, keywords }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [blogsItems, setBlogItems] = useState<any>([]);
  const [filteredBlogs, setFilteredBlogs] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [blogsPerPage] = useState<number>(10);
  const [allCats, setAllCats] = useState<any>(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>('');

  // const [pageInfo, setPageInfo] = useState<any>()
  // const [bannerName, setBannerName] = useState<any>()
  // const [caption, setCaption] = useState<any>()

  const [pageData, setPageData] = useState<{ banner: any; title: string; description: string } | null>(null);


  const BlogsRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const BlogsPerPage = 6;
  const { slug } = useParams();

  // const location = useLocation();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // Simulate fetching data
  //     const result = await pages(location?.pathname)
  //     result?.map((item: any) => {
  //       setPageInfo(item?.Banner);
  //       setBannerName(item?.Title)
  //       setCaption(item?.Description)
  //     })

  //   };
  //   fetchData();
  // }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await pages(location?.pathname);

        if (result && result.length > 0) {
          const { Banner, Title, Description } = result[0]; // Only process the first item if applicable
          setPageData({ banner: Banner, title: Title, description: Description });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (location?.pathname) {
      fetchData();
    }
  }, [location?.pathname]);

  // Accessing data
  const banner: any = pageData?.banner ? pageData?.banner : null;
  const bannerName: any = pageData?.title ? pageData?.title : null;
  const caption: any = pageData?.description ? pageData?.description : null;

  // const removeHtmlTags = (str: any) => {
  //   return str.replace(/<\/?[^>]+(>|$)/g, "");
  // };

  const truncateText = (text: any, length: any) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + '...';
  };

  const handleReadMore = (slug: any) => {
    navigate(`/blogs/${slug}`);
  };

  // const handleSearch = () => {
  //   if (!searchTerm) {
  //     setFilteredBlogs(blogsItems);
  //   } else {
  //     const lowercasedTerm = searchTerm.toLowerCase();
  //     const filtered = blogsItems.filter((item: any) =>
  //       item.title.toLowerCase().includes(lowercasedTerm) ||
  //       removeHtmlTags(item.description).toLowerCase().includes(lowercasedTerm)
  //     );
  //     setFilteredBlogs(filtered);
  //   }
  //   setCurrentPage(1); // Reset to the first page
  // };

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(event.target.value);
  // };

  // const listBlogs = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await getblogs().ActiveBlogList();
  //     const categoryname = await getblogs().getcategory();
  //     const updatedResponse = response?.map((item: any) => {
  //       const categoryNames = item?.catergory?.map((cateId: any) => {
  //         const category = categoryname?.find((cate) => cate?.id === cateId);
  //         return category ? category?.name : cateId;
  //       });
  //       return {
  //         ...item,
  //         categoryName: categoryNames
  //       };
  //     });
  //     setBlogItems(updatedResponse);
  //     const categoryIds = updatedResponse?.map((item: any) => item?.catergory);
  //     const catResponse = await getblogs().CategoryList();
  //     setCategories(catResponse);
  //     location?.state?.category && setSelectedCategory(location?.state?.category);
  //     setLoading(false);
  //     // console.log("categoryIds", categoryIds);
  //   } catch (error) {
  //     console.error('Error fetching blogs:', error);
  //     setLoading(false);
  //   }
  // };


  const listBlogs = async () => {
    try {
      setLoading(true);

      // Fetch blogs and categories concurrently
      const [response, categoryList] = await Promise.all([
        getblogs().ActiveBlogList(),
        getblogs().getcategory(),
      ]);

      // Map through the blogs and associate category names
      const updatedResponse = response?.map((item: any) => {
        const categoryNames = item?.catergory?.map((cateId: any) => {
          const category = categoryList?.find((cate) => cate?.id === cateId);
          return category ? category?.name : cateId;
        });

        return {
          ...item,
          categoryName: categoryNames,
        };
      });

      setBlogItems(updatedResponse);
      setLoading(false);

      // Set categories and selected category if available
      const catResponse = await getblogs().CategoryList();
      setCategories(catResponse);

      if (location?.state?.category) {
        setSelectedCategory(location.state.category);
      }

    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };





  useEffect(() => {
    listBlogs();
    window.scrollTo(0, 0);
  }, [location?.state?.category]);

  useEffect(() => {
    const filterCat = selectedCategory;
    const filterBlogs: any = filterCat && blogsItems?.filter((blog: any) => blog?.catergory[0]?.split(',')?.includes(filterCat));
    filterBlogs && setFilteredBlogs(filterBlogs);
  }, [selectedCategory, location?.state?.category, blogsItems]);

  const blogsFiltered: any = !selectedCategory ? blogsItems : filteredBlogs?.length > 0 ? filteredBlogs : [];
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const countByCategory = blogsItems && blogsItems?.reduce((acc: any, blog: any) => {
    const categoryIds = blog?.catergory[0]
    const splitString = categoryIds?.includes(',') ? categoryIds?.split(',') : [categoryIds];
    splitString?.forEach((catId: any) => {
      if (!acc[catId]) {
        acc[catId] = 0;
      }
      acc[catId]++;
    });

    return acc;
  }, {});

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
      (day % 10 === 2 && day !== 12) ? 'nd' :
        (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

    return `${day}${suffix} ${month} ${year}`;
  };

  // Pagination logic
  const indexOfLastBlogs = currentPage * BlogsPerPage;
  const indexOfFirstBlog = indexOfLastBlogs - BlogsPerPage;
  // const currentDoctors = blogsFiltered.slice(indexOfFirstBlog, indexOfLastBlogs);
  const currentBlogs = blogsFiltered?.slice(indexOfFirstBlog, indexOfLastBlogs);
  const totalPages = Math?.ceil(blogsFiltered?.length / BlogsPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  useEffect(() => {
    if (BlogsRef.current) {
      BlogsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);
  }, [currentPage]);



  const handleCategoryClick = (categoryId: string) => {
    setActiveCategory(categoryId);
    setSelectedCategory(categoryId);
    setAllCats(false);
    window.scrollTo(0, 0);
  };


  return (
    <>
      {/* {loading ? (
        <Loader />
      ) : ( */}
        <>
          <Helmet>
            <title>{title}</title>
            <meta name="keywords" content={keywords} />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta name="author" content="Gurram Dental Care" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>

          <section className="home-slider owl-carousel">
            <LazyLoad>
            <div
              className="slider-item bread-item"
              // style={{ backgroundImage: `url('images/bg_1.jpg')` }}
              style={{ backgroundImage: banner ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${banner})` : 'images/bg_1.jpg' }}
              data-stellar-background-ratio="0.5"
            >
              <div className="overlay"></div>
              <div className="container" data-scrollax-parent="true">
                <div className="row banner-text align-items-center">
                  <div className="col-md-12 col-sm-12 ftco-animate">
                    <h1 className="banner-ttl">{bannerName}</h1>
                    <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
                  </div>
                </div>
              </div>
            </div>
            </LazyLoad>
          </section><div>
            <ul className="ullist">
              <li>
                <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div><section className="ftco-section" ref={BlogsRef} id="blogs">

          {loading ? (
        <Loader />
      ) : (
            
            <div className="container">
              <div className="row">
                <div className="col-md-9 ftco-animate">
                
                  <div className="row">
                    {currentBlogs?.length > 0 ? (
                      currentBlogs?.map((item: any) => (
                        <div className="col-md-6" key={item?.id}>
                          <div>
                            <p>
                              <LazyLoad>
                                <img
                                  src={item?.blogImage?.length > 0 ? `${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.blogImage}` : 'images/blogsingle.jpg'}
                                  alt={item?.title}
                                  className="img-fluid imgcareer1" />
                              </LazyLoad>
                            </p>
                            <h5 className="headingfs" dangerouslySetInnerHTML={{ __html: truncateText(item?.title, 30) }} />
                            <p className="text-muted date">{formatDate(item?.createdAt)}</p>
                            <p className="descheight" dangerouslySetInnerHTML={{ __html: truncateText(item?.description, 80) }} />
                            <p className='mtbtn'>
                              <button
                                className=" btn read-more"
                                onClick={() => handleReadMore(item?.Slug)}
                              >
                                Read more
                              </button>
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-12">
                        <p style={{ textAlign: 'center', fontSize: '30px' }}>No Blogs available</p>
                      </div>
                    )}

                  </div>

                  

                </div>

                <div className="col-md-3 sidebar ftco-animate">
                  <div className="sidebar-box">
                    <form action="#" className="search-form">

                    </form>
                  </div>
                  <div className="sidebar-box ftco-animate">
                    <h3 className="sidebar-heading">Categories</h3>
                    <div className="categories-container">
                      <ul className="categorise-list">

                        <li style={{ backgroundColor: selectedCategory === "" ? '#2cbcbc' : 'transparent', color: selectedCategory === "" ? 'white' : 'black', padding: '10px', cursor: 'pointer' }} onClick={() => setSelectedCategory("")}>All</li>
                        {categories?.map((item: any) => {
                          // console.log("item", item);
                          const blogCount = countByCategory[item?.id] || 0;
                          // console.log("blogCount", blogCount);
                          return (
                            <li
                              className="capitalTxt"
                              key={item?.id}
                              onClick={() => handleCategoryClick(item?.id)}
                              style={{
                                cursor: 'pointer',
                                backgroundColor: selectedCategory === item?.id ? '#2cbcbc' : 'transparent', color: selectedCategory === item?.id ? 'white' : 'black', padding: '10px'
                              }}
                            >
                              {item?.name.toLowerCase()} <span>({blogCount})</span>
                            </li>
                          );
                        }
                        )}
                      </ul>
                    </div>

                  </div>

                </div>
                {/* Pagination Controls */}
                {currentBlogs?.length > 0 ?
                  <div className="custom-pagination">
                    <ul className="custom-pagination-list">
                      <li className={`custom-pagination-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                          Previous
                        </button>
                      </li>
                      {[...Array(totalPages)].map((_, index) => (
                        <li key={index} className={`custom-pagination-item ${currentPage === index + 1 ? 'active' : ''}`}>
                          <button onClick={() => paginate(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li className={`custom-pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  : null}
              </div>
            </div>
      )}


          </section></>
      {/* )} */}
    </>
  );
};

export default BlogDetails;
