import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LabServices from '../../Services/labServices';
import { updDelFile } from '../shared/fileupload';
import { Helmet } from 'react-helmet';
import './style.css';

interface Labs {
    PhoneNumber: string;
    EmailId: string;
    id: string;
    FirstName: string;
    LastName: string;
    Status: string;
}

const ListLabs = () => {

    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [labList, setLabList] = useState<Labs[]>([]);
    const [specializationtList, setspecializationtList] = useState<Labs[]>([]);

    useEffect(() => {
        const logUser: any = localStorage?.getItem('userData');
        const logUserObject = JSON?.parse(logUser);

        if (!logUserObject?.accessToken) {
            navigate('/ListPatient');
        }
    }, [navigate]);


    const getDoctors = async () => {

        const response = await LabServices().ListLab('Lab') as any;
        console.log(response);

        console.log(response, 'doctorsDetails');
        setLabList(response);

    };

    useEffect(() => {
        getDoctors();

    }, []);

    const handleDelete = async (id: string) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected lab?");
        if (confirmBox) {
            const user: any = await LabServices().getuserSingle(id);
            const filedel = await updDelFile(user?.data?.getUser?.userProfile, "images");
            if (filedel?.status === 200) {
                await LabServices().deleteLab(id);
                getDoctors();
            }
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex.test(part) ? <span key={index} className="highlighted-text">{part}</span> : part));
    };

    const filteredData = labList?.filter((lab: Labs) =>
        Object.values(lab)?.some((value: any) => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
    );

    const totalPages = Math?.ceil(filteredData?.length / pageSize);
    const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

    const handleEdit = (event: any) => {
        console.log("event", event);
        navigate('/addLab', { state: { id: event?.id } });
    }

    const handleSubmit = (setUpdate: boolean) => {
        navigate('/addLab');
    }

    const handleStatusToggle = async (rowData: any) => {
        const updatedStatus = rowData?.Status === "1" ? "0" : "1";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        console.log("updatedRowData", updatedRowData);
        const update = await LabServices().updateLabByData(updatedRowData);

        setLabList((prevRows) =>
            prevRows?.map((lab) =>
                lab?.id === rowData?.id ? { ...lab, Status: updatedStatus } : lab
            )
        );
    };

    return (
        <>
            <Helmet>
                <title>Labs</title>
                <meta name="description" content="This is the Lab page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Labs
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Labs</li>
                </ul>
            </div>
            <div className="container">
                <div className='row tablefs'>

                    <div className="col-md-12">
                        <div style={{ margin: '40px auto' }}>
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <div className='page-entry'>
                                    <Select
                                        value={pageSize}
                                        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                        style={{ marginRight: '10px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                    entries per page
                                </div>
                                <div className='table-pagination' style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        onClick={() => handleSubmit(false)}
                                        style={{ marginRight: '10px', backgroundColor: '#98B868', color: 'white', borderStyle: 'none', outline: 'none' }}
                                        className='btn btn-primary'
                                    >
                                        Add
                                    </Button>
                                    <TextField
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search..."
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <SearchIcon />,
                                        }}
                                    />
                                </div>
                            </div> */}
                            <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <div className="page-size-dropdown">

                                    <label style={{ padding: '5px' }}>Show</label>
                                    <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                    </select>
                                    <label style={{ padding: '5px' }}>entries</label>
                                </div>

                                <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                                  <div className="pb-3">
                                        <Button
                                            onClick={() => handleSubmit(false)}
                                            style={{ marginRight: '10px', float: 'right' }}
                                            // startIcon={<EditIcon style={{ color: 'green' }}  />}
                                            className='btn btn-primary'
                                        >
                                            Add
                                        </Button>
                                    </div>
                                    <p style={{ marginRight: '10px' }}>Search</p>
                                    <div>
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="search-bar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-striped table-bordered table-hover table-responsive listLab">
                                    <thead>
                                        <tr>
                                            <th className='sno'>S.No</th>
                                            <th className='mdl'>Labs Name</th>
                                            <th className='mdl'>Phone Number</th>
                                            <th className='mdl'>Email Id</th>
                                            <th className='actions'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData.map((lab: Labs, index: number) => (
                                            <tr key={lab.id} className="highlighted-row">
                                                {/* <td>{index + 1}</td> */}
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>{highlightText(`${lab?.FirstName} ${lab?.LastName}`)}</td>
                                                <td>{highlightText(lab?.PhoneNumber)}</td>
                                                <td>{highlightText(lab?.EmailId)}</td>
                                                <td className='actions'>

                                                    <Button title="Edit"
                                                        onClick={() => handleEdit(lab)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<EditIcon style={{ color: 'green' }} />}
                                                    >

                                                    </Button>
                                                    <Button title="Delete"
                                                        onClick={() => handleDelete(lab.id)}
                                                        startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                    >

                                                    </Button>

                                                    <Button title="Status"
                                                        onClick={() => handleStatusToggle(lab)}
                                                        className={lab?.Status === '1' ? 'statusSuccess' : 'statusDanger'}
                                                        startIcon={<VisibilityIcon />}
                                                    >
                                                    </Button>


                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='mttable'>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                <div className="paginationstyle mttable1">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                handlePageChange(currentPage - 1);
                                                window.scrollTo(0, 0); // Scroll to top
                                            }
                                        }}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage < totalPages) {
                                                handlePageChange(currentPage + 1);
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListLabs;