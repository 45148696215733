//Custom test function to validate spaces

import * as Yup from "yup";
const spaceValidation = (value: string) => {
  const hasLeadingOrTrailingSpaces = /^\s+|\s+$/.test(value);
  const hasMultipleSpacesInMiddle = /\s{3,}/.test(value);

  if (hasLeadingOrTrailingSpaces || hasMultipleSpacesInMiddle) {
    return false;
  } else {
    return true;
  }
};
const characterValidation = (value: any) => {
  // return /^[A-Za-z]+$/.test(value);
  return /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value);
};

export const registerValidationSchema = (id: any) => {
  return Yup.object().shape({
    FirstName: Yup.string()
      .required("First Name is required")
      .min(3, "First Name must be at least 3 characters")
      .test(
        "character-validation",
        "First Name must contain only characters",
        characterValidation
      ),
    LastName: Yup.string()
      .required("Last Name is required")
      .min(3, "Last Name must be at least 3 characters")
      .test(
        "character-validation",
        "Last Name must contain only characters",
        characterValidation
      ),
    EmailId: Yup.string()
      .email("Invalid email format")
      .required("Email is required")
      .test("valid-domain", "Invalid domain extension", (value) => {
        // Custom validation for domain extension
        if (value) {
          const domainRegex =
            /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
          return domainRegex.test(value);
        }
        return true; // Allow empty values, Yup's required handles that
      }),
    ...(!id ? {
      Password: Yup.string()
        .min(8, "Password is too short")
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("Password")], "Confirm Passwords must match with password")
        .when("password", (Password, schema) => {
          return Password
            ? schema.required("Confirm Password is required")
            : schema.notRequired();
        }),
    } : {}),
    PhoneNumber: Yup.string()
      .required("Phone Number is required")
      .test(
        "phone-validation",
        "Phone number must have exactly 10 digits",
        (value) => {
          const inputValue = value.replace(/\D/g, "");
          const phoneRegex = /^\d{10}$/;
          return phoneRegex.test(inputValue);
        }
      ),
    // countryCode: Yup.string()
    //   .required("Country Code is required")
    //   .test(
    //     "country-code-validation",
    //     "Country code should have exactly 2 digits",
    //     (value) => {
    //       const inputValue = value.replace(/\D/g, "");
    //       const phoneRegex = /^\d{2}$/;
    //       return /^\d+$/.test(inputValue) && phoneRegex.test(inputValue);
    //     }
    //   ),
    countryCode: Yup.string()
      .test(
        "country-code-validation",
        "Country code should have exactly 2 digits",
        (value) => {
          if (!value) return true; // Skip validation if value is empty (handled elsewhere)
          const inputValue = value.replace(/\D/g, "");
          const phoneRegex = /^\d{2}$/;
          return /^\d+$/.test(inputValue) && phoneRegex.test(inputValue);
        }
      ),
    Gender: Yup.string()
      .required("Gender is required")
      .oneOf(["Male", "Female"], "Gender must be either Male or Female"),
    Age: Yup.string()
      .required("Age is required")
      .typeError("Age must be a number"),
    // .positive('Age must be a positive number'),
    Specialization: Yup.string()
      .required("Specialization is required")
      .test(
        "space-validation",
        "Specialization must not contain spaces",
        (value) => {
          return spaceValidation(value);
        }
      ),
    // Description: Yup.string()
    //   .required('Description is required')
    //   .min(10, 'Description should be at least 10 characters')
    //   .max(500, 'Description should not exceed 500 characters'),
    Slug: Yup.string().required('Slug is required'),
    qualification: Yup.string().required('Qualification is required'),
  });
}

export function PasswordInput(value: any) {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (!passwordRegex.test(value)) {
    return { status: false, message: 'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character' };
  } else {
    return { status: true, message: '' };
  }
}




































