import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import userService from '../../Services/user/user.service';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReportServices from '../../Services/reportservices';
import ContentPasteSearch from '@mui/icons-material/ContentPasteSearch';
import ReportUtils from '../../utills/users/reports';
import DescriptionIcon from '@mui/icons-material/Description';
import PrescriptionServices from '../../Services/prescriptionServices';
import PrescriptionUtils from '../../utills/users/prescription';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Popover from '@mui/material/Popover';
import Modal from 'react-modal';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faPrint, faDownload } from '@fortawesome/free-solid-svg-icons';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportsFilesmanagement from '../shared/downloadandprint';
import PaymentServices from '../../Services/PatientAppointment services';
import { ConfigurationServicePlaceholders } from 'aws-sdk/lib/config_service_placeholders';
import Users from '../../utills/users';
import { Helmet } from 'react-helmet';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Card, Tab, Tabs, Col, Row, Form as BootstrapForm, DropdownMenu } from 'react-bootstrap';
import ModelComponent from '../shared/Model';
import Dropdown from 'react-bootstrap/Dropdown';

// GlobalWorkerOptions.workerSrc = pdfjsWorker;
Modal.setAppElement('#root');


interface Doctor {
    id: string;
    FirstName: string;
    LastName: string;
}

interface Patient {
    id: string;
    PatientName: string;
    // patientID: string;
    PhoneNumber: string;
    DoctorID: string;
    EmailId: string;
    AppointmentDate: string;
    AppointmentTime: string
    PaymentMode: string;
    Amount: string;
    PaymentStatus: string;
    AppointmentID: string;

}

const AppoinementListHistory = () => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;
    const location = useLocation();
    const initialState: Patient = {
        id: '',
        PatientName: '',
        // patientID: '',
        PhoneNumber: '',
        DoctorID: '',
        EmailId: '',
        AppointmentDate: '',
        AppointmentTime: '',
        PaymentMode: '',
        Amount: '',
        PaymentStatus: '',
        AppointmentID: '',
    };

    const navigate = useNavigate();
    const [data, setData] = useState<Patient>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [patientList, setPatientList] = useState<Patient[]>([]);
    const [doctorList, setDoctorList] = useState<Doctor[]>([]);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    const [viewFiles, setViewFiles] = useState<any>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [fileTypeList, setFileTypeList] = useState([]);
    const iframeRef = useRef<any>(null);
    const [mail, setMail] = useState({ appID: '', paymentMode: '' })
    const { slug }: any = useParams();
    const [loading, setLoading] = useState(false);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [SuccessMsg, setSuccessMsg] = useState("");
    const [timer, setTimer] = useState<number>(60);
    const [getModal, setModel] = useState(false);
    const [CurrentOTP, setCurrentOTP] = useState("");
    const [OTPErr, setOtpError] = useState("");
    const [appointmentID, setAppointmentID] = useState<any>("");
    const [cancel, setCancel] = useState(true);


    // console.log("appointmentDetails", location?.state?.appointmentDetails);
    // console.log("cancel", cancel);
    if (cancel === false && location?.state?.appointmentDetails) {
        location.state.appointmentDetails = [];
    }

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerRunning(false);
        }
        // Clean up the interval when the component unmounts or the timer is stopped
        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);

    useEffect(() => {
        const getAppointment = async () => {
            console.log({ slug })
            console.log({ slug })
            if (slug) {
                const appDecId = await PaymentServices().decodeBase64(slug);
                console.log({ appDecId })
                console.log({ appDecId })
                const getAppInfo = await userService().ListPatientAppointmentsById('', appDecId) as any;
                if (getAppInfo?.length > 0) {
                    // Set mail to true (this will trigger the second useEffect)
                    setMail({
                        appID: getAppInfo[0]?.id,
                        paymentMode: getAppInfo[0]?.PaymentMode
                    });
                }
            }
        };
        getAppointment();
    }, [slug]);

    // Second useEffect to handle appointment deletion when mail changes
    useEffect(() => {
        // async function ProvInfo() {
        if (mail && mail?.appID) {
            //         await PaymentServices().handleOTPFormOpenService(mail?.appID, "offline", setAppointmentID, logUserObject, setLoading, setSuccessMsg, setModel, setIsTimerRunning, setTimer, handleAppoinmentDelete)
            handleAppoinmentDelete(mail?.appID, mail?.paymentMode);
            // handleOTPFormOpen(mail?.appID, mail?.paymentMode);
        }
        // }
        // ProvInfo();
    }, [mail?.appID]);

    // useEffect(() => {
    //     const getAppointment = async () => {
    //         const appDecId = slug && PaymentServices().decodeBase64(slug);
    //         const getAppInfo = appDecId && await userService().ListPatientAppointmentsById('', appDecId) as any;
    //         if (getAppInfo && getAppInfo.length > 0) {
    //             await handleAppoinmentDelete(getAppInfo[0]?.id, getAppInfo[0]?.PaymentMode);

    //         }
    //     };
    //     getAppointment();
    // }, [slug]);

    const getPatients = async () => {
        const fileTypes: any = await ReportServices().reportTypeList();
        setFileTypeList(fileTypes);
        let response: any
        // if (logUserObject?.Role === "Doctor") {

        if (logUserObject?.Role === "Doctor") {
            const apponitment = (location?.state?.patientID && location.state.patientID !== "NA") ? await userService().ListPatientAppointmentsBypatientID(location?.state?.patientID) : await userService().ListPatientAppointmentsByphoneNumber(location?.state?.PhoneNumber);
            response = apponitment?.filter((user: any) => user?.DoctorID === logUserObject?.userId)
        } else {
            if (location?.state?.appointmentDetails) {
                response = location?.state?.appointmentDetails;
            } else {
                response = (location?.state?.patientID && location.state.patientID !== "NA") ? await userService().ListPatientAppointmentsBypatientID(location?.state?.patientID) : await userService().ListPatientAppointmentsByphoneNumber(location?.state?.PhoneNumber);
            }
        }
        //     const sortedAppointments = response?.sort((a: any, b: any) => {
        //         const dateA = new Date(a.updatedAt || a.createdAt).getTime();
        //         const dateB = new Date(b.updatedAt || b.createdAt).getTime();

        //         return dateB - dateA; // Sort in descending order
        //     });
        //     setPatientList(sortedAppointments);
        // };

        const sortedAppointments = response?.sort((a: any, b: any) => {
            const dateTimeA = new Date(`${a.AppointmentDate}T${a.AppointmentTime}`).getTime();
            const dateTimeB = new Date(`${b.AppointmentDate}T${b.AppointmentTime}`).getTime();

            return dateTimeA - dateTimeB; // Sort in ascending order
        });

        setPatientList(sortedAppointments);
    };

    const handleStatusToggle = (rowData: any) => async (event: any) => {
        const updatedStatus = event?.target?.checked === true ? "1" : "0";
        const updatedRowData = { ...rowData, id: rowData?.id, PaymentStatus: updatedStatus };
        const update = await PaymentServices().updatePatientPayment(updatedRowData);
        setPatientList((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, PaymentStatus: updatedStatus } : row
            )
        );
    };

    const getDoctors = async () => {
        const response = await userService().ListDoctor('Doctor');
        setDoctorList(response);
    };
    useEffect(() => {
        getPatients();
        getDoctors();
    }, []);
    const handleDelete = async (id: string) => {
        await userService().Deletepatient(id);
        getPatients();
    };
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
            (day % 10 === 2 && day !== 12) ? 'nd' :
                (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

        return `${day}${suffix} ${month} ${year}`;
    };

    const handleOTPFormOpen: any = async (appID: string, PaymentMode: string) => {
        setAppointmentID(appID);
        await PaymentServices().handleOTPFormOpenService(appID, PaymentMode, setAppointmentID, logUserObject, setLoading, setSuccessMsg, setModel, setIsTimerRunning, setTimer, handleAppoinmentDelete, "")
    }

    const handleAppoinmentDelete = async (appID: string, PaymentMode: string) => {

        const confirmBox = window.confirm("Are you sure you want to cancel the appointment?");
        if (!confirmBox) {
            alert('Cancellation process aborted.');
            return; // Exit the function if the user does not confirm
        }
        // if (confirmBox) {
        try {
            if (PaymentMode === "online") {
                // List payments
                const paymentResp = await PaymentServices().ListPayments(appID);
                const paymentID = paymentResp[0]?.razorpay_payment_id;
                const payAmount = paymentResp[0]?.Amount;

                const response = await userService().cancelAppointMent(appID);
                if (response) {
                    const currentDate = new Date();
                    const formattedDate = currentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(',', ''); // Removing comma from the formatted date
                    const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                    if (response?.data?.deletePatientAppointment?.EmailId) {
                        try {
                            const EmailResponse: any = await Users().generateEmailSMS(
                                response?.data?.deletePatientAppointment?.EmailId,
                                `<html><body><div>Welcome to Gurram Dental!<br /><br />Thank you for contacting us.your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime}.<br/><br />Best Regards,<br /> Gurram Dental Hospital Team</div></body></html>`,
                                'Gurram Dental Hospitals,Patient Appointment',
                                'Your appointment cancelled successfully'
                            );
                        } catch (err) {
                            console.error("Error sending Email:", err);
                        }
                    }
                    try {
                        const MobileSMSResponse = await Users().generateMobileMsgBulk("+91" + response?.data?.deletePatientAppointment?.PhoneNumber, `Thank you! Your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime} - CLUSTERIT`, "Your appointment created successfully")
                    } catch (err) {
                        console.error("Error sending SMS:", err);
                    }
                    // const MobileSMSResponse = await Users().generateMobileMsg(
                    //     "+91" + response?.data?.deletePatientAppointment?.PhoneNumber,
                    //     `Thank you, your appointment  ${response?.data?.deletePatientAppointment?.AppointmentID} on ${appDate} at ${response?.data?.deletePatientAppointment?.AppointmentTime} cancelled succesfully.`,
                    //     'Your appointment cancelled successfully'
                    // )
                    if (response?.data) {
                        alert('Appointment canceled.');
                        getPatients();
                    }
                } else {
                    console.error("Error in CancleAppointment:", response);
                    alert('An error occurred while canceling the appointment. Please try again.');
                }

                // console.log("paymentResp", paymentResp[0]);

                // const response = await fetch(`https://api.razorpay.com/v1/payments/${paymentID}/refund`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': `Basic ${btoa(`${process.env.REACT_APP_RAZORPAY_KEY}:${process.env.REACT_APP_RAZORPAY_SECRET_KEY}`)}`
                //     },
                //     body: JSON.stringify({
                //         amount: payAmount // Amount to be refunded (in paise)
                //     })
                // });

                // if (!response.ok) {
                //     const errorText = await response.text();
                //     throw new Error(`Refund request failed: ${response.status} - ${errorText}`);
                // }

                // const refundResult = await response.json();
                // console.log("Refund result:", refundResult);

                // if (refundResult.status === 'authorized') {
                //     alert('Refund initiated successfully!');
                // } else {
                //     alert('Refund failed. Please try again.');
                // }

            } else if (PaymentMode === "offline") {
                const response = await userService().cancelAppointMent(appID);
                const appDate = formatDate(response?.data?.deletePatientAppointment?.AppointmentDate)
                const appEncId = PaymentServices().encodeBase64(response?.data?.deletePatientAppointment?.AppointmentID)
                if (response) {
                    const currentDate = new Date();
                    const formattedDate = currentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(',', ''); // Removing comma from the formatted date
                    const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                    if (response?.data?.deletePatientAppointment?.EmailId) {
                        try {
                            const SMSresponse: any = await Users().generateEmailSMS(
                                response?.data?.deletePatientAppointment?.EmailId,
                                `<html><body><div>Welcome to Gurram Dental!<br /><br />Thank you for contacting us.your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime}.<br/><br />Best Regards,<br /> Gurram Dental Hospital Team</div></body></html>`,
                                'Gurram Dental Hospitals,Patient Appointment',
                                'Your appointment cancelled successfully'
                            );
                        } catch (err) {
                            console.error("Error sending Email:", err);
                        }
                    }
                    try {
                        const MobileSMSResponse = await Users().generateMobileMsgBulk("+91" + response?.data?.deletePatientAppointment?.PhoneNumber, `Thank you! Your appointment ${response?.data?.deletePatientAppointment?.AppointmentID} for doctor consultation has been successfully canceled on ${formattedDate} - ${formattedTime} - CLUSTERIT`, "Your appointment created successfully")
                    } catch (err) {
                        console.error("Error sending SMS:", err);
                    }
                    // const MobileSMSResponse = await Users().generateMobileMsg(
                    //     "+91" + response?.data?.deletePatientAppointment?.PhoneNumber,
                    //     `Thank you, your appointment  ${response?.data?.deletePatientAppointment?.AppointmentID} on ${appDate} at ${response?.data?.deletePatientAppointment?.AppointmentTime} cancelled succesfully.`,
                    //     'Your appointment cancelled successfully'
                    // )
                    if (response?.data) {
                        alert('Appointment canceled.');
                        await getPatients();
                    }
                } else {
                    console.error("Error in CancleAppointment:", response);
                    alert('An error occurred while canceling the appointment. Please try again.');
                }
            }
        } catch (error) {
            console.error('Error during cancellation:', error);
            alert('An error occurred while canceling the appointment. Please try again.');
        }
    }
    //     else {
    //         alert('Cancellation process aborted.');
    // }
    const handleUpload = async (row: any) => {
        navigate("/addReports", { state: { appointmentId: row?.id, patientID: row?.patientID } });
    };

    const handleUploadprescription = async (row: any) => {
        navigate("/addprescription", { state: { appointmentId: row?.id, patientID: row?.patientID, doctorid: row?.DoctorID } });
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };
    const getDoctorNameById = (doctorId: string) => {
        const doctor = doctorList?.find(doc => doc?.id === doctorId);
        return doctor ? `${doctor.FirstName} ${doctor.LastName}` : 'Unknown Doctor';
    };

    const filteredData: any = patientList?.filter((row: Patient) =>
        Object.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData?.length / pageSize);
    const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };
    const handleSubmit = (setUpdate: boolean) => {
        navigate('/PatientAppointment', { state: { setUpdate: false } });
    }
    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

    const handleEdit = (row: Patient) => {
        navigate("/PatientAppointment", { state: { patientId: row?.id, path: location?.state?.path, PhoneNumber: location?.state?.PhoneNumber } });
    }

    const handleReportEdit = async (event: any) => {
        navigate('/addReports', { state: { appointmentID: event?.id } });
    }
    const handlePrescriptionEdit = async (event: any) => {
        navigate('/addprescription', { state: { appointmentID: event?.id } });
    }

    const manageReports = async (event: any) => {
        const getReport = await ReportServices().reportsListBypatientID(event?.id);
        if (getReport?.length > 0) {
            handleReportEdit(event);
        } else {
            handleUpload(event);
        }
    }

    const managePrescriptions = async (event: any) => {
        const getPrescription = await PrescriptionServices().prescriptionListBypatientID(event?.id);

        console.log('getPrescription', getPrescription)
        if (getPrescription?.length > 0) {
            handlePrescriptionEdit(event);
        } else {
            handleUploadprescription(event);
        }
    }
    const handleReportDelete = async (doctor: any) => {
        const singleReport = await ReportServices().reportsListBypatientID(doctor?.id);
        const confirmBox = window.confirm("Are you sure you want to delete the selected report?");
        if (confirmBox) {
            const result = await ReportUtils().reportDelete(singleReport[0]?.id);
            if (result) {
                getPatients();
            }
        } else {
            alert('Deletion canceled.');
        }
    };

    const handlePrescriptiontDelete = async (doctor: any) => {
        const singlePrescription = await PrescriptionServices().prescriptionListBypatientID(doctor?.id);
        const confirmBox = window.confirm("Are you sure you want to delete the selected prescription?");
        if (confirmBox) {
            const result = await PrescriptionUtils().prescriptionDelete(singlePrescription[0]?.id);
            if (result) {
                getPatients();
            }
        } else {
            alert('Deletion canceled.');
        }
    }
    const getFileUrl = (fileName: any) => {
        const baseUrl = process.env.REACT_APP_S3_BUCKET_FETCH;
        const file = `${baseUrl}/images/${fileName}`;
        return file;
    };

    const handleReportDownload: any = async (doctor: any, type: any) => {
        const report = type === "reports"
            ? await ReportServices().reportsListBypatientID(doctor?.id)
            : await PrescriptionServices().prescriptionListBypatientID(doctor?.id);
        const downloadfile = report[0];
        const zip = new JSZip();

        const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
        const folderName = type === "reports" ? `reports${timestamp}` : `prescriptions${timestamp}`;
        const folder: any = zip.folder(folderName);
        if (downloadfile?.file?.length > 0) {
            try {
                for (const file of downloadfile?.file) {
                    // Handle file names and download
                    const fileNames = Array.isArray(file?.name) ? file?.name : [file?.name];
                    for (const fileName of fileNames) {
                        try {
                            const fileUrl = getFileUrl(fileName);
                            const response = await fetch(fileUrl);

                            if (!response?.ok) {
                                console.error(`Failed to fetch file: ${fileName}. Status: ${response.status}`);
                                continue;
                            }

                            const fileBlob = await response.blob();

                            if (!fileBlob.size) {
                                console.error(`Empty file received: ${fileName}`);
                                continue;
                            }
                            // Determine MIME type
                            let mimeType = fileBlob.type;
                            if (mimeType === "application/octet-stream") {
                                const extension = fileName.split('.').pop().toLowerCase();
                                switch (extension) {
                                    case 'jpg':
                                    case 'jpeg':
                                        mimeType = 'image/jpeg';
                                        break;
                                    case 'png':
                                        mimeType = 'image/png';
                                        break;
                                    default:
                                        mimeType = 'application/octet-stream';
                                }
                            }
                            // Create new Blob with the correct MIME type
                            const correctBlob = new Blob([fileBlob], { type: mimeType });
                            // Add file to zip
                            folder?.file(fileName, correctBlob, { binary: true });
                        } catch (fetchError) {
                            console.error(`Error processing file: ${fileName}`, fetchError);
                        }
                    }
                    // Handle description and generate PDF
                    if (file?.description) {
                        await ReportsFilesmanagement().imageCanvas(file?.description, folder, true, file?.fileType);
                    }
                }
                const content = await zip.generateAsync({ type: 'blob' });
                saveAs(content, `${folderName}.zip`);
            } catch (error) {
                console.error('Error while downloading files:', error);
            }
        } else {
            alert("you don't any files to download.")
        }
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setModel(false);
        // setModalHeading("");
    };
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % viewFiles?.length);
    };
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + viewFiles?.length) % viewFiles?.length);
    };
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    const renderFile = (file: any) => {
        const { name, description, fileType, patientName, AppointmentID } = file;
        const formattedDate = ReportsFilesmanagement().formatDate(file?.date);
        const fileType1: any = fileTypeList?.find((type: any) => type.id === fileType);
        // console.log("file", file);

        // console.log("name", name);

        if (name) {
            const fileExtension = file?.name?.split('.').pop().toLowerCase();

            return (
                <div>
                    {fileExtension === 'pdf' ? (
                        //  return (
                        <>
                            <div style={{ float: "left" }}>
                                <strong>Patient Name: </strong>{patientName}<br />
                                <strong>Appointment ID: </strong>{AppointmentID}
                            </div>
                            <div className='center'>
                                <strong>ReportType: </strong> {fileType1?.name}<br />
                                <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
                            </div>
                            <div className='clrbth' >


                                {/* <Document file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${file}`}>
                    
                             <Page pageNumber={1} />
                    
                             </Document> */}

                                < Document
                                    file={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`
                                    }
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    className="pdf-viewer"
                                >
                                    {
                                        Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <Page key={`page_${index + 1}`} pageNumber={index + 1
                                                } />
                                            )
                                        )}
                                </Document>


                                {/* <div>{viewFiles[currentIndex]}</div> */}
                            </div>

                            < div className='clrbth' >

                                <button className="nav-button reportbtn" onClick={handlePrev} >
                                    <FontAwesomeIcon style={{ color: "white" }} icon={faArrowLeft} />
                                </button>

                                < button className="nav-button reportbtn" onClick={handleNext} >
                                    <FontAwesomeIcon style={{ color: "white" }} icon={faArrowRight} />
                                </button>

                            </div>



                        </>
                        // );

                    ) : (
                        <>
                            <div style={{ float: "left" }}>
                                <strong>Patient Name: </strong>{patientName}<br />
                                <strong>Appointment ID: </strong>{AppointmentID}
                            </div>
                            <div className='center'>
                                <strong>ReportType: </strong> {fileType1?.name}<br />
                                <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
                            </div>
                            <div className='clrbth' >
                                <img
                                    key={file}
                                    src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${name}`
                                    }
                                    alt={file}
                                    className='img-viewer'
                                />
                            </div>

                            < div className='clrbth' >

                                <button className="reportbtn" onClick={handlePrev} >
                                    <FontAwesomeIcon style={{ color: "white" }} icon={faArrowLeft} />
                                </button>
                                < button className="reportbtn" onClick={handleNext} >
                                    <FontAwesomeIcon style={{ color: "white" }} icon={faArrowRight} />
                                </button>

                            </div>
                        </>
                    )}
                </div>
            )
        } else if (description) {
            return (
                <>
                    <div style={{ float: "left" }}>
                        <strong>Patient Name: </strong>{patientName}<br />
                        <strong>Appointment ID: </strong>{AppointmentID}
                    </div>
                    <div className='center'>
                        <strong>ReportType: </strong> {fileType1?.name}<br />
                        <strong style={{ marginLeft: "40px" }}>Report Date: </strong> {formattedDate}
                    </div>
                    <div className='clrbth  mt-5 textDesc'>
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                    </div>


                    < div className='clrbth' >

                        <button className="nav-button reportbtn" onClick={handlePrev} >
                            <FontAwesomeIcon style={{ color: "white" }} icon={faArrowLeft} />
                        </button>
                        < button className="nav-button reportbtn" onClick={handleNext} >
                            <FontAwesomeIcon style={{ color: "white" }} icon={faArrowRight} />
                        </button>

                    </div>

                </>
            );
        } else {
            return <div>No content available</div>;
        }
    }

    const handleOTPSubmit = async () => {
        const www = await PaymentServices().handleOTPSubmitService(setOtpError, timer, CurrentOTP, setModel, handleAppoinmentDelete, appointmentID, setIsTimerRunning, setTimer, getPatients, setCancel, "");
    };

    const ResendEmail = async () => {
        setOtpError("");
        handleOTPFormOpen(appointmentID, "offline");
    };


    // console.log('paginatedData',paginatedData)
    return (

        <div>
            <Helmet>
                <title>Appointment History</title>
                <meta name="description" content="This is the Appointment History page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Appointment History
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Appointment History</li>
                </ul>
            </div>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-2" style={{ marginTop: '50px' }}>
            <Sidebar />
          </div> */}

                    {
                        !slug && (

                            <div>
                                <div className="row tablefs">

                                    <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="page-size-dropdown">
                                            <label style={{ padding: '5px' }}>Show</label>
                                            <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                            </select>
                                            <label style={{ padding: '5px' }}>entries</label>
                                        </div>
                                        <div className="page-size-dropdown" style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                                            {/* {logUserObject?.Role === "MasterAdmin" ? (
                                            <div className="pb-3">
                                                <Button
                                                    onClick={() => handleSubmit(false)} // Pass 'false' as the argument
                                                    style={{ marginRight: '10px', float: 'right' }}
                                                    className='btn btn-primary'
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                            ) : null}  */}
                                            <p style={{ marginRight: '10px' }}>Search</p>
                                            <div>
                                                <input
                                                    type="text"
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e?.target?.value)}
                                                    className="search-bar"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="tablefs" style={{ overflowX: 'auto' }}>
                                    <table className="table table-bordered table-striped table-hover table-responsive listHistory">
                                        <thead>
                                            <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                <th className="sno">S.No</th>
                                                <th className='mdl'>Patient Name</th>
                                                {/* <th className='mdl'>Email Id</th> */}
                                                <th>Appointment Date & Time</th>
                                                <th className='mdl'>AppointmentID</th>
                                                <th className='mdl'>Phone Number</th>
                                                <th className='mdl'>Doctor Name</th>
                                                <th className='mdl'>Payment Mode</th>
                                                <th className='mdl'>Amount</th>
                                                {logUserObject?.Role === "MasterAdmin" && (
                                                    <th className='paymentStatus'>Payment Status</th>
                                                )}
                                                <th className='actions'>Actions</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedData?.map((row: Patient, index: number) => {
                                                const appointmentDateTime = new Date(row?.AppointmentDate);
                                                const currentDateTime = new Date();
                                                const isAppointmentCompleted = appointmentDateTime < currentDateTime;

                                                return (
                                                    <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                        <td>{highlightText(row?.PatientName)}</td>
                                                        {/* <td>{highlightText(row?.EmailId)}</td> */}
                                                        <td>{highlightText(formatDate(row?.AppointmentDate))} {highlightText(row?.AppointmentTime)}</td>
                                                        <td>
                                                            {highlightText(row?.AppointmentID)}<br />
                                                            {/* ({highlightText(
                                                                new Date(row?.AppointmentDate).toLocaleDateString('en-GB', {
                                                                    day: 'numeric',
                                                                    month: 'short',
                                                                    year: 'numeric',
                                                                })
                                                            )}) */}
                                                        </td>
                                                        <td>{highlightText(row?.PhoneNumber)}</td>
                                                        <td>{highlightText(getDoctorNameById(row.DoctorID))}</td>
                                                        <td>{highlightText(row?.PaymentMode)}</td>
                                                        <td>{highlightText(row?.Amount)}</td>

                                                        {logUserObject?.Role === "MasterAdmin" && (
                                                            <td>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={row?.PaymentStatus === "1"}
                                                                            onChange={handleStatusToggle(row)}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={
                                                                        <span className={row?.PaymentStatus === "1" ? 'PaymentStatus-Paid' : 'PaymentStatus-NotPayed'}>
                                                                            {row?.PaymentStatus === "1" ? 'Paid' : 'NotPaid'}
                                                                        </span>
                                                                    }
                                                                /></td>
                                                        )}
                                                        <td className='actions'>
                                                            {(logUserObject?.Role !== "Pharmacy" || logUserObject?.Role !== "Lab") ? <>


                                                                {(!logUserObject || logUserObject?.Role === "Patient" || logUserObject?.Role === "MasterAdmin") && (
                                                                    <Button
                                                                        onClick={isAppointmentCompleted ? undefined : () => handleEdit(row)}
                                                                        title='Edit Appointment'
                                                                        startIcon={<EditIcon style={{ color: 'green', opacity: isAppointmentCompleted ? '0.2' : '1' }} />}
                                                                    >
                                                                    </Button>
                                                                )}




                                                                {/* {!isAppointmentCompleted ? (
                                                                    <>
                                                                        {(!logUserObject || logUserObject?.Role === "Patient" || logUserObject?.Role === "MasterAdmin") && (
                                                                            // <Button
                                                                            //     onClick={() => handleEdit(row)}
                                                                            //     title='Edit Appointment'
                                                                            //     startIcon={<EditIcon style={{ color: 'green' }} />}
                                                                            // >

                                                                            // </Button>
                                                                            <Button
                                                                                onClick={() => handleEdit(row)}
                                                                                title='Edit Appointment'
                                                                                startIcon={<EditIcon style={{ color: 'green' }} />}
                                                                            >
                                                                            </Button>
                                                                        )}
                                                                    </>
                                                                ) : 
                                                                
                                                                <>
                                                                        {(!logUserObject || logUserObject?.Role === "Patient" || logUserObject?.Role === "MasterAdmin") && (
                                                                            <Button
                                                                                
                                                                                title='Edit Appointment'
                                                                                startIcon={<EditIcon style={{ color: 'green', opacity: '0.2' }} />}
                                                                            >

                                                                            </Button>
                                                                        )}
                                                                    </>} */}


                                                                {/* {(logUserObject && logUserObject?.Role !== "Doctor" && logUserObject?.Role !== "Lab") && (
                                                                <Button
                                                                    onClick={() => handleEdit(row)} title='Edit Appitment'
                                                                    // style={{ marginRight: '10px' }}
                                                                    startIcon={<EditIcon style={{ color: 'green' }} />}
                                                                >
                                                                </Button>
                                                            )} */}

                                                                {(logUserObject && logUserObject?.Role === "MasterAdmin") && (
                                                                    <Button title='Delete'
                                                                        onClick={() => handleDelete(row?.id)}
                                                                        startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                                    >
                                                                    </Button>
                                                                )}

                                                                {
                                                                    (!logUserObject || logUserObject?.Role !== "Pharmacy") && (
                                                                        <Dropdown className="dropright">
                                                                            <Dropdown.Toggle className='dropdowncolor dropdown-no-hover' id="dropdown-basic" title="Reports" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                                                                <FolderOpenIcon style={{ color: '#2cbcbc', cursor: 'pointer', marginTop: '-9px' }} />
                                                                            </Dropdown.Toggle>

                                                                            {
                                                                                !logUserObject ? (

                                                                                    <Dropdown.Menu className='dropdownbg newdropdown'>
                                                                                        <span className='spanfont'>
                                                                                            <span style={{ margin: '2px' }}>Please </span>

                                                                                            <Link to="/register" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                                <span style={{ color: 'black' }}>register</span>
                                                                                            </Link>

                                                                                            <span style={{ margin: '2px', color: 'black' }}>/ </span>
                                                                                            <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                                <span style={{ color: 'black' }}>login</span>
                                                                                            </Link>
                                                                                            <span style={{ margin: '2px' }}>to view reports</span>
                                                                                        </span>

                                                                                    </Dropdown.Menu>

                                                                                ) :
                                                                                    (

                                                                                        <Dropdown.Menu className='dropdownbg'>


                                                                                            {logUserObject && logUserObject?.Role !== "Patient" && (
                                                                                                <Dropdown.Item className='dropdownbg' as="button" title="Manage Reports" onClick={() => manageReports(row)}>
                                                                                                    <ContentPasteSearch style={{ color: 'blue' }} />
                                                                                                </Dropdown.Item>
                                                                                            )}

                                                                                            <Dropdown.Item className='dropdownbg'
                                                                                                as="button"
                                                                                                title="View Report" // This will show the tooltip when you hover over the item
                                                                                                onClick={() => {
                                                                                                    ReportsFilesmanagement().handleView(row, setCurrentIndex, setViewFiles, setIsModalOpen, "reports");
                                                                                                }}
                                                                                            >
                                                                                                <VisibilityIcon style={{ color: 'blue' }} />
                                                                                            </Dropdown.Item>

                                                                                            <Dropdown.Item as="button" className='dropdownbg' title="Download Report" onClick={() => handleReportDownload(row, "reports")}>
                                                                                                <DownloadIcon style={{ color: 'blue' }} />
                                                                                            </Dropdown.Item>


                                                                                        </Dropdown.Menu>

                                                                                    )
                                                                            }



                                                                        </Dropdown>
                                                                    )}


                                                                {(!logUserObject || logUserObject?.Role !== "Lab") ?
                                                                    <Dropdown className="dropright">
                                                                        <Dropdown.Toggle
                                                                            title="Prescriptions"
                                                                            className="dropdowncolor dropdown-no-hover"
                                                                            id="dropdown-basic"
                                                                            style={{ backgroundColor: 'transparent', border: 'none' }}
                                                                        >
                                                                            <InsertDriveFileIcon
                                                                                style={{ color: '#2cbcbc', cursor: 'pointer', marginTop: '-6px' }}
                                                                            />
                                                                        </Dropdown.Toggle>


                                                                        {
                                                                            !logUserObject ? (
                                                                                <Dropdown.Menu className='dropdownbg newdropdown1'>
                                                                                    {/* <span className='spanfont'>Please register / login to view prescription</span> */}

                                                                                    <span className='spanfont'>
                                                                                        <span style={{ margin: '2px' }}>Please </span>

                                                                                        <Link to="/register" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                            <span style={{ color: 'black' }}>register</span>
                                                                                        </Link>

                                                                                        <span style={{ margin: '2px', color: 'black' }}>/ </span>
                                                                                        <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                            <span style={{ color: 'black' }}>login</span>
                                                                                        </Link>
                                                                                        <span style={{ margin: '2px' }}>to view prescription</span>
                                                                                    </span>


                                                                                </Dropdown.Menu>
                                                                            ) : (
                                                                                <Dropdown.Menu className="dropdownbg">
                                                                                    {logUserObject && logUserObject?.Role !== "Patient" && (
                                                                                        <Dropdown.Item
                                                                                            className="dropdownbg"
                                                                                            as="button"
                                                                                            title="Manage Prescriptions"
                                                                                            onClick={() => managePrescriptions(row)}
                                                                                        >
                                                                                            <DescriptionIcon style={{ color: 'blue' }} />
                                                                                        </Dropdown.Item>
                                                                                    )}

                                                                                    <Dropdown.Item
                                                                                        className="dropdownbg"
                                                                                        as="button"
                                                                                        title="View Prescription"
                                                                                        onClick={() => {
                                                                                            ReportsFilesmanagement().handleView(
                                                                                                row,
                                                                                                setCurrentIndex,
                                                                                                setViewFiles,
                                                                                                setIsModalOpen,
                                                                                                "prescriptions"
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <VisibilityIcon style={{ color: 'blue' }} />
                                                                                    </Dropdown.Item>

                                                                                    <Dropdown.Item
                                                                                        className="dropdownbg"
                                                                                        as="button"
                                                                                        title="Download Prescription"
                                                                                        onClick={() => handleReportDownload(row, "prescriptions")}
                                                                                    >
                                                                                        <DownloadIcon style={{ color: 'blue' }} />
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            )
                                                                        }

                                                                    </Dropdown>

                                                                    : null}





                                                                {(isAppointmentCompleted && logUserObject?.Role === "MasterAdmin") || !isAppointmentCompleted ? (
                                                                    <>
                                                                        {(!logUserObject || logUserObject?.Role === "Patient" || logUserObject?.Role === "MasterAdmin") && (
                                                                            <Button
                                                                                title="Cancel Appointment"
                                                                                onClick={() => handleOTPFormOpen(row?.id, row?.PaymentMode)}
                                                                                startIcon={<CancelIcon style={{ color: 'red' }} />}
                                                                            />
                                                                        )}
                                                                    </>
                                                                ) : null}


                                                                {/* 
                                                                {(!logUserObject || logUserObject?.Role === "Patient" || logUserObject?.Role === "MasterAdmin" || logUserObject?.Role === "Doctor") && (
                                                                    <Button
                                                                        title='cancel Appointment'
                                                                        onClick={() => handleOTPFormOpen(row?.id, row?.PaymentMode)}
                                                                        startIcon={<CancelIcon style={{ color: 'red' }} />}
                                                                    >
                                                                    </Button>
                                                                )} */}
                                                            </>
                                                                : null}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                                <div className="tablefs">Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                    <div className="paginationstyle">
                                        <div
                                            style={{
                                                cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                                marginRight: '10px',
                                                borderBottom: '1px solid red',
                                                fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                            }}
                                            onClick={() => {
                                                if (currentPage > 1) {
                                                    handlePageChange(currentPage - 1);
                                                    window.scrollTo(0, 0); // Scroll to top
                                                }
                                            }}
                                            className={currentPage === 1 ? 'disabled' : ''}
                                        >
                                            Previous
                                        </div>
                                        <div className="pageNumb">{currentPage}</div>
                                        <div
                                            style={{
                                                cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                                marginLeft: '10px',
                                                borderBottom: '1px solid red',
                                                fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                            }}
                                            onClick={() => {
                                                if (currentPage < totalPages) {
                                                    handlePageChange(currentPage + 1);
                                                    window.scrollTo(0, 0);
                                                }
                                            }}
                                            className={currentPage === totalPages ? 'disabled' : ''}
                                        >
                                            Next
                                        </div>
                                    </div>
                                </div>

                                <div className={`modal ${isModalOpen ? "open" : ""}`} style={{ display: isModalOpen ? "block" : "none" }}>
                                    <div className="modal-content2 modalmob" style={{ marginTop: '100px', height: '600px', width: '1000px' }}>
                                        <span className="close" onClick={closeModal}>
                                            &times;
                                        </span>
                                        <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary downloadicon" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "download")}><FontAwesomeIcon style={{ color: 'white' }} icon={faDownload} /></button>
                                        <button style={{ float: 'right', marginTop: '10px', marginRight: '30px' }} className="btn btn-primary downloadicon" onClick={() => ReportsFilesmanagement().singleDownload(viewFiles[currentIndex], "print")}><FontAwesomeIcon className="downloadicon" style={{ color: 'white' }} icon={faPrint} /></button>
                                        <div style={{ textAlign: 'center' }}>
                                            {viewFiles?.length > 0 ?
                                                renderFile(viewFiles[currentIndex])
                                                : "No file to View"
                                            }

                                            <div className="thumbnail-container">
                                                {viewFiles?.map((file: any, index: number) => {
                                                    const extension = file?.name?.split('.').pop().toLowerCase()
                                                    // console.log('extension', extension),
                                                    return (
                                                        <img
                                                            key={index}
                                                            className={`thumb-view thumbnail ${currentIndex === index ? 'active' : ''}`}
                                                            src={(extension === "pdf" || !file?.name) ? 'images/pdf-thumbnail.png' : getFileUrl(file?.name)}
                                                            alt={`Thumbnail ${index + 1}`}
                                                            onClick={() => setCurrentIndex(index)}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {getModal && (
                                    <ModelComponent setCurrentOTP={setCurrentOTP} SuccessMsg={SuccessMsg} timer={timer} OTPErr={OTPErr} handleOTPSubmit={handleOTPSubmit} ResendEmail={ResendEmail} closeModal={closeModal} getModal={getModal} />
                                )

                                }
                            </div>
                        )}
                </div>
            </div>

        </div >
    );
};

export default AppoinementListHistory;