import ContactServices from "../../../Services/ContactServices";
import userService from "../../../Services/user/user.service";
import Users from '../../../utills/users';

const ContactUtils = () => {

    // const sendNotifications = async (inputDetails: any) => {
    //     const options = {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Accept": "application/json, text/plain, */*"
    //         },
    //         body: JSON.stringify(inputDetails)
    //     };

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_SNS_Function}`, options);
    //         if (response.ok) {
    //             const responseData = await response.json();
    //             // console.log("Notification sent successfully", responseData);
    //             return { msg: "Notification sent successfully", data: responseData };
    //         } else {
    //             // console.error("Failed to send notification");
    //             return { msg: "Failed to send notification", status: response.status };
    //         }
    //     } catch (error) {
    //         // console.error("An error occurred while sending notification:", error);
    //         throw new Error("Failed to send notification");
    //     }
    // };

    const admin: any = async () => {
        const getAdmin: any = await userService().ListDoctor("MasterAdmin");
        return getAdmin
    }


    const handleSubmit = async (values: any) => {

        try {

            const adminData = await admin();
            const contactResponse = await ContactServices().contactCreate(values);
            console.log("contactResponse", contactResponse);

            if (contactResponse?.status === 200) {

                const SMSresponse: any = await Users().generateEmailSMS(
                    adminData[0]?.EmailId, `A new enquiry has been submitted by ${contactResponse?.response?.Name}. You can contact them at ${contactResponse?.response?.phoneNumber} or via email at ${contactResponse?.response?.emailID}.The message they left is: ${contactResponse?.response?.Message}- CLUSTERIT`,
                    'Welcome to Gurram Dental Hospital',
                    'success'
                );

                const MobileSMSResponse = await Users().generateMobileMsgBulk(adminData[0]?.countryCode + adminData[0]?.PhoneNumber, `A new enquiry has been submitted by ${contactResponse?.response?.Name}. You can contact them at ${contactResponse?.response?.phoneNumber} or via email at ${contactResponse?.response?.emailID} - CLUSTERIT`, "success")

                if (SMSresponse && MobileSMSResponse) {
                    return { contactResponse };
                }
            } else {
                return { contactResponse };
            }

        } catch (error) {
            console.error("Error in handleSubmit:", error);
            return { error: "An error occurred during submission" };
        }


    };

    // const handleSubmit = async (values: any) => {

    //     const adminData = await admin();
    //     const contactResponse = await ContactServices().contactCreate(values);
    //     console.log("contactResponse", contactResponse);

    //     if (contactResponse?.status === 200) {

    //         const SMSresponse: any = await Users().generateEmailSMS(
    //             adminData[0]?.EmailId, `A new enquiry has been submitted by ${contactResponse?.response?.Name}. You can contact them at ${contactResponse?.response?.phoneNumber} or via email at ${contactResponse?.response?.emailID}.The message they left is: ${contactResponse?.response?.Message}- CLUSTERIT`,
    //             'Welcome to Gurram Dental Hospital',
    //             'success'
    //         );

    //         // const MobileSMSResponse = await Users().generateMobileMsg(userRegResponse?.countryCode + userRegResponse?.PhoneNumber,
    //         //     userRegResponse?.PhoneNumber,
    //         //     userRegResponse?.countryCode + userRegResponse?.PhoneNumber,
    //         //     `Thank you for registering with us.Your Credentials for login :EmailId :${userRegResponse?.EmailId} / Phone Number :${userRegResponse?.PhoneNumber} Password : ${PassWord} Please login to your account ${process.env.REACT_APP_HOST}login`,
    //         //     'Your Registered successfully'
    //         // )

    //         // const MobileSMSResponse = await Users().generateMobileMsgBulk(adminData[0]?.countryCode + adminData[0]?.PhoneNumber, `A new enquiry has been submitted by ${contactResponse?.response?.Name}. You can contact them at ${contactResponse?.response?.phoneNumber} or via email at ${contactResponse?.response?.emailID}.Their message: ${contactResponse?.response?.Message}- CLUSTERIT`, "success")

    //         const MobileSMSResponse = await Users().generateMobileMsgBulk(adminData[0]?.countryCode + adminData[0]?.PhoneNumber, `A new enquiry has been submitted by ${contactResponse?.response?.Name}. You can contact them at ${contactResponse?.response?.phoneNumber} or via email at ${contactResponse?.response?.emailID} - CLUSTERIT`, "success")

    //         // A new enquiry has been submitted by anusha. You can contact them at 9988998877 or via email at anusha@gmail.com - CLUSTERIT.

    //         // const notificationInputDetails = {
    //         //     Name: values.Name,
    //         //     emailID: values.emailID, // Assuming values contains an email field
    //         //     phoneNumber: values.phoneNumber,
    //         //     Message: values.Message  // Assuming values contains a phone field
    //         // };
    //         // console.log("notificationInputDetails",notificationInputDetails)
    //         // const notificationResponse = await sendNotifications(notificationInputDetails);
    //         if (SMSresponse && MobileSMSResponse) {
    //             return { contactResponse };
    //         }
    //     } else {
    //         return { contactResponse };
    //     }
    // };



    return {
        handleSubmit
    };
};

export default ContactUtils;
