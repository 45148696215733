import ReportServices from "../../../Services/reportservices";
import userService from "../../../Services/user/user.service";
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import AWS from 'aws-sdk';
import { SendFile, updDelFile } from "../../../views/shared/fileupload";
import FileType from "../../../views/Reports/fileType";
import { FormatFile } from "../../Fileformat";

const ReportUtils: any = () => {
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }

    const handleSubmit = async (values: any) => {
        let getUser: any;
        console.log("values utils", values);
        if (values?.patientID) {
            getUser = await userService().getuserSingle(values?.patientID);
        } else {
            getUser = await userService().getSingleAppointment(values?.appointmentID);
        }
        console.log("getUser", getUser);
        const singleUser = getUser?.data?.getUser ? getUser?.data?.getUser : getUser?.data?.getPatientAppointment;

        // const updatedFile = await Promise.all(values?.file?.map(async (file: any) => {
        //     // Extract name values from the file object
        //     const names = await Promise.all(file?.name?.map(async (file1: any) => {
        //         const fileName = Date.now() + "_" + file1?.name;
        //         const s3names = await SendFile(file1, fileName, "images");
        //         console.log("Uploaded file name:", s3names);
        //         return fileName; // Return the name that is inserted into S3
        //     }) || []);

        //     return {
        //         fileType: file?.fileType,
        //         type: file?.type,
        //         description: file?.description,
        //         name: names // Use the updated names array
        //     };
        // }));
        const updatedFile = await Promise.all(values?.file?.map(async (file: any) => {
            // Log the file object to see what it contains
            console.log("Processing file:", file);

            // Check if the file.name array is not empty
            if (file?.name?.length > 0) {
                // console.log("hiiiiii")
                // Extract name values from the file object
                const names = await Promise.all(file?.name?.map(async (file1: any) => {
                    // const updatedFileName = file1?.name.replace(/ /g, '_');
                    // const fileName = Date.now() + "_" + updatedFileName;
                   
                    const  newFilename = await FormatFile(file1)
                    console.log("newFilename",{file1, newFilename})
                    const s3names = await SendFile(file1, newFilename, "images");
                    console.log("Uploaded file name:", s3names);
                    return newFilename; // Return the name that is inserted into S3
                }));

                return {
                    fileType: file?.fileType,
                    type: file?.type,
                    description: file?.description,
                    name: names // Use the updated names array
                };
            }else {
                // Handle the case where file.name is empty
                console.warn("No files to upload for this file object:", file);
                return {
                    fileType: file?.fileType,
                    type: file?.type,
                    description: file?.description,
                    name: [] // Return an empty array if no files to upload
                };
            }
        }));

        console.log("Updated file array:", updatedFile);

        const createData = {
            patientID: values?.patientID,
            appointmentID: values?.appointmentID,
            updatedBy: logUserObject?.id,
            patientName: singleUser?.FirstName ? `${singleUser?.FirstName} ${singleUser?.LastName}` : singleUser?.PatientName,
            patientEmail: singleUser?.EmailId,
            patientPhoneNumber: singleUser?.PhoneNumber,
            Status: singleUser?.Status,
            file: updatedFile,
            type: "reports",
        }
        console.log("createData", createData);

        const response = await ReportServices().CreateReport(createData);
        console.log("response in utils", response);
        return response

    }

    // const handleSubmit: any = async (values: any) => {

    //     let getUser: any;
    //     console.log("values utils", values);
    //     if (values?.patientID) {
    //         getUser = await userService().getuserSingle(values?.patientID);
    //     } else {
    //         getUser = await userService().getSingleAppointment(values?.appointmentID);
    //     }
    //     console.log("getUser", getUser);

    //     const updatedFile = values?.file?.map((file: any) => {
    //         // Extract name values from the file object
    //         const names = file?.name?.map((file1: any) => file1?.name) || [];

    //         // Return the updated file object with desired structure
    //         return {
    //             fileType: file.fileType,
    //             name: names
    //         };
    //     });
    //     console.log("updatedFile", updatedFile);

    //     const createData = {
    //         patientID: values?.patientID,
    //         updatedBy: logUserObject?.id,
    //         patientName: getUser[0]?.FirstName + " " + getUser?.LastName,
    //         patientEmail: getUser[0]?.EmailId,
    //         patientPhoneNumber: getUser[0]?.PhoneNumber,
    //         Status: getUser[0]?.Status,
    //         file: updatedFile
    //     }
    //     console.log("createData", createData);

    //     const response = await ReportServices().CreateReport(createData);
    //     console.log("response in utils", response);
    //     return response






    //     let getUser: any;
    //     if (values?.patientID) {
    //         getUser = await userService().getuserSingle(values?.patientID);
    //     } else {
    //         getUser = await userService().getSingleAppointment(values?.appointmentID);
    //     }
    //     const singleUser = getUser?.data?.getUser ? getUser?.data?.getUser : getUser?.data?.getPatientAppointment;
    //     const flattenedFileNames = await Promise.all(
    //         values?.file?.flatMap(async (file: any) => {
    //             return await Promise.all(
    //                 file?.name?.map(async (file1: any) => {
    //                     const fileName = Date.now() + "_" + file1?.name;
    //                     await SendFile(file1, fileName, "images");
    //                     return {
    //                         fileType: file?.fileType,
    //                         name: fileName
    //                     };
    //                 })
    //             );
    //         })
    //     );

    //     const flattenedFiles = flattenedFileNames.flat();

    //     const createDataRecords = flattenedFiles?.map((file) => ({
    //         patientID: values?.patientID,
    //         updatedBy: logUserObject?.userId,
    //         patientName: `${singleUser?.FirstName} ${singleUser?.LastName}` ? `${singleUser?.FirstName} ${singleUser?.LastName}` : singleUser?.PatientName,
    //         patientEmail: singleUser?.EmailId,
    //         patientPhoneNumber: singleUser?.PhoneNumber,
    //         Status: singleUser?.Status,
    //         appointmentID: values?.appointmentID,
    //         file,
    //         type: "reports"
    //     }));

    //     const responses = await Promise.all(
    //         createDataRecords.map(async (createData) => {
    //             const response = await ReportServices().CreateReport(createData);
    //             return response;
    //         })
    //     );
    //     return responses;
    // }

    // const reportUpdateUtil = async (values: any) => {
    //     const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
    //     const sanitizedUserInfo = removeFields(values, fieldsToRemove);
    //     console.log("sanitizedUserInfo", sanitizedUserInfo);

    //     let getUser: any;
    //     if (values?.patientID) {
    //         getUser = await userService().getuserSingle(sanitizedUserInfo?.patientID);
    //     } else {
    //         getUser = await userService().getSingleAppointment(sanitizedUserInfo?.appointmentID);
    //     }

    //     const singleUser = getUser?.data?.getUser ? getUser?.data?.getUser : getUser?.data?.getPatientAppointment;

    //     const existingReports = await ReportServices().reportsListBypatientID(sanitizedUserInfo?.appointmentID);
    //     console.log("existingReports", existingReports);

    //     // Map existing files by fileType and name
    //     const existingFilesMap: any = {};
    //     existingReports?.forEach((report: any) => {
    //         console.log("report", report);
    //         report?.file?.forEach((file: any) => {
    //             if (!existingFilesMap[file?.fileType]) {
    //                 existingFilesMap[file?.fileType] = new Map();
    //             }
    //             file?.name?.forEach((fileName: any) => {
    //                 if (!existingFilesMap[file?.fileType].has(fileName)) {
    //                     existingFilesMap[file?.fileType].set(fileName, report?.id);
    //                 }
    //             });
    //         });
    //         // report?.file?.forEach((file: any) => {
    //         //     if (!existingFilesMap[file?.fileType]) {
    //         //         existingFilesMap[file?.fileType] = new Map();
    //         //     }
    //         //     file?.name?.forEach((fileName: any) => existingFilesMap[file?.fileType].add(fileName));
    //         // });
    //     });
    //     console.log("existingFilesMap", existingFilesMap);

    //     // Identify new files to be inserted and files to be updated
    //     const newFiles: any = [];
    //     const filesToUpdate: any = [];
    //     // sanitizedUserInfo?.file?.forEach((file: any) => {
    //     //     const { fileType, name } = file;
    //     //     name?.forEach((fileName: any) => {
    //     //         let found = false;
    //     //         for (const [existingFileType, fileNames] of Object.entries(existingFilesMap)) {
    //     //             if ((fileNames as Set<string>).has(fileName)) {
    //     //                 found = true;
    //     //                 if (existingFileType !== fileType) {
    //     //                     filesToUpdate.push({

    //     //                         // oldFileType: existingFileType,
    //     //                         // newFileType: fileType,
    //     //                         fileType: fileType ? fileType : existingFileType,
    //     //                         name: [fileName]
    //     //                     });
    //     //                 }
    //     //                 break;
    //     //             }
    //     //         }
    //     //         if (!found) {
    //     //             newFiles.push({
    //     //                 fileType,
    //     //                 name: [fileName]
    //     //             });
    //     //         }
    //     //     });
    //     // });

    //     sanitizedUserInfo?.file?.forEach((file: any) => {
    //         const { fileType, name } = file;
    //         name?.forEach((fileName: any) => {
    //             let found = false;
    //             for (const [existingFileType, fileNamesMap] of Object.entries(existingFilesMap)) {
    //                 if ((fileNamesMap as Map<string, string>).has(fileName)) {
    //                     found = true;
    //                     const reportId = (fileNamesMap as Map<string, string>).get(fileName);
    //                     if (existingFileType !== fileType) {
    //                         filesToUpdate.push({
    //                             id: reportId, // Include the id of the report
    //                             fileType: fileType ? fileType : existingFileType,
    //                             name: [fileName]
    //                         });
    //                     }
    //                     break;
    //                 }
    //             }
    //             if (!found) {
    //                 newFiles.push({
    //                     fileType,
    //                     name: [fileName]
    //                 });
    //             }
    //         });
    //     });

    //     console.log("filesToUpdate", filesToUpdate);

    //     // Handle fileType updates
    //     const updateFileTypePromises = filesToUpdate?.map(async (file: any) => {
    //         console.log("file", file);
    //         const { id, ...fileWithoutId } = file;
    //         const updateData = {
    //             id: file?.id,
    //             patientID: values?.patientID,
    //             updatedBy: logUserObject?.userId,
    //             patientName: `${singleUser?.FirstName} ${singleUser?.LastName}` ? `${singleUser?.FirstName} ${singleUser?.LastName}` : singleUser?.PatientName,
    //             patientEmail: singleUser?.EmailId,
    //             patientPhoneNumber: singleUser?.PhoneNumber,
    //             Status: singleUser?.Status,
    //             appointmentID: values?.appointmentID,
    //             file: fileWithoutId
    //         };
    //         console.log("updateData", updateData);

    //         // existingReports?.map(async (item: any) => {
    //         //     const input = {
    //         //         id: item?.id,
    //         //         ...updateData
    //         //     }
    //         const response = await ReportServices().UpdateReportFileType(updateData);
    //         console.log("response", response);
    //         return response;
    //         // })

    //     });

    //     const updateFileTypeResponses = await Promise.all(updateFileTypePromises);

    //     if (newFiles?.length > 0) {
    //         const flattenedFileNames = await Promise.all(
    //             newFiles?.flatMap(async (file: any) => {
    //                 return await Promise.all(
    //                     file?.name?.map(async (file1: any) => {
    //                         return {
    //                             fileType: file?.fileType,
    //                             name: file1
    //                         };
    //                     })
    //                 );
    //             })
    //         );

    //         const flattenedFiles = flattenedFileNames.flat();
    //         const createDataRecords = flattenedFiles?.map((file) => ({
    //             patientID: values?.patientID,
    //             updatedBy: logUserObject?.userId,
    //             patientName: `${singleUser?.FirstName} ${singleUser?.LastName}` ? `${singleUser?.FirstName} ${singleUser?.LastName}` : singleUser?.PatientName,
    //             patientEmail: singleUser?.EmailId,
    //             patientPhoneNumber: singleUser?.PhoneNumber,
    //             Status: singleUser?.Status,
    //             appointmentID: values?.appointmentID,
    //             file
    //         }));

    //         const responses = await Promise.all(
    //             createDataRecords?.map(async (createData) => {
    //                 const response = await ReportServices().CreateReport(createData);
    //                 return response;
    //             })
    //         );
    //         return { data: responses, msg: "success" };
    //     } else {
    //         console.log("No new files to insert.");
    //         return { data: updateFileTypeResponses, msg: "File types updated." };
    //     }
    // };

    const reportUpdateUtil = async (values: any) => {
        const getReport = await ReportServices().getReportSingle(values?.id);
        let updatedArray = [];

        if (values?.file && values?.file?.length > 0) {
            updatedArray = values?.file?.map((item: any) => {
                const newItem = { ...item };
                delete newItem.data; // Remove the `data` property
                return newItem;
            });
        }

        getReport.file = updatedArray;

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(getReport, fieldsToRemove);

        console.log({sanitizedUserInfo})

        const response = await ReportServices().ReportUpdate(sanitizedUserInfo);
        console.log("response in utils update", response);
        return response
    }
    const check = async (values: any) => {
        console.log("values at check", values);
        if (!values?.file || values?.file?.length === 0) {
            console.log("No files to process.");
            return values; // Return the original values if there are no files to process
        }

        const getReport = await ReportServices().getReportSingle(values?.appointmentID);

        // Extract existing file names from the `name` array in the `file` objects
        const existingFileNames = getReport?.file?.length > 0
            ? getReport.file.flatMap((fileObject: any) => fileObject?.name || [])
            : [];

        let nonExistentFiles: any = [];

        await Promise.all(
            values?.file?.length > 0 && values?.file?.map(async (fileObject: any) => {
                // Extract file name from `name` array or use fileObject itself if it's a string
                const fileNames = fileObject?.name || [];
                await Promise.all(fileNames?.map(async (fileName: any) => {
                    AWS.config.update({
                        accessKeyId: process.env.REACT_APP_INSTANCE_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_INSTANCE_SECRET_KEY,
                        region: process.env.REACT_APP_REGION
                    });
                    const s3 = new AWS.S3();
                    let params: any;
                    if (fileName?.name) {
                        params = {
                            Bucket: process.env.REACT_APP_S3_BUCKET,
                            Key: `images/${fileName?.name}`
                        };
                    } else {
                        params = {
                            Bucket: process.env.REACT_APP_S3_BUCKET,
                            Key: `images/${fileName}`
                        };
                    }

                    try {
                        await s3.headObject(params).promise();
                        console.log(`File ${fileName} already exists in the bucket.`);
                    } catch (error: any) {
                        if (error.code === 'NotFound') {
                            console.log(`File ${fileName?.name} does not exist in the bucket, adding to upload list.`);
                            nonExistentFiles.push({ fileType: fileObject.fileType, name: fileName }); // Add the whole fileObject for later processing
                        } else {
                            console.error("Error checking file existence:", error);
                            throw error; // Re-throw the error if it's not a NotFound error
                        }
                    }
                }));
            })
        );

        let fileNames: any = [];
        if (nonExistentFiles?.length > 0) {
            fileNames = await Promise.all(
                nonExistentFiles.map(async (fileObject: any) => {
                    // const newFileName = Date.now() + "_" + fileObject?.name?.name;
                    const  newFileName = await FormatFile(fileObject?.name)
                    await SendFile(fileObject?.name, newFileName, "images");
                    return { fileType: fileObject.fileType, name: newFileName };
                })
            );
        }

        const updatedFileValues = values?.file?.map((fileObject: any) => {
            const newNames = fileNames
                .filter((f: any) => f?.fileType === fileObject?.fileType)
                .map((f: any) => f?.name);
            console.log("newNames", newNames);
            return {
                ...fileObject,
                name: [...fileObject.name, ...newNames].filter(name => typeof name === 'string')
            };
        });
        values.file = updatedFileValues;
        return values;
    };


    const reportDelete = async (data: any) => {
        console.log("data", data);

        const report = await ReportServices().getReportSingle(data);
        console.log("report", report);

        // const results = await Promise.all(promises);
        // const allReports = results.flat();
        const Delpromises = report?.file?.map(async (item: any) => {
            console.log("item", item);
            if (item?.name?.length > 0) {
                item?.name?.map(async (fileName: any) => {
                    const fileresp = await updDelFile(fileName, "images");
                    if (fileresp?.status === 200) {
                        const delRep = await ReportServices().ReportDelete(report?.id);
                        console.log("delRep", delRep);
                        // return delRep;
                    }
                })
            } else {
                const delRep = await ReportServices().ReportDelete(report?.id);
                console.log("delRep", delRep);
                // return delRep;
            }
        });
        // console.log("Delpromises", Delpromises);
        return Delpromises;
    }


    return {
        handleSubmit,
        reportUpdateUtil,
        reportDelete,
        check,

    }
}
export default ReportUtils;