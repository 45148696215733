import React, { useState, useEffect } from "react";
import ListTermsAndConditions from "./../../Services/pageServices";
import { string } from "yup";
import { Helmet } from 'react-helmet';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import pages from "../../utills/pageUtils";
import LazyLoad from "react-lazyload";
interface compProps {
  title: string;
  description: string;
}


interface TermsAndConditions {
  Description: string
}

const TermsAndConditions: React.FC<compProps> = ({ title, description }) => {
  const [TermsConditionsList, setListTermsConditions] = useState<any>();
  const [pageInfo, setPageInfo] = useState<any>()
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()
  const location = useLocation();
  const navigate = useNavigate();

 

  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetching data
      const result = await pages(location?.pathname)
      console.log("result", result)

      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)

      })

    };
    fetchData();
  }, [])


  console.log("pageInfo", pageInfo)
  const listTermsConditions = async () => {
    try {
      const response = await ListTermsAndConditions().TermsAndConditionsList();
      console.log("response", response);
      response?.map((item) => {
        if (item?.Link === "termsCondition") {
          setListTermsConditions(item);
        }
      })
      
    } catch (error) {
      console.error('Error fetching terms and conditions:', error);
    }
  };

  console.log("TermsConditionsList", TermsConditionsList);
  useEffect(() => {
    listTermsConditions();
    window.scrollTo(0, 0);
  }, []);

  const removeHtmlTags = (str: string) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  // const truncateText = (text: string, length: number) => {
  //     if (text.length <= length) return text;
  //     return text.slice(0, length) + '...';
  // };

  return (
    <div className="page-wrapper">
       <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
          </Helmet><section className="home-slider owl-carousel">
            <LazyLoad>
            <div
              className="slider-item bread-item"
              style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})` : 'images/bg_1.jpg' }}
              data-stellar-background-ratio="0.5"
            >
              <div className="overlay"></div>
              <div className="container" data-scrollax-parent="true">
                <div className="row banner-text align-items-center">
                  <div className="col-md-12 col-sm-12 ftco-animate">
                    {/* <h2 style={{ textAlign: 'center', marginBottom: '100px' }}>
    {bannerName}
    </h2>
    <span>{caption}</span> */}
                    <h1 className="banner-ttl">{bannerName}</h1>
                    <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
                  </div>
                </div>
              </div>
            </div>
            </LazyLoad>
          </section>
          <div>
            <ul className="ullist">
              <li>
                <a className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</a>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div>
      <section className="story" style={{ marginBottom: "0px !important" }}>
        <div className="container">
          <div className="row">

            <div className="col-md-12">
              <span className="blog-content" dangerouslySetInnerHTML={{ __html: TermsConditionsList?.Description }} />

            </div>

          </div>
        </div>
      </section >
    </div >
  );
};

export default TermsAndConditions;
