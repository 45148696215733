import React, { useRef, useCallback, useState } from 'react';
import Webcam from 'react-webcam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faRedo } from '@fortawesome/free-solid-svg-icons';

const CameraComponent: any = (props: any) => {
    const webcamRef: any = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc);
        if (imageSrc) {
            // Convert data URL to Blob
            const byteString = atob(imageSrc.split(',')[1]);
            const mimeString = imageSrc.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: mimeString });

            // Create a File object from the Blob
            const file: any = new File([blob], "captured_image.png", { type: mimeString, lastModified: Date.now() });
            props?.setFile(file);
        }
    }, [webcamRef]);

    const retake = () => {
        setImageSrc(null);
        props?.setFile(null);
    };

    const uploadImage = () => {
        console.log("imageSrc", imageSrc);
        // Logic to upload image to your server
        // For example, using fetch or axios to send a POST request
        // with the image data.
    };

    return (
        <div className="col-md-8 mb-3 mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {!imageSrc ? (
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    style={{ width: '320px', height: '240px' }}
                />
            ) : (
                <img src={imageSrc} alt="Captured" style={{ width: '320px', height: '240px' }} />
            )}
            <div className="col-md-8 mb-3 mt-2" style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                {!imageSrc ? (
                    <button type="button" className="col-md-4 justify-content-center" onClick={capture} style={{ margin: '10px' }}>
                        <FontAwesomeIcon icon={faCamera} /> Capture photo
                    </button>
                ) : (
                    <>
                        <button type="button" className="col-md-4 justify-content-center" onClick={retake} style={{ margin: '10px' }}>
                            <FontAwesomeIcon icon={faRedo} /> Retake photo
                        </button>
                        {/* <button className="col-md-4 justify-content-center" onClick={uploadImage} style={{ margin: '10px' }}>
                            Upload photo
                        </button> */}
                    </>
                )}
                {/* {imageSrc && (
                <>
                    <img src={imageSrc} alt="Captured" style={{ width: '320px', height: '240px', margin: '10px auto' }} />
                    <button onClick={uploadImage} style={{ margin: '10px auto' }}>Upload photo</button>
                </>
            )} */}
            </div>
        </div>

    );
};

export default CameraComponent;