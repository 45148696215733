import * as Yup from 'yup';



const characterValidation = (value: any) => {
    // return /^[A-Za-z]+$/.test(value);
    return /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value);
};

export const ReviewvalidationSchema = Yup.object({
    // name: Yup.string().required('name is Required'),
    Name: Yup.string()
        .required('Name is required')
        .min(3, 'Name must be at least 3 characters')
        .test('character-validation', 'Name must contain only characters', characterValidation),
    // email: Yup.string().email('Invalid email address').required('email is Required'),
    EmailId: Yup.string()
        .required('Email is required')
        .test(
            'valid-email',
            'Invalid email',
            (value) => {
                // Check if the value is either an email or a phone number
                const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                // const phoneRegex = /^[0-9]{10}$/;


                if (emailRegex.test(value)) {
                    return true;
                }
                return false;
            }
        ),
    Rating: Yup.number().min(1, 'Minimum Rating is 1').max(5, 'Maximum rating is 5').required('Rating is Required'),
    Comment: Yup.string().required('Comment is Required')
        .min(3, 'Message must be at least 3 characters')
        .max(120, 'Message must be at most 120 characters only'),
});