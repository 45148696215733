import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContactServices from '../../Services/ContactServices';
import { Helmet } from 'react-helmet';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';



interface Review {
    Name: string;
    EmailId: string;
    phoneNumber: any;
    Message: string;
    // Status: any;
}

const ListContacts = () => {
    const initialState: Review = {
        Name: '',
        EmailId: '',
        phoneNumber: '',
        Message: '',
        // Status: ''
    };

    const navigate = useNavigate();
    const [data, setData] = useState<Review>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [reviewsList, setReviewList] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('PDF Viewer Modal');
    const [singleReview, setSingleReview] = useState<any>({});


    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // const logUserObject:any = useAuthRedirect();
    // useAuthRedirect(logUserObject);
    
    const listReview = async () => {
        // const response = await ReviewServices().reviewList();
        const response = await ContactServices().contactList();
        setReviewList(response);
    };

    useEffect(() => {
        listReview();
    }, []);

    const handleDelete = async (id: any) => {
        const confirmBox = window.confirm("Are you sure you want to delete the selected Review?");
        if (confirmBox) {
            await ContactServices().contactdelete(id);
            listReview();
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const filteredData: any = reviewsList?.filter((row: Review) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData.length / pageSize);
    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    };

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);

    const openModal = async (files: any) => {
        const singleReview = await ContactServices().getcontactSingle(files?.id);
        setSingleReview(singleReview);
        setIsModalOpen(true);
        setModalHeading(files?.Name);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalHeading("");
    };


    const handleStatusToggle = (rowData: any) => async (event: any) => {
        const updatedStatus = event?.target?.checked === true ? "1" : "0";
        const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
        const update = await ContactServices().contactUpdate(updatedRowData);

        setReviewList((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, Status: updatedStatus } : row
            )
        );
    };

    const truncateText = (text: any) => {
        if (!text) return '';
        const truncatedText = text?.length > 10 ? text?.substring(0, 10) + '...' : text;
        return truncatedText;
    };

    // const handleSubmit = () => {
    //     navigate('/contactus');
    // }


    return (
        <>
            <Helmet>
                <title>Enquiries</title>
                <meta name="description" content="This is the Enquiries page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Enquiries
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='tablefs '>
                <ul className="paginationbanner" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
                    <li>
                        <a className="aColor" onClick={() => navigate('/')}>Home  /&nbsp;</a>
                    </li>
                    <li>Enquiries</li>
                </ul>
            </div>
            <div className="container">
                <div className="row tablefs">
                    {/* <div className="col-md-2" style={{ marginTop: '50px' }}>
                        <Sidebar />
                    </div> */}
                    <div className="col-md-12">
                        <div>
                            <div className="row">

                                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <div className="page-size-dropdown">

                                        <label style={{ padding: '5px' }}>Show</label>
                                        <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e?.target?.value))}>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={20}>20</option>
                                        </select>
                                        <label style={{ padding: '5px' }}>entries</label>
                                    </div>
                                    <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                                        <p style={{ marginRight: '10px' }}>Search</p>
                                        <div>
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e?.target?.value)}
                                                className="search-bar"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ overflowX: 'auto' }}>
                                <table className="table table-bordered table-striped table-responsive listEnquiries">
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                            <th className='sno'>S.No</th>
                                            <th className='mdl'>Name</th>
                                            <th className='mdl'>EmailId </th>
                                            <th className='mdl'>PhoneNumber</th>
                                            <th className='mdl'>Message</th>
                                            <th className='actions'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((row: any, index: number) => (
                                            <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>{highlightText(row?.Name)}</td>
                                                <td>{highlightText(row?.emailID)}</td>
                                                <td>{highlightText(row?.phoneNumber)}</td>
                                                <td>{highlightText(truncateText(row?.Message))}</td>
                                                <td className='actions'>
                                                    <Button title="View"
                                                        onClick={() => openModal(row)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<VisibilityIcon style={{ color: 'green' }} />}
                                                    >

                                                    </Button>
                                                    <Button title="Delete"
                                                        onClick={() => handleDelete(row?.id)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                    >

                                                    </Button>


                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='mttable'>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                <div className="paginationstyle mttable1">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                handlePageChange(currentPage - 1);
                                                window.scrollTo(0, 0); // Scroll to top
                                            }
                                        }}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage < totalPages) {
                                                handlePageChange(currentPage + 1);
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>

                            </div>

                            <div className={`modal ${isModalOpen ? "open" : ""}`}>
                                <div className="modal-content1" style={{ marginTop: '177px', height: '380px' }}>

                                    <h6 className="text-center">Message By <strong>{modalHeading}</strong></h6>
                                    {singleReview && (
                                        <div style={{ padding: '40px' }}>
                                            <span className="close closebtn" onClick={closeModal}>
                                                &times;
                                            </span>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Name:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.Name)}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Email ID:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.emailID)}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>PhoneNumber:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.phoneNumber)}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                                <div style={{ flexBasis: '80px', marginRight: '20px' }}>
                                                    <strong>Message:</strong>
                                                </div>
                                                <div>
                                                    <span>{highlightText(singleReview.Message)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >

        </>

    );
};

export default ListContacts;

