import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  Title: Yup.string().required('Title is required'),
  Caption: Yup.string().required('Caption is required'),
  Files: Yup.array()
    .min(1, 'At least one file is required') // Ensure the array has at least one file
    .of(
      Yup.mixed()
        .required('A file is required') // Validation for each file in the array
    ),
});

