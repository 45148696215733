
import React, { useState, useEffect, Suspense } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import './style.css';
import ReactPlayer from "react-player";
import GalleryServices from "../../Services/galleryServices";
import { Helmet } from 'react-helmet';
import pages from "../../utills/pageUtils";
import Loader from "../shared/Loader";
import LazyLoad from "react-lazyload";

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

interface GalleryItem {
  id: any;
  Category: string;
  Files: [];
  fileType: string;
  url?: string;
}

const Gallery: React.FC<compProps> = ({ title, description, keywords }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [selectedMainCategory, setSelectedMainCategory] = useState<string>("Images");
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>("All");
  const [galleryItems, setGalleryItems] = useState<GalleryItem[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [videoCategories, setVideoCategories] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
  const location = useLocation();
  const [pageInfo, setPageInfo] = useState<any>();
  const [bannerName, setBannerName] = useState<any>()
  const [caption, setCaption] = useState<any>()
  const [visibleItems, setVisibleItems] = useState(6);
  const [loadMore, setLoadMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const navigate = useNavigate();
  const [displayedFilesCount, setDisplayedFilesCount] = useState(6);


  useEffect(() => {
    const fetchData = async () => {
      const result = await pages(location?.pathname);
      result?.map((item: any) => {
        setPageInfo(item?.Banner);
        setBannerName(item?.Title)
        setCaption(item?.Description)
        
      });
    };
    fetchData();
  }, []);

  const openModal = (videoUrl: string, heading: string) => {
    setSelectedVideoUrl(videoUrl);
    setModalHeading(heading);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideoUrl('');
    setModalHeading("");
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get('category');
    if (category) {
      setSelectedMainCategory(category);
      
    }
  }, [location]);

  useEffect(() => {
    async function fetchGalleryItems() {
      setLoading(true);
      const response = await GalleryServices().ActivegalleryList();
      setGalleryItems(response);
    
     

      const imageCategories = response
        .filter((item: GalleryItem) => item?.fileType === "image")
        .map((item: GalleryItem) => item?.Category);

      setSubCategories(["All", ...Array.from(new Set(imageCategories))]);
     

      const videoAndUrlCategories = response
        .filter((item: GalleryItem) => item?.fileType === "video" || item?.fileType === "url")
        .map((item: GalleryItem) => item?.Category);

      setVideoCategories(["All", ...Array.from(new Set(videoAndUrlCategories))]);
      setLoading(false);
    }

    fetchGalleryItems();
    // setLoading(false);
  }, []);

  const handleMouseOver = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const handleMainCategoryClick = (category: string) => {
    setSelectedMainCategory(category);
    setSelectedSubCategory("All"); // Reset sub-category to "All" when changing main category
    setLoadMore(true);
    setVisibleItems(6);
    setDisplayedFilesCount(6);
  };

  const handleSubCategoryClick = (category: string) => {
    setSelectedSubCategory(category);
    setDisplayedFilesCount(3);
  };

  const mapFileTypeToCategory = (fileType: any) => {
    if (fileType.toLowerCase() === "video" || fileType.toLowerCase() === "url") {
      return "Videos";
    }
    return "Images";
  };

  const filteredGalleryItems = galleryItems?.filter((item: GalleryItem) => {
    const category = mapFileTypeToCategory(item?.fileType);
    return (
      category === selectedMainCategory &&
      (selectedSubCategory === "All" || item?.Category === selectedSubCategory)
    );
  });

  const allFiles = filteredGalleryItems.flatMap((item: any) => item?.Files?.map((file: any) => ({ file, fileType: item?.fileType })));

  const handleLoadMore = () => {
    setDisplayedFilesCount(prevCount => prevCount + 3);
  };

  const itemsToDisplay = filteredGalleryItems?.slice(0, visibleItems);
  const hasMoreItems = filteredGalleryItems?.length > visibleItems;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let galleryCount = 0;
  // const handleLoadMore = () => {
  //   setLoadMore(false);
  //   galleryCount++;
  //   setVisibleItems((prev) => prev + 3); // Load 3 more items on click
  // };

  return (
    <>
        <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta name="author" content="Gurram Dental Care" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>
        
        <section className="home-slider owl-carousel">
          <LazyLoad>
            <div
              className="slider-item bread-item"
              style={{ backgroundImage: pageInfo ? `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo})` : 'images/bg_1.jpg' }}
              data-stellar-background-ratio="0.5"
            >
              <div className="overlay"></div>
              <div className="container" data-scrollax-parent="true">
                <div className="row banner-text align-items-center">
                  <div className="col-md-12 col-sm-12 ftco-animate">
                  
                    <h1 className="banner-ttl">{bannerName}</h1>
                    <div className="captionName" dangerouslySetInnerHTML={{ __html: caption }}></div>
                  </div>
                </div>
              </div>
            </div>
            </LazyLoad>
          </section>
          
          <div>
            <ul className="ullist" style={{ textAlign: 'left', display: 'flex', justifyContent: 'left', marginTop: '10px', listStyle: 'none', textDecoration: 'none' }}>
              <li>
                <span className="aColor" onClick={() => navigate('/')}>Home  /&nbsp;</span>
              </li>
              <li>{bannerName}</li>
            </ul>
          </div>
          
          <div className="tabs" style={{ textAlign: 'center', marginBottom: '20px' }}>
            <button
              className={`tab ${selectedMainCategory === "Images" ? "active" : ""}`}
              onClick={() => handleMainCategoryClick("Images")}
            >
              Photos
            </button>
            <button
              className={`tab ${selectedMainCategory === "Videos" ? "active" : ""}`}
              onClick={() => handleMainCategoryClick("Videos")}
            >
              Videos
            </button>
          </div>


          

            {loading ? (
        <Loader />
      ) : (
          
          <section className="ftco-section" style={{ paddingTop: "0px" }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 text-center heading-section">
                  {selectedMainCategory === "Images" && (
                    <div className="sub-categories">
                      {subCategories?.map((category, index) => (
                        <button
                          key={index}
                          onClick={() => handleSubCategoryClick(category)}
                          className={`category-tab ${selectedSubCategory === category ? "active" : ""}`}
                        >
                          {category}
                        </button>
                      ))}
                    </div>
                  )}
                  {selectedMainCategory === "Videos" && (
                    <div className="sub-categories">
                      {videoCategories?.map((category, index) => (
                        <button
                          key={index}
                          onClick={() => handleSubCategoryClick(category)}
                          className={`category-tab ${selectedSubCategory === category ? "active" : ""}`}
                        >
                          {category}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                {allFiles?.length === 0 ? (
                  <p>No items to display</p>
                ) : (
                  allFiles.slice(0, displayedFilesCount)?.map((item, index) => {
                    // console.log("item", item);
                    return item?.fileType.toLowerCase() === "image" ? (
                      <div key={index} className="col-md-4 col-sm-4">
                        <div className="gallery-item img-fluid"  onMouseOver={() => handleMouseOver(index)} onMouseOut={handleMouseOut}>
                          <LazyLoad>
                          <img
                            src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${item?.file}`}
                            className="img-fluid listGallery"
                            alt={item?.fileType}
                            style={{
                              filter: hoveredIndex === index ? "brightness(80%)" : "brightness(100%)"
                            }}
                          />
                          </LazyLoad>
                        </div>
                      </div>
                    ) : item?.fileType.toLowerCase() === "video" ? (
                      <div key={`video-${index}`} className="col-md-4 col-sm-6">
                        <div className="gallery-item" onMouseOver={() => handleMouseOver(index)} onMouseOut={handleMouseOut}>
                          <video
                            src={`${process.env.REACT_APP_S3_BUCKET_FETCH}/videos/${item?.file}`}
                            className="img-fluid listGallery"
                            controls
                            style={{
                              filter: hoveredIndex === index ? "brightness(80%)" : "brightness(100%)",
                              // maxHeight: "200px",
                              width: "100%"
                            }}
                          />
                        </div>
                      </div>
                    ) : item?.fileType.toLowerCase() === "url" ? (
                      (() => {
                        const itemUrl = item?.file;
                        let embedLink = itemUrl;
                        if (itemUrl.includes("youtu.be")) {
                          const videoId = itemUrl.split("/").pop().split("?")[0];
                          embedLink = `https://www.youtube.com/embed/${videoId}`;
                        } else if (itemUrl.includes("youtube.com/watch")) {
                          embedLink = itemUrl.replace("watch?v=", "embed/").split("&")[0];
                        }
                        return (
                          <div key={`url-${index}`} className="col-md-4 col-sm-6">
                            <div className="video-thumbnail" style={{ position: 'relative' }}>
                              <iframe
                                src={embedLink}
                                className="img-fluid"
                                title={`Video ${index}`}
                                style={{
                                  filter: hoveredIndex === index ? "brightness(80%)" : "brightness(100%)",
                                  transition: "filter 0.3s ease",
                                  height: "200px",
                                  width: "100%",
                                  border: "1px solid #A9BED0"
                                }}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                        );
                      })()
                    ) : null;
                  })
                )}
              </div>

              {displayedFilesCount < allFiles?.length && (
                <div className="text-center">
                  <button
                    className="load-more-button"
                    onClick={handleLoadMore}
                  
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
          </section>

           )}

          

          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <button onClick={closeModal} className="close-button">&times;</button>
                <h2>{modalHeading}</h2>
                <ReactPlayer url={selectedVideoUrl} controls width="100%" height="400px" />
              </div>
            </div>
          )}

     
    </>
  );
}

export default Gallery;