

import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import userService from '../../Services/user/user.service';
import ReviewServices from '../../Services/reviewServices/service';
import ContactServices from '../../Services/ContactServices';
import ReportServices from '../../Services/reportservices';
import PrescriptionServices from '../../Services/prescriptionServices';

interface MainContentProps {
    user: any;
}

export default function MainContent({ user }: MainContentProps) {
    const navigate = useNavigate();
    const logUser: any = localStorage?.getItem('userData');
    const logUserObject: any = JSON.parse(logUser);

    const [listReviews, setListReviews] = useState('');
    const [listDoctors, setListDoctors] = useState('');
    const [listPatients, setListPatients] = useState('');
    const [listAppointments, setListAppointments] = useState('');
    const [enquiryList, setEnquiryList] = useState('');
    const [doctorlistAppointments, setDoctorlistAppointments] = useState('');
    const [patientlistAppointments, setPatientlistAppointments] = useState('');
    const [reports, setReports] = useState([]);
    const [prescriptions, setPrescriptions] = useState([]);

    async function getReviews() {
        const listReviews: any = await ReviewServices().reviewList();
        // console.log("listReviews", listReviews);
        return listReviews;
    }

    useEffect(() => {
        async function fetchReviews() {
            try {
                const reviews = await getReviews();
                setListReviews(reviews.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchReviews();
    }, []);


    async function getDoctors() {
        const response = await userService().ListDoctor('Doctor');
        console.log("response", response);
        return response;
    }

    useEffect(() => {
        async function fetchDoctors() {
            try {
                const doctors = await getDoctors();

                console.log("doctors", doctors)
                setListDoctors(doctors.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);

    async function getPatients() {
        const response = await userService().ListDoctor('Patient');
        console.log("response", response);
        return response;
    }
    useEffect(() => {
        async function fetchDoctors() {
            try {
                const Patients = await getPatients();

                console.log("doctors", Patients)
                setListPatients(Patients.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);

    async function getAppointments() {
        const response = await userService().ListPatientAppointments("");
        console.log("response", response);
        return response;
    }
    useEffect(() => {
        async function fetchDoctors() {
            try {
                const Appointments = await getAppointments();

                console.log("doctors", Appointments)
                setListAppointments(Appointments.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);

    async function getEnquiryList() {
        const response = await ContactServices().contactList();
        console.log("response", response);
        return response;
    }

    useEffect(() => {
        async function fetchDoctors() {
            try {
                const EnquiryList = await getEnquiryList();

                console.log("doctors", EnquiryList)
                setEnquiryList(EnquiryList.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);
    async function getDoctorAppointmnets() {
        const response = await userService().ListPatientAppointments(logUserObject?.userId);
        console.log("response", response);
        return response;
    }

    useEffect(() => {
        async function fetchDoctors() {
            try {
                const Appointmnets = await getDoctorAppointmnets();

                console.log("doctors", Appointmnets)
                setDoctorlistAppointments(Appointmnets.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);


    async function getPatientAppointmnets() {
        const response = await userService().ListPatientAppointmentsById(logUser?.userId,'');
        const patients = response?.filter((user: any) => user?.patientID === logUserObject?.userId)
        console.log("response", patients);
        return patients;
    }
    useEffect(() => {
        async function fetchDoctors() {
            try {
                const Appointmnets = await getPatientAppointmnets();

                console.log("doctors", Appointmnets)
                setPatientlistAppointments(Appointmnets.length.toString());
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        }

        fetchDoctors();
    }, []);

    async function getReportsPrescriptions() {
        const response: any = await ReportServices().reportsList();
        console.log("response", response);
        setReports(response?.length);
        const prescriptions: any = await PrescriptionServices().prescriptionList();
        setPrescriptions(prescriptions?.length)
    }
    useEffect(() => {
        getReportsPrescriptions()
    })




    return (
        <div className="container">
            <div className="row welcomeRole">
                <h1  style={styles.h1 as CSSProperties}>Welcome, {logUserObject?.userName}</h1>
                <div style={styles.mainContent as CSSProperties}>
                    {/* <div className="col-md-12">
                            {logUserObject?.Role === 'MasterAdmin' ? (
                                <div style={styles.stats as CSSProperties}>
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/ListRegisteredPatients')}>
                                        <i className="fa fa-users" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Patients <br />
                                                <span>120</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/AppointmentList')}>
                                        <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Appointments <br />
                                                <span>40</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/ListDoctor')}>
                                        <i className="fa fa-stethoscope" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Doctors <br />
                                                <span>10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/listreviews')}>
                                        <i className="fa fa-heartbeat" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Reviews <br />
                                                <span>160</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/enquirylist')}>
                                        <i className="fa fa-user" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Enquiries <br />
                                                <span>80</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/sendSms')}>
                                        <i className="fa fa-user" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                               Send SMS <br />
                                                <span>80</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {logUserObject?.Role === 'Doctor' ? (
                                <div style={styles.stats as CSSProperties}>
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/AppointmentList')}>
                                        <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Appointments <br />
                                                <span>40</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {logUserObject?.Role === 'Patient' ? (
                                <div style={styles.stats as CSSProperties}>
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/AppointmentList')}>
                                        <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Appointments <br />
                                                <span>40</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div> */}
                    <div className="row admin-log">
                        {logUserObject?.Role === 'MasterAdmin' ? (

                            <div style={styles.stats as CSSProperties}>
                                <div className="col-md-3">
                                    <div  style={styles.statItem as CSSProperties} onClick={() => navigate('/ListRegisteredPatients')}>
                                        <i className="fa fa-users" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="ParaSize">
                                                Patients <br />
                                                <span className="countdashboard" >{listPatients}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/AppointmentList')}>
                                        <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="ParaSize">
                                                Appointments <br />
                                                <span className="countdashboard">{listAppointments}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/ListDoctor')}>
                                        <i className="fa fa-stethoscope" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="ParaSize">
                                                Doctors <br />
                                                <span className="countdashboard">{listDoctors}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/listReviews')}>
                                        <i className="fa fa-heartbeat" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="ParaSize">
                                                Reviews <br />
                                                <span  className="countdashboard">{listReviews}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/enquirylist')}>
                                        <i className="fa fa-user" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="ParaSize">
                                                Enquiries <br />
                                                <span  className="countdashboard">{enquiryList}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ) : null
                        }
                        {logUserObject?.Role === 'Doctor' ? (
                            <div style={styles.stats as CSSProperties}>

                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/AppointmentList')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p className="ParaSize">
                                            Appointments <br />
                                            <span className="countdashboard">{doctorlistAppointments}</span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        ) : null
                        }
                        {logUserObject?.Role === 'Patient' ? (
                            <div style={styles.stats as CSSProperties}>

                                <div style={styles.statItem as CSSProperties} onClick={() => navigate('/AppointmentList')}>
                                    <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        <p className="ParaSize">
                                            Appointments <br />
                                            <span className="countdashboard">{patientlistAppointments}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null
                        }
                        {logUserObject?.Role === 'Lab' ? (
                            <div style={styles.stats as CSSProperties}>
                                <div className="col-md-3">
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/AppointmentList')}>
                                        <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="ParaSize">
                                                Appointments <br />
                                                <span className="countdashboard" >{listAppointments}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div style={styles.statItem as CSSProperties} onClick={() => navigate('/reports')}>
                                    <div className="col-md-3">
                                        <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Reports <br />
                                                <span>{reports}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        ) : null
                        }
                        {logUserObject?.Role === 'Pharmacy' ? (
                            <div style={styles.stats as CSSProperties}>
                                <div className="col-md-3">
                                    <div style={styles.statItem as CSSProperties} onClick={() => navigate('/AppointmentList')}>
                                        <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="ParaSize" >
                                                Appointments <br />
                                                <span className="countdashboard">{listAppointments}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div style={styles.statItem as CSSProperties} onClick={() => navigate('/prescriptions')}>
                                    <div className="col-md-3">
                                        <i className="fa fa-calendar" style={styles.icon as CSSProperties}></i>
                                        <div style={{ textAlign: 'center' }}>
                                            <p style={{ color: 'white', fontSize: '20px' }}>
                                                Prescriptions <br />
                                                <span>{prescriptions}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    mainContent: {
        flex: 1,
        padding: '20px',
    } as CSSProperties,
    h1: {
        fontSize: '36px',
        // marginBottom: '50px',
        float: 'right' as CSSProperties['float'],
        // marginTop: '20px',
        textAlign: 'right',
    },
    stats: {
        display: 'flex',
        // justifyContent: 'space-around',
        flexWrap: 'wrap',
        marginTop: '20px',
        // marginLeft: '30px',
    } as CSSProperties,
    statItem: {
        background: '#48bdc5',
        color: 'white',
        padding: '9px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        cursor: 'pointer',
        marginBottom: '20px',
        flex: '1 0 21%', // Adjust this value as needed
        maxWidth: '220px', // Adjust this value as needed
    } as CSSProperties,
    icon: {
        fontSize: '20px',
        marginTop: '-28px'
    } as CSSProperties,
    texttitle: {
        color: "white",
    } as CSSProperties,
    p: {
        color: "none !important",
        fontSize: '18px',

    } as CSSProperties,
};
