import GalleryServices from "../../Services/galleryServices";
import userService from "../../Services/user/user.service";
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import AWS from 'aws-sdk';
import { SendFile } from "../../views/shared/fileupload";

const GalleryUtils: any = () => {

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }


    const handleSubmit: any = async (values: any) => {
        const response = await GalleryServices().galleryCreate(values);
        console.log("response in utils", response);
        return response
    }

    const handleList: any = async () => {
        const response = await GalleryServices().galleryList();
        return response
    }

    const fetchSingleGallery = async (id: any) => {
        const response = await GalleryServices().getSingleGallery(id);
        return response
    }

    const galleryUpdateUtil = async (values: any) => {
        console.log("hiiiiiii", values);

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(values, fieldsToRemove);

        const response = await GalleryServices().galleryUpdate(sanitizedUserInfo);
        console.log("response in utils update", response);
        return response
    }



    const galleryUpdateUtilatfileremove = async (values: any) => {

        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(values, fieldsToRemove);

        const response = await GalleryServices().galleryUpdate(sanitizedUserInfo);
        console.log("response in utils update", response);
        return response
    }

    const check = async (values: any) => {
        console.log("values", values);

        const getGallery = values?.fileType === "image" || values?.fileType === "video" ? await GalleryServices().getSingleGallery(values?.id) : "";
        console.log("getGallery", getGallery);

        const existingFileNames = getGallery?.Files?.length > 0 && getGallery?.Files?.map((fileObject: any) => fileObject || []);
        console.log("existingFileNames", existingFileNames);
        let nonExistentFiles: any = [];
        let newfiles: any = [];

        if (values?.Files?.length > 0 && (values?.fileType === "image" || values?.fileType === "video")) {
            await Promise.all(values.Files.map(async (fileName: any) => {
                AWS.config.update({
                    accessKeyId: process.env.REACT_APP_INSTANCE_ACCESS_KEY,
                    secretAccessKey: process.env.REACT_APP_INSTANCE_SECRET_KEY,
                    region: process.env.REACT_APP_REGION
                });
                const s3 = new AWS.S3();

                let params: any;
                if (values?.fileType === "image") {
                    params = {
                        Bucket: process.env.REACT_APP_S3_BUCKET,
                        Key: `images/${fileName?.name || fileName}`
                    };
                } else if (values?.fileType === "video") {
                    params = {
                        Bucket: process.env.REACT_APP_S3_BUCKET,
                        Key: `videos/${fileName?.name || fileName}`
                    };
                }

                console.log("params", params);
                if (values?.fileType !== getGallery?.fileType) {
                    try {
                        const resp = await s3.headObject(params).promise();
                        console.log("resp", resp);
                        if (resp) {
                            console.log(`File ${fileName} already exists in the bucket.`);
                        }
                    } catch (error: any) {
                        if (error.code === 'NotFound') {
                            console.log(`File ${fileName?.name || fileName} does not exist in the bucket, adding to upload list.`);
                            newfiles.push(fileName);
                        } else {
                            console.error("Error checking file existence:", error);
                            throw error; // Re-throw the error if it's not a NotFound error
                        }
                    }
                } else {
                    try {
                        const resp = await s3.headObject(params).promise();
                        console.log("resp", resp);
                        if (resp) {
                            console.log(`File ${fileName} already exists in the bucket.`);
                        }
                    } catch (error: any) {
                        if (error.code === 'NotFound') {
                            console.log(`File ${fileName?.name || fileName} does not exist in the bucket, adding to upload list.`);
                            nonExistentFiles.push(fileName);
                        } else {
                            console.error("Error checking file existence:", error);
                            throw error; // Re-throw the error if it's not a NotFound error
                        }
                    }
                }
            }));
        }

        console.log("nonExistentFiles", nonExistentFiles);
        console.log("newfiles", newfiles);

        let fileNames: any = [];
        if (newfiles?.length > 0 && values?.fileType !== getGallery?.fileType) {
            if (values?.fileType === "image") {
                fileNames = await Promise.all(
                    values?.Files?.map(async (file: any) => {
                        const fileName = Date.now() + "_" + file?.name;
                        await SendFile(file, fileName, "images");
                        return fileName;
                    })
                );
            } else if (values?.fileType === "video") {
                fileNames = await Promise.all(
                    values?.Files?.map(async (file: any) => {
                        const fileName = Date.now() + "_" + file?.name;
                        await SendFile(file, fileName, "videos");
                        return fileName;
                    })
                );
            } else if (values?.fileType === "url") {
                fileNames = [values?.Files];
            }
            console.log("fileNames", fileNames);
            // if (getGallery?.fileType !== values?.fileType) {
            //     return values.Files = fileNames
            // } else {
            return values.Files = fileNames;
        } else {
            if (nonExistentFiles?.length > 0 && values?.fileType === "image") {
                fileNames = await Promise.all(
                    nonExistentFiles?.map(async (file: any) => {
                        const fileName = Date.now() + "_" + file?.name;
                        await SendFile(file, fileName, "images");
                        return fileName;
                    })
                );
            } else if (nonExistentFiles?.length > 0 && values?.fileType === "video") {
                fileNames = await Promise.all(
                    nonExistentFiles?.map(async (file: any) => {
                        const fileName = Date.now() + "_" + file?.name;
                        await SendFile(file, fileName, "videos");
                        return fileName;
                    })
                );
            } else if (values?.fileType === "url") {
                fileNames = [values?.Files];
            }
            console.log("fileNames", fileNames);
            // if (getGallery?.fileType !== values?.fileType) {
            //     return values.Files = fileNames
            // } else {
            return values.Files = existingFileNames ? [...fileNames, ...existingFileNames] : fileNames;
            // }
        }
        // console.log("values in services update", values);
    }


    const galleryDelete = async (id: any) => {
        const response = await GalleryServices().galleryDelete(id);
        console.log("response", response);
        return response

    }


    return {
        handleSubmit,
        handleList,
        fetchSingleGallery,
        galleryUpdateUtil,
        galleryUpdateUtilatfileremove,
        check,
        galleryDelete
    }


}
export default GalleryUtils;

