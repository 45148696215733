import React, { useEffect, useRef, useState } from "react";
// import userService from "../services/user/user.service";
import userService from "../../Services/user/user.service";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import Renderfiletype from "./renderfiletype";
import FileInput from "./renderfiletype";
import ReportUtils from "../../utills/users/reports";
import ReportServices from "../../Services/reportservices";
import { validationSchema } from "./validationSchema";
import { Helmet } from 'react-helmet';

interface Reports {
    patientID: string;
    updatedBy: string;
    patientName: string;
    patientEmail: string;
    patientPhoneNumber: string;
    doctorID: string;
    Status: string;
    file: any[];
    appointmentID: string;
    type: string;
}

const initialState: Reports = {
    patientID: "",
    updatedBy: "",
    patientName: "",
    patientEmail: "",
    patientPhoneNumber: "",
    doctorID: "",
    Status: "",
    file: [{ fileType: "", name: [], type: "", description: "" }],
    appointmentID: "",
    type: "reports"
}

const AddReports = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // const [formData, setFormData] = useState(initialState);
    const [formData, setFormData] = useState(initialState);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);

    // useEffect(() => {
    //     if (!logUserObject?.accessToken) {
    //         navigate('/login');
    //     }
    // }, [navigate]);

    async function ProvInfo() {
        const patients: any = await userService().ListDoctor("Patient");
        // const selectedUser = patients?.find((user: any) => user?.id === userID?.id);
        // setSingleuser(selectedUser);
        // const singleReport = await ReportServices().getReportSingle(location?.state?.id);

        const reportsbasedonpatirntId: any = await ReportServices().reportsListBypatientID(location?.state?.appointmentID);
        // console.log("reportsbasedonpatirntId", reportsbasedonpatirntId);
        const combinedFiles: any = {};
        reportsbasedonpatirntId.forEach((report: any) => {
            report?.file?.forEach((file: any) => {
                const { fileType, name } = file;

                if (!combinedFiles[fileType]) {
                    combinedFiles[fileType] = {
                        fileType,
                        names: [],
                        type: file?.type,
                        description: file?.description
                    };
                }
                combinedFiles[fileType].names.push(...name);
            });
        });
        const combinedFileList: any = Object.values(combinedFiles)?.map((fileGroup: any) => ({
            fileType: fileGroup?.fileType,
            name: fileGroup?.names,
            type: fileGroup?.type,
            description: fileGroup?.description,
            data: "existed"
        }));
        const combinedReport: any = {
            id: reportsbasedonpatirntId[0]?.id,
            reports: reportsbasedonpatirntId,
            file: combinedFileList,
            patientID: reportsbasedonpatirntId[0]?.patientID,
            appointmentID: reportsbasedonpatirntId[0]?.appointmentID,
            updatedBy: reportsbasedonpatirntId[0]?.updatedBy,
            type: "reports",
        }
        setFormData(combinedReport);
    }

    useEffect(() => {
        ProvInfo();
    }, [location?.state?.appointmentID]);

    const handleSubmit = async (values: Reports, { setSubmitting }: any) => {
        console.log("location?.state?.patientID", location?.state?.patientID);
        console.log("location?.state?.PhoneNumber", location?.state?.PhoneNumber);

        let result: any;
        if (location?.state?.appointmentID) {
            const s3Check = await ReportUtils().check(values);
            values.type = "reports"
            result = await ReportUtils().reportUpdateUtil(values);
        } else {
            values.appointmentID = location?.state?.appointmentId
            values.patientID = location?.state?.patientID
            result = await ReportUtils().handleSubmit(values);
        }
        if (result) {
            // navigate('/AppointmentList');
            navigate('/ViewAppointment', { state: { patientID: location?.state?.patientID, PhoneNumber: location?.state?.PhoneNumber } });
        }
    };


    // console.log("userID", userID);
    return (
        <>

            <Helmet>
                <title>Upload Reports</title>
                <meta name="description" content="This is the Reports page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Upload Reports
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
                    </li>
                    <li>Upload Reports</li>
                </ul>
            </div>


            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 boxshadowcolor">
                        <div className="mb-3">
                            <Formik
                                initialValues={location?.state?.appointmentID ? formData : initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                                enableReinitialize
                            >
                                {({ setFieldValue, values, errors, touched }) => (
                                    <Form autoComplete="off">
                                        {/* <h2  >Upload Reports</h2> */}
                                        <div>
                                            {/* {formData?.file?.map((file: any, index: any) => ( */}
                                            <FileInput
                                                setFieldValue={setFieldValue}
                                                values={values}
                                            />
                                            {/* ))} */}
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Upload
                                            </button>
                                        </div >

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </>
    );
};

export default AddReports;
