import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import userService from '../../../Services/user/user.service';
import { AppointmnetValidationSchema } from '../../PatientAppointment/AppointmentValidationSchema';
import { useIdleTimeout } from '../SessionExpire';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Homeservices from '../../../Services/homeServices';
import './Footer.css';
import { ConfigurationServicePlaceholders } from 'aws-sdk/lib/config_service_placeholders';
import PaymentServices from '../../../Services/PatientAppointment services';
import Users from '../../../utills/users';


interface Kyc {
  pastHealthConditions: String;
  pastHealthConditionsDescription: String;
  foodAllergies: String;
  foodAllergiesDescription: String;
  CurrentMedications: String;
  CurrentMedicationsDescription: String;
  ChronicIllnesses: String;
  ChronicIllnessesDescription: String;
  SmokingStatus: String;
  AlcoholConsumption: String;
  heardFrom: String;
}

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const [ErrMsgMatch, setErrMsgMatch] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [doctorID, setDoctorID] = useState('');
  const [doctAvail, setDoctAvail] = useState<any>([]);
  const [user, setUser] = React.useState<any>(null);
  const [token, setToken] = React.useState<any>(null);
  const [showRazorpay, setShowRazorpay] = useState(false);
  const location = useLocation();
  const isIdle = useIdleTimeout(1000 * 900);
  const isActive = useIdleTimeout(1000 * 900);
  const [showModal, setShowModal] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [paymentMode, setPaymentMode] = useState('');
  const [services, setServices] = useState([]);
  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);
  const [makepayment, setMakepayment] = useState(false);
  const [storePaymentDetails, setStorePaymentDetails] = useState<any>([]);
  const [UserDetails, setUserDetails] = useState<any>();
  // const [InputDetails, setInputDetails] = useState(initialState);


  const validateForm = (values: any) => {
    const requiredFields = ['PatientName', 'PhoneNumber'];
    const isComplete = requiredFields.every(field => values[field]);
    setIsFormComplete(isComplete);
  }


  const handleNavigation = (service: any) => {
    // navigate('/servicePage', { state: { id: service?.id } });
    // navigate(`/services/${service?.Slug}`);
    navigate(`/services/${service?.Slug}`, { replace: true });
    window.scrollTo(0, 0);
  };
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  let [updateDoctor, setUpdateDoctor] = useState<boolean>(false);
  const [doctorSelected, setDoctorSelected] = useState(false);
  const [doctorDate, setdoctorDate] = useState<string[]>([]);
  const [timeSlot, setTimeSlot] = useState<any>(15)
  const [appointmentTime, setAppointmentTime] = useState<any>('');
  const [availableTimes, setAvailableTimes] = useState([]);
  const [appDecId, setAppDecId] = useState<any>('')
  // const [UserDetails, setUserDetails] = useState<any>();
  // const [LogUser, setLogUser] = useState<any>();
  const [InputDetails, setInputDetails] = useState<any>();

  async function ProvInfo() {
    const response: any = await Homeservices().FooterservicesList();
    const sortedServices = response.sort((a: any, b: any) => {
      const dateA = new Date(a.updatedAt || a.createdAt).getTime();
      const dateB = new Date(b.updatedAt || b.createdAt).getTime();

      return dateB - dateA; // Sort in descending order
    });
    const latestServices = sortedServices.slice(0, 5);
    setServices(latestServices);
  }

  const getUserDetails = async () => {
    if (logUserObject?.userId && logUserObject?.Role === "Patient") {
      const userDetails: any = await userService().getuserSingle(logUserObject?.userId);
      // console.log("userDetails", userDetails);
      if (userDetails) {
        setUserDetails(userDetails?.data?.getUser);
      }
    }
  }

  useEffect(() => {
    const today = new Date();
    const maxDay = new Date();
    maxDay.setDate(today.getDate() + 7);
    setMinDate(today);
    setMaxDate(maxDay);
  }, []);

  useEffect(() => {
    getDoctor();
    ProvInfo();
    getUserDetails();
  }, []);

  const getDoctor = async () => {
    try {
      const response: any = await userService().ListDoctor('Doctor');
      // console.log("response", response);
      const doctorIds: any = response.map((doctor: { id: any }) => doctor.id);
      setdoctorDate(doctorIds);
      setDoctors(response);
    } catch (error) {
      console.error('Error fetching doctors:', error);
    }
  };
  const getDefaultAvailableTimes = (date: any) => {
    const times = [];
    const start = new Date(date);
    start.setHours(10, 0, 0, 0);
    const end = new Date(date);
    end.setHours(20, 0, 0, 0);
    while (start < end) {
      times.push(`${start.getHours()}:${start.getMinutes() === 0 ? '00' : start.getMinutes()}`);
      start.setMinutes(start.getMinutes() + timeSlot);
    }

    return times;
  };

  const handleDoctorChange = async (event: any) => {
    const selectedDoctorID = event?.target?.value;
    setDoctorID(selectedDoctorID);

    setDoctorSelected(selectedDoctorID && selectedDoctorID !== 'Choose Doctor');

    const doctorAppointments = await userService().ListPatientAppointments(selectedDoctorID);
    console.log("doctorAppointments", doctorAppointments);

    const appointmentTimes: any = doctorAppointments?.map((item: any) => ({
      date: item?.AppointmentDate,
      time: item?.AppointmentTime

    }));
    setAppointmentTime(appointmentTimes)

    console.log("AppointmentTimes", appointmentTime);

    if (selectedDoctorID && selectedDoctorID !== 'Choose Doctor') {
      try {
        const doctAvailable = await userService().getlistDoctorAvailableDates(selectedDoctorID);
        console.log("doctAvailable", doctAvailable);

        if (Array.isArray(doctAvailable)) {
          const availableDates = doctAvailable
            .map((item) => ({ date: item?.Date, times: item?.Time }))
            .filter((item) => item.date && Array.isArray(item.times) && item.times.length > 0);
          setDoctAvail(availableDates);
          console.log("availableDates", availableDates);
        } else {
          console.error("Unexpected data format from getlistDoctorAvailableDates");
        }

        const doctorDetails = await userService().ListDoctorsById(selectedDoctorID);
        console.log("doctorDetails", doctorDetails);
        if (doctorDetails && doctorDetails[0]?.TimeSlot) {
          setTimeSlot(doctorDetails[0].TimeSlot);
        } else {
          setTimeSlot(15);
        }
      } catch (error) {
        console.error('Error fetching doctor details:', error);
      }
    } else {
      setDoctAvail([]);
      setTimeSlot(15);
    }
  };
  const isDateAvailable = (date: any) => {

    const moment = require('moment');
    const today = moment().startOf('day');
    const oneWeekLater = moment().add(7, 'days').startOf('day');

    if (!doctorSelected || doctorID === '' || doctorID === 'Choose Doctor') {
      return date >= today && date <= oneWeekLater;
    }

    const previousDay = new Date(date);
    previousDay.setDate(date.getDate() + 1);
    const formattedDate = previousDay.toISOString().split("T")[0];
    // console.log("isDateAvailable", formattedDate, doctAvail);
    console.log("doctAvail", doctAvail);
    return doctAvail.some((item: any) => item.date === formattedDate);
  };
  const getAvailableTimes = (date: any) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    const dateAvailability = doctAvail.find((item: any) => item.date === formattedDate);

    let availableTimes = dateAvailability ? dateAvailability.times : getDefaultAvailableTimes(date);


    const bookedTimesForDate = appointmentTime && appointmentTime
      .filter((item: any) => item?.date === formattedDate)
      .map((item: any) => item?.time);

    availableTimes = availableTimes.filter((time: any) => !bookedTimesForDate.includes(time));


    const today = new Date();
    if (formattedDate === format(today, "yyyy-MM-dd")) {
      const currentTime = `${today.getHours()}:${today.getMinutes() < 10 ? '0' : ''}${today.getMinutes()}`;
      console.log(today, "currentTime")
      availableTimes = availableTimes.filter((time: any) => {
        const [hours, minutes] = time.split(":");
        const formattedTime = `${parseInt(hours)}:${parseInt(minutes) < 10 ? '0' : ''}${parseInt(minutes)}`;

        return formattedTime >= currentTime;
      });

    }

    return availableTimes;
  };
  const formatAvailableTimes: any = (times: string[], date: Date) => {
    return times.map(time => {
      console.log("times", time);
      const [hours, minutes] = time.split(":");
      const newDate = new Date(date);
      newDate.setHours(parseInt(hours), parseInt(minutes));
      return newDate;
    });
  };
  const handleDateChange = (date: any, setFieldValue: any) => {
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm");
    setFieldValue("AppointmentDate", formattedDate);
    const availableTimes = getAvailableTimes(date);
    const formattedTimes: any = formatAvailableTimes(availableTimes, date);

    setAvailableTimes(formattedTimes);
  };

  // useEffect(() => {
  //   if (!isActive && logUserObject) {
  //     localStorage.removeItem('userData');
  //     alert('Your session has expired. Please login again.');
  //     navigate('/');
  //   } else if (!isIdle) {
  //     localStorage.removeItem('userData');
  //     alert('Your session has expired. Please login again.');
  //     navigate('/');
  //   }
  // }, [logUserObject, isActive, navigate, isIdle]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
      (day % 10 === 2 && day !== 12) ? 'nd' :
        (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

    return `${day}${suffix} ${month} ${year}`;
  };
  const encodeAppId = (input: string): string => {
    return btoa(input);
  }
  useEffect(() => {
    const getExistingUser = async () => {
      if (location?.state?.patientId || appDecId) {
        // const getPatientDetails = await userService().ListPatientAppointmentsById(location?.state?.patientId);
        const getPatientDetails = await userService().ListPatientAppointmentsById(location?.state?.patientId, appDecId);

        console.log({ getPatientDetails })

        getPatientDetails && setInputDetails(getPatientDetails[0]);
        setUpdateDoctor(true);
      }
    };
    getExistingUser();
  }, [location?.state?.patientId, appDecId]);

  const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    const [date, time] = values?.AppointmentDate.split('T');
    let userResponse: any;
    console.log("values", values);

    try {
      let newId;
      const appIDResponse = await PaymentServices().generateAppointmentID();
      console.log("appIDResponse", appIDResponse);

      if (appIDResponse.status === 400) {
        newId = "CIT001";
      } else if (appIDResponse.status === 200) {
        const generatedID = appIDResponse.data?.AppointmentID;
        const prefix = generatedID.slice(0, 5);
        const numericPart = generatedID.slice(5);
        const newNumericPart = (parseInt(numericPart, 10) + 1).toString().padStart(3, '0');
        newId = prefix + newNumericPart;
      }
      console.log("newId", newId);

      const GetInsertData = {
        PatientName: values?.PatientName,
        patientID: logUserObject?.Role === "Patient" ? logUserObject?.userId : null,
        PhoneNumber: values?.PhoneNumber,
        DoctorID: values?.DoctorID,
        EmailId: values?.EmailId,
        Message: values?.Message,
        AppointmentDate: date,
        AppointmentTime: time,
        PaymentMode: values?.PaymentMode,
        Amount: values?.Amount,
        Address: values?.Address,
        City: values?.City,
        Street: values?.Street,
        pinCode: values?.pinCode,
        DOB: values?.DOB,
        HouseNumber: values?.HouseNumber,
        MaritalStatus: values?.MaritalStatus,
        userType: values?.userType,
        KYC: values?.KYC,
        AppointmentID: newId,
        PaymentStatus: values?.PaymentMode === 'online' ? '1' : '0'
      };
      console.log("GetInsertData", GetInsertData);
      if (values?.PaymentMode === 'offline') {
        // Create appointment for offline payment mode
        userResponse = await userService().getcreateAppointment(GetInsertData);
        // const appDate = formatDate(userResponse?.data?.createPatientAppointment?.AppointmentDate);
        console.log("userResponse", userResponse);
      } else if (values?.PaymentMode === 'online') {
        // Handle online payment process with Razorpay
        const response: any = await handlePay(values, setSubmitting, resetForm, GetInsertData);
        console.log("response", response);
      }
      if (userResponse?.status === 200) {
        const appDate = formatDate(userResponse?.data?.createPatientAppointment?.AppointmentDate);
        const appointID = encodeAppId(userResponse?.data?.createPatientAppointment?.AppointmentID);
        console.log("appDate", appDate)
        const SMSresponse: any = await Users().generateEmailSMS(
          userResponse?.data?.createPatientAppointment?.EmailId,
          `<html><body><div>Welcome to Dental Hospitals!<br /><br />Thank you for contacting us. Your appointment is booked successfully for ${appDate},${userResponse?.data?.createPatientAppointment?.AppointmentTime}. We appreciate your interest and will get back to you shortly.<br/>Please <a href='${process.env.REACT_APP_HOST}PatientAppointment/${appointID}'>click here</a> to re-schedule your appointment.<br/>Click here to cancel your appointment <a href='${process.env.REACT_APP_HOST}appointmentHistory/${appointID}'>click here</a><br/><br />Best Regards,<br />Dental Hospitals Team</div></body></html>`,
          'Dental Hospitals, Patient Appointment',
          'Your appointment was created successfully'
        );
        console.log("SMSresponse", SMSresponse)
        const MobileSMSResponse = await Users().generateMobileMsg(
          "+91" + userResponse?.data?.createPatientAppointment?.PhoneNumber,
          `Thank you, your appointment is booked successfully on ${appDate},${userResponse?.data?.createPatientAppointment?.AppointmentTime}. Please click here to re-schedule your appointment: ${process.env.REACT_APP_HOST}PatientAppointment/${appointID}. Click here to cancel your appointment: ${process.env.REACT_APP_HOST}appointmentHistory/${appointID}`,
          'Your appointment was created successfully'
        );

        if (SMSresponse && MobileSMSResponse) {
          alert("Patient Appointment Booked successfully!");
        }
        resetForm();
      } else {
        console.error("Error in BookAppointment:", userResponse);
      }
      setSubmitting(false);
      if (logUserObject?.Role === 'Patient') {
        navigate('/AppointmentList');
      } else {
        navigate('/');
      }
    }
    catch (error) {
      console.error('Error during form submission:', error);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePayment = async (options: any) => {
    console.log("options", options);

    if (!(window as any).Razorpay) {
      console.error("Razorpay is not loaded");
      return;
    }
    try {
      const rzp = new (window as any).Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error in Razorpay payment:", error);
    }
  };
  const handlePay = async (values: any, setSubmitting: any, resetForm: any, GetInsertData: any) => {

    console.log('values', values);
    // if (logUserObject?.accessToken) {
    try {
      const options: any = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: 50000,
        currency: 'INR',
        name: 'Gurram Dental Care',
        description: 'Course Description',

        handler: async (response: any) => {
          // alert('Payment successful!');
          const razorpay_payment_id = response;
          console.log('razorpay_payment_id', response?.razorpay_payment_id);
          setStorePaymentDetails(response?.razorpay_payment_id);
          // console.log("response", { response, storePaymentDetails })
          // Create appointment for online payment
          const userResponse = response?.razorpay_payment_id && await userService().getcreateAppointment(GetInsertData);

          // create payment data
          const paymentData = {
            appointmentId: userResponse?.data?.createPatientAppointment?.id,
            razorpay_payment_id: response?.razorpay_payment_id,
            Amount: userResponse?.data?.createPatientAppointment?.Amount,
          }
          const PyResponse = await await PaymentServices().PaymentDetails(paymentData);
          console.log("PyResponse", PyResponse)
          const appDate = formatDate(userResponse?.data?.createPatientAppointment?.AppointmentDate)
          const appEncId = await PaymentServices().encodeBase64(userResponse?.data?.createPatientAppointment?.AppointmentID)
          if (userResponse?.status === 200) {

            const SMSresponse: any = await Users().generateEmailSMS(
              userResponse?.data?.createPatientAppointment?.EmailId,
              `<html><body><div>Welcome to Gurram Dental Hospitals!<br /><br />Thank you for contacting us.your appointment booked succesfully ${appDate},${userResponse?.data?.createPatientAppointment?.AppointmentTime}. We appreciate your interest and will get back to you shortly.<br/>Please <a href='${process.env.REACT_APP_HOST}appointment/${appEncId}'>click here</a> to Re-Schedule your Appointment<br />click here to cancle your Appointmnet <a href='${process.env.REACT_APP_HOST}appointmentHistory/${appEncId}'>click here</a><br/><br />Best Regards,<br />Gurram Dental Hospital Team</div></body></html>`,
              'Gurram Dental Hospitals,Patient Appointment',
              'Your appointment created successfully'
            );

            const MobileSMSResponse = await Users().generateMobileMsg(

              "+91" + userResponse?.data?.createPatientAppointment?.PhoneNumber,
              `Thank you, your appointment booked successfully at${appDate},${userResponse?.data?.createPatientAppointment?.AppointmentTime}. Please click here to Re-Schedule your Appointment ${process.env.REACT_APP_HOST}appointment/${appEncId} click here to cancle your Appointmnet${process.env.REACT_APP_HOST}appointmentHistory/${appEncId}`,
              'Your appointment created successfully'

            )
            if (SMSresponse && MobileSMSResponse) {
              alert("Patient Appointment Booked successfully!");
            }
            resetForm();
          }
          // const PyResponse = await PaymentServices().PaymentDetails(paymentData);
          // console.log("PyResponse", PyResponse)
          if (logUserObject.accessToken) {
            navigate("/AppointmentList");
          }
        },
        prefill: {
          email: 'Gurram Dental Care',
          contact: '9848022338',
        },
        notes: {
          address: 'Gurram Dental Care',
        },
        theme: {
          color: '#528FF0',
        },
      };

      handlePayment(options);
      // }
    } catch (error) {
      console.error('Error payment:', error);
    }
    // } else {
    //   navigate("/")
    // }
  };

  const fields = [
    { name: 'pastHealthConditions', label: 'Past Health Conditions' },
    { name: 'foodAllergies', label: 'Allergies' },
    { name: 'CurrentMedications', label: 'Current Medications' },
    { name: 'ChronicIllnesses', label: 'Chronic Illnesses' },
    { name: 'SmokingStatus', label: 'Smoking Status' },
    { name: 'AlcoholConsumption', label: 'Alcohol Consumption' },
    { name: 'sugar', label: 'Diabetes' },
    { name: 'bloodPressure', label: 'bloodPressure' },
    { name: 'heartAttack', label: 'heartAttack' },
    { name: 'pregnacy', label: 'pregnacy' }
  ];
  // console.log('UserDetails', UserDetails)
  const initialState = {
    PatientName: UserDetails ? UserDetails?.FirstName : "",
    patientID: 'NA',
    PhoneNumber: UserDetails ? UserDetails?.PhoneNumber : "",
    DoctorID: '',
    EmailId: UserDetails ? UserDetails?.EmailId : "",
    Message: '',
    AppointmentDate: '',
    AppointmentTime: '',
    PaymentMode: '',
    PaymentStatus: '0',
    Amount: process.env.REACT_APP_APPOINTMENT_AMOUNT,
    Address: '',
    City: UserDetails ? UserDetails?.City : "",
    Street: UserDetails ? UserDetails?.Street : "",
    pinCode: UserDetails ? UserDetails?.pinCode : "",
    DOB: UserDetails ? UserDetails?.DOB : "",
    HouseNumber: '',
    MaritalStatus: '',
    userType: (logUserObject && logUserObject?.Role === "Patient") ? 'R' : 'G',
    KYC: [{
      pastHealthConditions: '0',
      foodAllergies: '0',
      CurrentMedications: '0',
      ChronicIllnesses: '0',
      SmokingStatus: '',
      AlcoholConsumption: '',
      heardFrom: '',
      pastHealthConditionsDescription: '',
      foodAllergiesDescription: '',
      CurrentMedicationsDescription: '',
      ChronicIllnessesDescription: '',
      sugar: '',
      bloodPressure: '',
      heartAttack: '',
      pregnacy: '',
    }]
  };

  return (
    <footer className="ftco-footer ftco-bg-dark ftco-section mtop">
      {/* <div className="container"> */}
      <div>
        <div className="row ftresponsive">
          <div className="col-md-1"></div>

          <div className="col-md-2 ftr-block">
            {/* <div className="ftco-footer-widget mb-4 ml-md-5"> */}
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">SITE LINKS</h2>
              <ul className="list-unstyled">
                <li>
                  <a href="javascript:void(0);" onClick={() => { navigate('/AboutUs'); window.scrollTo(0, 0); }} className="py-2 d-block">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" onClick={() => { navigate('/gallery'); window.scrollTo(0, 0); }} className="py-2 d-block">
                    Gallery
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" onClick={() => { navigate('/Doctors#Doctors'); window.scrollTo(0, 0); }} className="py-2 d-block">
                    Doctors
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" onClick={() => { navigate('/Blogs'); window.scrollTo(0, 0); }} className="py-2 d-block">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" onClick={() => { navigate('/ContactUs'); window.scrollTo(0, 0); }} className="py-2 d-block">
                    Contact
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" onClick={() => { navigate('/termsConditions'); window.scrollTo(0, 0); }} className="py-2 d-block">
                    Terms and Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 ftr-block">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">SERVICES</h2>

              {/* <ul className="list-unstyled">
                {
                  services?.map((service: any) => (
                    <li key={service?.id}>
                      <a href="javascript:void(0);" className="py-2 d-block" onClick={() => handleNavigation(service)}>
                        {service?.Title}
                      </a>
                    </li>
                  ))
                }
              </ul> */}
              <ul className="list-unstyled">
                {
                  services?.map((service: any) => (
                    <li key={service?.id}>
                      <a href="javascript:void(0);" className="py-2 d-block" onClick={() => handleNavigation(service)}>
                        {service?.Title.charAt(0).toUpperCase() + service?.Title.slice(1)}
                      </a>
                    </li>
                  ))
                }
              </ul>



              {/* <ul className="list-unstyled">
    <li>
    <a href="javascript:void(0);" className="py-2 d-block" onClick={handleNavigation}>
    Teeth Whitening
    </a>
    </li>
    <li>
    <a href="javascript:void(0);" className="py-2 d-block" onClick={handleNavigation}>
    Teeth Cleaning
    </a>
    </li>
    <li>
    <a href="javascript:void(0);" className="py-2 d-block" onClick={handleNavigation}>
    Tooth Braces
    </a>
    </li>
    <li>
    <a href="javascript:void(0);" className="py-2 d-block" onClick={handleNavigation}>
    Quality Brackets
    </a>
    </li>
    <li>
    <a href="javascript:void(0);" className="py-2 d-block" onClick={handleNavigation}>
    Dental Implants
    </a>
    </li>
    </ul> */}
            </div>
          </div>
          <div className="col-md-2 ftr-block">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Review US</h2>
            </div>

            <ul className="ftco-footer-social list-unstyled float-md-left float-lft">
              <li className="ftco-animate">
                <a href="javascript:void(0);">
                  <span className="icon-twitter"></span>
                </a>
              </li>
              <li className="ftco-animate">
                <a href="javascript:void(0);">
                  <span className="icon-linkedin"></span>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 ftr-block">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Social Media</h2>
              <ul className="ftco-footer-social list-unstyled float-md-left float-lft">
                <li className="ftco-animate">
                  <a href="javascript:void(0);">
                    <span className="icon-facebook"></span>
                  </a>
                </li>
                <li className="ftco-animate">
                  <a href="javascript:void(0);">
                    <span className="icon-whatsapp"></span>
                  </a>
                </li>
                <li className="ftco-animate">
                  <a href="javascript:void(0);">
                    <span className="icon-instagram"></span>
                  </a>
                </li>
                <li className="ftco-animate">
                  <a href="javascript:void(0);">
                    <span className="icon-youtube"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 ftr-block">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2" style={{ marginBottom: '47px' }}>STAY IN TOUCH</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <span className="icon icon-map-marker"></span>
                    <span className="text">
                      Gurram Dental Care, ZP Center, Jammi Banda Rd, Khammam, Telangana 507002
                    </span>
                  </li>
                  <li>
                    <span onClick={() => window.location.href = "tel:+919949533357"}>
                      <span className="icon icon-phone"></span>
                      <span className="text">+91 9949533357</span>
                    </span>
                  </li>
                  <li>
                    <span style={{ overflowWrap: 'anywhere' }} onClick={() => window.location.href = 'mailto:contactus@gurramdentalcare.com'}>
                      <span className="icon icon-envelope"></span>
                      <span className="text">contactus@gurramdentalcare.com</span>
                    </span>
                  </li>

                  <div className='rateFAQ'> <li style={{ listStyleType: 'none', marginTop: '0px' }}>


                    <a href="javascript:void(0);" onClick={() => navigate('/rating')} className="py-2 d-block">
                      <i className="fa fa-star" aria-hidden="true"></i>  <span>Rate Us</span>
                    </a>
                  </li>
                    <li style={{ listStyleType: 'none', marginTop: '0px', marginLeft: '40px' }}>
                      <span></span><a href="javascript:void(0);" onClick={() => {
                        window.scrollTo(0, 400);
                        navigate('/Doctors#faq');
                      }} className="py-2 d-block">
                        <span>FAQs</span>
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-12">
            <div className="footer-bottom">
              <div className="container clearfix">
                <div className="copyright-text" style={{ textAlign: 'center' }}>
                  <p>&copy; Copyright 2024. All Rights Reserved by &nbsp;
                    <span onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                      ClusterIT Solutions</span></p>
                </div>
              </div>
            </div>
          </div>



          <div className="modal fade" id="modalRequest" tabIndex={-1} role="dialog"
            aria-labelledby="modalRequestLabel" aria-hidden="true"
            style={{ display: showModal ? 'block' : 'none' }}
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="modal-dialog" role="document">
                  <div className="modal-content modalwidth">
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalRequestLabel">Make an Appointment</h5>
                      {successMessage && <div className="text-success mt-2">{successMessage}</div>}
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Formik
                        enableReinitialize
                        // initialValues={logUserObject?.userId ? InputDetails : initialState}
                        initialValues={initialState}
                        validationSchema={AppointmnetValidationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          handleSubmit(values, { setSubmitting, resetForm });
                          resetForm();
                          setShowModal(false);
                        }}
                      >
                        {({ isSubmitting, values, setFieldValue, handleChange, setSubmitting, resetForm }) => (
                          <Form onChange={() => validateForm(values)} autoComplete='off'>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <Field
                                    type="text"
                                    name="PatientName"
                                    className="form-control"
                                    placeholder="Name*" autoComplete="new-name"
                                    readOnly={logUserObject?.Role === "Patient" ? true : false}
                                  />
                                  <ErrorMessage name="PatientName" component="div" className="text-danger" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <Field
                                    type="text"
                                    name="PhoneNumber"
                                    className="form-control"
                                    placeholder="Phone Number*"
                                    readOnly={logUserObject?.userId && logUserObject?.Role === "Patient" ? true : false}
                                  />
                                  <ErrorMessage name="PhoneNumber" component="div" className="text-danger" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <Field
                                    type="email"
                                    name="EmailId"
                                    className="form-control"
                                    placeholder="Email"
                                  />
                                  {/* <ErrorMessage name="EmailId" component="div" className="text-danger" /> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <Field
                                    as="select"
                                    name="DoctorID"
                                    className="form-control selectOption"
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      handleDoctorChange(e);
                                    }}
                                  >
                                    <option value="" label="Select a dentist*" />
                                    {doctors.map((doctor: any) => (
                                      <option key={doctor.id} value={doctor.id}>
                                        {doctor.FirstName} {doctor.LastName}
                                      </option>
                                    ))}
                                  </Field>
                                  {/* <ErrorMessage name="DoctorID" component="div" className="text-danger" /> */}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div style={{ width: '193%' }}>
                                    <DatePicker
                                      selected={values.AppointmentDate ? new Date(values.AppointmentDate) : null}
                                      onChange={(date) => handleDateChange(date, setFieldValue)}
                                      minDate={new Date()}
                                      maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                                      filterDate={isDateAvailable}
                                      dateFormat="yyyy-MM-dd'T'HH:mm"
                                      placeholderText="Select your appointment date and time *"
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={timeSlot}
                                      timeCaption="time"
                                      includeTimes={availableTimes}
                                      name="AppointmentDate"
                                      autoComplete="off"
                                      className='form-control'
                                    />
                                  </div>
                                  <ErrorMessage name="AppointmentDate" component="div" className="errMsg" />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <Field
                                    as="textarea"
                                    name="Message"
                                    className="form-control"
                                    placeholder="Describe Your Problem"
                                    style={{ resize: 'none' }} />
                                  {/* <ErrorMessage name="Message" component="div" className="text-danger" /> */}
                                </div>
                              </div>
                              <label htmlFor="Address" className="form-label selectOption">Address</label>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <Field
                                    type="text"
                                    name="City"
                                    className="form-control"
                                    placeholder="City" autoComplete="new-city"
                                  />
                                  {/* <ErrorMessage name="City" component="div" className="text-danger" /> */}
                                </div>
                              </div>
                              {/* <div className="col-md-6">
    <div className="form-group">
    <Field
    type="text"
    name="Street"
    className="form-control"
    placeholder="Street"
    />
    <ErrorMessage name="Street" component="div" className="text-danger" />
    </div>
    </div> */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <Field
                                    type="text"
                                    name="pinCode"
                                    className="form-control"
                                    placeholder="PinCode" autoComplete="new-pincode"
                                  />
                                  {/* <ErrorMessage name="pinCode" component="div" className="text-danger" /> */}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <Field
                                    type="text"
                                    name="Address"
                                    className="form-control"
                                    placeholder="Enter your Street,Area,RoadNo.,Building Name" autoComplete="new-address"
                                  />
                                  {/* <ErrorMessage name="Address" component="div" className="text-danger" /> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="Date of Birth" className="selectOption">Date of Birth</label>
                                  <Field
                                    type="date"
                                    name="DOB"
                                    className="form-control selectOption"
                                    placeholder="Date of Birth*"
                                  />
                                  {/* <ErrorMessage name="DOB" component="div" className="text-danger" /> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group" style={{ marginTop: '30px' }}>
                                  <Field
                                    as="select"
                                    name="MaritalStatus"
                                    className="form-control selectOption"
                                    value={values?.MaritalStatus}
                                    onChange={(e: any) => setFieldValue("MaritalStatus", e?.target?.value)}
                                  >
                                    <option value="" label="Select marital status" />
                                    <option value="Single" label="Single" />
                                    <option value="Married" label="Married" />
                                    <option value="Divorced" label="Divorced" />
                                    <option value="Widowed" label="Widowed" />
                                  </Field>
                                  {/* <ErrorMessage name="MaritalStatus" component="div" className="text-danger" /> */}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="KYC" className="form-label selectOption">KYC</label>


                              <FieldArray name="KYC" >
                                {() => (
                                  <div className="row"> <br />
                                    {fields?.map((field, index) => {

                                      const fieldName = field?.name;
                                      const descriptionFieldName = `${fieldName}Description`;

                                      // Type definition for KYCField
                                      type KYCField = 'pastHealthConditions' | 'foodAllergies' | 'CurrentMedications' | 'ChronicIllnesses' | 'SmokingStatus' | 'AlcoholConsumption' | 'sugar' | 'bloodPressure' | 'heartAttack' | 'pregnacy';

                                      // Handle rendering for SmokingStatus and AlcoholConsumption as radio buttons
                                      if (['SmokingStatus', 'AlcoholConsumption', 'sugar', 'bloodPressure', 'heartAttack', 'pregnacy'].includes(fieldName)) {
                                        return (


                                          <div key={index} className="col-md-6" style={{ top: '20px' }}>
                                            <label className="selectOption">{field?.label}</label> <br />
                                            <label className="selectOption" style={{ marginRight: '10px' }}>
                                              <Field
                                                type="radio"
                                                name={`KYC[0].${fieldName}`}
                                                value="1"
                                                checked={String(values.KYC?.[0]?.[fieldName as KYCField]) === '1'}
                                                onChange={() => setFieldValue(`KYC[0].${fieldName as KYCField}`, '1')}
                                                className="mr-1 selectOption" />
                                              Yes
                                            </label>
                                            <label className="selectOption">
                                              <Field
                                                type="radio"
                                                name={`KYC[0].${fieldName}`}
                                                value="0"
                                                checked={String(values.KYC?.[0]?.[fieldName as KYCField]) === '0'}
                                                onChange={() => setFieldValue(`KYC[0].${fieldName as KYCField}`, '0')}
                                                className="mr-1 selectOption" />
                                              No
                                            </label>
                                          </div>

                                        );
                                      }

                                      return (
                                        <div key={index} className='col-md-12'>
                                          <label className="selectOption">
                                            <Field
                                              type="checkbox"
                                              style={{ width: '15px', height: '15px', display: 'inline', marginRight: '10px', marginTop: '20px' }}
                                              name={`KYC[0].${field?.name}`}
                                              checked={String(values.KYC?.[0]?.[field?.name as KYCField]) === '1'}
                                              onChange={() => {
                                                const newValue = String(values.KYC?.[0]?.[field?.name as KYCField]) === '1' ? '0' : '1';
                                                setFieldValue(`KYC[0].${field?.name as KYCField}`, newValue);
                                              }}
                                            />
                                            {field?.label}
                                          </label>
                                          {String(values.KYC?.[0]?.[field?.name as KYCField]) === '1' && !['SmokingStatus', 'AlcoholConsumption']?.includes(fieldName) && (
                                            <div>
                                              {/* <label htmlFor={`KYC[0].${descriptionFieldName}`}>
    Description for {field?.label}</label><br/> */}
                                              <Field
                                                as="textarea"
                                                name={`KYC[0].${descriptionFieldName}`}
                                                placeholder={`Enter description for ${field?.label}`}
                                                rows={5}
                                                style={{ resize: 'none', width: '100%' }}
                                                className="selectOption" />
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </FieldArray>

                            </div>

                            <div className="mb-3">
                              <label htmlFor="PaymentMode" className="form-label selectOption">Make Payment</label>
                              <div className="d-flex">
                                <div className="form-check me-5">
                                  <Field
                                    type="radio"
                                    name="PaymentMode"
                                    value="offline"
                                    className="form-check-input"
                                    id="offline"
                                    onClick={() => {
                                      setShowRazorpay(false);
                                      setMakepayment(false)
                                    }}
                                    disabled={!isFormComplete}// Disable if form is incomplete
                                  />
                                  <label className="form-check-label" htmlFor="offline">
                                    Offline
                                  </label>
                                </div>
                                <div className="form-check me-3">
                                  <Field
                                    type="radio"
                                    name="PaymentMode"
                                    value="online"
                                    className="form-check-input"
                                    id="online"
                                    onClick={() => {
                                      setShowRazorpay(true);
                                      setMakepayment(true)
                                    }}
                                    disabled={!isFormComplete}
                                  />
                                  <label className="form-check-label" htmlFor="online">
                                    Online
                                  </label>
                                </div>
                                {/* {showRazorpay && !isSubmitting && (
    <div id="razorpay-container">
    <button onClick={() => {
    handlePay(values, setSubmitting, resetForm)
    }} type="button" className="btn btn-primary">
    Make Payment Here
    </button>
    </div>
    )} */}
                              </div>
                              {/* <ErrorMessage name="PaymentMode" component="div" className="errMsg" /> */}
                            </div>

                            {values.PaymentMode === 'offline' && (
                              <div className="form-group">
                                <Field
                                  type="number"
                                  name="Amount"
                                  className="form-control"
                                  placeholder="Enter amount*"
                                  value={process.env.REACT_APP_APPOINTMENT_AMOUNT}
                                // value='500' 
                                />
                                {/* <ErrorMessage name="Amount" component="div" className="text-danger" /> */}
                              </div>
                            )}

                            {values.PaymentMode === 'online' && (
                              <div className="form-group">
                                <Field
                                  type="number"
                                  name="Amount"
                                  className="form-control"
                                  placeholder="Amount"
                                  value={process.env.REACT_APP_APPOINTMENT_AMOUNT}
                                  // value='500' // Set a fixed value or dynamically calculate it if needed
                                  disabled
                                />
                                {/* <ErrorMessage name="Amount" component="div" className="text-danger" /> */}
                              </div>
                            )}

                            <button type="submit" className="btn btn-primary">
                              {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                            {ErrMsgMatch && <div className="text-danger mt-2">{ErrMsgMatch}</div>}
                            {errMsg && <div className="text-danger mt-2">{errMsg}</div>}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;


