import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import userService from '../../Services/user/user.service';
import { TextField, Button, Select, MenuItem, Switch, FormControlLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import HomeIcon from '@mui/icons-material/Home';
import { updDelFile } from '../shared/fileupload';
import Sidebar from '../DashBoard/sidebar';
import { Helmet } from 'react-helmet';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';

interface Doctor {
  ShowInTeam: string;
  PhoneNumber: string;
  EmailId: string;
  Specialization: string;
  id: string;
  FirstName: string;
  LastName: string;
  Status: string;
}

const ListDoctor = () => {

  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [doctorList, setDoctorList] = useState<Doctor[]>([]);
  const [specializationtList, setspecializationtList] = useState<Doctor[]>([]);
  const logUser: any = localStorage.getItem('userData');
  const logUserObject = JSON.parse(logUser);  
  // useAuthRedirect(logUserObject);

  useEffect(() => {
    if (!logUserObject?.accessToken) {
      navigate('/ListPatient');
    }
  }, [navigate, logUserObject]);
  const getspec = async () => {
    const response = await userService().ListSpecializations('Doctor') as any;
    setspecializationtList(response);
  };

  // const getDoctors = async () => {
  //   const response = await userService().ListDoctor('Doctor') as any;
  //   // console.log("response", response)
  //   const specializationtList = await userService().getlistSpecializations() as any;
  //   response?.map(async (item: any, index: number) => {
  //     const specName = specializationtList?.find((spec: any) => spec?.id === item?.Specialization);
  //     item.Specialization = specName?.name
  //   })
  //   setDoctorList(response);
  //   // console.log("response", response)
  // };


  const getDoctors = async () => {
    const response = await userService().ListDoctor('Doctor') as any;
    const specializationtList = await userService().getlistSpecializations() as any;
    setDoctorList(response);
    const updatedResponse = await Promise.all(response.map(async (item: any) => {
      const specName = specializationtList?.find((spec: any) => spec?.id === item?.Specialization);
      return {
        ...item,
        Specialization: specName?.name || item?.Specialization // Use the name if found, otherwise keep the ID
      };
    }));
    setDoctorList(updatedResponse);
  };

  useEffect(() => {
    getDoctors();
    getspec();
  }, []);

  const handleDelete = async (id: string) => {
    const confirmBox = window.confirm("Are you sure you want to delete the selected doctor?");
    if (confirmBox) {
      const user: any = await userService().getuserSingle(id);
      const filedel = await updDelFile(user?.data?.getUser?.userProfile, "images");
      if (filedel?.status === 200) {
        await userService().deleteDoctor(id);
        getDoctors();
      }
      // await userService().deleteDoctor(id);
      // getDoctors();
    } else {
      alert('Deletion canceled.');
    }
  };


  const highlightText = (text: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text?.split(regex);
    return parts?.map((part, index) => (regex?.test(part) ? <span key={index} className="highlighted-text">{part}</span> : part));
  };

  const filteredData = doctorList?.filter((doctor: Doctor) =>
    Object?.values(doctor)?.some((value: any) => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
  );

  const totalPages = Math?.ceil(filteredData?.length / pageSize);
  const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleSearch = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    console.log(logUserObject?.Role)
    setSearchTerm('');
  }, [currentPage]);

  async function handleEdit(event: any) {
    console.log("event", event);
    navigate('/docRegister', { state: { id: event?.id } });
  }

  const handleSubmit = () => {
    navigate('/docRegister');
  }

  const handleStatusToggle = async (rowData: any) => {
    const updatedStatus = rowData?.Status === "1" ? "0" : "1";
    const updatedRowData = { ...rowData, id: rowData?.id, Status: updatedStatus };
    // console.log("updatedRowData", updatedRowData);
    const update = await userService().updateDoctorByData(updatedRowData);
    setDoctorList((prevRows) =>
      prevRows?.map((doctor) =>
        doctor?.id === rowData?.id ? { ...doctor, Status: updatedStatus } : doctor
      )
    );
  };


  const handleShowteam = async (rowData: any) => {
    const updatedStatus = rowData?.ShowInTeam === "1" ? "0" : "1";
    const updatedRowData = { ...rowData, id: rowData?.id, ShowInTeam: updatedStatus };
    const update = await userService().updateDoctorByData(updatedRowData);
    setDoctorList((prevRows) =>
      prevRows?.map((doctor) =>
        doctor?.id === rowData?.id ? { ...doctor, ShowInTeam: updatedStatus } : doctor
      )
    );
  };
  useEffect(() => {
    if (!logUserObject?.accessToken) {
        navigate('/login');
    }
}, [navigate]);


  return (
    <>
      <Helmet>
        <title>Doctors</title>
        <meta name="description" content="This is the Doctors page description" />
      </Helmet>
      <section className="admin-section">
        <div
          className="slider-item bread-item"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h1>
                  Doctors
                </h1>

              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <ul className="paginationbanner">
          <li>
          <span className="aColor" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Home  /&nbsp;</span>
          </li>
          <li>Doctors</li>
        </ul>
      </div>


      <div className="container ">
        <div className="row">
          {/* <div className="col-md-2">
            <Sidebar />
          </div> */}
          <div className="col-md-12" >
            <div className="row">
              <div className="col-md-8">
                {/* <h1 className="mt-100 textAlign">Doctor List</h1> */}

              </div>
              <div className="col-md-3 textAlign">
              </div>
              <div className="col-md-1"></div>
            </div>
              {/* 30-09-24 */}
            <div className="row  tablefs">
              <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div className="page-size-dropdown">

                  <label style={{ padding: '5px' }}>Show</label>
                  <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                  <label style={{ padding: '5px' }}>entries</label>
                </div>
  {/* 30-09-24 */}
                <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                  {logUserObject?.Role === "MasterAdmin" ? (<div className="pb-3">
                    <Button
                      onClick={() => handleSubmit()}
                      style={{ marginRight: '10px', float: 'right' }}
                      // startIcon={<EditIcon style={{ color: 'green' }}  />}
                      className='btn btn-primary'
                    >
                      Add
                    </Button>
                  </div>) : null}
                  <p style={{ marginRight: '10px' }}>Search</p>
                  <div>
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="search-bar"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12" style={{overflowX:'auto'}}>
                {/* <table className="table table-bordered table-striped table-hover listDoctors" > */}
                <table className="table table-bordered table-striped table-responsive table-hover listDoctors">
                  <thead>
                    <tr>
                    
                      <th className='sno'>S.No</th>
                      <th className='mdl'>Doctor Name</th>
                      <th className='mdl'>Specialization</th>
                      <th className='mdl'>Phone Number</th>
                      <th className='mdl'>Email Id</th>
                      <th className='actions'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((doctor: Doctor, index: number) => (
                      <tr key={doctor?.id} className="highlighted-row">
                        {/* <td>{index + 1}</td> */}
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{highlightText(`${doctor?.FirstName} ${doctor?.LastName}`)}</td>
                        <td>{highlightText(doctor?.Specialization)}</td>
                        <td>{highlightText(doctor?.PhoneNumber)}</td>
                        <td>{highlightText(doctor?.EmailId)}</td>
                        <td className='actions'>

                          <Button
                            onClick={() => handleEdit(doctor)}
                            title='Edit'
                            startIcon={<EditIcon style={{ color: 'green' }} />}
                          >
                          </Button>
                         
                     
                          <Button title="Active/Inactive Doctor"
                            onClick={() => handleStatusToggle(doctor)}
                            className={doctor?.Status === '1' ? 'statusSuccess' : 'statusDanger'}
                            startIcon={<VisibilityIcon />}
                          >
                          </Button>

                          <Button title="Show/Hide Doctor"
                            onClick={() => handleShowteam(doctor)}
                            className={doctor?.ShowInTeam === "1" ? 'statusSuccess' : 'statusDanger'}
                            startIcon={<HomeIcon />}
                          >
                          </Button>
                          <Button
                            onClick={() => handleDelete(doctor?.id)}
                            title='Delete'
                            startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                          >
                          </Button>
                        </td>
                  
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* 30-09-24 */}
                <div className='mttable'>
                Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                <div className="paginationstyle  mttable1">
                  <div 
                    style={{
                      cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                      marginRight: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage > 1 ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage > 1) {
                        handlePageChange(currentPage - 1);
                        window.scrollTo(0, 0); // Scroll to top
                      }
                    }}
                    className={currentPage === 1 ? 'disabled' : ''}
                  >
                    Previous
                  </div>
                  <div className="pageNumb">{currentPage}</div>
                  <div
                    style={{
                      cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                      marginLeft: '10px',
                      borderBottom: '1px solid red',
                      fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                    }}
                    onClick={() => {
                      if (currentPage < totalPages) {
                        handlePageChange(currentPage + 1);
                        window.scrollTo(0, 0); // Scroll to top
                      }
                    }}
                    className={currentPage === totalPages ? 'disabled' : ''}
                  >
                    Next
                  </div>
                </div>
                </div>

              </div>


            </div>

          </div>


        </div>
      </div >
    </>
  );
};

export default ListDoctor;

