import ReportServices from "../../../Services/reportservices";
import userService from "../../../Services/user/user.service";
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import AWS from 'aws-sdk';
import { SendFile, updDelFile } from "../../../views/shared/fileupload";
import FileType from "../../../views/Reports/fileType";
import PrescriptionServices from "../../../Services/prescriptionServices";
import { FormatFile } from "../../Fileformat";

const PrescriptionUtils: any = () => {
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);

    const removeFields: any = (obj: any, fieldsToRemove: any) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeFields(item, fieldsToRemove));
        } else if (typeof obj === 'object' && obj !== null) {
            const newObj: any = {};
            for (const key in obj) {
                if (!fieldsToRemove.includes(key)) {
                    newObj[key] = removeFields(obj[key], fieldsToRemove);
                }
            }
            return newObj;
        }
        return obj;
    }

    const handleSubmit = async (values: any) => {
        let getUser: any;
        if (values?.patientID) {
            getUser = await userService().getuserSingle(values?.patientID);
        } else {
            getUser = await userService().getSingleAppointment(values?.appointmentID);
        }
        console.log("getUser", getUser);
        const singleUser = getUser?.data?.getUser ? getUser?.data?.getUser : getUser?.data?.getPatientAppointment;

        const updatedFile = await Promise.all(values?.file?.map(async (file: any) => {
            // Log the file object to see what it contains

            // Check if the file.name array is not empty
            if (file?.name?.length > 0) {
                // Extract name values from the file object
                const names = await Promise.all(file?.name?.map(async (file1: any) => {
                    // const updatedFileName = file1?.name.replace(/ /g, '_');
                    // const fileName = Date.now() + "_" + updatedFileName;
                    console.log("file1",file1)
                    const  newFilename = await FormatFile(file1)
                    console.log("newFilename",newFilename)
                    const s3names = await SendFile(file1, newFilename, "images");
                    console.log("Uploaded file name:", s3names);
                    return newFilename; // Return the name that is inserted into S3
                }));

                return {
                    fileType: file?.fileType,
                    type: file?.type,
                    description: file?.description,
                    name: names // Use the updated names array
                };
            }else {
                // Handle the case where file.name is empty
                console.warn("No files to upload for this file object:", file);
                return {
                    fileType: file?.fileType,
                    type: file?.type,
                    description: file?.description,
                    name: [] // Return an empty array if no files to upload
                };
            }
        }));

        // const updatedFile = await Promise.all(values?.file?.map(async (file: any) => {
        //     // Extract name values from the file object
        //     const names = await Promise.all(file?.name?.map(async (file1: any) => {
        //         const fileName = Date.now() + "_" + file1?.name;
        //         const s3names = await SendFile(file1, fileName, "images");
        //         console.log("Uploaded file name:", s3names);
        //         return fileName; // Return the name that is inserted into S3
        //     }) || []);

        //     return {
        //         fileType: file.fileType,
        //         name: names // Use the updated names array
        //     };
        // }));

        const createData = {
            patientID: values?.patientID,
            appointmentID: values?.appointmentID,
            updatedBy: logUserObject?.id,
            patientName: singleUser?.FirstName ? `${singleUser?.FirstName} ${singleUser?.LastName}` : singleUser?.PatientName,
            patientEmail: singleUser?.EmailId,
            patientPhoneNumber: singleUser?.PhoneNumber,
            Status: singleUser?.Status,
            file: updatedFile,
            foodDiet: values?.foodDiet,
            type: "prescriptions",
        }
        // console.log("createData", createData);

        const response = await PrescriptionServices().CreatePrescription(createData);
        // console.log("response in utils", response);
        return response
    }

    const prescriptionUpdateUtil = async (values: any) => {
        const getReport = await PrescriptionServices().getPrescriptionSingle(values?.id);
        let updatedArray = [];

        if (values?.file && values?.file?.length > 0) {
            updatedArray = values?.file.map((item: any) => {
                const newItem = { ...item };
                delete newItem.data; // Remove the `data` property
                return newItem;
            });
        }

        getReport.file = updatedArray;
        // getReport.file = values?.file;
        const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
        const sanitizedUserInfo = removeFields(getReport, fieldsToRemove);
        const response = await PrescriptionServices().PrescrptiontUpdate(sanitizedUserInfo);
        return response
    }

    const check = async (values: any) => {
        console.log("values at check", values);

        if (!values?.file || values?.file?.length === 0) {
            console.log("No files to process.");
            return values; // Return the original values if there are no files to process
        }

        const getReport = await PrescriptionServices().getPrescriptionSingle(values?.appointmentID);

        // Extract existing file names from the `name` array in the `file` objects
        const existingFileNames = getReport?.file?.length > 0
            ? getReport.file.flatMap((fileObject: any) => fileObject?.name || [])
            : [];

        let nonExistentFiles: any = [];

        await Promise.all(
            values?.file?.length > 0 && values?.file?.map(async (fileObject: any) => {
                // Extract file name from `name` array or use fileObject itself if it's a string
                const fileNames = fileObject?.name || [];
                await Promise.all(fileNames?.map(async (fileName: any) => {
                    AWS.config.update({
                        accessKeyId: process.env.REACT_APP_INSTANCE_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_INSTANCE_SECRET_KEY,
                        region: process.env.REACT_APP_REGION
                    });
                    const s3 = new AWS.S3();
                    let params: any;
                    if (fileName?.name) {
                        params = {
                            Bucket: process.env.REACT_APP_S3_BUCKET,
                            Key: `images/${fileName?.name}`
                        };
                    } else {
                        params = {
                            Bucket: process.env.REACT_APP_S3_BUCKET,
                            Key: `images/${fileName}`
                        };
                    }

                    try {
                        await s3.headObject(params).promise();
                        console.log(`File ${fileName} already exists in the bucket.`);
                    } catch (error: any) {
                        if (error.code === 'NotFound') {
                            console.log(`File ${fileName?.name} does not exist in the bucket, adding to upload list.`);
                            nonExistentFiles.push({ fileType: fileObject.fileType, name: fileName }); // Add the whole fileObject for later processing
                        } else {
                            console.error("Error checking file existence:", error);
                            throw error; // Re-throw the error if it's not a NotFound error
                        }
                    }
                }));
            })
        );

        let fileNames: any = [];
        if (nonExistentFiles?.length > 0) {
            fileNames = await Promise.all(
                nonExistentFiles.map(async (fileObject: any) => {
                    // const newFileName = Date.now() + "_" + fileObject?.name?.name;
                    const  newFileName = await FormatFile(fileObject?.name)
                    console.log("fileObject", {fileObject, newFileName});
                    await SendFile(fileObject?.name, newFileName, "images");
                    return { fileType: fileObject.fileType, name: newFileName };
                })
            );
        }

        const updatedFileValues = values?.file?.map((fileObject: any) => {
            const newNames = fileNames
                .filter((f: any) => f?.fileType === fileObject?.fileType)
                .map((f: any) => f?.name);
            console.log("newNames", newNames);
            return {
                ...fileObject,
                name: [...fileObject.name, ...newNames].filter(name => typeof name === 'string')
            };
        });
        values.file = updatedFileValues ? updatedFileValues : [];
        return values;
    };

    // const handleSubmit: any = async (values: any) => {
    //     let getUser: any;
    //     if (values?.patientID) {
    //         getUser = await userService().getuserSingle(values?.patientID);
    //     } else {
    //         getUser = await userService().getSingleAppointment(values?.appointmentID);
    //     }
    //     const singleUser = getUser?.data?.getUser ? getUser?.data?.getUser : getUser?.data?.getPatientAppointment;


    //     // const fileNames = await Promise.all(
    //     //     values?.file?.map(async (file: any) => {
    //     //         const updatedNames = await Promise.all(
    //     //             file?.name?.map(async (file1: any) => {
    //     //                 const fileName = Date.now() + "_" + file1?.name;
    //     //                 await SendFile(file1, fileName, "images");
    //     //                 return fileName;
    //     //             })
    //     //         );
    //     //         return {
    //     //             fileType: file?.fileType,
    //     //             name: updatedNames
    //     //         };
    //     //     })
    //     // );
    //     // console.log("fileNames", fileNames);
    //     const flattenedFileNames = await Promise.all(
    //         values?.file?.flatMap(async (file: any) => {
    //             return await Promise.all(
    //                 file?.name?.map(async (file1: any) => {
    //                     const fileName = Date.now() + "_" + file1?.name;
    //                     await SendFile(file1, fileName, "images");
    //                     return {
    //                         fileType: file?.fileType,
    //                         name: fileName
    //                     };
    //                 })
    //             );
    //         })
    //     );

    //     const flattenedFiles = flattenedFileNames.flat();

    //     const createDataRecords = flattenedFiles?.map((file) => ({
    //         patientID: values?.patientID,
    //         updatedBy: logUserObject?.userId,
    //         patientName: `${singleUser?.FirstName} ${singleUser?.LastName}` ? `${singleUser?.FirstName} ${singleUser?.LastName}` : singleUser?.PatientName,
    //         patientEmail: singleUser?.EmailId,
    //         patientPhoneNumber: singleUser?.PhoneNumber,
    //         Status: singleUser?.Status,
    //         appointmentID: values?.appointmentID,
    //         file,
    //         Prescription: values?.Prescription,
    //         type: "prescriptions"
    //     }));
    //     console.log("createDataRecords", createDataRecords);

    //     const responses = await Promise.all(
    //         createDataRecords.map(async (createData) => {
    //             const response = await PrescriptionServices().CreatePrescription(createData);
    //             console.log("response", response)
    //             return response;
    //         })
    //     );
    //     return responses;
    // }


    // const prescriptionUpdateUtil = async (values: any) => {
    //     const fieldsToRemove = ['__typename', 'createdAt', 'updatedAt'];
    //     const sanitizedUserInfo = removeFields(values, fieldsToRemove);

    //     let getUser: any;
    //     if (values?.patientID) {
    //         getUser = await userService().getuserSingle(sanitizedUserInfo?.patientID);
    //     } else {
    //         getUser = await userService().getSingleAppointment(sanitizedUserInfo?.appointmentID);
    //     }

    //     const singleUser = getUser?.data?.getUser ? getUser?.data?.getUser : getUser?.data?.getPatientAppointment;

    //     const existingReports = await ReportServices().reportsListBypatientID(sanitizedUserInfo?.appointmentID);

    //     // Map existing files by fileType and name
    //     const existingFilesMap: any = {};
    //     existingReports?.forEach((report: any) => {
    //         report?.file?.forEach((file: any) => {
    //             if (!existingFilesMap[file?.fileType]) {
    //                 existingFilesMap[file?.fileType] = new Set();
    //             }
    //             file?.name?.forEach((fileName: any) => existingFilesMap[file?.fileType].add(fileName));
    //         });
    //     });

    //     // Identify new files to be inserted and files to be updated
    //     const newFiles: any = [];
    //     const filesToUpdate: any = [];
    //     sanitizedUserInfo?.file?.forEach((file: any) => {
    //         const { fileType, name } = file;
    //         name?.forEach((fileName: any) => {
    //             let found = false;
    //             for (const [existingFileType, fileNames] of Object.entries(existingFilesMap)) {
    //                 if ((fileNames as Set<string>).has(fileName)) {
    //                     found = true;
    //                     if (existingFileType !== fileType) {
    //                         filesToUpdate.push({
    //                             // oldFileType: existingFileType,
    //                             // newFileType: fileType,
    //                             fileType: fileType ? fileType : existingFileType,
    //                             name: [fileName]
    //                         });
    //                     }
    //                     break;
    //                 }
    //             }
    //             if (!found) {
    //                 newFiles.push({
    //                     fileType,
    //                     name: [fileName]
    //                 });
    //             }
    //         });
    //     });
    //     // Handle fileType updates
    //     const updateFileTypePromises = filesToUpdate?.map(async (file: any) => {
    //         const updateData = {
    //             patientID: values?.patientID,
    //             updatedBy: logUserObject?.userId,
    //             patientName: `${singleUser?.FirstName} ${singleUser?.LastName}` ? `${singleUser?.FirstName} ${singleUser?.LastName}` : singleUser?.PatientName,
    //             patientEmail: singleUser?.EmailId,
    //             patientPhoneNumber: singleUser?.PhoneNumber,
    //             Status: singleUser?.Status,
    //             appointmentID: values?.appointmentID,
    //             file: file
    //             // oldFileType: file?.oldFileType,
    //             // newFileType: file?.newFileType,
    //             // name: file?.name
    //         };

    //         existingReports?.map(async (item: any) => {
    //             const input = {
    //                 id: item?.id,
    //                 ...updateData
    //             }
    //             const response = await ReportServices().UpdateReportFileType(input);
    //             return response;
    //         })

    //     });

    //     const updateFileTypeResponses = await Promise.all(updateFileTypePromises);

    //     if (newFiles?.length > 0) {
    //         const flattenedFileNames = await Promise.all(
    //             newFiles?.flatMap(async (file: any) => {
    //                 return await Promise.all(
    //                     file?.name?.map(async (file1: any) => {
    //                         return {
    //                             fileType: file?.fileType,
    //                             name: file1
    //                         };
    //                     })
    //                 );
    //             })
    //         );

    //         const flattenedFiles = flattenedFileNames.flat();
    //         const createDataRecords = flattenedFiles?.map((file) => ({
    //             patientID: values?.patientID,
    //             updatedBy: logUserObject?.userId,
    //             patientName: `${singleUser?.FirstName} ${singleUser?.LastName}` ? `${singleUser?.FirstName} ${singleUser?.LastName}` : singleUser?.PatientName,
    //             patientEmail: singleUser?.EmailId,
    //             patientPhoneNumber: singleUser?.PhoneNumber,
    //             Status: singleUser?.Status,
    //             appointmentID: values?.appointmentID,
    //             file
    //         }));

    //         const responses = await Promise.all(
    //             createDataRecords?.map(async (createData) => {
    //                 const response = await ReportServices().CreateReport(createData);
    //                 return response;
    //             })
    //         );
    //         return { data: responses, msg: "success" };
    //     } else {
    //         console.log("No new files to insert.");
    //         return { data: updateFileTypeResponses, msg: "File types updated." };
    //     }
    // };


    // const check = async (values: any) => {

    //     const getReport = await ReportServices().getReportSingle(values?.appointmentID);

    //     // Extract existing file names from the `name` array in the `file` objects
    //     const existingFileNames = getReport?.file?.length > 0
    //         ? getReport.file.flatMap((fileObject: any) => fileObject?.name || [])
    //         : [];

    //     let nonExistentFiles: any = [];

    //     await Promise.all(
    //         values?.file?.length > 0 && values?.file?.map(async (fileObject: any) => {
    //             // Extract file name from `name` array or use fileObject itself if it's a string
    //             const fileNames = fileObject?.name || [];
    //             await Promise.all(fileNames?.map(async (fileName: any) => {
    //                 AWS.config.update({
    //                     accessKeyId: process.env.REACT_APP_INSTANCE_ACCESS_KEY,
    //                     secretAccessKey: process.env.REACT_APP_INSTANCE_SECRET_KEY,
    //                     region: process.env.REACT_APP_REGION
    //                 });
    //                 const s3 = new AWS.S3();
    //                 let params: any;
    //                 if (fileName?.name) {
    //                     params = {
    //                         Bucket: process.env.REACT_APP_S3_BUCKET,
    //                         Key: `images/${fileName?.name}`
    //                     };
    //                 } else {
    //                     params = {
    //                         Bucket: process.env.REACT_APP_S3_BUCKET,
    //                         Key: `images/${fileName}`
    //                     };
    //                 }


    //                 try {
    //                     await s3.headObject(params).promise();
    //                     console.log(`File ${fileName} already exists in the bucket.`);
    //                 } catch (error: any) {
    //                     if (error.code === 'NotFound') {
    //                         console.log(`File ${fileName?.name} does not exist in the bucket, adding to upload list.`);
    //                         nonExistentFiles.push({ fileType: fileObject.fileType, name: fileName }); // Add the whole fileObject for later processing
    //                     } else {
    //                         console.error("Error checking file existence:", error);
    //                         throw error; // Re-throw the error if it's not a NotFound error
    //                     }
    //                 }
    //             }));
    //         })
    //     );

    //     let fileNames: any = [];
    //     if (nonExistentFiles?.length > 0) {
    //         fileNames = await Promise.all(
    //             nonExistentFiles.map(async (fileObject: any) => {
    //                 const newFileName = Date.now() + "_" + fileObject?.name?.name;
    //                 await SendFile(fileObject?.name, newFileName, "images");
    //                 return { fileType: fileObject.fileType, name: newFileName };
    //             })
    //         );
    //     }


    //     const updatedFileValues = values?.file?.map((fileObject: any) => {
    //         const newNames = fileNames
    //             .filter((f: any) => f?.fileType === fileObject?.fileType)
    //             .map((f: any) => f?.name);
    //         return {
    //             ...fileObject,
    //             name: [...fileObject.name, ...newNames].filter(name => typeof name === 'string')
    //             // name: [...fileObject?.name, ...newNames]
    //         };
    //     });

    //     // console.log("updatedFiles", updatedFileValues);
    //     // Return or use the updatedFiles as needed
    //     // };
    //     values.file = updatedFileValues;
    //     return values;
    // };


    const prescriptionDelete = async (data: any) => {
        const prescription = await PrescriptionServices().getPrescriptionSingle(data);
        const Delpromises = prescription?.file?.map(async (item: any) => {
            if (item?.name?.length > 0) {
                item?.name?.map(async (fileName: any) => {
                    const fileresp = await updDelFile(fileName, "images");
                    if (fileresp?.status === 200) {
                        const delRep = await PrescriptionServices().PrescriptionDelete(prescription?.id);
                        console.log("delRep", delRep);
                    }
                })
            } else {
                const delRep = await PrescriptionServices().PrescriptionDelete(prescription?.id);
            }
        });
        return Delpromises;
    }

    return {
        handleSubmit,
        prescriptionUpdateUtil,
        prescriptionDelete,
        check,
    }
}
export default PrescriptionUtils;