import React, { useState, useEffect, Suspense } from "react";
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from "react-router-dom";
import ListTermsAndConditions from '../../Services/pageServices';
import Loader from "../shared/Loader";
import pages from "../../utills/pageUtils";
import "./style.css";
import LazyLoad from "react-lazyload";

interface compProps {
  title: string;
  description: string;
  keywords: string;
}

const AboutUs: React.FC<compProps> = ({ title, description, keywords }) => {
  const [pageInfo, setPageInfo] = useState<any>(null);
  const [about, setAbout] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();




  useEffect(() => {
    const fetchData = async () => {
      try {



        setLoading(true);

        const link = location.pathname;

        // Fetch data in parallel for efficiency
        const [pageData, termsData] = await Promise.all([
          pages(link),
          ListTermsAndConditions().TermsAndConditionsList()
        ]);

        // Extract the page from fetched page data
        const page = pageData?.[0];



        // const aboutPageDetails = localStorage.getItem("about");

        // const page = aboutPageDetails ? JSON.parse(aboutPageDetails) : pageData?.[0];

        // if (!aboutPageDetails || (JSON.stringify(pageData?.[0]) !== aboutPageDetails) || aboutPageDetails === undefined) {
        //   localStorage.setItem("about", JSON.stringify(pageData?.[0]));
        // }





        // Only update the state if page exists
        if (page) {
          setPageInfo({
            banner: page.Banner,
            title: page.Title,
            description: page.Description,
          });
        }

        // Find the about page in the terms data
        const aboutPage = termsData?.find((item: any) => item.Link === "about");

        aboutPage && setAbout(aboutPage); setLoading(false);



      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetch function on component mount

    // Scroll to top of the page immediately (not inside async)
    window.scrollTo(0, 0);

    // Cleanup logic or unmounting side effects (if needed)
    return () => {
      setLoading(false); // In case the component unmounts while fetching
    };
  }, [location.pathname]); // Dependency array to re-trigger on location change

  return (

    
    
    <div className="page-wrapper">
      
  
        
          <Helmet>
            <title>{title}</title>
            <meta name="keywords" content={keywords} />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta name="author" content="Gurram Dental Care" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>

          <section className="home-slider owl-carousel">
            <LazyLoad>
              <div
                className="slider-item bread-item"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_S3_BUCKET_FETCH}/images/${pageInfo?.banner})`                }}
                data-stellar-background-ratio="0.5"
              >

                <div className="overlay"></div>
                <div className="container" data-scrollax-parent="true">
                  <div className="row banner-text align-items-center">
                    <div className="col-md-12 col-sm-12 ftco-animate">
                      <h1 className="banner-ttl">{pageInfo?.title}</h1>
                      <div
                        className="captionName"
                        dangerouslySetInnerHTML={{ __html: pageInfo?.description }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </LazyLoad>
          </section>

          <div>
            <ul className="ullist">
              <li>
                <span
                  className="aColor"
                  onClick={() => navigate('/')}
                  style={{ cursor: 'pointer' }}
                >
                  Home  /&nbsp;
                </span>
              </li>
              <li>{pageInfo?.title}</li>
            </ul>
          </div>

    {loading ? (
        <Loader />
      ) : (
          <section className="story">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <span
                    className="blog-content"
                    dangerouslySetInnerHTML={{ __html: about?.Description }}
                  />
                </div>
              </div>
            </div>
          </section>
          )}
      
      
    </div>
    
    
  );
};

export default AboutUs;