import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { TextField, Button, Select, MenuItem } from '@mui/material';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import './listBlogs.css';
import getblogs from '../../Services/BlogServices';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import { updDelFile } from '../shared/fileupload';
import { Helmet } from 'react-helmet';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import useAuthRedirect from '../../utills/Redirect/useAuthRedirect';



interface Blogs {
    catergory: any,
    title: string,
    description: string,
    bolgImage: string
}

const ListBlogs = () => {
    const initialState: Blogs = {
        catergory: '',
        title: '',
        description: '',
        bolgImage: '',
    };

    const navigate = useNavigate();
    const [data, setData] = useState<Blogs>(initialState);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [bloglist, setBloglist] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState('PDF Viewer Modal');
    const [singleReview, setSingleReview] = useState<any>({});
    const [categories, setCategories] = useState([]);
    const logUser: any = localStorage.getItem('userData');
    const logUserObject = JSON.parse(logUser);
    // useAuthRedirect(logUserObject);


    
    useEffect(() => {
      if (!logUserObject?.accessToken) {
          navigate('/login');
      }
    }, [navigate]);
    
    const listBlogs = async () => {
        const response = await getblogs().BlogList();
        const categoryname = await getblogs().getcategory();
        const categoryIds = response?.map((item: any) => item?.catergory)
        // console.log("categoryIds", categoryIds)
        const updatedResponse = response?.map((item: any) => {
            const categoryItems = item?.catergory[0]?.split(',');
            // console.log("categoryItems", item?.catergory[0])
            const categoryNames = categoryItems?.map((cateId: string) => {
                const category = categoryname?.find((cate: any) => cate?.id === cateId);
                return category ? category?.name : cateId;
            });
            return {
                ...item,
                categoryName: categoryNames
            };
        });

        setBloglist(updatedResponse);
    };
    
   
    const catergoryname = async () => {
        const response: any = await getblogs().CategoryList();
        setCategories(response)
        const ids = response.map((category: any) => category.id);
    };

    useEffect(() => {
        listBlogs();
        catergoryname();
    }, []);

    const handleDelete = async (id: any) => {
        const confirmBox = window?.confirm("Are you sure you want to delete the selected blogs?");
        if (confirmBox) {
            const blog: any = await getblogs().getSingleBlog(id);
            console.log("blog", blog);
            const filedel = blog?.bolgImage?.length > 0 && await updDelFile(blog?.bolgImage[0], "images");
            if (filedel && filedel?.status === 200) {
                await getblogs().BlogDelete(id);
                listBlogs();
            } else {
                await getblogs().BlogDelete(id);
                listBlogs();
            }
        } else {
            alert('Deletion canceled.');
        }
    };

    const highlightText = (text: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text?.split(regex);
        return parts?.map((part, index) => (regex?.test(part) ? <span key={index} style={{ backgroundColor: '#ffcc80' }}>{part}</span> : part));
    };

    const filteredData: any = bloglist?.filter((row: any) =>
        Object?.values(row).some((value: any) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredData?.length / pageSize);
    const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize: number) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const handleSearch = () => {
        if (!searchTerm) {
            setData(initialState);
        } else {
            setData(filteredData);
        }
        setCurrentPage(1);
    }

    useEffect(() => {
        setSearchTerm('');
    }, [currentPage]);


    const handleStatusToggle = async (rowData: any)  => {
        const updatedStatus = rowData?.status === 1 ? 0 : 1;
        const updatedRowData = { ...rowData, id: rowData?.id, status: updatedStatus };
        const { categoryName, ...newObject } = updatedRowData;
        const update = await getblogs().BlogUpdate(newObject);
        setBloglist((prevRows) =>
            prevRows?.map((row) =>
                row?.id === rowData?.id ? { ...row, status: updatedStatus } : row
            )
        );
    };
    async function handleEdit(event: any) {
        navigate('/addBlogs', { state: { id: event?.id } });
    }

    const handleSubmit = (setUpdateService: boolean) => {
        navigate('/addBlogs', { state: { setUpdateService: false } });
    };
    const truncateText = (text: string, length: number = 100) => {
        if (!text) return '';
        return text.length > length ? text.substring(0, length) + '...' : text;
    };
    const removeHtmlTags = (str: any) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    return (
        <>
            <Helmet>
                <title>List Blogs</title>
                <meta name="description" content="This is the Blog page description" />
            </Helmet>
            <section className="admin-section">
                <div
                    className="slider-item bread-item"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1>
                                    Blogs
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul className="paginationbanner">
                    <li>
                        <a onClick={() => navigate('/')} className="aColor">Home  /&nbsp;</a>
                    </li>
                    <li>Blogs</li>
                </ul>
            </div>

            <div className="container">
                <div className="row tablefs">
                    <div className="col-md-12">
                        <div>
                            <div className="row ">
                                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="page-size-dropdown">
                                        <label style={{ padding: '5px' }}>Show</label>
                                        <select className="pageSize" style={{ paddingRight: "20px" }} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={20}>20</option>
                                        </select>
                                        <label style={{ padding: '5px' }}>entries</label>
                                    </div>
                                    <div className='mtsearch' style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                                        {logUserObject?.Role === "MasterAdmin" ? (
                                            <div className="pb-3">
                                                <Button
                                                    onClick={() => handleSubmit(false)} // Pass 'false' as the argument
                                                    style={{ marginRight: '10px', float: 'right' }}
                                                    className='btn btn-primary'
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        ) : null}
                                        <p style={{ marginRight: '10px' }}>Search</p>
                                        <div>
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e?.target?.value)}
                                                className="search-bar"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ overflowX: 'auto' }}>
                            <table className="table table-bordered table-striped table-hover table-responsive listBlogs">
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #ccc' }}>
                                            <th className="sno">S.No</th>
                                            <th className='mdl'>Title</th>
                                            {filteredData?.length > 0 && (
                                                <th className='mdl'>Description</th>
                                            )}
                                            <th className='mdl'>Category</th>
                                            <th className='actions'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.map((row: any, index: number) => (
                                            <tr key={row.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                <td>{highlightText(row?.title)}</td>
                                                {filteredData?.length > 0 && (

                                                    // <td style={{ padding: '10px' }} dangerouslySetInnerHTML={{ __html: row?.description }} />
                                                    <td
                                                        dangerouslySetInnerHTML={{
                                                            __html: row?.description?.substring(0, 30) + (row?.description?.length > 30 ? "..." : "")
                                                        }}
                                                    />

                                                )}
                                                <td>
                                                    {row?.categoryName?.map((catName: any, index: number) => (
                                                        <div key={index} style={{ marginRight: '5px' }}>
                                                            {catName}{index < row.categoryName.length - 1 ? ',' : ''}
                                                        </div>
                                                    ))}
                                                </td>
                                                {/* <td style={{ padding: '10px' }}>{highlightText(row?.catergory.join(', '))}</td> */}
                                                <td className='actions'>
                                                    <Button
                                                        onClick={() => handleEdit(row)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<EditIcon style={{ color: 'green' }} />}
                                                    >

                                                    </Button>
                                                   
                                                    <Button title="Status"
                                                        onClick={() => handleStatusToggle(row)}
                                                        className={row?.status === 1 ? 'statusSuccess' : 'statusDanger'}
                                                        startIcon={<VisibilityIcon />}
                                                    >
                                                    </Button>
                                                    <Button title="Delete"
                                                        onClick={() => handleDelete(row?.id)}
                                                        style={{ marginRight: '10px' }}
                                                        startIcon={<DeleteIcon style={{ color: '#af0e0e' }} />}
                                                    >

                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        
                            <div className='mttable'>Showing {filteredData.length === 0 ? 0 : ((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, filteredData.length)} of {filteredData.length} entries
                                <div className="paginationstyle mttable1">
                                    <div
                                        style={{
                                            cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
                                            marginRight: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage > 1 ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage > 1) {
                                                handlePageChange(currentPage - 1);
                                                window.scrollTo(0, 0); // Scroll to top
                                            }
                                        }}
                                        className={currentPage === 1 ? 'disabled' : ''}
                                    >
                                        Previous
                                    </div>
                                    <div className="pageNumb">{currentPage}</div>
                                    <div
                                        style={{
                                            cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
                                            marginLeft: '10px',
                                            borderBottom: '1px solid red',
                                            fontWeight: currentPage < totalPages ? 'bold' : 'normal',
                                        }}
                                        onClick={() => {
                                            if (currentPage < totalPages) {
                                                handlePageChange(currentPage + 1);
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                        className={currentPage === totalPages ? 'disabled' : ''}
                                    >
                                        Next
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div >

        </>

    );
};

export default ListBlogs;


